@use 'global/icons';
@use 'global/vars';
@use "sass:math";
@import '../../scss/shell';

$golden-ratio : 1.618;

:global {
	.id-uploader-block {
		padding: 0 1rem;
		text-align: center;
		font-size: 1.4rem;
		margin-bottom: _rem(_dim('global-gutter'));

		@include _on('mobile') {
			margin-top: -0.5rem;
			padding: 0;
		}

		&__item {
			border: 0.2rem dashed var(--id-uploader--border);
			border-radius: 1rem;
			height: 22rem;

			@include _on('mobile') {
				border: 0.2rem dashed var(--id-uploader--border);
				height: 14rem;
				overflow: hidden;
			}
		}

		&__sample {
			padding: 20px!important;
		}

		&__mobile-sample {
			padding: 6rem 2rem!important;
			position: fixed;
			z-index: 100;
			top: 0!important;
			left: 0!important;
			height: 100%;
			width: 100%;
			max-width: 100% !important;

			.icon-gallery-close-x {
				position: absolute;
				top: vars.spacer(2);
				right: vars.spacer(2);
				padding: vars.spacer(2);
			}

			img {
				max-height: 50vh;

				@include _on('mobile', false, 'landscape') {
					width: auto;
					float: left;
					margin-right: 2rem;
				}

				@include _on('mobile', false, 'portrait') {
					width: 100%;
				}
			}
		}

		&__title {
            display: flex;
            align-items: center;
            justify-content: center;

			@include _on('mobile') {
				left: -1rem;
				position: relative;
			}

			i {
				margin-left: 0.4rem;
				text-align: left;
				font-size: 2.4rem;
				position: relative;

				&.icon-check-circular-solid {
					color: var(--icon--ok--green);
				}

				&.icon-info-circular-outline {
					color: var(--form--info-icon);
					&:hover {
						color: var(--form--info-icon-hover);

                        &::before {
                            content: icons.get('info-circular-solid');
                        }
					}
				}

				&.icon-alert {
					color: var(--icon--alert--yellow);
					font-size: 2.4rem;

					.protip-container {
						z-index: 2;
					}
				}

                @include _on('mobile') {
					z-index: 1;
				}
			}

			li {
				margin-top: 0.5rem;
				line-height: 1.5;
				text-align: left;

				&:first-child {
					margin-top: 1rem;
					margin-bottom: 1rem;
				}
			}
		}
		&__uploaded {
			img {
				max-width: 80%;
				max-height: 9.5rem;
				display: block;
				margin: 1rem auto 0;
			}

			i {
				color: var(--id-uploader--icon);
			}
		}

		.uploader5 {
			margin-top: 1rem;

			// Absolute positioned file input relative to dropzone element on mobile
			@include _on('mobile') {
				position: inherit;
			}

			&__container {
				margin-top: 0;
				line-height: 1.5;
				width: 100%;

				span {
					display: inline-block;
					vertical-align: top;
				}
			}

			&__info {
				display: none;
			}

			&__details {
				width: 100%;
				font-size: 1rem;
				position: absolute;
				left: 0;
				margin-top: 3.5rem;
			}

			&__progress {
				width: 100%;

				&-bar {
					width : 80%;
				}
			}

            &__analyzing {
                color: var(--text--secondary);

                .icon-clock {
                    margin-right: 0.6rem;
                }
			}

			&__button {
				// Absolute positioned file input relative to dropzone element on mobile
				@include _on('mobile') {
					position: inherit;
				}

				a {
					display: inline-block;

					// Absolute positioned file input relative to dropzone element on mobile
					@include _on('mobile') {
						position: inherit;
					}
				}
			}

			&__dropzone {
				position: relative;
				background-color: var(--id-uploader--dropzone-background);

				&.uploader5--is-drag-over {
					background-color: var(--id-uploader--dropzone-background-lighten);
				}
			}

			@at-root :global {
				.uploader5--is-dragging &__dropzone {
					box-shadow: 0 0 20px var(--id-uploader--shadow) inset;
				}
			}
		}

        .js-remove .remove-msg {
            margin-left: 1.8rem;
        }
	}

	// Variants

	.id-uploader-block {
		// Simplified registration flow for Amateur Category
		// @link MSC-3479

		:global(.step-simplified-owner-details) & {
			display: flex;
			flex-direction: column;
			padding: 0;

			&__item {
				position: relative;
				border-width: .2rem;
				border-radius: .4rem;
				padding-top: percentage(math.div(1, $golden-ratio));
			}

			&__item-wrapper {
				display: flex;
				position: absolute;
				left: 0;
				right: 0;
				top: 0;
				bottom: 0;
				flex-direction: column;
				justify-content: center;
				font-size: 1.2rem;
				padding: 2rem;
			}

			&__uploader {
				min-height: 5rem;
				margin-top: 1rem;
			}

			.uploader5 {
				margin-top: 0;
			}

			.uploader5__details {
				position: static;
				margin-top: 1rem;
			}

			// Variants

			&__step--idPage1 {
				order: 2;
			}

			&__step--idPage2 {
				order: 3;
			}

			&__step--faceAndId {
				order: 1;
			}

			// Screen adaptivity

			@include _on('small', 'up') {
				flex-direction: row;
				padding: 0 1rem;
			}
		}
	}
}

li.point {
	position: relative;
	list-style: none;
	padding-left: 18px;

	&::before {
		position: absolute;
		content: "\2022";
		color: var(--text--link);
		left: 0;
		font-size: 1.4em;
	}
}


ol {
	list-style: decimal outside none;

	li {
		margin: 0 0 0 20px;
	}
}

.tooltipList {
	margin: 10px 0 10px 0!important;
	display: inline-block!important;
	font-size: 11px;
	text-align: left!important;
	width: auto!important;
}
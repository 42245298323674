@use 'global/icons';
@use 'global/colors';
@use 'global/typography';
@use 'global/vars';
@import '../../../scss/shell';

:global {

	.mediaBox.photo,
	.mediaBox.video {
		position: relative;

		.boxInner {
			border-color: var(--channel--disabled--inner-border);
		}

		&:hover,
		&.hover,
		&.focus {
			.boxInner {
				border-radius: vars.border-radius(1) !important;
			}
			.title {
				border-color: transparent;
				background: inherit;

				i {
					display: none;
				}
			}
		}
	}

	.mediaBox {
		position: relative;

		.boxInner .whiteLoader {
			position: absolute;
			left: 50%;
			top: 50%;
			margin: -15px 0 0 -15px;
		}

		.boxInnerIconContent {
			transform: translateY(-50%);
			top: 50%;
			width: 100%;
			left: 0;
			position: absolute;
		}

		&.rotating {
			pointer-events: none;

			.boxInner .whiteLoader {
				display: block;
				z-index: 10;
			}

			.boxInner .controls-icon {
				display: none;
			}
		}

		&.disabled .controls .mediaItemClickBox {
			cursor: auto;
		}

		&.promo-enabled-active {
			.boxInner {
				position: relative;

				&:after {
					@include _horizontal-gradient(
									var(--channel--box--promo-ribbon-bg-from),
									var(--channel--box--promo-ribbon-bg-to)
					);

					content: 'promo';
					position: absolute;
					top: 5px;
					right: -20px;
					transform: rotate(45deg);
					padding: .5rem 2rem;
					text-transform: uppercase;
					font-weight: 600;
					color: var(--channel--box--promo-ribbon-text);
					z-index: 2;
				}
			}
		}

		.boxInner {
			position: relative;
			width: 100%;
			border: 2px solid var(--channel--box--inner-border);
			border-radius: vars.border-radius(1);
			padding: vars.spacer(1);
			overflow: hidden;

			.controls .protip i {
				position: relative;
				bottom: 0;
				left: 0;
				padding: vars.spacer(2) vars.spacer(2) vars.spacer(1) vars.spacer(1);
			}

			.whiteLoader {
				display: none;
			}

			// TODO: we should use a common class for these
			// TODO: we should use flexbox but it has many impacts on child photos = longer refact
			.box-inner {
				&-note-add,
				&-create-album,
				&-album,
				&-photo,
				&-photo-loading,
				&-photo-converting,
				&-video-converting,
				&-video-loading,
				&-note-waiting,
				&-note-rejected,
				&-note {
					display: table;
					table-layout: fixed;
					width: 100%;
					border-radius: vars.border-radius(1);

					> div:not(.controls) {
						display: table-cell;
						vertical-align: middle;
					}
				}
			}

			> div {
				width: 100%;
				overflow: hidden;

				> div {
					width: 100%;
					vertical-align: middle;
					position: relative;
					background: var(--channel--box--inner-bg);
					text-align: center;
				}
			}

			img {
				max-width: 100%;
				max-height: 100%;
				display: block;
			}
		}

		.price {
			position: absolute;
			left: 1rem;
			top: 1rem;
			z-index: 6;

			span {
				background: RGBA(var(--channel--price-bg-rgb), 0.7);
				padding: 0.3rem 1rem;
				border-radius: vars.border-radius(1);
				color: var(--channel--price) !important;
				font-size: 1.2rem;
				margin: 0 !important;
				font-weight: normal !important;
				display: inline-block;
			}

			> i {
				font-size: 1.2rem;
				margin-right: 0.2rem;
				color: var(--channel--price);
			}
		}

		.dropdown-list {
			position: absolute;
			top: 0.3rem;
			right: 0;
			width: auto;
			background: var(--channel--dropdown--bg);
			z-index: 12;
			border-radius: vars.border-radius(1);
			padding: 1rem 0;

			a {
				@include typography.body2Regular;
				color: var(--channel--dropdown--link);
				display: block;
				padding: vars.spacer(4) vars.spacer(4) vars.spacer(4) vars.spacer(11);
				position: relative;

				&:hover {
					background: var(--channel--dropdown--bg-hover);
					color: var(--channel--dropdown--link-hover);

					.icon-promotion-outline::before {
						content: icons.get('promotion-solid');
					}

					.icon-credits-outline::before {
						content: icons.get('credits-solid');
					}

					.icon-trash-outline::before {
						content: icons.get('trash-solid');
					}

					.icon-comments-on-outline::before {
						content: icons.get('comments-on-solid');
					}

					.icon-edit-outline::before {
						content: icons.get('edit-solid');
					}
				}
			}

			i {
				position: absolute;
				top: vars.spacer(3);
				left: 1rem;
				font-size: 2.4rem;
				width: 2.8rem;
				text-align: center;
			}

			&:after {
				@include css-triangle(0.5rem, var(--channel--dropdown--bg), bottom);

				position: absolute;
				top: -1rem;
				right: 1.8rem;
			}
		}

		.boxInner {
			i.icon-info-circle {
				position: absolute;
				display: block;
				z-index: 10;
				font-size: 24px !important;
				padding: vars.spacer(2);
				right: auto;
				top: auto;
				left: 0;
				bottom: 0;
				cursor: pointer;
				color: var(--channel--box--icon-active);

				&:hover::before {
					content: icons.get('info-circular-solid');
					color: #{colors.get(neutral, 100)};
				}

				&:active {
					color: var(--channel--box--icon-active);
				}
			}
		}

		//Photo box modifiers
		&.photo,
		&.video {
			.boxInner > div > div {
				height: 144px;
				overflow: hidden;

				img {
					transform: translate(-50%, -50%);
					top: 50%;
					left: 50%;
					position: absolute;
				}
			}
		}

		.boxInner {
			> div > div ph-row {
				overflow: hidden;
				height: 100%;

				> ph-col {
					position: relative;
					height: 100%;
					overflow: hidden;

					img {
						max-height: none;
						max-width: none;
					}
				}
			}

			.controls {
				top: 0;
				transform: none;
				position: absolute;
				height: 100%;
				background: transparent;

				.controls-icon {
					font-size: 2.4rem;
					display: block;
					position: absolute;
					color: var(--channel--box--icon-active);
					bottom: 0;
					z-index: 2;

					&.controls-action-menu {
						right: 0;
						bottom: 0;
						padding: vars.spacer(1);
					}

					&.controls-rotate {
						left: 0;
						bottom: 0;
						padding: vars.spacer(1);
					}

					&:hover,
					&[triggered]{
						opacity: 1;
						color: var(--channel--box--icon-active);

						&::before {
							background: none;
						}
					}

					&[triggered] i {
						color: var(--channel--box--icon-active);
					}

					i.icon-info-circle {
						color: var(--channel--box--icon-active);
					}
				}
			}
		}

		&.photo.failed .boxInner,
		&.video.failed .boxInner {
			i.icon-info-circle {
				padding: vars.spacer(1);
				left: 0;
				bottom: 0;
				z-index: 12;
			}
		}

		.oneImg img,
		.twoImg img,
		.threeImg img,
		.fourImg img {
			transform: translate(-50%, -50%);
			height: 180%;
			position: absolute;
			left: 50%;
			top: 50%;
			-webkit-backface-visibility: hidden;
		}

		.threeImg ph-row {
			height: 50% !important;

			&:first-child img {
				height: auto !important;
				width: 100% !important;
			}
		}

		.fourImg ph-row {
			height: 50% !important;
		}

		&.note {
			&.failed,
			&.rejected,
			&.waiting {
				.error {
					min-height: 263px;
				}
			}
			// dark overlay while hovering a note

			&:hover {
				.boxInner {
					&:before {
						background: linear-gradient(to top, var(--channel--note--overlay-bg), transparent);
					}
				}
			}

			// but not while editing a note hide the overlay
			&.editor {
				&:hover {
					.boxInner {
						&:before {
							display: none;
						}
					}
				}
			}

			.boxInner {
				border-radius: vars.border-radius(1) !important;

				> div > div {
					height: 263px;
				}
			}
			textarea {
				height: 263px;
				padding: 30px 19px !important;

				@include _on('mobile') {
					padding: 1.5rem 1.9rem!important;
				}
			}
			.noteText {
				// Reset textarea
				border: 0 !important;
				margin: 0;
				border-radius: 0;
				background: transparent;
				box-shadow: none;
				outline: 0;
				overflow: hidden;

				// Non-reset
				width: 100%;
				text-align: center;
				padding: 0 20px;
				line-height: 100%;
				color: var(--text--secondary);
				word-wrap: break-word;
				cursor: pointer;

				&::-webkit-input-placeholder,
				&::-moz-placeholder,
				&:-ms-input-placeholder {
					font-size: 4.6rem;
					line-height: 1.1;
				}

				@include _on('mobile') {
					&::-webkit-input-placeholder,
					&::-moz-placeholder,
					&:-ms-input-placeholder {
						font-size: 2.2rem;
						line-height: 1.2;
					}
				}
			}

			.folder_gradient {
				background: transparent;
			}

			.folder_overlay {
				font-size: 18px;
				line-height: 80px;
				width: 259px;
				height: 263px;
			}

			.info {
				position: absolute;
				bottom: 0;
				left: 0;
				width: 100%;
				height: 28px;
				font-size: 11px;
				color: var(--channel--note-info-text);
				padding: 0 20px;

				@include _on('mobile') {
					bottom: -1rem;
					padding: 0 1rem;
				}

				.winterCounter {
					float: left;
				}

				.postButton {
					float: right;
					color: var(--text--link);
					cursor: pointer;

					&:hover {
						color: var(--text--link-hover);
					}
				}
			}

			.message {
				.button.delete_confirm {
					margin: 2rem 0;
				}
			}

			.actionBlock .boxInner > div > div i {
				font-size: 56px;
			}

			@include _on('mobile') {
				textarea,
				.boxInner > div > div {
					height: 14.2rem;
				}
				&.failed,
				&.rejected,
				&.waiting {
					.error {
						min-height: 14.2rem;
					}
				}
			}
		}

		&.video {
			margin: 5px 0;

			> div > div > div {
				height: 106px;
			}

			.boxInnerIconContent .add_media_item_title {
				margin: 0;
			}

			.folder_overlay {
				@include _flexbox();
				@include _flexbox-justify-content(center);
				@include _flexbox-align(center);
			}

			&.promo-enabled-active {
				.controls-icon-promo:hover {
					.icon-promo-circle {
						display: none;
					}

					.icon-delete-promo-circle {
						display: block;
					}
				}
			}

			&.promo-enabled-inactive {
				.controls-icon-promo:hover {
					.icon-promo-circle {
						display: none;
					}
					.icon-add-promo-circle {
						display: block;
					}
				}
			}

			.promo-response-message {
				display: block;
				position: absolute;
				top: 50%;
				left: 0;
				right: 0;
				width: auto;
				padding: 0 2rem;
				margin: 0 1rem;
				text-align: center;
				font-size: 1.2rem;
				transform: translateY(-50%);
				z-index: 10;
				font-weight: 600;

				i {
					display: block;
					margin-bottom: 1.2rem;
				}

				&.promo-response-message-rejected {
					bottom: 2rem;
					top: auto;
					transform: none;
					text-align: left;
					padding: 0 1rem;

					i {
						color: var(--icon--alert--yellow);
						display: inline;
						margin-right: 0.5rem;
						margin-bottom: 0;
					}
				}
			}

			.controls-icon.controls-icon-play {
				left: 50%;
				top: 50%;
				bottom: unset;
				transform: translate(-50%, -50%);
				font-size: 3.2rem;
				color: var(--channel--box--icon-active) !important;
			}
		}

		&.folderBox {
			position: relative;
			margin-bottom: 25px;

			&.video {
				margin: 0;
			}

			.boxInner {
				overflow: visible;
				display: block;

				> div > div {
					overflow: visible;
				}
			}
		}

		.folder_gradient {
			@include _vertical-gradient(RGBA(var(--channel--folder-bg-rgb), 0), RGBA(var(--channel--folder-bg-rgb), 0.4));

			position: absolute;
			bottom: 0;
			left: 0;
			width: 100%;
			height: 50%;

			@at-root :global .ext-ie11 .myContent .photo .folder_gradient {
				top: 105px;
				bottom: auto;
			}
		}

		.folder_overlay {
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			background: RGBA(var(--channel--overlay-bg-rgb), 0.9);
			font-weight: bold;
			color: var(--channel--folder-overlay-text);
			font-size: 12px;

			.inner {
				position: absolute;
				width: 100%;
				top: 50%;
				transform: translateY(-50%);
				left: 0;
				font-size: 1.4rem;
			}

			i {
				display: block;
				font-size: 2.4rem;
				margin-bottom: vars.spacer(1);
				vertical-align: middle;
			}
		}

		.controls {
			display: block;
			position: absolute;
			width: 100%;
			z-index: 5;
			top: 50%;
			left: 0;
			transform: translateY(-50%);

			@include _on('mobile') {
				left: 100%;
				transition: 0s left;
			}

			a,
			span {
				* {
					display: none;
				}
				.icon-alert {
					display: inline;
				}
			}

			.albumClickBox,
			.mediaItemClickBox {
				position: absolute;
				left: -100px;
				bottom: -100px;
				width: 600px;
				height: 600px;
				z-index: 1;
				cursor: pointer;
				background: transparent;
				opacity: 0;

				@include _on('mobile') {
					height: 0;
					transition: 0s height;
				}
			}

			.icon-trash:hover::before {
				content: icons.get('trash-solid');
			}

			.icon-editor:hover::before {
				content: icons.get('menu-more-vertical-solid');
			}

			.icon-rotate:hover::before {
				content: icons.get('rotate-solid');
			}

			span {
				position: absolute;
				bottom: 0;
				left: 0;
				margin: 0 5px;
				padding: 5px;
				font-size: 11px;
				font-weight: bold;
				color: var(--channel--box--control-text);

				i {
					font-size: 12px;
					margin-right: 3px;
				}

				.icon-folder-solid {
					transform: translateY(0.2rem);
				}

				.icon-alert {
					display: none;
				}

				&.rejectedFolder {
					.icon-folder {
						display: none;
					}

					.icon-alert {
						display: inline;
						position: relative;
						top: vars.spacer(0.5);
						z-index: 2;
						color: var(--channel--box--icon-alert);
					}
				}
			}
		}

		&:hover,
		[triggered] {
			.controls {
				a,
				span {
					* {
						display: inline-block;
					}
				}
			}

			&:not(.editor) .noteText {
				opacity: 0.3;
			}

			.albumClickBox,
			.mediaItemClickBox {
				height: 600px;
				transition-delay: 0.1s;
			}

			.controls {
				left: 0;
				transition-delay: 0.1s;
			}
		}

		&.focus {
			.title {
				display: flex;
			}

			.saveButton {
				display: inline-block;
				font-size: 1.2rem;
				color: var(--text--link);
				padding: 0 0 0 1rem;
				cursor: pointer;

				&:hover {
					color: var(--text--link-hover);
				}
			}
		}

		.title {
			width: 100%;
			padding: 0 8px;
			position: relative;
			line-height: 38px;
			color: var(--text--secondary);
			height: 38px;
			overflow: hidden;
			text-overflow: ellipsis;
			border-radius: 0 0 0.4rem 0.4rem;
			border: 2px solid transparent;
			border-top: 0;

			input.title_input {
				// Reset input
				@include typography.body2Regular;
				padding       : 0;
				border        : 0 !important;
				border-radius : 0;
				background    : transparent;
				box-shadow    : none;
				outline       : 0;
				width         : 100%;
				position      : relative;
				z-index       : 2;
				color         : var(--channel--box--input-text);
				height        : 38px;
				text-overflow : ellipsis;

				&::placeholder {
					color : var(--channel--box--input-placeholder-text);
				}
			}

			i {
				transform : translate(0, -50%);

				display   : none;
				position  : absolute;
				z-index   : 3;
				right     : 8px;
				top       : 50%;
				font-size : 12px;
				color     : var(--text--secondary);

				&:before {
					position : relative;
					z-index  : 2;
				}

				&:after {
					@include _horizontal-gradient(
									RGBA(var(--channel--box--bg-gr-rgb), 0),
									var(--channel--box--bg-gr)
					);

					content  : "";
					display  : block;
					width    : 24px;
					height   : 40px;
					top      : -3px;
					right    : 0;
					position : absolute;
				}
			}

			span {
				font-size: 12px;
				font-weight: bold;
				color: var(--channel--box--text2);
				opacity: 0.6;

				&:empty::after {
					content: attr(data-default);
					display: inline;
				}
			}
		}

		&:hover,
		&.hover,
		&.focus,
		[triggered] {
			&[triggered],
			.boxInner {
				&:before {
					content: '';
					position: absolute;
					top: vars.spacer(1);
					bottom: vars.spacer(1);
					right: vars.spacer(1);
					left: vars.spacer(1);
					border-radius: vars.border-radius(1);
					background: linear-gradient(to top, var(--channel--overlay-bg), transparent);
					z-index: 2;
					opacity: 0.8;
				}
			}
			.title span {
				opacity: 1;
			}

			.title {
				border-color : var(--channel--box--inner-bg-hover);
				background   : var(--channel--box--hover-title-bg);
				height       : 38px;

				i {
					display : inline-block;
				}
			}
		}

		&.rejected,
		&.waiting,
		&.failed,
		&.converting {
			.boxInner::before {
				background: none;
			}
		}

		&.actionBlock,
		&.dummyActionBlock {
			cursor: default;

			&[data-action] {
				cursor: pointer;
			}

			.boxInner > div > div {
				background: var(--channel--box--inner-bg);
				text-align: center;

				& > i,
				& div > div > i {
					font-size: 4rem;
					bottom: -1.8rem;

					&.icon-info-circle {
						padding: vars.spacer(2) vars.spacer(2) vars.spacer(1) vars.spacer(1);
						color: var(--text--secondary);
					}
				}

				span {
					display: inline-block;
					color: var(--channel--box--action-text);
					margin-top: 4px;
					font-size: 1.4rem;
					font-weight: 500;
				}
			}

			.boxInner > div > div  {
				color: var(--channel--box--action-text);
			}

			&.inactive {
				cursor: default;
				position: relative;

				&:before {
					content: '';
					position: absolute;
					width: 100%;
					height: 100%;
					left: 0;
					top: 0;
					background: var(--channel--box--inactive-mask);
					z-index: 1;
					opacity: 0.5;
				}
			}
		}

		.message {
			@include _flexbox();
			@include _flexbox-direction(column);
			@include _flexbox-align(center);
			@include _flexbox-justify-content(center);

			position: absolute;
			left: 0;
			top: 0;
			bottom: 0;
			right: 0;
			text-align: center;
			background: RGBA(var(--channel--message-bg-rgb), 0.7);
			z-index: 13;
			line-height: 1.2;
			padding: 1.5rem;

			.messageTitle {
				color: var(--text--base);
				font-size: 1.3rem;
				margin: 0.5rem;
				display: block;
			}

			.button {
				display: inline-block;
				margin: 0 0 0.7rem;
			}

			.cancel {
				cursor: pointer;
				font-size: 13px;
				display: block;
			}
		}

		&.failed .boxInner i.icon-info-circle {
			padding: 0.5rem;
			bottom: 0;
		}

		&.failed,
		&.rejected,
		&.waiting,
		&.converting {
			.folder_gradient {
				position: absolute;
				bottom: 0;
				left: 0;
				width: 0;
				height: 0;
				background: transparent;
			}

			.controls {
				background: transparent;
				z-index: 11;

				a {
					display: inline-block;
				}
			}

			.boxInner {
				text-align: center;
				border-radius: vars.border-radius(1) !important;
			}

			.error {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				color: var(--channel--overlay-text);
				background: RGBA(var(--channel--overlay-bg-rgb), 0.9) !important;
				text-align: center;
				z-index: 10;
				border-radius: vars.border-radius(1);

				.inner {
					position: absolute;
					width: 100%;
					top: 50%;
					transform: translateY(-50%);
					left: 0;
					font-size: 1.4rem;
				}

				span {
					font-weight: 500;
					line-height: 2rem;
					display: inline-block;
				}

				i {
					display: block;
					font-size: 2.4rem;
					margin-bottom: vars.spacer(1);
					vertical-align: middle;
					color: var(--channel--box--inner-error-color);
				}
			}

			.title {
				border-color: transparent;
				background: transparent;
				text-align: left;
			}
		}

		&.video.waiting:hover,
		&.video.waiting.focus,
		&.photo.waiting.focus,
		&.photo.waiting:hover {
			.boxInner {
				border-color               : var(--channel--box--inner-border);
				border-bottom-left-radius  : 0 !important;
				border-bottom-right-radius : 0 !important;

				> div > div {
					background : var(--channel--box--inner-bg-hover) !important;
				}
			}
			.title {
				border-color : var(--channel--box--inner-border);
				background   : var(--channel--box--inner-bg);
				height       : 38px;

				i {
					display : inline-block;
				}
			}
		}

		&.converting,
		&.loading {
			cursor: default;
			color: var(--text--secondary);

			.boxInner > div > div {
				i {
					font-size: 46px;
					color: var(--channel--box--icon);
				}

				span {
					display: inline-block;
					margin-top: 15px;
					font-size: 12px;
					font-weight: bold;
				}

				* {
					position: relative;
					z-index: 2;
				}

				&:before {
					content: ' ';
					position: absolute;
					display: block;
					left: 0;
					top: 0;
					width: 100%;
					height: 100%;
					background: var(--channel--box--inner-bg);
					z-index: 1;
					opacity: 0.5;
				}
			}
		}
	}

	#pageContainer.myContent {
		padding: 0 40px 40px 40px;

		> *:first-child {
			margin: 0 -40px;
		}

		.ph-box__header > * {
			padding: 0 2rem;
		}

		form.price.ph-form {
			position: relative;
			z-index: 15;
		}

		.commonTabs {
			width: 100%;
			margin: 0;
			text-align: center;
			white-space: normal;
			display: flex;
			justify-content: center;
			border-bottom: 0.1rem solid var(--common-tabs--link-border);

			.tab {
				position: relative;
				width: auto;
				min-width: 16rem;

				a {
					display: flex;
					padding: 0 0.5rem 0.1rem;
					justify-content: center;
					align-items: center;
					background: none;
					border-right: none;
					border-radius: 0;

					&:hover {
						color: var(--common-tabs--link);
						font-weight: bold;
					}

					i {
						padding: 0;
						margin: 0 0 0 0.6rem;
						white-space: initial;
						max-width: 100%;
						font-size: 2rem;
						color: var(--channel--box--icon-alert);
						position: relative;
						top: -(vars.spacer(0.5));
					}
				}

				&.active {
					cursor: default;

					a {
						color: var(--common-tabs--link-active-secondary);
						padding-bottom: 0;
						background: none;
					}

					&::after {
						content: '';
						position: absolute;
						left: 0;
						bottom: -0.2rem;
						width: 100%;
						height: 0.2rem;
						background-color: var(--common-tabs--link-active-border);
						z-index: 10;
					}
				}
			}
		}

		> .banner {
			position: relative;
			left: -40px;
		}

		.error_message {
			margin: 40px 0 0 0;
			line-height: 40px;
		}

		.darkerHeader {
			width: calc(100% + 8rem);
			background: var(--channel--editor-header);
			margin: 0 -4rem 2.5rem;
			padding: 0;
			box-sizing: border-box;
		}

		.commonTabsContent {
			opacity: 0;
			overflow: hidden;
			height: 0;
			width: 0;
			padding: 0;
			margin: 0;

			&.active {
				opacity: 1;
				overflow: visible;
				height: auto;
				width: auto;
				padding: auto;
				margin: auto;
			}

			&.uploading .uploading_tooltip {
				display: block;
				z-index: 5;
				width: 100%;
				height: 100%;
			}

			.icon-arrow-left,
			.icon-arrow-right {
				font-size: 2.4rem;
			}

			.icon-arrow-left:hover::before {
				content: icons.get('arrow-left-solid');
			}

			.icon-arrow-right:hover::before {
				content: icons.get('arrow-right-solid');
			}

			.fullAlbum .full_album_tooltip {
				display: block;
				z-index: 5;
				width: 100%;
				height: 100%;
			}

			.actionBlock {
				position: relative;
			}

			.uploading_tooltip,
			.full_album_tooltip {
				display: none;
				position: absolute;
				width: 0;
				height: 0;
				overflow: hidden;
				left: 0;
				top: 0;
			}
		}

		.channel_interface {
			display: flex;
			align-items: flex-start;
			padding: 2rem 4rem;

			.button {
				margin-right: 1rem;
				padding: 0.7rem 1.8rem;
			}

			#channelPriceSettings + .editorButton {
				margin-right: 20px;
			}

			.subscribed_members {
				float: right;
			}

			p {
				flex: 1;
				padding-top: 0.1rem;
				font-size: 1.2rem;
				line-height: 1.3;
				color: var(--text--secondary);
			}

			i {
				position: relative;
				margin: 0 0.4rem 0 0;
				font-size: 2rem;
				vertical-align: middle;
				display: inline-block;
				top: -0.1rem;
				transform: none;
			}
		}

		.showMoreWrapper {
			text-align: center;
			margin-bottom: 2rem;
		}

		#showMoreHolder {
			margin-top: 15px;
		}

		#channelProgressBarContainer {
			padding: 4rem 4rem 0;
		}

		// Top progressbar of channel creation
		#channelCreationProgressTitle {
			color: var(--channel--listpage-text);
			font-size: 14px;
			width: 100%;
			text-align: center;
		}

		#channelCreationProgress {
			margin: 15px 0 40px 0;
			height: 8px;
			background: var(--channel--creation-progress-bg);
			border-radius: 20px;

			> div {
				height: 100%;
				float: left;
				transition: all 1s ease;
				-webkit-transition: all 1s ease;
				-moz-transition: all 1s ease;
				-o-transition: all 1s ease;

				&:last-child > span {
					border-radius: 0 20px 20px 0;
				}

				&:last-child > span > span {
					box-shadow: 0 1px 2px var(--channel--creation-progress-bs);
				}

				> span {
					width: 100%;
					height: 100%;
					display: block;
					position: relative;
					border-radius: 20px 0 0 20px;

					> span {
						@include _vertical-gradient(
								var(--channel--creation-progress-bar-gr-done),
								var(--channel--creation-progress-bar-gr-done-darken)
						);

						position: absolute;
						top: 50%;
						right: -3px;
						margin: -10px 0 0 0;
						display: block;
						width: 20px;
						height: 20px;
						border-radius: 50%;
						z-index: 2;
						color: var(--channel--creation-progress-text);
						box-shadow: 0 1px 2px var(--channel--creation-progress-bs);
						text-align: center;

						i {
							display: inline-block;
							margin: 3px 0 0 0;
							font-size: 14px;
						}

						em {
							position: absolute;
							display: inline-block;
							width: 100px;
							left: 50%;
							top: 100%;
							margin: 7px 0 0 -50px;
							text-align: center;
							font-size: 12px;
						}
					}
				}

				&.green > span,
				&.green > span > span {
					@include _vertical-gradient(
							var(--channel--creation-progress-bar-gr-done),
							var(--channel--creation-progress-bar-gr-done-darken)
					);

					i {
						margin: 4px 0 0 0;
						font-size: 11px;
					}

					em {
						color: var(--common--success);
					}
				}

				&.yellow > span,
				&.yellow > span > span {
					@include _vertical-gradient(
							var(--channel--creation-progress-bar-gr),
							var(--channel--creation-progress-bar-gr-darken)
					);

					em {
						color: var(--text--link);
					}
				}
			}
		}

		#channel2 {
			margin-right: 40px; // DEV FIX
		}

		.channelFolderBrowser {

			.hidden {
				height: 0;
			}

			[data-font-size="1"],
			.note_content_size_1 {
				font-size: 46px;
			}

			[data-font-size="2"],
			.note_content_size_2 {
				font-size: 46px;
			}

			[data-font-size="3"],
			.note_content_size_3 {
				font-size: 34px;
			}

			[data-font-size="4"],
			.note_content_size_4 {
				font-size: 28px;
			}

			[data-font-size="5"],
			.note_content_size_5 {
				font-size: 24px;
			}

			[data-font-size="6"],
			.note_content_size_6 {
				font-size: 20px;
			}

			@include _on('mobile') {
				[data-font-size="1"],
				.note_content_size_1 {
					font-size: 2.2rem;
				}

				[data-font-size="2"],
				.note_content_size_2 {
					font-size: 2rem;
				}

				[data-font-size="3"],
				.note_content_size_3 {
					font-size: 1.8rem;
				}

				[data-font-size="4"],
				.note_content_size_4 {
					font-size: 1.6rem;
				}

				[data-font-size="5"],
				.note_content_size_5 {
					font-size: 1.4rem;
				}

				[data-font-size="6"],
				.note_content_size_6 {
					font-size: 1.3rem;
				}
			}

			.album_requirements {
				position: relative;
				display: none;

				&.active {
					display: block;
				}
			}

			.contentInfo {
				ul {
					list-style-type: none;
					border: 2px solid var(--channel--box--inner-bg);
					border-radius: 1rem;
					padding: 20px 20px 20px 43px;
					color: var(--channel--listpage-text);

					li {
						position: relative;
						font-size: 14px;
						line-height: 25px;

						&:before {
							content: '';
							position: absolute;
							width: 8px;
							height: 8px;
							top: 8px;
							left: -20px;
							background: var(--channel--box--icon);
							border-radius: 50%;
						}
					}
				}

				&.album,
				&.photo {
					ul {
						height: auto;
					}
				}
			}

			.commonProgressBar {
				width: 80%;
				display: inline-block;
				position: relative;

				.progressTitle {
					position: absolute;
					width: 100%;
					left: 0;
					bottom: -20px;
					font-weight: bold;
					font-size: 12px;
				}
			}

			.uploaderBox {
				height: 100%;

				.boxInnerIconContent {
					margin-top: -0.5rem;
				}

				.uploader5 {
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					margin: 0;
					z-index: 2;

					&__container {
						margin: 0;
						width: 100%;
						height: 100%;

						> * {
							display: block;
							width: 100%;
							height: 100%;
							margin: 0;
							padding: 0;
						}

					}

					&__button a {
						min-width: 0;
						width: 100%;
						height: 100%;
						background: none;
						box-shadow: none;

						@include _on('mobile') {
							height: 75%;
						}

						i {
							display: none
						}
					}
				}

			}

			.boxInnerIconContent {
				transform: translateY(-50%);

				top: 50%;
				width: 100%;
				left: 0;
				position: absolute;
			}
		}

		.protip_container.channelMediaInfoTooltip {
			ul {
				list-style-type: disc;
				line-height: 20px;
				padding-left: 16px;
			}

			> span {
				bottom: -10px;
				left: 37px;
			}
		}

		/* Channel loader */
		.loader_container {
			margin: auto;
			display: none;
			height: 60px;
			width: 60px;
			transition: all 0.4s ease;
			-webkit-transition: all 0.4s ease;
			-moz-transition: all 0.4s ease;
			-o-transition: all 0.4s ease;
			z-index: 50;
			border-radius: 100px;

			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;

			&.show {
				display: block;
			}

			.black_loader {
				@include animation(channelLoaderRotate 1s infinite linear);

				background: url('./channel_overlay_loader.png') no-repeat !important;
				position: absolute;
				margin: auto;
				display: block;
				height: 60px;
				width: 60px;
				z-index: 1;
			}

			.black_loader_frame {
				background: url('./channel_overlay_letter.png') no-repeat !important;
				position: absolute;
				margin: auto;
				display: block;
				height: 24px;
				top: 17px;
				left: 20px;
				width: 14px;
				z-index: 1;
			}

			.loading_percent {
				position: relative;
				top: 25px;
				font-size: 17px;
				font-family: 'open_sanslight';
				color: var(--text--base);
				display: inline-block;
			}
		}

		/** LANDING PAGE CONTENT*/
		.channelPage {
			.page-row {
				margin: 0;
			}

			.banner {
				display: block;
			}
		}

		#channelContent {
			text-align: center;
			width: 100%;
			float: left;

			h1 {
				margin: 35px 0 0;
				font-weight: bold;
				font-size: 36px;
				line-height: 40px;
				color: var(--text--base);

				&.inactive {
					margin: 128px 0 30px;
				}
			}

			h1 + .regularTextNormal {
				text-align: center;
				font-size: 18px;
				line-height: 20px;
				margin: 0 auto;
				width: 620px;
			}
		}

		/** TOOLTIP HELPERS */
		.invitationTooltipHelper,
		.movieTooltipHelper {
			p {
				font-weight: bold;
				color: var(--text--base);
			}

			ul {
				list-style-type: disc;
				font-size: 11px;
				color: var(--text--tertiary);
			}

			li {
				margin: 5px 0 0 15px;
			}
		}

		/** WIKI LINK */
		#channelContent .moreInfoLink {
			display: block;
			font-weight: bold;
			font-size: 14px;
			line-height: 14px;
			margin: 25px 0 0;

			i {
				font-size: 16px;
				margin-right: 3px;
			}
		}

		.back-to-folders {
			margin: 0 0 10px;
			cursor: pointer;

			&:hover {
				a {
					color: var(--text--base);
				}
				.switchPageContainer a {
					background-color: var(--form--input-background);
					color: var(--text--base);
					box-shadow: 0 1px 0 RGBA(var(--switch-box--shadow-rgb), 0.3);
				}
			}

			.switchPageContainer {
				height: auto;
				margin: 0 10px 0 0;
			}

			a {
				margin: 0;

				span {
					font-size: 1.6rem;
					font-weight: bold;
				}

				i {
					text-align: center;
					width: 100%;
					display: inline-block;
				}
			}
		}

		/** FOLDER TITLE */
		.folderNameHolder {
			padding: 0 5px;
			margin-bottom: 20px;
			margin-top: 10px;

			> ph-col:first-child {
				line-height: 4.4rem;
				position: relative;
				overflow: hidden;
				text-overflow: ellipsis;
			}

			.contentCount {
				margin-left: 15px;
				top: -2px;
				position: relative;
				color: var(--channel--album-item-counter-text);
			}

			strong {
				font-size: 20px;
				line-height: 34px;
				color: var(--channel--listpage-title);
			}

			.editDisabledNotification {
				display: none;
				top: -4px;
				position: absolute;
				font-size: 11px;
				color: var(--text--secondary);
				padding-left: 15px;
				font-weight: bold;
				white-space: nowrap;

				i {
					color: var(--common--alert);
					padding-right: 5px;
					font-size: 1.4em;
					position: relative;
					top: 1px;
				}

				@at-root :global .disableMedia & {
					display: inline;
				}
			}
		}

		#trackbar_wrapper {
			position: relative;
			height: 100px;
			width: 100%;

			ul {
				display: table;
				position: relative;
				text-align: left;
				top: 15px;
				width: 100%;

				li {
					display: inline-block;
					height: 27px;
					font-size: 12px;
					line-height: 27px;
					padding: 0;
					width: 45px;
					text-align: center;
					color: var(--channel--playlist--track-bar);
					float: left;

					&:first-child {
						margin: 0;
					}

					&.active {
						color: var(--text--secondary) !important;
					}

					&.available {
						color: var(--text--link);
					}

					&.selected {
						font-weight: bold;
						position: relative;
						border-radius: 2px;
						background: var(--channel--playlist--track-bar-bg);
						color: var(--text--base);
					}

					#price_tooltip {
						display: block;
						opacity: 0;
						font-weight: bold;
						font-size: 14px;
						line-height: 14px;
						position: absolute;
						top: 30px;
						padding: 10px 20px;
						border-radius: 4px;
						transition: opacity 0.4s ease-in;
						z-index: 2;
						background: var(--channel--price-settings-tooltip-bg);
						color: var(--text--base);
					}

					&:hover #price_tooltip {
						opacity: 1;
					}
				}
			}

			.close_settings {
				position: absolute;
				top: 30px;
				font-size: 16px;
				right: -26px;
				color: var(--text--secondary);
			}
		}

		#save_price {
			opacity: 0.3;

			&.active {
				opacity: 1;
			}
		}

		.protip-container.reasonInfoText {
			ul {
				margin: 0 0 0 10px;

				li {
					margin: 4px 0 4px;
					list-style-type: disc;
					color: var(--text--base);

					p {
						font-size: 12px;
						line-height: 16px;
						color: var(--text--base);
						margin: 0;
					}
				}
			}
			span {
				margin-left: 22px;
				left: 0;
			}
		}

		.upload_tooltip {
			text-align: center;
			color: var(--text--base);
			font-size: 12px;
			line-height: 14px;
		}

		/* Channel Price Settings */
		#channelPriceSettings {
			position: relative;
			float: right;

			i, label {
				position: relative;
				display: inline-block;
				font-weight: bold;
				font-size: 14px;
				line-height: 38px;
				color: var(--text--base);
			}

			i {
				top: 2px;
				float: left;

				&.icon-coins {
					font-size: 14px !important;
				}

				&.icon-coins-up {
					margin: 0 6px 0 0 !important;
				}
			}

			label {
				margin: 0 10px 0 0;
				width: auto;
			}

			.whiteLoader {
				position: absolute;
				top: 10px;
				right: -25px;
			}

			.mask {
				opacity: 0.5;
				background: var(--channel--price-settings-mask);
				border-radius: 5px;
			}
		}

		#select_channelPrice {
			float: right;
			position: relative;
			margin: 0;

			input {
				background: var(--channel--price-settings-scroll-container-bg);
				width: 100px;
				border: none !important;
				margin: 0;

				&:focus, &.focus {
					border: none !important;
				}
			}

			.scrollContainer {
				background: var(--channel--price-settings-scroll-container-bg);
				border-radius: 5px;
				z-index: 10000;
			}

			.dropDownContainer {
				width: 69px;
			}
		}

		.protip-container.channelPriceTooltip ul li {
			list-style-type: disc;
			margin: 4px 0 4px 15px;
		}

		/* HTML5 Video Player */
		#mvc_overlay_container #video_player {
			background: var(--channel--overlay-bg);
			position: relative;
			width: 100%;
			height: 100%;
		}
		.overlay_comment_container.fitscreen .content_container {
			background: var(--channel--overlay-bg);
		}

		/**
    * Playlist editor - Media Bar
    */
		#media_bar {
			transition: all 0.2s ease-out 0s;

			position: fixed;
			bottom: -250px;
			left: 0;
			right: 0;
			background: var(--channel--playlist--bg);
			height: 203px;
			box-shadow: 0 7px 8px -9px RGBA(var(--channel--playlist--shadow-rgb), 0.8) inset,
			0 -5px 26px -4px RGBA(var(--channel--overlay-bg-rgb), 0.75);
			z-index: 1000;

			&.show {
				bottom: 0;
			}

			#media_bar_inner {
				@include _vertical-gradient(
						var(--channel--playlist--inner-gr-lighten),
						var(--channel--playlist--inner-gr)
				);

				width: 100%;
				height: 160px;
				overflow: hidden;
				box-shadow: 0 3px 10px -3px var(--channel--overlay-bg),
				0 1px 1px RGBA(var(--channel--playlist--shadow-rgb), 0.24) inset;
			}

			.close-playlist {
				position: absolute;
				top: 0;
				right: 0;
				font-size: 10px;
				color: var(--channel--playlist-close);
				z-index: 11;
				cursor: pointer;

				i {
					display: block;
					padding: 10px;
				}
			}

			.saveBtn {
				position: relative;
				oveflow: visible;

				.whiteLoader {
					display: none;
					position: absolute;
					left: -30px;
					top: 4px;
				}

				&.loading {
					opacity: 0.5;

					.whiteLoader {
						display: block;
					}
				}
			}

			.pager {
				transition: all, 0.5s, linear;

				position: absolute;
				font-size: 14px;
				color: var(--channel--playlist-pager);
				top: 0;
				height: 150px;
				z-index: 4;
				cursor: pointer;

				i {
					display: block;
					margin: 75px 0 0;
				}

				&-left {
					@include _horizontal-gradient(
							var(--channel--playlist--pager-gr),
							RGBA(var(--channel--playlist--pager-gr-rgb), 0)
					);

					padding: 0 50px 0 10px;
					left: -69px;

					&.visible {
						left: 0;
					}
				}

				&-right {
					@include _horizontal-gradient(
							RGBA(var(--channel--playlist--pager-gr-rgb), 0),
							var(--channel--playlist--pager-gr)
					);

					padding: 0 10px 0 50px;
					right: -69px;

					&.visible {
						right: 0;
					}
				}
			}

			.timeline_wrapper {
				display: inline-block;
				min-width: 100%;
				font-size: 0;
				background-position: left 10px bottom 6px, left 10px top 10px;
				background-repeat: repeat-x;
				background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAPCAYAAAA71pVKAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyFpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNS1jMDIxIDc5LjE1NDkxMSwgMjAxMy8xMC8yOS0xMTo0NzoxNiAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wTU09Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9tbS8iIHhtbG5zOnN0UmVmPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvc1R5cGUvUmVzb3VyY2VSZWYjIiB4bWxuczp4bXA9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC8iIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6RkI0RDgxODBEREUzMTFFNEJCRkVEQTZEOEM1NjE4RUMiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6RkI0RDgxN0ZEREUzMTFFNEJCRkVEQTZEOEM1NjE4RUMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIChXaW5kb3dzKSI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuaWlkOkEwRjlGOUJBRERFMjExRTRCNTk1ODhERkU0OTdERDkwIiBzdFJlZjpkb2N1bWVudElEPSJ4bXAuZGlkOkEwRjlGOUJCRERFMjExRTRCNTk1ODhERkU0OTdERDkwIi8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+4mz4bAAAALNJREFUeNrEU8ENwjAMdIBIebJBV0gfbJSlsiQ/JCjExq4cKXWbPugDSydVvrvavqqOiODXOsGBOmQGWbsi5+y891duj4ybwSicaKp+NbmUMujjZNByc13sGYgoCT4ZH1lM+060zHk9tWyZpd6M14a5WKE1OzVV2Dqrpps2NRN3+9ZMnCj2voxy1J0cY7yzaDU5hDBzezdjSumhadsXo4aGPTNoUNgECM2qi5Pc336MrwADAJm8TkdhdzLcAAAAAElFTkSuQmCC'),
				url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAPCAYAAAA71pVKAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyFpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNS1jMDIxIDc5LjE1NDkxMSwgMjAxMy8xMC8yOS0xMTo0NzoxNiAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wTU09Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9tbS8iIHhtbG5zOnN0UmVmPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvc1R5cGUvUmVzb3VyY2VSZWYjIiB4bWxuczp4bXA9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC8iIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6RkI0RDgxODBEREUzMTFFNEJCRkVEQTZEOEM1NjE4RUMiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6RkI0RDgxN0ZEREUzMTFFNEJCRkVEQTZEOEM1NjE4RUMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENDIChXaW5kb3dzKSI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuaWlkOkEwRjlGOUJBRERFMjExRTRCNTk1ODhERkU0OTdERDkwIiBzdFJlZjpkb2N1bWVudElEPSJ4bXAuZGlkOkEwRjlGOUJCRERFMjExRTRCNTk1ODhERkU0OTdERDkwIi8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+4mz4bAAAALNJREFUeNrEU8ENwjAMdIBIebJBV0gfbJSlsiQ/JCjExq4cKXWbPugDSydVvrvavqqOiODXOsGBOmQGWbsi5+y891duj4ybwSicaKp+NbmUMujjZNByc13sGYgoCT4ZH1lM+060zHk9tWyZpd6M14a5WKE1OzVV2Dqrpps2NRN3+9ZMnCj2voxy1J0cY7yzaDU5hDBzezdjSumhadsXo4aGPTNoUNgECM2qi5Pc336MrwADAJm8TkdhdzLcAAAAAElFTkSuQmCC');
				height: 100%;
			}

			.items,
			.fake-items {
				counter-reset: item;
				padding: 30px 0 0 20px;
				white-space: nowrap;
			}

			.item,
			.fake-item {
				@include draggable-cursor-grab;

				display: inline-block;
				position: relative;
				margin: 0 10px 0 0;
				height: 130px;
				vertical-align: top;

				.time {
					position: absolute;
					left: 0;
					bottom: 8px;
					font-size: 11px;
					color: var(--channel--playlist--time);
				}

				.duration {
					transition: all, 0.1s;
					transform: translate(10px, 10px) scale(0);
					@include transform-origin(-50% 100%);

					position: absolute;
					left: 20px;
					bottom: 20px;
					font-size: 11px;
					color: var(--channel--playlist--duration);
					opacity: 0;
					z-index: 4;

					i {
						position: relative;
						left: -2px;

						&.icon-film {
							font-size: 16px;
							top: 2px;
						}

						&.icon-folder {
							top: 0;
							font-size: 13px;
						}
					}
				}

				&.draggable-clone {
					transform: translate3d(0, 0, 0);

					.frame:before,
					.time,
					.duration,
					.remove {
						display: none;
					}
				}

				&.removed {
					display: none !important;
				}

				&:hover,
				&.draggable-hover {
					.duration,
					.remove {
						transform: translate(0, 0) scale(1);

						opacity: 1;
					}
				}
			}

			.fake-item {
				width: 164px;
				height: 102px;
				cursor: default;

				.frame {
					height: 100%;

					&:before {
						display: none;
					}
				}
			}

			.fake-items {
				position: absolute;
				display: inline-block;
				padding: 0;
			}

			.fade {
				@include _vertical-gradient(
						RGBA(var(--channel--playlist--fade-rgb), 0),
						var(--channel--playlist--fade)
				);

				position: absolute;
				display: block;
				width: 100%;
				height: 40px;
				bottom: 10px;
				left: 10px;
				z-index: 1;
			}

			.frame {
				display: block;
				position: relative;
				padding: 10px;
				background: var(--channel--playlist--frame);
				border-radius: 3px;
				overflow: hidden;
				height: 102px;
				box-shadow: 0 1px 1px RGBA(var(--channel--playlist--frame-shadow-rgb), 0.1),
				0 2px 4px RGBA(var(--channel--overlay-bg-rgb), 0.4) inset;

				img {
					width: 142px;
					height: 80px;
					border: 1px solid var(--channel--playlist--frame);
					box-sizing: content-box;
				}

				.fourImg {
					width: 100px;

					> div {
						overflow: hidden;
						height: 50% !important;
						position: relative;
					}

					img {
						transform: translate(-50%, -50%);
						-webkit-backface-visibility: hidden;

						width: auto;
						height: 180%;
						position: absolute;
						left: 50%;
						top: 50%;
					}
				}

				// Counter
				&:before {
					position: absolute;
					bottom: 19px;
					right: 19px;
					color: var(--channel--album-total-counter);
					font-weight: bold;
					counter-increment: item;
					content: counter(item);
					z-index: 3;
				}
			}

			.remove {
				transition: all, 0.1s;
				transform: translate(-10px, -10px) scale(0);
				@include transform-origin(200% 0);

				position: absolute;
				right: 16px;
				top: 16px;
				background: RGBA(var(--channel--overlay-bg-rgb), 0.6);
				color: var(--text--tertiary);
				border-radius: 3px;
				font-size: 8px;
				padding: 5px;
				opacity: 0;
				cursor: pointer;
			}

			.scrollbarContainer {
				width: 100%;
				height: 42px;
				table-layout: inherit;

				> div:first-child {
					padding: 0 20px;
					width: 93%;
				}

				.saveBtnHolder {
					width: 1%;
					padding: 0 20px 0 0;

					button {
						white-space: nowrap;
						margin: 0;
					}
				}
			}

			.scroll-pane-horizontal {
				bottom: auto;
				background: var(--channel--playlist--scrollbar-bg-darken);
				border: none;
				height: 10px;
				box-shadow: 0 3px 6px -6px var(--channel--playlist--scrollbar-gr-to),
				0 2px 6px -3px var(--channel--overlay-bg) inset;
			}

			.scrollbar {
				@include _vertical-gradient(
						var(--channel--playlist--scrollbar-gr-from),
						var(--channel--playlist--scrollbar-gr-to)
				);

				border: none;
				height: 10px;
				margin: 0 0 0 2px;
			}

			.topMsg {
				transform: translateX(-50%);

				position: absolute;
				left: 50%;
				top: 1px;
				padding: 0 5px;
				display: block;
				font-size: 11px;
				color: var(--channel--playlist--top-msg-text);
				height: 29px;
				line-height: 30px;
				background: var(--channel--playlist--top-msg-bg);
				z-index: 1;
				white-space: nowrap;

				&.disableText {
					z-index: 2;

					> span {
						display: none;

						i {
							position: relative;
							font-size: 16px;
							color: var(--common--alert);
							top: 2px;
							margin: 0 5px 0;
						}

						b {
							color: var(--text--base);
							font-weight: bold;
						}

						&.show {
							display: block;
						}
					}
				}
			}

			.mediaBarOverlay {
				background: var(--channel--media-bar-overlay-bg);
				position: absolute;
				top: 0;
				bottom: 0;
				left: 0;
				right: 0;
				z-index: 5;
			}

			.overlayMsgWrap {
				position: absolute;
				text-align: center;
				left: 50%;
				transform: translateX(-50%);
				top: 65px;

				&.buttoned {
					top: 45px;
				}

				> * {
					display: inline-block;
					vertical-align: top;
				}

				.icon-ok {
					color: var(--common--success);
					margin-top: 7px;
				}

				.icon-alert {
					color: var(--common--alert);
					margin-top: 7px;
					font-size: 19px;
				}

				.msgCaption {
					display: none;
					font-size: 14px;
					line-height: 35px;
					color: var(--text--base);
					margin: 0 0 0 15px;

					&.shw {
						display: inline-block;
					}
				}

				.loadErrorOk {
					margin: 10px auto 0 0;
				}
			}
		}

		/* Channel Editor Extensions */
		/* Checkbox */
		.playlistHelper {
			transition: opacity, 0.2s;

			position: absolute;
			top: -22px;
			height: 230px;
			width: 100%;
			background: var(--channel--playlist--helper-bg);
			opacity: 0;
		}

		.playlistCheckbox {
			transition: opacity, 0.2s;

			position: absolute;
			bottom: 100%;
			background: var(--primary);
			left: 20px;
			border-top-left-radius: 4px;
			border-top-right-radius: 4px;
			z-index: 1;
			overflow: hidden;
			max-height: 100%;
			box-shadow: 2px 0 0 0 var(--channel--playlist--checkbox-shadow) inset,
			-2px 0 0 0 var(--channel--playlist--checkbox-shadow) inset,
			0 -2px 0 var(--primary) inset,
			0 0 0 2px var(--channel--playlist--checkbox-shadow) inset;
			margin-bottom: -7px;
			transition-delay: 0s;
			opacity: 0;
		}

		.channelFolderBrowser input[type="checkbox"] {
			display: none;

			& + .playlistCheckbox label {
				transition: all 0.2s ease-out 0s;
				transform: scale(0);

				outline: none;
				background: var(--channel--playlist--checkbox-label);
				box-shadow: inset 0 1px 1px 0 RGBA(var(--channel--overlay-bg-rgb), 0.3);
				width: 16px;
				height: 16px;
				border-radius: 4px;
				display: inline-block;
				padding: 0;
				position: relative;
				cursor: pointer;
				z-index: 1;
				margin: 6px 6px 4px;
				opacity: 0;

				&:before {
					display: inline-block;
					position: absolute;
					visibility: hidden;
					line-height: 16px;
					font-size: 7px;
					transition-duration: 0s;
					text-shadow: 0 1px 0 RGBA(var(--channel--overlay-bg-rgb), 0.17);
					color: var(--text--base);
					overflow: hidden;
					text-align: center;
				}
			}

			&:checked + .playlistCheckbox {
				label:before {
					transition: all 0.2s ease-out 0s;

					opacity: 1;
					visibility: visible;
					width: 16px;
				}
			}

			&[disabled] {
				&, & + .playlistCheckbox label {
					opacity: 0.5;
				}
			}
		}

		@at-root :global .disableMedia #pageContainer.channelEditor.myContent {
			.channelFolderBrowser input[type="checkbox"] {
				&:checked + .playlistCheckbox {
					box-shadow: 2px 0 0 0 var(--channel--disabled--checkbox-shadow) inset,
					-2px 0 0 0 var(--channel--disabled--checkbox-shadow) inset,
					0 -2px 0 var(--primary) inset,
					0 0 0 2px var(--channel--disabled--checkbox-shadow) inset;
				}

				&:checked + div + .boxInner {
					border-color: var(--channel--disabled--checkbox-shadow);
				}
			}

			.notSelectable {
				cursor: default !important;
				pointer-events: none;

				.playlistHelper {
					transition: opacity 0.2s;
					z-index: 50;
					background: var(--channel--playlist--helper-bg);
					opacity: 0.6;
				}
			}

			#photos_folder .active_folder {

				.boxInner > div > div {
					background: var(--channel--box--inner-bg) !important;
				}
			}

			#videos_folder {

				.playlistHelper {
					height: 185px;
				}

				.active_folder {

					.playlistHelper {
						height: 190px;
					}

					.controls .play,
					.mediaItemClickBox {
						display: none;
					}
				}
			}

			#notes {
				.playlistHelper {
					height: 315px;
				}

				.controls {
					display: none;
				}

				.boxInner > div > div {
					background: var(--channel--disabled--notes-inner-bg) !important;
				}
			}

			.controls .controls-action-menu {
				display: none !important;
			}

			.protip_container.albumTitle {
				display: none;
			}
		}

		.addedToPlaylist,
		.removedFromPlaylist {
			transition: all 0.2s ease-out 0s;

			display: table;
			position: absolute;
			opacity: 0;
			top: -22px;
			left: 52px;
			color: var(--text--base);
			background: var(--channel--overlay-bg);
			padding: 4px;
			border-radius: 4px;
			z-index: 3;
			font-size: 11px;
		}

		@at-root :global .activePlaylist & {
			.playlistCheckbox {
				opacity: 1;
			}

			.channelFolderBrowser input[type="checkbox"] + .playlistCheckbox label {
				transform: scale(1);
				opacity: 1;
			}

			.editorButton {
				em.open {
					display: none !important
				}
				em.exit {
					display: inline !important
				}
			}
		}

		@for $i from 0 through 50 {
			.activePlaylist .mediaBox:nth-of-type(#{$i}) .playlistCheckbox {
				transition-delay: #{$i * 50}ms;

				label {
					animation-delay: #{$i * 50}ms;
				}
			}
		}

		.ui-card {
			$main-selector: &;

			position: relative;
			padding: 50px 60px 40px;

			@include _on('mobile') {
				width: calc(100% - 4rem);
				margin: 0 2rem;
				padding: 5.2rem 1.5rem 4rem;
			}

			// Styles
			&--default {
				background-color: var(--card--default--background);
				border-radius: 0.5rem;

				.ui-card__number {
					background-color: var(--card--default--number-bg-darker);
				}
			}

			// Inner elements
			&__number {
				transform: translate(-50%, -50%);

				position: absolute;
				top: 0;
				left: 50%;
				font-size: 3.6rem;
				font-weight: bold;
				width: 7rem;
				height: 7rem;
				line-height: 7rem;
				text-align: center;
				border-radius: 50%;
				border: 0.2rem solid var(--card--default--number-border);
				color: var(--card--default--number-text);

				&--active {
					border: 0.2rem solid var(--card--active--number-border);
					color: var(--card--default--number-active-text);
					box-shadow: 0 0 0 0.2rem var(--card--default--number-active-shadow) inset;
				}
			}
		}
	}

	#channelDeleteConfirmOverlay,
	#promotionConfirmOverlay {
		form, .button {
			display: inline-block;

			@include _on('mobile') {
				display: block;
			}
		}

		button[type="submit"] {
			margin: 0 18px 0 0;
		}

		i {
			margin-right: vars.spacer(1);
			font-size: 2rem;
			position: relative;
			top: vars.spacer(1);
		}

		p:not(.ph-p--secondary) {
			font-size: 1.4rem;
			margin-bottom: 10px;
			line-height: 1.3;
			color: var(--channel--info-text);
		}
	}

	.mycontent-info {
		.box_header {
			background-color: transparent;
		}
	}

	.mycontent-info-section {
		padding: 5.6rem;

		h2 {
			font-size: 2.4rem;
			line-height: 1.1;
		}

		h3 {
			font-size: 1.6rem;
			text-transform: uppercase;
			line-height: 1.2;
			color: var(--channel--info-title);
		}

		p {
			font-size: 1.4rem;
			color: var(--channel--info-text);
		}

		li {
			font-size: 1.6rem;
			color: var(--channel--info-number);
		}

		&__introduction {
			padding-top: 0;

			h1 {
				margin-bottom: 2rem;
				font-size: 32px;
				line-height: 1.2;
				color: var(--channel--info-title);
			}

			h3 {
				color: var(--text--secondary);
				margin: 20px 0 10px;
			}

			p {
				line-height: 1.3;
				z-index: 2;
				position: relative;

				&:first-of-type {
					margin-bottom: 2.2rem;
				}
			}

			.illustration {
				color: var(--channel-info--intro--background-icon);
				position: absolute;
				right: 80px;
				top: 110px;
				font-size: 12em;
				z-index: 1;
			}
		}

		&__advantages {
			background-image: url('mycontent-info-advantages-section.jpg');
			height: 737px; // Like the background image's height
			position: relative;

			h3 {
				margin-bottom: 1.2rem;
			}

			p {
				line-height: 1.3;
				margin: 0 0 1rem 0;
			}

			img {
				position: absolute;
				bottom: 0;
				right: 0;
				max-width: none;
			}
		}

		&__howto {
			background-image: url('mycontent-info-howto-section.png');
			background-position: right -39px bottom -34px; //-39px: correction of the PNG
			background-repeat: no-repeat;
			background-color: var(--channel--info-bg);

			h3 {
				margin-bottom: 1.2rem;

				&:last-of-type {
					margin-top: 2.2rem;
				}
			}

			p {
				width: 23rem;
				margin: 0 0 1rem 0;
				line-height: 1.3;
			}
		}

		&__whocansee {
			background-image: url('mycontent-whocansee-background.jpg');
			background-repeat: no-repeat;
			background-size: 100% auto;
			background-color: var(--channel-info--bg);
			background-position: left bottom 0;
			padding-bottom: 0;

			h3 {
				margin-bottom: 1.2rem;
			}

			p {
				line-height: 1.3;
			}

			img {
				border-radius: 7px 7px 0 0;
				display: block;
				top: 0 !important;
			}
		}

		&__call-to-action {
			text-align: center;

			h3 {
				font-size: 1.8rem;
				margin-bottom: 1.6rem;
			}

			p {
				font-size: 1.4rem;
				margin-bottom: 2rem;
			}
		}

		@include _on('mobile') {
			padding: 3.7rem 2rem 3.5rem;

			h3 {
				line-height: 1.2;
			}

			&__introduction {
				padding-top: 0.5rem;
			}

			&__advantages {
				height: 71.8rem;
				background-size: cover;
				background-position: center;

				img {
					max-width: 113%;
				}
			}

			&__whocansee {
				padding: 3.7rem 2rem 0;

				p {
					height: auto;
				}
			}

			.mycontent-landing__image-wrapper {
				img {
					top: 0.9rem;
					left: -1.6rem;
					max-width: 111%;

					&.ph-float-right {
						left: initial;
						right: -1.6rem;
					}
				}
			}

			&__howto {
				padding: 4rem 2rem 20rem;
				background-position: right -4rem bottom -62rem;

				p {
					width: 100%;
				}
			}
		}
	}

	.channelInfo {
		.box_header {
			border: 0;
			background: var(--overlay-background);
		}
	}

	.moreInfoButton {
		display: inline-block;
		width: 100%;
		text-align: center;
		margin-top: 2rem;

		.text {
			display: flex;
			align-items: center;

			.msc-icon {
				margin-right: 0.4rem;
				font-size: 2.4rem;
			}

			&:hover .msc-icon::before {
				content: icons.get('info-circular-solid');
			}
		}
	}

	.rejectedUploadedContent {
			display: block;
			font-weight: bold;
			font-size: 1.1rem;
			line-height: 1.6rem;
			margin: 1.5rem 0 0.7rem;
			text-align: left;

			i.icon-info-circle {
				font-size: 1.6rem;
				color: var(--protip--link);
				display: inline-block;
				margin: 0 0.6rem 0 0;
				top: 0.1rem;
				position: relative;
			}

			.linkArrowRight {
				display: inline-block;
				margin: 0 0 0 0.6rem;
				position: relative;
				font-size: 1.2rem;
				line-height: 1.1rem;
			}
	}

	#mycontentfreeeditor_index,
	#mycontentpremiumeditor_index {
		.overlayContent {
			width: 100%;

			form-submit > div .button {
				margin-right: 1rem;
			}
		}

		.showMoreLink {
			padding-left: 0;
			font-size: 1.4rem;
		}

		@include _on('mobile') {
			&.no-overscroll {
				overflow: hidden !important;
			}

			.overlayContent {
				.overlayBlock {
					padding-left: 2rem;
					padding-right: 2rem;

					&.mycontent-info {
						padding: 0;
					}
				}

				.button {
					width: 100%;
					margin-bottom: 2rem !important;
				}

				.closeButton i {
					text-shadow: none;
				}

				form-submit > div {
					display: block;
				}
			}
		}
	}

	@include _on('mobile') {
		#pageContainer.myContent {
			padding: 0 0 4rem;

			>:first-child {
				margin: 0;
			}

			.commonTabs {
				width: 100%;
				margin: 0;
				border-bottom: 0;

				.tab {
					flex: 1;
					min-width: auto;

					a {
						border-bottom: 0.1rem solid var(--common-tabs--link-border);
					}
				}
			}

			.commonTabsContent.active {
				padding: 2rem 1.5rem 0;
			}

			.darkerHeader {
				width: 100%;
				margin: 0;
				padding: 0;
			}

			#channelProgressBarContainer {
				padding: 3rem 2rem 0;
			}

			.channel_interface {
				padding: 2rem;
			}

			.mediaBox.folderBox {
				margin-bottom: 1.5rem;
			}
		}
	}

	.oranum-theme {
		.mycontent-info-section__advantages {
			height: auto;
			background-image: url('mycontent-info-advantages-section-oranum.jpg');
		}

		.mycontent-info-section__howto {
			background-image: url('mycontent-info-howto-section-oranum.png');
			background-size: contain;
			background-position: right -6rem bottom -3.4rem;

			@include _on('mobile') {
				background-position: right -4rem bottom -66rem;
			}
		}

		.mycontent-info-section__whocansee {
			background-image: url('mycontent-whocansee-background-oranum.jpg');
		}

		#pageContainer.myContent {
			.mediaBox {
				&:hover,
				&.hover,
				&.focus,
				[triggered] {
					&[triggered],
					.boxInner {
						&:before {
							background: linear-gradient(to bottom, var(--channel--box--inner-bg-hover), var(--channel--box--inner-bg-hover-end));
						}
					}
				}
				&.rejected,
				&.waiting,
				&.failed,
				&.converting {
					.boxInner::before {
						background: none;
					}
				}
			}
		}
	}
}

@keyframes:global(channelConverting) {
	0% {
		background-position: -100px -100px;
	}
	50% {
		background-position: -500px -100px;
	}
	100% {
		background-position: -100px -100px;
	}
}

@keyframes:global(channelLoaderRotate) {
	from {
		transform: rotate(-360deg);
	}
	to {
		transform: rotate(0deg);
	}
}

@use 'global/colors';
@use 'global/icons';
@use 'components/Button/button';
@import '../../../../scss/shell';
@import 'common';

.uploadButton {
  margin: 0.4rem 0 4rem;

  @include _on('mobile') {
    width: 100%;
    margin-bottom: 2.4rem;
  }
}

.center {
  margin-top: 2.4rem;
  text-align: center;

    @include _on('mobile') {
      margin: 2rem 2.4rem;
    }
}

.hide {
  display: none;
}

.uploadInfoText {
  i {
    padding: 0 0.5rem;
  }

  ul {
    margin-bottom: 1.2rem;

    li {
      margin: 0;
      
      p {
        padding: 0;
      }
    }
  }
}

.tipSlidesContainer {
  width: 100%;
  padding: 4rem 3.6rem 2.4rem;
  background: var(--promo-tools--tipslides-bg);

  @include _on('mobile') {
    padding: 2.4rem 0;
  }

  h2 {
    margin: 0;
  }
}

.tipSlides {
  margin-top: 3.2rem;

  @include _on('mobile') {
    margin: 3.2rem 1.1rem 0;
  }
}

.block-main {
  padding: 4rem 0 0;
  font-weight: 400;
  display: flex;
  text-align: left;
  flex-wrap: wrap;
  justify-content: space-between;

  @include _on('mobile') {
    padding: 2.4rem 0 0;
  }

  h1 {
    font-size: 3.2rem;
    line-height: 3.6rem;
    margin: 0 4.8rem 3.2rem;
    flex-basis: 100%;
    text-align: center;

    @include _on('mobile') {
      line-height: 4rem;
      margin: 0 2.4rem 2rem;
    }
  }

  p {
    font-size: 1.6rem;
    margin-bottom: 2rem;
    line-height: 2rem;
    color: var(--text--paragraph);

    strong {
      color: var(--text--base);
      font-weight: bold;
    }
  }

  .video-wrapper {
    flex: 1;
    margin: 0 4.8rem 0 0;
    @include infoVideoPreview;

    @include _on('mobile') {
      margin: 0;
    }

    .preview {
      margin-top: 1rem;

      @include _on('mobile') {
        margin: 0 auto 3.6rem;
      }

      img {
        width: 38.2rem;
        height: 21.5rem;
        border-radius: 0.4rem;

        @include _on('mobile') {
          width: 100%;
          height: auto;
        }
      }

      &.portrait {
        height: 100%;
        margin: 0;
        text-align: center;
        background-image: url('../assets/videoPreviewBg.png');
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;

        @include _on('mobile') {
          padding: 0 4.4rem 3.6rem;
          background-image: none;
        }

        .previewWrapper {
          position: absolute;
          left: 50%;
          transform: translate(-50%, 0);

          @include _on('mobile') {
            position: relative;
            margin: 0 auto;
            width: fit-content;
            left: unset;
            transform: unset;
          }
        }

        img {
          width: auto;
          height: 25rem;

          @include _on('mobile') {
            width: 100%;
            height: auto;
            max-width: 28rem;
          }
        }

        .previewText {
          padding: 0.4rem 0.7rem;
          width: 80%;
          font-size: 1rem;

          @include _on('mobile') {
            font-size: 1.5rem;
            padding: 0.8rem 1.5rem;
            bottom: 3.2rem;
          }
        }
      }
    }

    &.erotic {
      margin: 0 3.8rem 0 0;
      max-width: fit-content;

      @include _on('mobile') {
        margin: 0 auto;
        width: 100%;
        max-width: unset;
      }

      .preview {
        margin-top: -2.4rem;
        background: none;

        @include _on('mobile') {
          margin: 0;
        }

        img {
          width: 100%;
          height: auto;
        }
      }
    }

    .promoTeaserPreview {
      .previewWrapper {
        position: static;
        transform: translate(0, 0);
        text-align: center;
        width: 100%;
      }

      img {
        width: 25.3rem;
        max-width: 24rem;
        height: 22.8rem;

        @include _on('mobile') {
          width: 100%;
          height: auto;
        }
      }

      &.portrait {
        background: none;

        img {
          width: 24.5rem;
          height: 22rem;

          @include _on('mobile') {
            width: 100%;
            height: auto;
            max-width: 34.3rem;
          }
        }
  
        @include _on('mobile') {
          padding-left: 0;
          padding-right: 0;
        }
      }
    }

    .previewText {
      position: absolute;
      bottom: 0;
      left: 0;
      padding: 0.8rem 1.5rem;
      text-align: left;
      text-transform: uppercase;
      font-size: 1.5rem;
      font-weight: bold;
      color: var(--promo-tools--preview-text);
      background: var(--promo-tools--preview-bg);
    }

    @include _on('mobile') {
      flex-basis: 100%;
    }
  }

  .text-wrapper {
    flex: 1;
    margin: 0 4rem 0 4.8rem;

    &.eroticDesktopTeaser {
      margin: 0 0 0 4.8rem;

      @include _on('mobile') {
        margin: 0 2.4rem;
      }
    }

    @include _on('mobile') {
      flex-basis: 100%;
      margin: 0 2.4rem;
    }
  }

  .button-wrapper {
    text-align: left;
    flex-basis: 100%;

    @include _on('mobile') {
      text-align: center;
    }
  }
}

.ph-hr {
  margin: 0;
  padding: 0 4.8rem;
}

.h2 {
  font-size: 2.4rem;
  text-transform: uppercase;
  text-align: center;
  margin: 3.2rem 0 0;
}

.linkText a {
  @include button.linkPrimary();
  display: inline-block;
}

.requirements a {
  @include button.linkPrimary();
}

.videoTip {
  position: absolute;
  margin-left: 0.4rem;
  font-size: 2.4rem;
  color: var(--tooltip);
  transform: translateY(-0.8rem);

  &:hover::before {
    color: var(--tooltip-hover);
    content: icons.get('info-circular-solid');
  }
}

.block-step {
  padding: 0 4.8rem;
  margin: 3.2rem 0 0;
  display: flex;
  align-items: center;

  @include _on('mobile') {
    flex-wrap: wrap;
    padding: 0;
    margin: 0;
  }

  &.mirrored {
    flex-direction: row-reverse;
    padding: 0 4.8rem;

    @include _on('mobile') {
      padding: 0;
      margin: 0;
    }

    figure {
      margin: 0 0 0 4.8rem;
      text-align: right;

      @include _on('mobile') {
        margin: 0;
      }
    }
  }

  figure {
    margin: 0 4.8rem 0 0;
    max-width: 50%;
    @include _on('mobile') {
      flex-basis: 100%;
      margin: 0;
      padding: 2.4rem;
      max-width: 100%;
    }
  }

  figcaption {
    flex: 1;
    font-weight: 400;

    @include _on('mobile') {
      padding: 0 2.4rem;
    }

    .step {
      font-weight: bold;
      margin: 0 0 0.4rem;
      font-size: 1.6rem;
      text-transform: uppercase;
      color: var(--promotion-tools--info-page--step-count);
    }

    .tips {
      font-size: 1.4rem;
      font-weight: bold;
      text-transform: uppercase;
    }

    .title {
      font-weight: bold;
      margin: 0 0 1.5rem;
      font-size: 2rem;
      line-height: 1.3;
      text-transform: uppercase;
    }

    .subtitle {
      margin: 1.2rem 0;
      color: var(--text--paragraph);

      strong {
        color: var(--text--base);
      }
    }

    p {
      font-size: 1.4rem;
      font-weight: 400;
      line-height: 1.3;
    }

    .box {
      font-size: 1.4rem;

      ul {
        padding: 1rem 0 0 0;
      }

      li {
        color: var(--text--paragraph);
      }

      @include list;

      li {
        // Technical requirements tooltip trigger
        em {
          font-weight: bold;
          color: var(--text--link);
        }

        :global i.icon-info-circle {
          color: var(--text--link);
          top: 0.1rem;
          left: 0.6rem;
          position: relative;

          @include _on('mobile') {
            pointer-events: none;
          }
        }
      }
    }
  }
}

.videoCallTeaser {
  .block-step {
    figcaption {
      margin: 0;
    }

    figure {
      text-align: center;
      flex: 1;

      picture img {
        width: auto;
      }
    }
  }

  .video-wrapper {
    text-align: center;

    .preview {
      margin-top: 0;

      img {
        width: auto;
        height: auto;
      }

      .previewText {
        width: 17.5rem;
        bottom: 0;
        left: unset;
        right: 18%;
        font-size: 1.8rem;
        text-transform: none;
        color: var(--promo-tools--preview-text-inverse);
        background: var(--promo-tools--preview-bg-inverse);

        @include _on('mobile') {
          right: 10%;
        }
      }
    }
  }
}

.livejasmin {
  --tooltip: #{colors.get(pink, 500)};
  --tooltip-hover: #{colors.get(neutral, 100)};
}

.oranum {
  --tooltip: #{colors.get(purple, 500)};
  --tooltip-hover: #{colors.get(purple, 800)};
  --box--info-bg: rgb(var(--color-background));
  --promotion-tools--info-page--step-count: rgb(var(--color-primary-5));
  --hr-background-lighten-25: transparent;
  --hr-background: transparent;
  --content-bg: rgb(var(--color-primary-1));
  --text--base: rgb(var(--color-primary));
  --text--paragraph: var(--text--base);

  .content {
    margin-bottom: 4rem;
    padding: 1rem 0;
    background-color: var(--content-bg);
  }

  .block-main,
  .button-wrapper {
    text-align: center;
  }

  .video-wrapper {
    display: none;
  }

  .title {
	color: var(--text--base);
  }

  .button,
  .block-step .box,
  .box li,
  .subtitle {
	--text--base: rgb(var(--color-text));
	--text--paragraph: rgb(var(--color-text));
	font-size: 1.4rem;
  }
}

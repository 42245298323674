@use 'global/typography';
@use 'global/vars';
@import '../../../scss/shell';

:global {
	.displayNameChange {
		max-width: 100vw !important;

		@include _on('mobile') {
			height: 100vh;
		}
	}

	#displayNameChange {
		padding: vars.spacer(12) vars.spacer(15);
		text-align: center;

		@include _on('mobile') {
			padding: vars.spacer(12) vars.spacer(9);
		}

		h4 {
			@include typography.title1Bold;
			margin: 0 0 vars.spacer(2);
			color : var(--display-name-overlay--text-strong);
		}

		p {
			@include typography.body2Regular;
			color : var(--display-name-overlay--text);

			strong {
				color : var(--display-name-overlay--text-strong);
			}
		}

		.rejectedBox {
			display: flex;
			margin: vars.spacer(6) 0;
			padding: 1.4rem vars.spacer(4) vars.spacer(3);
			text-align: left;
			border: 0.1rem solid var(--common--alert);
			border-radius: vars.border-radius(1);

			@include _on('mobile') {
				margin-bottom: vars.spacer(5);
			}

			i {
				position: relative;
				top: -0.3rem;
				font-size: 2.4rem;
				color: var(--common--alert);
			}

			.rejectedText {
				display: flex;
				flex-direction: column;
				margin-left: vars.spacer(2);
			}

			.title {
				@include typography.body2Medium;
				color : var(--display-name-overlay--text-strong);
				margin: 0 0 vars.spacer(1);
			}

			.text {
				@include typography.captionRegular;
				color : var(--display-name-overlay--caption);
			}
		}

		.requirements {
			@include typography.captionRegular;
			display: block;
			margin-top: vars.spacer(1);
			text-align: left;
			color : var(--display-name-overlay--caption);
		}

		label {
			padding-right: vars.spacer(3);
			font-weight: 500;
			color : var(--display-name-overlay--text-strong);

			@include _on('mobile') {
				margin-bottom: -(vars.spacer(1));
			}
		}

		.protip-container[data-pt-position="top-right"] .protip-arrow {
			right: 1.2rem;
		}

		.button-container {
			margin: vars.spacer(3) 0 vars.spacer(8);

			button {
				margin: 0 vars.spacer(1) 0 0;
				padding: 1rem vars.spacer(6);
			}
		}

		.notice {
			@include typography.captionRegular;
			color : var(--display-name-overlay--caption);
		}

		@include _on('mobile') {
			.ph-form-mobile .form-input-additions--outside {
				top: 0;
				right: 1rem;
			}
		}
	}
}
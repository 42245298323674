@use 'components/Button/button';

button {
  border: none;
}

.minimal {
  @include button.minimal();
}

.button,
a.button {
  &:not(
    &.secondary1,
    &[type="secondary1"],
    &.secondary2,
    &[type="secondary2"],
    &.textPrimary,
    &[type="textPrimary"],
    &.textSecondary1,
    &[type="textSecondary1"],
    &.textSecondary2,
    &[type="textSecondary2"],
    &.linkPrimary,
    &[type="linkPrimary"],
    &.linkSecondary1,
    &[type="linkSecondary1"],
    &.linkSecondary2,
    &[type="linkSecondary2"]
  ) {
    @include button.primary();
  }

  @include button.m();

  &.primary,
  &[type="primary"] {
    @include button.primary();
  }

  &.secondary1,
  &[type="secondary1"] {
    @include button.secondary1();
  }

  &.secondary2,
  &[type="secondary2"] {
    @include button.secondary2();
  }

  &.textPrimary,
  &[type="textPrimary"] {
    @include button.textPrimary();
  }

  &.textSecondary1,
  &[type="textSecondary1"] {
    @include button.textSecondary1();
  }

  &.textSecondary2,
  &[type="textSecondary2"] {
    @include button.textSecondary2();
  }

  &.linkPrimary,
  &[type="linkPrimary"] {
    @include button.linkPrimary();
  }

  &.linkSecondary1,
  &[type="linkSecondary1"] {
    @include button.linkSecondary1();
  }

  &.linkSecondary2,
  &[type="linkSecondary2"] {
    @include button.linkSecondary2();
  }

  &.xs,
  &[size="xs"] {
    @include button.xs();
  }

  &.s,
  &[size="s"] {
    @include button.s();
  }

  &.m,
  &[size="m"] {
    @include button.m();
  }

  &.l,
  &[size="l"] {
    @include button.l();
  }

  &.xl,
  &[size="xl"] {
    @include button.xl();
  }

  &.mobile-xs,
  &[size-mobile="xs"] {
    @include _on('mobile') {
      @include button.xs();
    }
  }

  &.mobile-s,
  &[size-mobile="s"] {
    @include _on('mobile') {
      @include button.s();
    }
  }

  &.mobile-m,
  &[size-mobile="m"] {
    @include _on('mobile') {
      @include button.m();
    }
  }

  &.mobile-l,
  &[size-mobile="l"] {
    @include _on('mobile') {
      @include button.l();
    }
  }

  &.mobile-xl,
  &[size-mobile="xl"] {
    @include _on('mobile') {
      @include button.xl();
    }
  }
}

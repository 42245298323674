// Arrow direction mixin
@mixin arrowDir($dir: 'left') {
	@if $dir == 'left' {
		left: -5px;
		right: auto;
		top: 15px;
		height: 10px;
		width: 6px;
	}
	@if $dir == 'right' {
		right: -6px;
		left: auto;
		top: 15px;
		height: 10px;
		width: 6px;
	}
	@if $dir == 'top' {
		top: 4px;
		width: 10px;
		height: 6px;
	}
	@if $dir == 'bottom' {
		left: 4px;
		bottom: 4px;
		width: 10px;
		height: 6px;
	}
}

.common-info-tooltip {
	z-index: 100;
	img {
		margin-bottom: 10px;
		display: block;
		max-width: none;
	}
}

.helpTipContainer,
.accountContainer,
.firstLoginContainer,
.upHelpTipContainerList,
.leftHelpTipContainer {
	cursor: default;
	position: absolute;
	z-index: 10001;
	color: var(--text--base);


	> .tooltip {
		margin: 0 0 0 10px;
		padding: 9px 15px;
		font-size: 11px;
		line-height: 22px;
		text-align: left;

		p {
			display: inline-block;
			font-size: 11px;
			line-height: 11px;
			padding: 0 !important;
			text-align: left !important;
			width: auto !important;
		}

		img {
			margin: 14px 6px 0 5px;
		}
	}

}

.helpTipContainer,
.upHelpTipContainer,
.upHelpTipContainerList,
.leftHelpTipContainer{
	.tooltip {
		background: var(--tooltip--bg);
	}
	.tooltip .arrow {
		background-position: -140px -1257px;
	}
}

.helpTipContainer,
.upHelpTipContainer,
.upHelpTipContainerList,
.leftHelpTipContainer {

	z-index: 1000;

	.tooltip p {
		color: var(--tooltip--text);
		word-wrap: break-word;
		position: relative;
	}

	.tooltipList {
		display: block;
		width: 100%;

		.point {
			display: block;
			width: 100%;
			float: left;
			line-height: 20px;
			font-size: 13px;
		}
	}
}

//Mintakep tooltip kontener
.accountContainer {
	> .tooltip {
		background: var(--tooltip--bg);
	}

	> .tooltip .arrow {
		background-position: -146px -1257px;
	}
}

//Altalanos tooltip class
.tooltip {
	float: left;
	font-size: 11px;
	line-height: 11px;
	max-width: 300px;
	border-radius: 4px;

	.arrow {
		display: block;
		position: absolute;
		z-index: 9999;
		background: url('../App/gfx_icons_sprite.png') no-repeat;
	}

	p,
	ul {
		cursor: default !important;
	}

	h6 {
		color: var(--text--base);
	}

	> [class^=icon-]{
		position: absolute;
		left: 21px;
		top: 8px;
		@include style('icon', 'smallerSize');

		&.icon-alert{
			@include style('icon', 'yellowColor');
		}
	}
}

/* Temporary style for photo gallery page */
#photos_index {
	.tooltip {
		max-width: 600px;
	}
}

//Informacios tooltip kontener
.helpTipContainer {
	display: none;
	top: -10px;
	width: 250px;

	.tooltip {
		line-height: 14px;

		.arrow {
			@include arrowDir('left');
		}

		h6 {
			margin: 10px 0 0 0;
		}

		p {
			margin: 10px 0 10px 0 !important;
		}
	}
}

// Guided Tour tooltipjeinek formazasa
.guide {
	pointer-events: none;

	.tooltip {
		width: 220px;
		padding: 0 30px 0 15px !important;
		position: relative;

		p {
			margin: 10px 0 10px 0 !important;
			white-space: normal;
		}
	}

	&.leftHelpTipContainer {
		.tooltip {
			padding: 0 0 0 30px !important;
		}
	}

	.arrow {
		top: 10px !important;
	}

	.orderNum {
		position: absolute;
		width: 50px;
		height: 50px;
		right: -35px;
		top: -5px;
		background: var(--text--base);
		color: var(--box--main-bg);
		text-align: center;
		font-weight: bold;
		font-size: 36px;
		line-height: 50px;
		border-radius: 50%;
	}

	// Left arrow guide
	&.leftHelpTipContainer {
		.orderNum {
			left: -30px;
		}
	}
}

//Hibauzenet tooltip kontener
.errorContainer {
	top: 0px;
	right: -250px;
}

.inputs {
	.clearLnk {
		position: absolute;
		right: 0;
		top: 0;
		padding: 11px;
		display: none;
	}
	.closeTooltip {
		position: relative;
		margin: 0;
	}
}

.firstLoginContainer {
	top: 32px;
	right: 0px !important;

	.tooltip {
		margin: 10px 0 0 0;
		padding: 20px;
		width: 270px;
	  	position: relative;

		.arrow {
			@include arrowDir('top');

		  	top: -6px;
			background-position: -152px -1257px;
			left: 220px;
		}

		.hugeInfo {
			float: left;
		}

		p {
			width: 200px !important;
			float: left;
			margin: 0px 0px 0px 15px !important;
		}
	}

	#firstLoginContainerClose {
		position: absolute;
		display: block;
		top: 0;
		right: 0;

		.closeTooltip {
			margin: 10px;
		}
	}
}

.upHelpTipContainerList {
	width: 200px;
	right: -15px;
	bottom: 28px;
	display: none;

	&.show {
		display: block;
	}

	.main {
		width: 200px;
		padding: 0 0 4px 0;
		position: relative;

		.arrow {
			bottom: -6px;
		}
	}

	.headText {
		display: block !important;
		line-height: 40px !important;
		margin: 0 0 0 17px !important;
		opacity: 1 !important;
	}

	.tooltipHelp {
		position: absolute;
		right: 10px;
		top: 12px;

		&:hover {
			> span {
				display: block;
			}
		}
	}

	.helpTipContainer {
		left: 12px;

		.tooltip .arrow {
			@include arrowDir('left');

			background-position: -146px -1257px;
			left: -6px;
		}
	}

	.tooltip{
		margin: 0 auto 10px auto;
		float: none;
		display: inline-block;
		background-color: var(--tooltip--bg);
	}

	.arrow{
		width: 10px;
		height: 6px;
		background-position: -152px -1264px !important;
	}
}

//Balra nyilo help tooltip
.leftHelpTipContainer {
	right: 20px;
	top: -10px;
	display: none;
	width: 250px;

	.tooltip {
		margin: 0 10px 0 0;
		float: right;
		background-color: var(--tooltip--bg);

		.arrow {
			@include arrowDir('right');

			background-position: -146px -1267px;
		}
	}
}

@import "tooltip.oranum";

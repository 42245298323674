@import '../../scss/shell';

:global {
	.periodSliderContainer {
		display: inline-block;
		position: relative;
		margin: 3.5rem 0 0.8rem;

		@include _on('mobile') {
			margin: 1.5rem 0 0.8rem;
		}

		.periodContainerText {
			font-weight: bold;
			font-size: 11px;
			line-height: 11px;
			color: var(--date-filter--text);
		}

		.periodStartText,
		.periodEndText {
			display: none;
			position: absolute;
			top: 0;
			white-space: nowrap;
		}

		.periodStartText {
			margin: 0 10px 0 0;
		}

		.periodEndText {
			margin: 0 0 0 10px;
		}

		.periodSliderBg {
			border-radius: 0.4rem;
			height: 0.4rem;
			background-color: var(--date-filter--bg);
			overflow: hidden;
		}

		.periodSliderLine {
			position: absolute;
			top: 0;
			height: 0.4rem;
			background: var(--statistics-income--slider-line-active);
		}

		.periodSliderLineEnd {
			position: absolute;
			top: -1.1rem;
			width: 2.6rem;
			height: 2.6rem;
			background-color: var(--statistics-income--slider-dot);
			border-radius: 50%;
			border: 0.4rem solid var(--statistics-income--slider-dot-border);
			cursor: pointer;
			&:hover {
				background-color: var(--statistics-income--slider-dot-hover);
			}
		}

		.sliderTooltip {
			position: absolute;
			top: -2.1rem;
			background: var(--statistics-income--tooltip-background);
			padding: 0.3rem 0.7rem;
			font-size: 1.2rem;
			white-space: nowrap;
			transform: translate(-50%, -50%);
			left: 50%;
			border-radius: 0.3rem;
			color: var(--statistics-income--tooltip-font);
			&:before {
				content: '';
				display: block;
				position: absolute;
				left: calc(50% - 0.7rem);
				bottom: -0.6rem;
				width: 0;
				height: 0;
				border-style: solid;
				border-width: 0.7rem 0.7rem 0 0.7rem;
				border-color: var(--statistics-income--tooltip-background) transparent transparent transparent;
			}
		}
	}
}
@import '../../scss/shell';

:global {

	.globalNotification {
		position: fixed;
		z-index: 10031;
		overflow: hidden;

		/**
		* Icon Slide
		*/
		&.iconSlide {
			transition: bottom, 0.2s, linear;

			height: 65px;
			bottom: -65px;
			right: 20px;

			.iconWrapper {
				background: var(--text--base);
				float: left;
				padding: 10px;
				z-index: 9999;
				position: relative;
			}

			i {
				font-size: 45px;
			}

			p {
				transition: right, 0.2s, linear, 0.3s;

				right: -100%;
				z-index: 9998;
				float: left;
				position: relative;
				font-weight: bold;
				font-size: 14px;
				line-height: 65px;
				color: var(--notification--icon-silde-text);
				padding: 0 10px;
				background: var(--text--base);
			}
		}

		/**
		* Default Slide
		*/
		&.defaultSlide {
			position: fixed;
			width: 100%;
			background: var(--notification--background);
			font-size: 0;
			text-align: center;

			.iconWrapper {
				display: inherit;
				margin: 18px 10px;
			}

			p {
				font-weight: bold;
				font-size: 14px;
				line-height: 1.4;
				color: var(--notification--text);
			}
		}
		&.overlay-warning {
			background: var(--primary);
		}
		/**
		* Top Slide
		*/
		&.top {
			transition: top, 0.1s, linear;

			top: -41px;
			left: 0;
		}

		/**
		* Bottom Slide
		*/
		&.bottom {
			transition: bottom, 0.5s, linear;

			bottom: -41px;
			left: 0;
		}

		.middleWrapper {
			display: flex;
			text-align: left;
			align-items: center;
			justify-content: center;
		}

		.close {
			position: absolute;
			top: 21px;
			font-size: 16px;
			right: 24px;
			color: var(--text--base);
		}
	}
}

// Loaded by SignupFlow.js
// TODO : be more generic

@use 'global/typography';
@use 'global/vars';
@import '../../../scss/shell';

:global {
	#crossloginnotification_index #main_content,
	#signup_signupflow #main_content {
		width: 90%;
		max-width: 830px;
		margin: 0 auto;
	}

	#signup_signupflow.livejasmin-theme #main_content {
		max-width: 920px;
	}

	#signup_signupflow #main_content {
		@include _on('mobile') {
			width: 100%;
			max-width: 100%;
		}
	}

	@include _on('mobile') {
		.box_container.independentContainer.normal.displayNameOverlayBox form-submit>div {
			display: block;

			.button {
				width: 100%;
			}
		}
	}

	.signupPersonViewOverlayBox {
		.skipBox {
			padding: 0 3rem;
		}

		.box_header {
			height: 5rem;
		}

		.ph-box__content {
			padding: 0 3rem 3rem 0;
		}
	}

	#authentication_login #main_content,
	#authentication_loginpost #main_content {
		margin: 0 auto vars.spacer(12);
		width: 90%;
		max-width: 830px;

		@include _on('small', 'down') {
			margin: 0 auto;
		}

		@include _on('mobile') {
			width: 100%;
		}

		form {
			.forgotLink {
				font-size: 1.2rem;
				margin: 0 0 0 1.5rem;

				@include onSmallAndDown {
					margin: 1.5rem 0 0;
				}
			}

			.registerLink {
				font-size: 1.5rem;
				font-weight: bold;
			}
		}
	}

	#authentication_forgotpassword #main_content,
	#authentication_resetpassword #main_content,
	#authentication_changepassword #main_content {
		width: 90%;
		max-width: 830px;
		margin: 0 auto vars.spacer(12);

		@include _on('small', 'down') {
			margin: 0 auto;
		}

		@include _on('mobile') {
			width: 100%;
		}

		.box_container {

			> p {
				text-align: center;
				margin: 3rem 0 0;
			}

			hr {
				float: none;
			}
		}

		form {
			.secondaryLarge,
			form-submit + a {
				margin: 0 0 0 5px;
			}

		}

		.passwordStatusBackBtn {
			margin-top: 30px;
		}
	}

	#forgetdataconfirmation_message,
	#authentication_changepasswordrequest #main_content,
	#authentication_confirmemail #main_content,
	#authentication_confirmemailfinish #main_content {
		width: 90%;
		max-width: 830px;
		margin: 50px auto 50px auto;
		text-align: center;

		@include _on('small', 'down') {
			margin: 0 auto;
		}

		.submitBtn {
			margin: 14px 0 0 0;
		}
	}

	#forgetdataconfirmation_message {
		padding: 80px 0;
	}

	#personCreationStepsBlock {
		position: absolute;
		top: 3rem;
		left: 0;
		z-index: 1;

		@include _on('mobile') {
			top: 0;
		}

		+ .overlayInnerBox {

			#newModelContent {
				padding-top: 9rem;

				@include _on('mobile') {
					padding-top: 3rem;
				}
			}

			@include _on('mobile') {
				margin-top: 5rem !important;
			}
		}
	}

	#crossloginnotification_index #main_content .ph-box__content {
		h2 {
			color: var(--crosslogin--header);
			margin-bottom: 28px;
		}

		p {
			color: var(--crosslogin--text);
			margin-bottom: 28px;
		}

		strong {
			color: var(--crosslogin--text-strong);
		}

		a {
			margin: 10px 0 0;

			&:nth-of-type(2) {
				display: block;
				font-size: 12px;
			}
		}
	}

	#personCreationStepsBlock,
	#companySteps,
	#registrationStepsBlock {
		padding: 6rem 0 5rem 0;
		background: var(--stepcrumb--background);
		float: none;

		@include _on('mobile') {
			padding: 2.4rem 0 0;
			background: var(--stepcrumb--background-mobile);
		}

		span {
			display: none;
			width: 2.4rem;
			height: 2.4rem;
			line-height: 2rem;
			position: inherit;
			top: -0.1rem;
			left: -1rem;
		}

		span.step-rank {
			display: inline-block;
			top: -0.7rem;
			left: -1rem;
            @include typography.body1Bold;
            line-height: 2.2rem;
			border-radius: 50%;
			border: 0.1rem solid var(--registration--step);
			color: var(--registration--step);
		}

		ul {
			text-align: center;

			@include _on('small', 'down') {
				min-width: auto;
				margin-left: 2rem;
			}

			li {
				display: inline-block;
				font-weight: bold;
				font-size: 1.6rem;
				line-height: 3rem;
				margin: 0 3.8rem 0 0;
				position: relative;
				color: var(--common--success);

				@include _on('small', 'down') {
					min-width: auto;
					margin: 0 2.1rem 0 0;
				}

				&.active {
					color: var(--text--base);

					span.step-rank {
						background: var(--registration--step-active-bg);
						color: var(--registration--step-active);
						border-color: var(--registration--step-active-border);
					}

					&~li {
						color: var(--registration--step-text);
						opacity: 1;
					}
				}

				&.done,
				&.stepDone {
					span {
						display: inline-block;

						i {
							font-size: 2.4rem;
						}
					}

					.step-rank {
						display: none;
					}

					em, i {
						color: var(--icon--ok--green);
					}
				}

				&:last-child {
					margin: 0;
				}

				em {
					position: relative;
					top: -0.5rem;
				}

				@include _on('small', 'down') {
					em {
						display: none;
					}

					&:after {
						content: '';
						display: inline-block;
						position: relative;
						width: 1rem;
						height: 0.1rem;
						top: -1rem;
						background: var(--stepcrumb--dash);
					}

					&.done:after,
					&.stepDone:after {
						background: var(--icon--ok--green);
					}

					&:last-child:after {
						display: none;
					}
				}
			}
		}

		@import 'Authentication.oranum';
	}

	// display name block
	.snss {
		&__table {
			display: table;
			float: none;
		}

		&__row {
			display: table-row;
			float: none;

			@include _on(small, down) {
				display: block;
			}
		}

		&__cell {
			display: table-cell;
			vertical-align: top;
			float: none;
		}

		&-label {
			position: absolute;
			right: calc(100% + 2.5rem);
			font-size: 1.4rem;
			top: 0.4rem;

			@include _on('small', 'down') {
				right: auto;
				left: 0;
				top: -2.5rem;
			}
		}

		&-container {
			margin-top: 1rem;
			position: relative;
			padding-right: 5rem;
			text-align: left;
			height: 3.5rem;
			overflow: hidden;

			@include _on('small', 'down') {
				margin-top: 2rem;
			}

			.button {
				margin: 0 1rem 1rem 0;

				@include _on('small', 'down') {
					margin-right: 0.5rem;
				}
			}

			> span {
				font-size: 1.2rem;
				line-height: 2.5rem;
				display: inline-block;
				margin-bottom: 1rem;
			}
		}
	}

	.oranum-theme {
		.snss {
			text-align: center;

			.step-title {
				font-size: 2.4rem;
				margin: 0 0 3.3rem;
				color: var(--text--secondary);
			}

			.step-paragraph {
				font-size: 1.2rem;
				color: var(--registration--step-text);
				line-height: 1.6rem;
				text-align: left;
			}

			.step-subtitle {
				font-size: 1.8rem;
				margin: 2.5rem 0 3.6rem 0;
				color: var(--text--base);
				font-weight: 600;
			}

			& > ph-row.display-block {
				display: block;
				margin: 0 auto;
			}
		}
	}

	#personCreationStepsBlock {
		@include _on('mobile') {
			padding: 2rem 0 0.6rem;
		}
	}

	#registrationReady {
		margin: 44px 0;
		font-weight: bold;
		font-size: 15px;
		line-height: 26px;
		text-align: center;
		color: var(--text--base);
		opacity: 0.5;
		filter: alpha(opacity=50);

		.done {
			margin: 0 14px 3px 0;
			vertical-align: middle;
		}
	}

	.displayNameHint,
	.hiddenFaceHint {
		@include typography.captionRegular;
		color: var(--registration--step-text);
		text-align: left;
	}

	.displayNameHint {
		margin-top: vars.spacer(2);
	}

	.hiddenFaceHint {
		margin-top: -(vars.spacer(1));
		margin-left: 3.3rem;

		strong {
			color: var(--registration--step-text-bold);
		}
	}

	.hiddenFaceContainer {
		text-align: left;

		.seeMore,
		.seeLess {
			@include typography.body2Bold;

			&:hover {
				color: var(--registration--step-text-bold);

				&::after {
					content: none;
				}
			}
		}

		form-input {
			margin-top: vars.spacer(3);
		}

		input[type=radio]+label {
			top: 0.7rem;
		}

		input[type=radio]+label+label {
			padding-left: 0.6rem;
		}

		label {
			font-weight: bold;
		}
	}

	.hiddenFaceTitle {
		@include typography.title2Bold;
		margin-bottom: vars.spacer(1);
	}

	.hiddenFaceDescription {
		@include typography.body2Regular;
		color: var(--registration--step-text);
		margin-bottom: vars.spacer(1);
	}

	#signup_signupflow div.displayNameCheckboxBlock div.form-input-container {
		display: flex;
		align-items: center;

		label.ph-inline-block {
			font-size: 1.2rem;
			line-height: 1.6rem;
			color: var(--registration--step-text);
		}
		label.icon-check {
			top: 0;
		}
	}

	.modified-breakpoint-on-small {
		@include _on('small') {
			.form-input-additions--outside {
				left: auto;
				right: 0;
				top: -4rem;
				height: 4rem;
			}
		}
	}

	.modified-height-on-small-and-below {
		@include _on('small', 'down') {
			.form-input-additions--outside {
				top: -9rem;
			}
		}
	}

	#signup_signupflow,
	.signupPersonOverlayBox,
	.signupPersonViewOverlayBox {
		.labelContainer {
			display: flex;
			height: 4rem;
			justify-content: right;
			align-items: center;

			label {
				line-height: 1.3;
			}

			@include _on('mobile') {
				height: auto;
			}
		}

		.id-uploader-block__uploaded img {
			max-width: 100%;
		}
	}

	#authentication_confirmemail {
		.box_container {
			padding: 0;
		}

		.box_header {
			height: 5rem;
			padding: 1.9rem 3rem;
		}

		.emailConfirmationStep {
			padding: 4rem 3rem;

			p {
				margin-top: 1.5rem;
			}
		}

		#main_content .submitBtn {
			margin-top: 3.5rem;
		}
	}

	.signupPersonOverlayBox .emailConfirmationStep {
		padding-top: 7rem;
	}

	.emailConfirmationStep {
		text-align: center;
		padding: 1rem 0;

		.hiddenBlock {
			height: 0;
			visibility: hidden;
		}

		.emailConfirmationIcon {
			margin-bottom: vars.spacer(2);
			font-size: 3.2rem;
			display: inline-block;

			&.icon-icon-warning {
				color: var(--common--warning);
			}
			&.icon-ok {
				color: var(--common--success);
			}
		}

		p {
			font-size: 1.4rem;
			line-height: 1.4;
			max-width: 60rem;
			margin: 2rem auto 0;
			color: var(--email-confirmation--text);

			span {
				color: var(--text--link);
				cursor: pointer;
			}
		}

		.button {
			margin: vars.spacer(8) vars.spacer(2) 0;
		}

		.emailConfirmationStepContent {
			max-width: 50rem;
			margin: 0 auto;

			.emailConfirmationIcon {
				display: inline-block;
				font-size: 3.2rem;
				margin-bottom: vars.spacer(2);
				color: var(--email-confirmation--icon);
			}

			.confirmEmailText {
				display: inline-block;
				margin: 0.2rem 0 0.8rem;
				font-size: 1.4rem;
				font-weight: bold;
				color: var(--email-confirmation--title);
			}
		}

		.emailConfirmationStepForm {
			position: relative;
			padding-top: 1rem;
			width: 100%;
			display: inline-block;

			.icon-gallery-close-x {
				color: var(--overlay--close-button);
				font-size: 1.4rem;
				position: absolute;
				z-index: 1;
				top: -1rem;
				right: -1rem;
				cursor: pointer;

				@include _on('mobile') {
					right: 0;
				}
			}
		}
	}

	@include _on('mobile') {
		#signup_signupflow,
		.signupPersonOverlayBox {
			.ph-box__header {
				height: 4rem;
				border-bottom: 1px solid var(--registration--step-border);
			}

			.ph-form label {
				line-height: 3rem;
			}

			.signupBox {
				padding: 0 2rem 2rem;
				background: var(--box--main-bg);
				box-shadow: none;
			}

			.box_header {
				text-align: center;
				margin: 0 2rem;
				padding: 2rem !important;
				overflow: initial;
				border-bottom: 1px solid var(--registration--step-border);
				background: var(--box--main-bg);

				.closeButton {
					top: -4.7rem;
					right: -1.7rem;

					i {
						text-shadow: none;
					}
				}
			}

			.signupBoxTitle {
				padding: 0;
				text-align: center;
				background: var(--box--main-bg);
			}

			.signupBoxContent {
				padding: 2.5rem 0;
				box-shadow: none;
			}

			form-submit>div {
				display: block;
			}

			.button {
				width: 100%;
			}

			.ph-form-mobile .form-input-additions--outside {
				height: 5rem;
			}

			form-input[name="lastName"] .form-input-additions--outside {
				top: -9rem;
			}

			.protip-container[data-pt-position="top-right"] .protip-arrow {
				right: 1rem;
			}
		}
	}

	input[name="isAccepted"] + label + label {
		padding-top: 0.4rem;
		line-height: 1.4 !important;

		a {
			font-weight: bold;
		}
	}
	.disclamer {
		margin-top: 1em;
		font-size: 1.4rem;
		color: var(--registration--step-text);
		line-height: 1.4;
	}

	.uploadConsent,
    .uploadBioConsent {
		padding: 0.6rem 2rem 3.5rem;

		@include _on('mobile') {
			padding: 0.6rem 0 3.5rem;
		}

		label {
			line-height: 1.3 !important;
			padding-right: 0 !important;
			color: var(--text--secondary);
		}

		.icon-check {
			top: 0 !important;
		}

		.form-input-additions {
			display: none;
		}
	}

	.id-uploader-form .uploadConsentTooltip,
    .id-uploader-form .uploadBioConsentTooltip {
		display: none;
	}

	.docler-theme {
		.disclamer {
			color: var(--text--tertiary);
		}
	}
	.oranum-theme,
	.docler-theme {
		.id-uploader-form .ph-p--secondary {
			color: var(--text--base);
		}
		.uploader5__details {
			color: var(--text--tertiary);
		}
	}
}

@use 'global/colors';
@use 'global/icons';
@use 'global/typography';
@use 'global/vars';
@import '../../../scss/shell';

:global {
	/* Jasmin Selection*/
	#jasminSelectionContainer {
		--selection-complete-icon: #{colors.get(alert, success_500)};
		--selection-warning-icon: #{colors.get(yellow, 500)};
		--selection-badge-gradient: #{colors.get-gradient(progress_500_yellow_500)};
		--selection-badge-bg: #{colors.get(red, 500)};
		--selection-content-bg: #{colors.get(red, 400, 0.4)};
		--selection-content-h3: #{colors.get(yellow, 500)};
		--selection-countdown-bg: #{colors.get(red, 700)};
		--selection-countdown-time: #{colors.get(pink, 400)};
		--selection-countdown-text: #{colors.get(pink, 500)};

		.activeSelectionContent,
		.inactiveSelectionContent {
			display: flex;
			align-items: center;
		}

		&.inactive .activeSelectionContent,
		&.active .inactiveSelectionContent {
			display: none;
		}

		&.inactive .widget-jasmin-selection-slide,
        &.active .widget-jasmin-selection-slide {
            padding-left: 48px;

			&__badge {
				position: absolute;
				left: 0;
				top: 0;
				height: 4.8rem;
				width: 4.8rem;
				display: flex;
				align-items: center;
				justify-content: center;
				border-radius: vars.border-radius('circle');
				background: var(--selection-badge-gradient);

				&:before {
					content: '';
					position: absolute;
					top: 0.3rem;
					bottom: 0.3rem;
					left: 0.3rem;
					right: 0.3rem;
					background-color: var(--selection-badge-bg);
					border-radius: vars.border-radius('circle');
				}

				i {
					@include typography.text-gradient(var(--selection-badge-gradient));
					z-index: 1;
					font-size: 2.4rem;
				}
			}
		}

        &.inactive .widget-jasmin-selection-slide {
			span {
				opacity: 0.5;
			}
		}
	}

	.selectionContainer {
		padding: vars.spacer(10) vars.spacer(12);
	}

	.selectionIndicatorContainer {
		padding: vars.spacer(10) vars.spacer(12);
		background: var(--selection-content-bg);

		img {
			display: block;
			margin-right: vars.spacer(8);
			width: 20rem;
		}

		h2 {
			@include typography.h2Bold;
			margin-bottom: vars.spacer(4);
		}

		h3 {
			@include typography.h3Bold;
			margin: 0;
			color: var(--selection-content-h3);
		}

		.inactiveSelectionContent .first {
			color: var(--common--alert);
		}

		.activeSelectionContent {
			position: relative;

			.activeSelectionTextContent {
				display: flex;
			}

			.countdownContainer {
				flex-shrink: 0;
				margin-left: 24px;
			}

			.bigCountdown {
				display: inline-block;

				div {
					float: left;
					width: 4.5rem;
					margin: 0 0.1rem 0 0;
					padding: vars.spacer(2) 0 0;
					background: var(--selection-countdown-bg);
					color: var(--selection-countdown-time);
					text-align: center;

					&.done {
						opacity: .5;
					}

					&.first {
						border-radius: vars.border-radius(1);
						margin: 0 vars.spacer(2) 0 0;
					}

					&.first + div {
						border-radius: 5px 0 0 5px;
					}

					&:last-child {
						border-radius: 0 5px 5px 0;
					}

					span {
						@include typography.title1Regular;
					}

					p {
						@include typography.fineprint1Regular;
						margin: vars.spacer(1) 0;
					}
				}
			}

			.timeLeft {
				@include typography.body2Bold;
				margin-top: vars.spacer(1);
				text-align: center;
				color: var(--selection-countdown-text);
			}
		}
	}

	.selectionTaskListContainer {
		float: left;
		width: 610px;
		margin: 0 vars.spacer(10) vars.spacer(10) 0;

        .icon-warning-circular-solid {
            font-size: 2.4rem;
            color: var(--selection-warning-icon);
        }

        .icon-check-circular-solid {
            font-size: 2.4rem;
            color: var(--selection-complete-icon);
        }

		.widget-main-item.earn-more {
			padding: 0;
			background-color: var(--primary);

			ul li {
				background-color: var(--earn-more--selection--widget-li-bg);

				&:hover {
					background-color: var(--earn-more--selection--widget-li-hover-bg);
				}
			}

			.time {
                height: 50px;
				font-size: 12px;

                a {
                    div {
                        display: flex;
                        align-items: center;
                        justify-content: end;
                    }

                    i {
                        font-size: 24px;
                    }

                    &:hover i::before {
                        content: icons.get('arrow-right-solid');
                    }
                }
			}

			.deadline, .countdown {
				position: absolute;
				right: 18rem;
                top: 1.8rem;
			}

			.icon-ok {
				font-size: 18px;
			}

			.completed,
			.invalid {
				background: var(--earn-more--selection--widget-completed-bg);
				cursor: default;

				&:hover {
					background: var(--earn-more--selection--widget-completed-bg);
				}

				.title {
					color: var(--earn-more--selection--widget-completed-title-text);
					font-weight: normal;
					padding: 0 0 0 17px;
				}

				.time {

					span {
						font-weight: bold;
						color: var(--common--success);
					}
				}
			}

			.unfixed .title {
				padding: 0 0 0 2px;
			}

			.invalid .title {
				color: var(--text--secondary) !important;
				font-size: 14px;
				padding: 0 0 0 27px;

				&:before {
					display: inline-block;
					content: "";
					width: 6px;
					height: 6px;
					background: var(--text--secondary);
					margin-right: 13px;
					position: relative;
					top: -1px;
					left: -6px;
				}
			}
		}
	}

	.selectionEarningsContainer {
		float: left;

		h2 {
			@include typography.body2Bold;
			text-transform: uppercase;
			margin-bottom: vars.spacer(4);
		}

		.widget-secondary-item {
			border: none;
		}

		ul {
			padding-top: vars.spacer(2);
		}

		li {
			display: flex;
			align-items: center;
			margin: 0 0 14px;

			span {
				max-width: 150px;
			}
		}

		hr {
			margin: 20px auto !important;
		}

		h2, li, .regularTextNormal {
			max-width: 20.4rem;
		}
	}
}
@import '../../../scss/shell';
/**
 * Go Online Notification overlay
 */

:global {

	#goOnlineNotificationOverlay {
		padding: 4rem 9rem 3rem;
		text-align: center;

		@include _on('mobile') {
			padding: 4rem 4rem 3rem;
		}

		.box_header {
			display: none;
		}

		h3 {
			font-size: 2.4rem;
			font-weight: normal;
		}

		p {
			font-size: 1.6rem;
			line-height: 2.5rem;
		}

		.button {
			margin: 3rem 1rem 0;
		}
	}
}
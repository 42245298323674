.ribbon {
	position       : absolute;
	width          : 12rem;
	height         : 12rem;
	top            : -0.1rem;
	right          : -0.1rem;
	overflow       : hidden;
	z-index        : 5;
	pointer-events : none !important;
	cursor         : pointer;
	&::after {
		transform: rotate(45deg);
		backface-visibility: hidden;
		@include _horizontal-gradient(var(--ribbon--shadow-end), var(--ribbon--shadow-end-darken));

		content                     : attr(data-title);
		display                     : block;
		position                    : absolute;
		top                         : 2.8rem;
		right                       : -3.5rem;
		width                       : 15.6rem;
		height                      : 3.1rem;
		text-transform              : uppercase;
		text-align                  : center;
		font                        : normal 1.4rem/3.1rem Roboto, sans-serif;
		color                       : var(--ribbon--text);
		box-shadow                  : 0 0.1rem 0.2rem 0 RGBA(var(--ribbon--shadow-start-rgb), 0.4), inset 0 0.1rem 0 0 RGBA(var(--ribbon--shadow-inner-rgb), 0.4);
	}
}

@include _on('mobile') {
	.ribbon {
		width: 7rem;
		height: 7rem;

		&::after {
			width : 12.7rem;
			height : 2.1rem;
			top: 1.7rem;
			font-size: .9rem;
			line-height: 2.1rem;
		}
	}
}

.ribbon-small {
	width: 7rem;
	height: 7rem;
	top: -0.2rem;
	right: -0.2rem;

	&::after {
		width: 11.4rem;
		height: 2.6rem;
		top: 0.9rem;
		font-size: 1rem;
		line-height: 2.8rem;
		font-weight: 500;
	}
}

.ribbon-tooltip {
	width: 3.2rem;
	height: 3.2rem;
	top: 0;
	right: 0;

	&::after {
		@include _horizontal-gradient(var(--ribbon--bg-start), var(--ribbon--bg-end));
		top : 0.5rem;
		right : -0.9rem;
		width : 4rem;
		height : 1.1rem;
		font-size: .8rem;
		font-weight: 900;
		line-height: 1.4;
		color: var(--ribbon--text-new);
	}
}

.ribbon-button {
	width: 4.4rem;
	height: 4.4rem;

	&::after {
		@include _horizontal-gradient(var(--ribbon--bg-start), var(--ribbon--bg-end));
		top : 0.4rem;
		right : -1.6rem;
		width : 6.1rem;
		height : 2.1rem;
	}

	i {
		position: absolute;
		z-index: 6;
		top: 0.8rem;
		left: 2.3rem;
		color: var(--ribbon--icon);
		font-size: 1.3rem;
	}
}

@import '../../scss/shell';

:global {
	.snapshot {
		position: relative;
		width: 830px;
		min-height: 710px;
		text-align: center;

		&__picture {
			margin: 30px 0;
			display: inline-block;
			width: 770px;
			height: 576px;
			position: relative;

			&-camera {
				background-color: var(--snapshot--camera-bg);
				position: absolute;

				object {
					position: absolute;
					top: 0;
					left: 0;
				}
			}

			&-result {
				position: absolute;
				top: 0;
				left: 0;
				width: 770px;
				height: 576px;

				img {
					height: 100%;
					width: 100%;
				}
			}

			&-grid {
				position: absolute;
				width: 100%;
				height: 100%;
				top: 0;
				left: 0;

				span {
					position: absolute;
					bottom: 30px;
					right: 25px;
					font-size: 28px;
					font-weight: bold;
					color: var(--common--alert);
				}

				&__picture {
					width: 372px;
					height: 412px;
					border-top: dashed;
					border-right: dashed;
					position: absolute;
					right: 104px;
					top: 5px;
					border-width: 6px;
					border-color: var(--common--alert);

					span {
						text-transform: uppercase;
					}

					&__left-top {
						position: absolute;
						top: 0;
						left: 0;
						height: 245px;
						border-left: dashed;
						border-width: 6px;
						border-color: var(--common--alert);
					}

					&__bottom {
						position: absolute;
						bottom: 0;
						right: 0;
						width: 240px;
						border-bottom: dashed;
						border-width: 6px;
						border-color: var(--common--alert);
					}
				}

				&__id {
					width: 326px;
					height: 206px;
					border: dashed;
					position: absolute;
					left: 80px;
					border-width: 6px;
					border-color: var(--common--alert);
					bottom: 100px;

					span {
						text-transform: uppercase;
					}
				}
			}

			.cameraFlash {
				background: var(--text--base);
				position: absolute;
				top: 0;
				width: 100%;
				height: 100%;
			}
		}

		&__warning {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background-color: var(--snapshot--warning-bg);

			&__camera {
				position: absolute;
				top: 200px;
				left: 0;
				right: 0;
				margin: auto;
				box-sizing: border-box;
				padding: 10px;
				z-index: 2;
				border-radius: 10px;
				line-height: 36px;
				font-size: 16px;
				color: var(--text--base);

				i {
					color: var(--common--alert);
					font-size: 22px;
				}

				button {
					margin: 10px 0;
				}
			}
		}

		&__counter {
			position: absolute;
			bottom: 30px;
			left: 0;
			right: 0;
			text-align: center;

			span {
				font-size: 45px;
				font-weight: bold;
				color: var(--snapshot--counter-text);
				padding: 0 20px;

				&.count {
					color: var(--snapshot--counter-text-active);
				}
			}
		}

		&__take {
			position: absolute;
			bottom: 30px;
			left: 0;
			right: 0;
		}

		&__ready {
			position: absolute;
			bottom: 30px;
			left: 0;
			right: 0;

			> span {
				float: left;
				border-radius: 4px;
				background-color: var(--snapshot--ready-bg);
				font-weight: bold;
				font-size: 14px;
				line-height: 42px;
				display: inline-block;
				width: 360px;
				height: 42px;

				i {
					position: relative;
					top: 2px;
					margin: 0 5px 0 0;
				}
			}
		}

		button {
			outline: 0;
		}

		&__button {
			&-take {
				max-width: 357px;
			}
			&-reset {
				max-width: 357px;
			}
		}
	}
}
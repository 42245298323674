[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-basketStat:before {
  content: "\e9fd";
}
.icon-add-portrait-video:before {
  content: "\e9fb";
}
.icon-settings-dots:before {
  content: "\e9fc";
}
.icon-star-circle-outline:before {
  content: "\e9f4";
}
.icon-tipStat:before {
  content: "\e9f7";
}
.icon-heart_outlined:before {
  content: "\e9f9";
}
.icon-heart_solid:before {
  content: "\e9fa";
}
.icon-email-confirm:before {
  content: "\e9f6";
}
.icon-oranum-app:before {
  content: "\e9f8";
  color: #9a35c0;
}
.icon-docler-app:before {
  content: "\e9f5";
  color: #7ea6f4;
}
.icon-battleStat:before {
  content: "\e900";
}
.icon-listStat:before {
  content: "\e9f2";
}
.icon-hotShowStat:before {
  content: "\e901";
}
.icon-modelReferralStat:before {
  content: "\e9f0";
}
.icon-minus-solid:before {
  content: "\e9ef";
}
.icon-creator-app:before {
  content: "\e9ee";
}
.icon-awardsStat:before {
  content: "\e9e0";
}
.icon-bonusStat:before {
  content: "\e9e1";
}
.icon-highlightsStat:before {
  content: "\e9e2";
}
.icon-imageStat:before {
  content: "\e9e3";
}
.icon-liveStat:before {
  content: "\e9e4";
}
.icon-messengerStat:before {
  content: "\e9e5";
}
.icon-myStoryStat:before {
  content: "\e9e6";
}
.icon-plusStat:before {
  content: "\e9e7";
}
.icon-referralStat:before {
  content: "\e9e8";
}
.icon-subscriptionsStat:before {
  content: "\e9e9";
}
.icon-surpriseStat:before {
  content: "\e9ea";
}
.icon-videoCallStat:before {
  content: "\e9eb";
}
.icon-videoStat:before {
  content: "\e9ec";
}
.icon-vipStat:before {
  content: "\e9ed";
}
.icon-play-filled:before {
  content: "\e9df";
}
.icon-birthday .path1:before {
  content: "\e902";
  color: rgb(166, 0, 0);
}
.icon-birthday .path2:before {
  content: "\e903";
  margin-left: -1em;
  color: rgb(237, 122, 122);
}
.icon-birthday .path3:before {
  content: "\e904";
  margin-left: -1em;
  color: rgb(255, 204, 204);
}
.icon-birthday .path4:before {
  content: "\e905";
  margin-left: -1em;
  color: rgb(255, 204, 204);
}
.icon-birthday .path5:before {
  content: "\e906";
  margin-left: -1em;
  color: rgb(255, 204, 204);
}
.icon-birthday .path6:before {
  content: "\e907";
  margin-left: -1em;
  color: rgb(255, 204, 0);
}
.icon-birthday .path7:before {
  content: "\e908";
  margin-left: -1em;
  color: rgb(255, 204, 0);
}
.icon-birthday .path8:before {
  content: "\e9f3";
  margin-left: -1em;
  color: rgb(255, 204, 0);
}
.icon-icon-warning:before {
  content: "\e9d6";
}
.icon-camera2:before {
  content: "\e9d5";
}
.icon-share-arrow:before {
  content: "\e9d2";
}
.icon-video-recorder:before {
  content: "\e9d3";
}
.icon-notes:before {
  content: "\e9d4";
}
.icon-notification:before {
  content: "\e9d1";
}
.icon-stream2:before {
  content: "\e9ce";
}
.icon-mobile-new:before {
  content: "\e9cf";
}
.icon-live-badge:before {
  content: "\e9d0";
}
.icon-stream:before {
  content: "\e9cd";
}
.icon-influencery-app:before {
  content: "\e9cc";
}
.icon-icon_addgroup:before {
  content: "\e9cb";
}
.icon-infinity:before {
  content: "\e9ca";
}
.icon-favorite:before {
  content: "\e9c9";
}
.icon-eye-earn-more:before {
  content: "\e9c8";
}
.icon-lll .path1:before {
  content: "\e9c6";
  color: rgb(0, 0, 0);
  opacity: 0.2;
}
.icon-lll .path2:before {
  content: "\e9c7";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-certified-circle:before {
  content: "\e925";
}
.icon-peopleStat:before {
  content: "\e96f";
}
.icon-remove-promotion:before {
  content: "\e9c4";
}
.icon-add-promotion:before {
  content: "\e9c5";
}
.icon-pricetag:before {
  content: "\e9c3";
}
.icon-people2:before {
  content: "\e9c1";
}
.icon-gift:before {
  content: "\e9c2";
}
.icon-mask:before {
  content: "\e9bd";
}
.icon-diamond:before {
  content: "\e9be";
}
.icon-share:before {
  content: "\e9bf";
}
.icon-lips-2:before {
  content: "\e9c0";
}
.icon-good:before {
  content: "\e9b8";
}
.icon-exclamation-thin:before {
  content: "\e9b9";
}
.icon-plus-circle-thin:before {
  content: "\e9bc";
}
.icon-garbage-closed:before {
  content: "\e9ba";
}
.icon-garbage-open:before {
  content: "\e9bb";
}
.icon-mic:before {
  content: "\e909";
}
.icon-pause-rounded:before {
  content: "\e9b6";
}
.icon-coins-2:before {
  content: "\e9b3";
}
.icon-members-plus:before {
  content: "\e9b4";
}
.icon-growth:before {
  content: "\e9b5";
}
.icon-earnings:before {
  content: "\e9af";
}
.icon-traffic:before {
  content: "\e9b0";
}
.icon-mobile:before {
  content: "\e9b1";
}
.icon-ios-sync:before {
  content: "\e9ad";
}
.icon-sync:before {
  content: "\e9ae";
}
.icon-cam-off:before {
  content: "\e9ab";
}
.icon-ios-cam-off:before {
  content: "\e9ac";
}
.icon-ios-call:before {
  content: "\e9a3";
}
.icon-ios-mic-off:before {
  content: "\e9a4";
}
.icon-ios-mic:before {
  content: "\e9a5";
}
.icon-ios-cam:before {
  content: "\e9a6";
}
.icon-call:before {
  content: "\e9a7";
}
.icon-mic-off:before {
  content: "\e9a8";
}
.icon-mic1:before {
  content: "\e9a9";
}
.icon-cam:before {
  content: "\e9aa";
}
.icon-folder-empty:before {
  content: "\e9a2";
}
.icon-mc-app-icon:before {
  content: "\e9a1";
}
.icon-lips:before {
  content: "\e9a0";
}
.icon-add-note:before {
  content: "\e90a";
}
.icon-add-photo:before {
  content: "\e90b";
}
.icon-add-photo-album:before {
  content: "\e90c";
}
.icon-add-video:before {
  content: "\e90d";
}
.icon-add-video-album:before {
  content: "\e90e";
}
.icon-alert:before {
  content: "\e90f";
}
.icon-angle-double-right:before {
  content: "\e910";
}
.icon-angle-double-up:before {
  content: "\e911";
}
.icon-angle-down:before {
  content: "\e912";
}
.icon-angle-left:before {
  content: "\e913";
}
.icon-angle-right:before {
  content: "\e914";
}
.icon-angle-up:before {
  content: "\e915";
}
.icon-arrow-curve-left:before {
  content: "\e916";
}
.icon-arrow-left:before {
  content: "\e917";
}
.icon-arrow-pager-left:before {
  content: "\e918";
}
.icon-arrow-pager-right:before {
  content: "\e919";
}
.icon-arrow-right:before {
  content: "\e91a";
}
.icon-asacp-logo:before {
  content: "\e91b";
}
.icon-awards:before {
  content: "\e91c";
}
.icon-ban:before {
  content: "\e91d";
}
.icon-calendar:before {
  content: "\e91e";
}
.icon-cam1:before {
  content: "\e91f";
}
.icon-cam2cam:before {
  content: "\e920";
}
.icon-camera:before {
  content: "\e921";
}
.icon-caret-old-down:before {
  content: "\e922";
}
.icon-caret-old-left:before {
  content: "\e923";
}
.icon-caret-old-right:before {
  content: "\e924";
}
.icon-caret-old-up:before {
  content: "\e926";
}
.icon-category-adviser:before {
  content: "\e927";
}
.icon-category-content:before {
  content: "\e928";
}
.icon-category-cosplay:before {
  content: "\e929";
}
.icon-category-dancer:before {
  content: "\e92a";
}
.icon-category-dj:before {
  content: "\e92b";
}
.icon-category-fashion:before {
  content: "\e92c";
}
.icon-category-fitness:before {
  content: "\e92d";
}
.icon-category-soulmate:before {
  content: "\e92e";
}
.icon-check:before {
  content: "\e92f";
}
.icon-checklist-circle:before {
  content: "\e930";
}
.icon-chevron-left:before {
  content: "\e931";
}
.icon-chevron-right:before {
  content: "\e932";
}
.icon-circle:before {
  content: "\e933";
}
.icon-clock:before {
  content: "\e934";
}
.icon-clock-back:before {
  content: "\e935";
}
.icon-close:before {
  content: "\e936";
}
.icon-close-filled:before {
  content: "\e937";
}
.icon-coins:before {
  content: "\e938";
}
.icon-coins-up:before {
  content: "\e939";
}
.icon-comments:before {
  content: "\e93a";
}
.icon-continue:before {
  content: "\e93b";
}
.icon-createmodel:before {
  content: "\e93c";
}
.icon-crown:before {
  content: "\e93d";
}
.icon-devices:before {
  content: "\e93e";
}
.icon-download:before {
  content: "\e93f";
}
.icon-edit:before {
  content: "\e940";
}
.icon-error:before {
  content: "\e941";
}
.icon-exclamation:before {
  content: "\e942";
}
.icon-eye:before {
  content: "\e943";
}
.icon-fill-size:before {
  content: "\e944";
}
.icon-film:before {
  content: "\e945";
}
.icon-flag:before {
  content: "\e946";
}
.icon-folder:before {
  content: "\e947";
}
.icon-folder-full:before {
  content: "\e948";
}
.icon-full-screen:before {
  content: "\e949";
}
.icon-gallery-close-x:before {
  content: "\e94a";
}
.icon-gem:before {
  content: "\e94b";
}
.icon-handshake-circle:before {
  content: "\e94c";
}
.icon-help:before {
  content: "\e94d";
}
.icon-house:before {
  content: "\e94e";
}
.icon-icra-logo:before {
  content: "\e94f";
}
.icon-ideas:before {
  content: "\e950";
}
.icon-income-circle:before {
  content: "\e951";
}
.icon-info-circle:before {
  content: "\e952";
}
.icon-jasmin:before {
  content: "\e953";
}
.icon-jasmincam:before {
  content: "\e954";
}
.icon-livejasmin-circle:before {
  content: "\e955";
}
.icon-jasmin-logo:before {
  content: "\e956";
}
.icon-landing:before {
  content: "\e957";
}
.icon-left-quote:before {
  content: "\e958";
}
.icon-like:before {
  content: "\e959";
}
.icon-logout:before {
  content: "\e95a";
}
.icon-magnifier:before {
  content: "\e95b";
}
.icon-mc:before {
  content: "\e95c";
}
.icon-menu-freechannel:before {
  content: "\e95d";
}
.icon-menu-gallery:before {
  content: "\e95e";
}
.icon-menu-help:before {
  content: "\e95f";
}
.icon-menu-members:before {
  content: "\e960";
}
.icon-menu-messages:before {
  content: "\e961";
}
.icon-menu-musiclibrary:before {
  content: "\e962";
}
.icon-menu-noclose:before {
  content: "\e963";
}
.icon-menu-payout:before {
  content: "\e964";
}
.icon-menu-pp:before {
  content: "\e965";
}
.icon-menu-stat:before {
  content: "\e966";
}
.icon-messenger:before {
  content: "\e967";
}
.icon-microphone:before {
  content: "\e968";
}
.icon-migration:before {
  content: "\e969";
}
.icon-minus:before {
  content: "\e96a";
}
.icon-money:before {
  content: "\e96b";
}
.icon-more:before {
  content: "\e96c";
}
.icon-mute:before {
  content: "\e96d";
}
.icon-my-website:before {
  content: "\e96e";
}
.icon-no-photo:before {
  content: "\e970";
}
.icon-normal-screen:before {
  content: "\e971";
}
.icon-normal-size:before {
  content: "\e972";
}
.icon-ok:before {
  content: "\e973";
}
.icon-ok-simple:before {
  content: "\e974";
}
.icon-page:before {
  content: "\e975";
}
.icon-pause:before {
  content: "\e976";
}
.icon-pause-1:before {
  content: "\e977";
}
.icon-pencil:before {
  content: "\e978";
}
.icon-pencil-0:before {
  content: "\e979";
}
.icon-people:before {
  content: "\e9b2";
}
.icon-people-0:before {
  content: "\e97a";
}
.icon-person:before {
  content: "\e97b";
}
.icon-play:before {
  content: "\e97c";
}
.icon-play-circle:before {
  content: "\e97d";
}
.icon-play-circle-thick:before {
  content: "\e97e";
}
.icon-play-circle-thin:before {
  content: "\e97f";
}
.icon-plus:before {
  content: "\e980";
}
.icon-plus-circle:before {
  content: "\e981";
}
.icon-profile-circle:before {
  content: "\e982";
}
.icon-promotion:before {
  content: "\e983";
}
.icon-refresh:before {
  content: "\e984";
}
.icon-rotate-right:before {
  content: "\e985";
}
.icon-rta-logo:before {
  content: "\e986";
}
.icon-screen:before {
  content: "\e987";
}
.icon-send:before {
  content: "\e988";
}
.icon-setprofilepic:before {
  content: "\e989";
}
.icon-settings:before {
  content: "\e98a";
}
.icon-star:before {
  content: "\e98b";
}
.icon-stats-pie:before {
  content: "\e98c";
}
.icon-stats-table:before {
  content: "\e98d";
}
.icon-time:before {
  content: "\e98e";
}
.icon-tips:before {
  content: "\e98f";
}
.icon-title:before {
  content: "\e990";
}
.icon-topic-avrating:before {
  content: "\e991";
}
.icon-topic-other:before {
  content: "\e992";
}
.icon-topic-picvid:before {
  content: "\e993";
}
.icon-topic-registration:before {
  content: "\e994";
}
.icon-topic-technical:before {
  content: "\e995";
}
.icon-trash:before {
  content: "\e996";
}
.icon-trash-circle:before {
  content: "\e997";
}
.icon-trash-filled:before {
  content: "\e998";
}
.icon-trash-normal:before {
  content: "\e999";
}
.icon-tumblr:before {
  content: "\e99a";
}
.icon-tutorial:before {
  content: "\e99b";
}
.icon-tv:before {
  content: "\e99c";
}
.icon-twitter:before {
  content: "\e99d";
}
.icon-upload-alternative:before {
  content: "\e99e";
}
.icon-user:before {
  content: "\e99f";
}
.icon-video:before {
  content: "\e9b7";
}
.icon-view:before {
  content: "\e9d7";
}
.icon-volume-one:before {
  content: "\e9d8";
}
.icon-volume-three:before {
  content: "\e9d9";
}
.icon-volume-two:before {
  content: "\e9da";
}
.icon-webcam-circle:before {
  content: "\e9db";
}
.icon-write:before {
  content: "\e9dc";
}
.icon-x:before {
  content: "\e9dd";
}
.icon-x-wide:before {
  content: "\e9de";
}
.icon-zoom-circle:before {
  content: "\e9f1";
}

@import '../../scss/shell';

:global {
	#channelSubscriberListOverlay {
		.header {
			> * {
				height: 3rem;
				background: var(--box--header-background);
				color: var(--text--secondary);
				text-transform: uppercase;
				font-size: 1rem;
				font-weight: bold;
			}
		}

		.list {
			border-top: 0.1rem solid var(--common--error);
			&:first-child {
				border: 0
			}

			> * {
				height: 7rem;
				font-size: 1.2rem;
			}
		}

		.name {
			color: var(--text--base);
			font-weight: bold;
		}

		.date {
			color: var(--text--secondary);
		}

		.price {
			color: var(--text--secondary);
			font-weight: bold;
		}
	}

	#channelSubscriberListScroll {
		max-height: 60rem;
	}
}

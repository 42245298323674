.protip-container {
	font-family: _typo('font-family.sans');
	line-height: 1.3;
	// Icon
	> i {
		&.icon-alert {
			color: var(--common--alert);
			top: 11px;
			left: 15px;
			font-size: 16px;
		}
	}

	@at-root :global .id-uploader-block .protip-container {

		&.protip-show {
			@media screen and (min-width:0\0) {
				transform: scale(1);
			}
		}
	}

	@include _on('mobile') {
		max-width: 60vw !important;
	}
}

$_protipDefaultSkinColors: (
		'black': var(--tooltip--bg)
);

$_protipDefaultSkinSizes: (
		'normal': (
				arrow-size: 5px,
				css: (
						padding: 13px 20px 12px,
						font-size: 12px,
						border-radius: 3px
				)
		)
);

@mixin protipDefaultSkinArrowGenerator($skin, $arrow-size, $offset, $color, $cornerRadiusFix) {

	///////////// TOP
	.#{$skin}[data-pt-position="top-left"],
	.#{$skin}[data-pt-position="top"],
	.#{$skin}[data-pt-position="top-right"] {
		.protip-arrow {
			top: 100%;
			left: auto;
			margin: 0 0 0 $arrow-size*-1;
			border-width: $arrow-size $arrow-size 0 $arrow-size;
			border-color: $color transparent transparent transparent;
		}
	}

	.#{$skin}[data-pt-position="top-left"]	.protip-arrow	{left: $offset}
	.#{$skin}[data-pt-position="top"] 		.protip-arrow	{left: 50%}
	.#{$skin}[data-pt-position="top-right"]	.protip-arrow	{left: auto; right: $offset; margin-right: $arrow-size*-1}

	///////////// BOTTOM
	.#{$skin}[data-pt-position="bottom-left"],
	.#{$skin}[data-pt-position="bottom"],
	.#{$skin}[data-pt-position="bottom-right"] {
		.protip-arrow {
			top: $arrow-size*-1;
			left: auto;
			margin: 0 0 0 $arrow-size*-1;
			border-width: 0 $arrow-size $arrow-size $arrow-size;
			border-color: transparent transparent $color transparent;
		}
	}

	.#{$skin}[data-pt-position="bottom-left"]		.protip-arrow	{left: $offset}
	.#{$skin}[data-pt-position="bottom"]			.protip-arrow	{left: 50%}
	.#{$skin}[data-pt-position="bottom-right"]	.protip-arrow	{left: auto; right: $offset; margin-right: $arrow-size*-1}

	///////////// LEFT
	.#{$skin}[data-pt-position="left-top"],
	.#{$skin}[data-pt-position="left"],
	.#{$skin}[data-pt-position="left-bottom"] {
		.protip-arrow {
			top: auto;
			left: 100%;
			margin: $arrow-size*-1 0 0 0;
			border-width: $arrow-size 0 $arrow-size $arrow-size;
			border-color: transparent transparent transparent $color;
		}
	}

	.#{$skin}[data-pt-position="left-top"]	.protip-arrow 	{top: $offset}
	.#{$skin}[data-pt-position="left"]		.protip-arrow 	{top: 50%}
	.#{$skin}[data-pt-position="left-bottom"]	.protip-arrow 	{top: auto; bottom: $offset; margin-bottom: $arrow-size*-1}

	///////////// RIGHT
	.#{$skin}[data-pt-position="right-top"],
	.#{$skin}[data-pt-position="right"],
	.#{$skin}[data-pt-position="right-bottom"] {
		.protip-arrow {
			top: auto;
			right: 100%;
			margin: $arrow-size*-1 0 0 0;
			border-width: $arrow-size $arrow-size $arrow-size 0;
			border-color: transparent $color transparent transparent;
		}
	}

	.#{$skin}[data-pt-position="right-top"]		.protip-arrow 	{top: $offset}
	.#{$skin}[data-pt-position="right"]			.protip-arrow 	{top: 50%}
	.#{$skin}[data-pt-position="right-bottom"]	.protip-arrow 	{top: auto; bottom: $offset; margin-bottom: $arrow-size*-1}
	/////////////

	///////////// CORNERS
	.#{$skin}[data-pt-position="corner-left-top"] {
		@if $cornerRadiusFix {
			border-bottom-right-radius: 0;
		}

		.protip-arrow {
			top: 100%;
			left: 100%;
			margin: 0 0 0 $arrow-size*-1;
			border-width: 0 $arrow-size $arrow-size 0;
			border-color: transparent $color transparent transparent;
		}
	}

	.#{$skin}[data-pt-position="corner-right-top"] {
		@if $cornerRadiusFix {
			border-bottom-left-radius: 0;
		}

		.protip-arrow {
			top: 100%;
			left: $arrow-size*-1;
			margin: 0 0 0 $arrow-size;
			border-width: $arrow-size $arrow-size 0 0;
			border-color: $color transparent transparent transparent;
		}
	}

	.#{$skin}[data-pt-position="corner-left-bottom"] {
		@if $cornerRadiusFix {
			border-top-right-radius: 0;
		}

		.protip-arrow {
			top: $arrow-size*-1;
			left: 100%;
			margin: 0 0 0 $arrow-size*-1;
			border-width: 0 0 $arrow-size $arrow-size;
			border-color: transparent transparent $color transparent;
		}
	}

	.#{$skin}[data-pt-position="corner-right-bottom"] {
		@if $cornerRadiusFix {
			border-top-left-radius: 0;
		}

		.protip-arrow {
			top: 0;
			left: $arrow-size*-1;
			margin: $arrow-size*-1 0 0 $arrow-size;
			border-width: $arrow-size 0 0 $arrow-size;
			border-color: transparent transparent transparent $color;
		}
	}

}

// Schemes
@each $colorName, $color in $_protipDefaultSkinColors {

	$skinSel: protip-skin-msc--scheme-#{$colorName};

	.#{$skinSel} {
		&.protip {
			&-container {
				color: var(--protip--text);
				background: $color;
				opacity: 0;
				transition: opacity 0.2s ease-out;
				-webkit-transform: translateZ(0px); // Against Safari's font aliasing bug whil doing transition

				a {
					color: var(--protip--link);

					&:hover {
						color: var(--protip--link-hover);
					}
				}

				.protip-content p {
					color: var(--protip--text);
				}

				.icon-info-circular-solid {
					display: inline-block;
					margin-right: 0.4rem;
					font-size: 1.6rem;
					transform: translateY(0.3rem);
				}
			}

			&-show {
				opacity: 1;
			}
		}
	}

	@include protipDefaultSkinArrowGenerator(
	$skin: $skinSel,
	$arrow-size: 8px,
	$offset: 20px,
	$color: $color,  // Ugly, i know :(
	$cornerRadiusFix: 0
	);
}

// Sizes
@each $sizeName, $sizeMap in $_protipDefaultSkinSizes {
	$sizeSel: protip-skin-msc--size-#{$sizeName};

	.#{$sizeSel} {
		@each $prop, $val in map-get($sizeMap, css) {
			#{$prop}: #{$val};
		}
	}

	@include protipDefaultSkinArrowGenerator (
	$skin: $sizeSel,
	$arrow-size: map-get($sizeMap, arrow-size),
	$offset: 20px,
	$color: invalid,  // Ugly, i know :(
	$cornerRadiusFix: 0
	);
}

/// CSS3 horizontal gradient with browser prefixes. If end-color isn't provided, the mixin will assume it is a key and tries to get the gradient from the color library.
/// @access public
/// @param {String}			$start-color			Gradient key or start color.
/// @param {String | Bool}	$end-color		[false] End color.
@mixin _horizontal-gradient($start-color, $end-color: false, $text: false) {
	@if $end-color == false {
		$colors: _config('_color-library.gradient.' + $start-color);
		$start-color: nth($colors, 1);
		$end-color: nth($colors, 2);

		@if $start-color == true {
			$start-color: nth($colors, 2);
			$end-color: nth($colors, 1);
		}
	}
	@else{

	}

	@if $text == false {
		background: $start-color;
		background: -moz-linear-gradient(left, $start-color 0%, $end-color 100%);
		background: -o-linear-gradient(left, $start-color 0%, $end-color 100%);

		background: -ms-linear-gradient(left, $start-color 0%, $end-color 100%);
		background: linear-gradient(to right, $start-color 0%, $end-color 100%);
	}

	background: -webkit-gradient(linear, left top, right top, color-stop(0%,$start-color), color-stop(100%,$end-color));
	background: -webkit-linear-gradient(left,  $start-color 0%,$end-color 100%);

	@if $text == true {
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
	}

}

/// CSS3 vertical gradient with browser prefixes. If end-color isn't provided, the mixin will assume it is a key and tries to get the gradient from the color library.
/// @access public
/// @param {String}         $start-color	Gradient key or start color.
/// @param {String | Bool}  $end-color		[false] End color.
/// @param {String}         $start-from		Start percentage.
/// @param {Bool}           $start-from		True, if we are using gradient for text.
@mixin _vertical-gradient($start-color: false, $end-color: false, $start-from: 0%, $stop-from: 100%, $text: false) {
	@if $end-color == false {
		$colors: _config('_color-library.gradient.' + $start-color);
		$start-color: nth($colors, 1);
		$end-color: nth($colors, 2);

		@if $start-color == true {
			$start-color: nth($colors, 2);
			$end-color: nth($colors, 1);
		}
	}
	@else {

	}

	@if $text == false {
		background: $start-color;
		background: -moz-linear-gradient(top, $start-color $start-from, $end-color $stop-from);
		background: -o-linear-gradient(top, $start-color $start-from, $end-color $stop-from);
		background: -ms-linear-gradient(top, $start-color $start-from, $end-color $stop-from);
		background: linear-gradient(to bottom, $start-color $start-from, $end-color $stop-from);
	}

	background: -webkit-gradient(linear, left top, left bottom, color-stop($start-from,$start-color), color-stop($stop-from,$end-color));
	background: -webkit-linear-gradient(top,  $start-color $start-from,$end-color $stop-from);

	@if $text == true {
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
	}
}

/// Custom scrollbar styles in IE10+ and Chrome. All other browsers use native.
/// Configured from system config, the mixin just applies the style.
/// @access public
@mixin _scrollbar ($map: _system('custom-scrollbar')){
	$button: map-get($map, 'button');
	$thumb: map-get($map, 'thumb');

	& {
		// IE
		scrollbar-base-color: _config('_color-library.' + map-get($thumb, 'background'));
		scrollbar-track-color: _config('_color-library.' + map-get($map, 'track-background'));
		scrollbar-arrow-color: _config('_color-library.' + map-get($map, 'arrow-color'));
		scrollbar-shadow-color: RGBA(0,0,0,0);
	}

	// Chrome
	&::-webkit-scrollbar {
		width: map-get($map, 'width');
		height: map-get($map, 'height');
	}

	&::-webkit-scrollbar-button {
		background-color: _config('_color-library.' + map-get($button, 'background'));
		display: map-get($button, 'display');
	}

	&::-webkit-scrollbar-track-piece {
		background-color: _config('_color-library.' + map-get($map, 'track-background'));
	}

	&::-webkit-scrollbar-thumb {
		background-color: _config('_color-library.' + map-get($thumb, 'background'));
		border-radius: map-get($thumb, 'radius');
	}

	&::-webkit-scrollbar-corner {
		display: none;
	}

	&::-webkit-resizer {
		background-color: RGBA(255,255,255, 0.5);
	}
}
:global .oranum-theme {
	.firstLoginOverlayBox {
		.box_header {
			border-bottom: none;
		}
	}
	#firstLoginOverlay {

		h2 {
			color: var(--first-login--title-bold);
		}

		h3 {
			color: var(--first-login--title);
		}

		p {
			color: var(--first-login--text);

			em {
				color: var(--first-login--title);
			}
		}

		.steps {
			p {
				color: var(--first-login--step-text-color);

				& + p {
					color: var(--first-login--title);
				}
			}

			i:before {
				background: var(--first-login--icon-color);
				-webkit-background-clip: text;
				-webkit-text-fill-color: transparent;
			}
		}
	}
}
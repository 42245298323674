@import '../../scss/shell';

:global {

	.tabContainer {
		overflow: hidden;
	}

	.commonTabs {
		width: 90%;
		height: 54px; // inline-block related fix :\
		margin: 0 auto;
		float: none;
		white-space: nowrap;

		@include _on('mobile', false, 'portrait') {
			font-size: 0;
		}

		> .tab {
			float: left;
			height: 54px;
			cursor: pointer;

			a {
				position: relative;
				display: block;
				width: 100%;
				height: 100%;
				line-height: 2.4;
				text-align: center;
				background: var(--common-tabs--link-bg);
				border-radius: 6px 6px 0 0;
				border-right: 2px solid var(--common-tabs--link-border);
				color: var(--common-tabs--link);
				font-size: 16px;
				z-index: 2;
				cursor: pointer;
				user-select: none;
				-webkit-user-drag: none;

				&:before {
					content: '';
					display: none; //later debug
					width: 10px;
					height: 100%;
					position: absolute;
					left: -4px;
					top: 0;
					z-index: 1;
				}

				.icon-alert {
					color: var(--common--alert);
					font-size: 1.6rem;
					margin: 0 0.4rem;
					vertical-align: middle;
				}

				.icon-ok {
					font-size: 1.6rem;
					margin: 0 0.4rem;
					vertical-align: middle;

					@include _on('mobile', false, 'portrait') {
						margin: 0 0 0 2px;
					}
				}
			}

			&:hover a {
				color: var(--common-tabs--link-hover);
			}

			&.disabled a {
				cursor: default;
				pointer-events: none;
				opacity: 0.3;
			}

			&.active a {
				font-weight: bold;
				color: var(--common-tabs--link-active);
				background: var(--common-tabs--link-active-bg);
				// To prevent mouse actions on drag.
				pointer-events: none;

				&:before {
					background: var(--common-tabs--link-active-bg);
					border-radius: 6px 0 0;
					z-index: 1;
				}
			}

			&:last-child a {
				border: 0;
			}

			&:first-child a:before {
				display: none;
			}
		}
	}

	.filterBox {
		height: 75px;
		width: 100%;
		overflow: hidden;
		text-align: left;
		background: var(--box--skip-bg);

		li {
			display: block;
			float: left;

			a {
				display: block;
				padding: 25px 20px;
				color: var(--messenger--tab--a);
				font-size: 14px;
				line-height: 28px;

				@include _on('mobile') {
					padding: 25px 10px;
				}

				span {
					@include style('text', 'smallSize', 'regularColor');

					border-radius: 10px;
					padding: 3px 8px 3px 8px;
					background: var(--box--header-background);
				}

				&:hover,
				&.active {
					color: var(--text--base);

					span {
						color: var(--text--base);
					}
				}

				&.active {
					background-color: var(--box--main-bg);

					span {
						box-shadow: 0 1px 0 RGBA(var(--text--passive-rgb), 0.3);
						background-color: var(--background);
					}
				}
			}
		}
	}

	.docler-theme,
	.oranum-theme {
		.filterBox li a.active{
			color: var(--messenger--tab-active);
		}
		.filterBox li a:hover{
			color: var(--messenger--tab-active);
		}
	}

	#settings_personaldata .filterBox li a,
	#languagesettings_language .filterBox li a {
		display: table-cell;
		vertical-align: middle;
		height: 75px;
		padding: 0 20px;
		width: 195px;
		word-wrap: break-word;
		font-weight: bold;
		font-size: 14px;
		line-height: 24px;
	}

	@import "Tabs.mainstream";
}

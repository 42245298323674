@use "sass:math";

/////////////////////////
// The grid
/////////////////////////

@mixin gridItemGenerator($i, $percentage, $suffix: '', $isPure: false){
	.#{grid('columnPrefix')}#{$i}#{$suffix}{
		width: $percentage;

		@if $isPure == true {
			&.important{
				width: $percentage !important;
			}
		}
	}

	.#{grid('columnIndentLeftPrefix')}#{$i}#{$suffix}{
		margin-left: $percentage;
	}

	.#{grid('columnIndentRightPrefix')}#{$i}#{$suffix}{
		margin-right: $percentage;
	}
}

@mixin gridGenerator($suffix: '', $full: false, $isPure: false){
	@for $i from 1 through grid('columnCount') {
		$percentage: 100%;
		@if $full != true {
			$percentage: percentage(math.div(100, grid('columnCount')) * math.div($i, 100));
		}
		@include gridItemGenerator($i, $percentage, $suffix, $isPure);
	}
	@include gridItemGenerator(0, 0, $suffix, $isPure);
	@include gridItemGenerator(5eq, percentage(0.2), $suffix, $isPure);

	@each $item in grid('gutterList'){
		@for $i from 1 through 20 {
			$g: $i * 5;
			[class^=#{ grid('columnPrefix')}].#{$item}#{$g}#{$suffix}{
				padding-#{$item}: #{$g}px;
			}
		}
		[class^=#{ grid('columnPrefix')}].#{$item}#{$suffix}{
			padding-#{$item}: grid('gutter');
		}
	}

	@for $i from 1 through 20 {
		$g: $i * 5;
		[class^=#{ grid('columnPrefix')}].all#{$g}#{$suffix}{
			padding: #{$g}px;
		}
	}
	[class^=#{ grid('columnPrefix')}].all#{$suffix}{
		padding: grid('gutter');
	}
}

.row:after,
.clr:after,
.clear:after{
	content: "";
	display: table;
	clear: both;
}

.row{
	width: grid('rowWidth');
	max-width: 100%;
	clear: both;

	.row{
		width: 100%;
		float: left;
		padding-left: 0;
		padding-right: 0;
	}

	&.center{
		margin: 0 auto;
	}

	//@include onBreakPoint{
	//padding-left: grid('gutter');
	//padding-right: grid('gutter');
	//}

	> [class^=#{grid('columnPrefix')}]{
		float: left;
	}
}

// Basic grid
@include gridGenerator('',false,true);

// Only these are needed
@include onSmallAndDown {
	@include gridGenerator(responsiveSuffix('onSmallAndDown'));
};
@import '../../../scss/shell';

.messagingStreak {
	.section1 {
		position: relative;
		padding: 57px 10px 48px;
		background: url('./section1_background.jpg') no-repeat;
		background-position: center;
		background-size: cover;
		text-align: center;

		h2 {
			margin: 0 0 12px;
			font-size: 22px;
			font-weight: 300;
			text-transform: uppercase;
			color: var(--text--secondary);
		}

		h3 {
			margin: 0 0 56px;
			font-size: 30px;
			text-transform: uppercase;
		}

		p {
			max-width: 530px;
			margin: 0 auto 18px auto;
			font-size: 18px;
			font-weight: 300;
			line-height: 1.3;
			color: var(--text--secondary);

			span {
				font-weight: bold;
				text-transform: uppercase;
				color: var(--text--base);
			}

			strong {
				font-weight: bold;
				color: var(--text--base);
			}
		}
	}

	.section2 {
		padding: 55px 40px 65px;
		text-align: center;

		@include _on('mobile') {
			padding: 55px 10px 65px;
		}

		@include _vertical-gradient(
						var(--earn-more--private-show-discount--gradient-from),
						var(--earn-more--private-show-discount--gradient-to),
						0%, 100%
		);

		h2 {
			font-size: 22px;
			text-transform: uppercase;
		}

		ul {
			display: flex;
			margin: 38px 0 0;
		}

		li {
			margin: 0 20px;
			text-align: left;
			color: var(--text--secondary);

			@include _on('mobile') {
				text-align: center;
				margin: 0 0 0 10px;
			}

			p {
				font-size: 18px;
				font-weight: 300;
				line-height: 1.2;

				strong {
					font-weight: bold;
					color: var(--text--base);
				}

				&:last-of-type {
					padding-left: 62px;

					@include _on('mobile') {
						padding-left: 0;
					}
				}
			}

			span {
				float: left;
				width: 48px;
				height: 48px;
				margin: 0 14px 0 0;
				border: 3px var(--text--link) solid;
				border-radius: 50%;
				color: var(--text--link);
				text-align: center;

				@include _on('mobile') {
					display: block;
					float: none;
					margin: 0 auto 10px auto;
				}

				i {
					position: relative;
					top: 14px;
					font-size: 15px;
					color: var(--text--link);

					@include _vertical-gradient(
									var(--earn-more--text-gradient-start),
									var(--earn-more--text-gradient-stop),
									0%, 100%,
									true
					);

					&.money {
						top: 9px;
						font-size: 25px;
					}

					&.comments {
						top: 7px;
						font-size: 28px;
					}
				}
			}
		}
	}

	.section3 {
		position: relative;
		min-height: 495px;
		padding: 36px 0 25px;
		text-align: center;

		h2 {
			font-size: 22px;
			text-transform: uppercase;
		}

		section {
			width: 100%;
			display: flex;
			align-items: center;

			div {
				text-align: left;

				&.image {
					flex-basis: 43%;

					@include _on('mobile') {
						flex-basis: 100%;
					}
				}

				&.text {
					flex-basis: 57%;
					padding: 0 60px 0 8px;

					@include _on('mobile') {
						flex-basis: 100%;
						padding: 0 10px;
					}
				}
			}

			&.tabContent1 {
				margin: 10px 0 0;

				@include _on('mobile') {
					flex-wrap: wrap;
				}

				.text {
					margin: -4px 0 0;
				}
			}

			h4 {
				margin: 35px 0 12px;
				font-size: 18px;
				line-height: 1.3;
				text-transform: uppercase;

				&:first-child {
					margin-top: 0;
				}
			}

			p {
				font-size: 16px;
				font-weight: 200;
				line-height: 1.25;
				color: var(--earn-more--text);
			}
		}
	}

	.moreInfo {
		display: block;
		margin: 20px 0;
		text-align: center;
	}
}

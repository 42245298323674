@import '../../../scss/shell';

:global {

    #error_error {
        #main_content {
            margin: 0 auto;
        }

        .box_container {
            text-align: center;
            min-height: 388px;
            width: 90%;
            max-width: 830px;

            h1 {
                font-weight: bold;
                font-size: 30px;
                line-height: 40px;
                color: var(--text--base);
                margin: 114px 30px 0 30px;
            }

            p {
                font-size: 18px;
                line-height: 20px;
                color: var(--text--secondary);
                margin: 10px 30px 27px 30px;
            }

            a {
                font-size: 13px;
                line-height: 38px;
            }
        }
    }

    #confirmOverlay {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate3d(-50%, -50%, 0);
        backface-visibility: hidden;
        min-height: 0 !important;
    }
}


@import '../../../scss/shell';

:global {
	#migrationFinishProcess {
		padding: 0 0 20px;

		.info {
			margin: 20px auto;
			width: 640px;
			font-size: 12px;
			color: var(--text--secondary);
		}

		label {
			width: 211px;
		}

		.formSubmit {
			margin-left: 221px;
		}
	}
}
.progressIndicator {
	transition: opacity, 0.2s;

	z-index: -1;
	display: block;
	opacity: 0;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: RGBA(var(--global-progress-indicator--bg-rgb), 0.8);
	backface-visibility: hidden;
	overflow: hidden;

	&.show {
		z-index: 1000002;
		opacity: 1;

		+ #main_container {
			//@include blur();
		}

		span, em {
			display: block;
			opacity: 1;

			img {
				width: 100%;
				height: 100%;
			}
		}

		@at-root :global .is-mobile-device .progressIndicator.show + #main_container {
			@include noFilter();
		}
	}

	&.local {
		position: relative;
		background: RGBA(var(--global-progress-indicator--inner-bg-rgb), 0.4);
	}

	&.indicatorLoading {
		display: block !important;
		opacity: 1 !important;
	}

	span,
	em {
		transition: opacity 0.5s 1s;
		transform: translate(-50%, -50%);

		display: none;
		position: absolute;
		left: 50%;
		top: 50%;
		width: 60px;
		height: 60px;
		opacity: 0;
	}

	span {
		background: var(--global-progress-indicator--inner-bg);
		border-radius: 50%;
		box-shadow: 0 0 50px 10px var(--global-progress-indicator--inner-bg);
	}

	em {
		img {
			width: 100%;
		}
	}
	.mainstream-loader {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		width: 40px;
		height: 40px;
		display: grid;
		grid-template-rows: repeat(3, 1fr);
		grid-template-columns: repeat(3, 1fr);
		justify-items: center;
		align-items: center;

		> div {
			width: 10px;
			height: 10px;
			background-color: #2bbebe;
			border-radius: 50%;
			animation: mainstream-loader-fade 1.5s alternate ease-in-out infinite;
		}

		> div:nth-of-type(2),
		> div:nth-of-type(4) {
			animation-delay: 0.25s;
		}

		> div:nth-of-type(3),
		> div:nth-of-type(5),
		> div:nth-of-type(7) {
			animation-delay: 0.5s;
		}

		> div:nth-of-type(6),
		> div:nth-of-type(8) {
			animation-delay: 0.75s;
		}

		> div:nth-of-type(9) {
			animation-delay: 1s;
		}
	}
}

.whiteLoader{
	display: inline-block;
	background-image: url('./whiteLoader.svg');
	background-size: cover;
	background-repeat: no-repeat;
	width: 30px;
	height: 30px;

	&.small{
		width: 18px;
		height: 18px;
	}
}

.mainstream-theme {
	.whiteLoader {
		background-image: url('./whiteLoader_mainstream.svg');
	}
}

.bimbim-theme {
	.whiteLoader {
		background-image: url('./whiteLoader_bimbim.svg');
	}
}

@keyframes  :global(mainstream-loader-fade) {
	to {
		opacity: 0.2;
	}
}
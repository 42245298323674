@use "sass:math";

////
/// @group Grid
/// @author Viktor Vincze
////
/// Formats grid selector
/// @access private
/// @param {ArgList}	$args	Selectors to be imploded
/// @return {String}
@function _format-grid-selector($args...){
	$string: _format-selector(_str-implode($args), false, false, true);
	@return $string;
}

/// Generates one cell style
/// @access private
/// @param {Number} $i				Counter of the cell
/// @param {Number} $percentage		Width in percentage
/// @param {String} $suffix ['']	Suffix appended to the selector
@mixin _grid-item-generator($i, $percentage, $suffix: ''){

	$_column-selector: _format-selector(_grid('selector.column'), false, false, true);
	@if _system('tag-mode') == false {
		$_column-selector: '[' + $_column-selector + ']';
	}

	#{$_column-selector + '[' + _format-grid-selector(_grid('selector.column'), $i, $suffix) + ']'}{
		width: $percentage;
	}


	#{$_column-selector + '[' + _format-grid-selector(_grid('selector.indent-left'), $i, $suffix) + ']'}{
		margin-left: $percentage;
	}

	#{$_column-selector + '[' + _format-grid-selector(_grid('selector.indent-right'), $i, $suffix) + ']'}{
		margin-right: $percentage;
	}
}

/// Generates the full grid
/// @access public
/// @param {string}		$suffix ['']	Suffix appended to the selector
/// @param {boolean}	$full   [false]	Indicates if a 100% width cell is necessary
@mixin _grid-generator($suffix: '', $full: false){
	$_column-selector: _format-selector(_grid('selector.column'), false, false, true);
	@if _system('tag-mode') == false {
		$_column-selector: '[' + $_column-selector + ']';
	}

	// Columns
	@for $i from 1 through _grid('column-count') {
		$percentage: 100%;
		@if $full != true {
			$percentage: percentage(math.div(100, _grid('column-count')) * math.div($i, 100));
		}
		@include _grid-item-generator($i, $percentage, $suffix);
	}

	// Column Zero
	@include _grid-item-generator(0, 0, $suffix);

	// Equals
	@if _grid('equal-columns'){
		@each $eq in _grid('equal-columns') {
			$percentage: percentage(math.div(math.div(100, $eq), 100));
			$eq-i: $eq + '-' + _grid('selector.equal');
			@include _grid-item-generator($eq-i, $percentage, $suffix);
		}
	}

	@if _grid('gutter.enabled'){

		@each $dir in (top right bottom left){

			@if _grid('gutter.stepped-enabled') {
				@for $i from 1 through (math.div(_grid('gutter.max'), _grid('gutter.step'))) {
					$g: $i * _grid('gutter.step');
					#{'[' + _format-grid-selector(_grid('selector.gutter'), $dir, $g, $suffix) + ']'}{
						@include _rem(padding-#{$dir},$g);
					}
				}
			}

			#{'[' + _format-grid-selector(_grid('selector.gutter'), $dir, $suffix) + ']'}{
				@include _rem(padding-#{$dir}, _dim(global-gutter));
			}
		}

		@if _grid('gutter.stepped-enabled') {
			@for $i from 1 through (math.div(_grid('gutter.max'), _grid('gutter.step'))) {
				$g: $i * _grid('gutter.step');
				#{'[' + _format-grid-selector(_grid('selector.gutter'), _grid('selector.all'), $g, $suffix) + ']'}{
					@include _rem(padding, $g);
				}
			}
		}

		#{$_column-selector + '[' + _format-grid-selector(_grid('selector.gutter'), _grid('selector.all'), $suffix) + ']'}{
			padding: _dim(global-gutter);
		}
	}

	#{$_column-selector + '[' + _format-grid-selector(_grid('selector.center'), $suffix) + ']'} {
		margin: 0 auto;
		float: none;
	}
}

// Generate basic grid and it's styles
$isGridModuleDefaultRendered: false;
@if _module('grid') and $isGridModuleDefaultRendered == false {
	$isGridModuleDefaultRendered: true !global;

	$_column-selector: _format-selector(_grid('selector.column'), false, false, true);
	$_row-selector: _format-selector(_grid('selector.row'), false, false, true);
	$_clear-selector: _format-selector(_grid('selector.clear'), false, false, true);

	@if _system('tag-mode') == false {
		$_column-selector: '[' + $_column-selector + ']';
		$_row-selector: '[' + $_row-selector + ']';
		$_clear-selector: '[' + $_clear-selector + ']';
	}

	#{$_row-selector}:after,
	#{$_clear-selector}:after{
		display: table;
		content: " ";
		clear: both;
	}

	#{$_clear-selector}:after{
		display: block;
	}

	#{$_row-selector} {
		width: 100%;
		max-width: 100%;
		clear: both;
		float: left;
		display: block;

		#{$_row-selector} {
			padding-left: 0;
			padding-right: 0;
		}
	}

	#{$_column-selector} {
		float: left;
		display: block;
	}

	@include _grid-generator('', false);
}

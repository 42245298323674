@import '../../../scss/shell';

:global {

	.consentBlock {
		.box_header {
			display: none;
		}
	}

	#consentBlock {
		max-width: 90vw;
		width: 690px;
		padding: 70px 0 30px !important;
		h4 {
			font-size: 24px;
			font-weight: bold;
		}
		p {
			font-size: 16px;
			line-height: 1.3;
			margin-top: 7px;
		}
		.button-container {
			margin: 36px 0 51px;
		}
		.button {
			padding: 12px 27px;
			font-size: 14px;
		}
	}
}
@use 'global/vars';
@import '../../../scss/shell';

:global {

	#newModelContent.step-screen-name-and-category {
		padding: vars.spacer(9);

		@include _on('mobile') {
			padding: vars.spacer(6);
		}
	}

	.step-screen-name-and-category {
		margin: 0 -2rem; // grid is simply just ignored in the design

		@include _on('mobile') {
			margin: 0;
		}

		@include _on('small') {
			.ph-form-row.ph-flex-on-small-and-up {
				display: block !important;
			}
		}

		.category-selector {
			&:first-of-type {
				margin-left: vars.spacer(7);

				@include _on('mobile') {
					margin-left: 0;
				}
			}
			&:last-of-type {
				margin-right: vars.spacer(7);
			}

			form-selectorbox {
				border: 0;
				outline: 0.1rem solid var(--form--selectorbox-border);

				&:hover {
					outline-color: var(--form--selectorbox-border-hover);
				}

				& > div {
					opacity: 1;
					text-align: left;
					padding: vars.spacer(6);
				}

				&.selected {
					background: var(--category-selector--active-bg);
					outline: 0.2rem solid var(--category-selector--border-selected);

					p {
						color: var(--category-selector--text-active);
					}

					.category-restriction {
						color: var(--category-selector--text-bold);
					}
				}
			}

			p {
				font-size: 1.4rem;
				line-height: 2rem;
				color: var(--category-selector--text);
				margin-bottom: 0;

				a {
					display: table;
					margin: vars.spacer(2) 0 vars.spacer(4);
				}

				br {
					display: block;
					content: '';
					margin-top: vars.spacer(2);
				}
			}

			h3 {
				font-size: 1.6rem;
				line-height: 2.4rem;
				margin: 0 0 vars.spacer(2);
			}

			.category-restriction {
				font-weight: bold;
				text-align: center;
				color: var(--category-selector--text-bold);
				align-content: end;
			}
		}

		form-selectorbox {
			z-index: 3;

			& > div {
				padding: 2.8rem 1rem 1rem;
			}

			p {
				font-size: 1.4rem;
				line-height: 1.5;
				color: var(--text--secondary);
				margin-bottom: .5rem;
			}

			form-mixin-ok i,
			form-mixin-error i {
				font-size: 0 !important;
			}
		}

		form-input[name=canSeeStatistics] {
			padding-right: 3.5rem;

			label[name=yieldLabel] {
				padding-right: 0;
				width: auto;

				~ .form-input-additions--outside {
					position: relative;
					display: inline-block;
					top: 8px;
					left: 0;

					@include _on(mobile) {
						top: 0;
					}
				}
			}
		}

		form-input[type=select] {
			margin-top: 1.5rem;
		}

		h3 {
			margin: 0 1rem 1.6rem; // make all the title break into newline
		}

		.form-content {
			@include _flexbox;
			@include _flexbox-direction(column);
			@include _flexbox-justify-content(space-between);

			p {
				flex-grow: 1;
			}
		}
	}
}
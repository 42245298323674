.no-script{
	.icon.checkbox-inactive, .icon.checkbox-active, .icon.checkbox-disabledInactive, .icon.checkbox-disabledActive {
		display: none;
	}

	.icon.radio-inactive, .icon.radio-active, .icon.radio-disabledInactive, .icon.radio-disabledActive {
		display: none;
	}

	input[type="radio"], input[type="checkbox"] {
		visibility: visible;
	}

	.selectContainer select {
		visibility: visible !important;
		display: block;
		margin: 10px 0 0;
	}

	.photoPageContainerNew .galleryContainer .loading.galleryImage {
		-webkit-box-shadow: none;
		box-shadow: none;
	}

	.imageCropTable img.loading {
		opacity: 1;
	}
}

@use 'global/icons';
@use 'global/vars';
@import '../../scss/shell';

:global {

	/** overlay comment container **/
	.overlay_comment_container {
		position: relative;
		z-index: 10000;
		top: 80px;
		display: table;
		width: auto;
		max-height: 100%;
		margin: auto;
		border-radius: 3px;

		.loader_container {
			display: none;
		}
		&.fitscreen {
			position: relative;
			width: 95%;
			height: 90%;
			top: 5%;

			&.picture_comment_container {
				transform: translateX(-50%);
				width: auto;
				height: auto;
				max-width: 95%;
				max-height: 90%;
				top: 5%;
				bottom: 0;
				left: 50%;
				right: 0;
				margin: 0 auto;
				position: absolute;

				.content_container {
					display: flex;
					justify-items: center;
					align-items: center;
					position: relative;
					margin-right: 350px;
					width: auto;
					height: auto;
					background: var(--channel--overlay-bg);

					@include _on('mobile') {
						background: none;
					}

					img {
						display: block;
						width: auto;
						height: auto;
						max-width: calc(95vw - 350px);
						max-height: 90vh;
						position: relative;
						margin: auto;
					}
				}
			}
			#channel_next_media {
				right: 10px;
			}
			.photo_table_frame {
				display: flex;
				position: relative;
				max-width: 100%;
				max-height: 100%;
			}

			.comment_inner {
				position: absolute;
				right: 0;
				top: 0;
				height: 100%;
				width: 350px;
				z-index: 10;
				.comment_content {
					position: relative;
					width: 100%;
					overflow: hidden;
				}
			}
		}
		&.video_comment_container {
			.content_container {
				display: table-cell;
				position: absolute;
				padding-right: 350px;
				width: 100%;
				height: 100%;
				max-width: 100%;

				.comment_like_container {
					bottom: 1.5rem;
				}
			}

			.video_player_container {
				background-color: var(--channel--overlay-bg);
			}

			#flash_video_container {
				position: relative;
				height: 100%;
				width: 100%;
				object {
					position: absolute;
					width: 100%;
					height: 100%;
				}
				embed {
					width: auto;
					height: 100%;
					max-width: 100%;
					max-height: 98%;
					position: absolute;
					top: 0;
					left: 0;
					bottom: 0;
					right: 0;
				}
			}
		}
		&.note_comment_container {
			&[data-exclusive=true] {
				.content_container {
					min-height: 300px;
					background: var(--channel--note-overlay-content-bg);
					border-radius: 4px 4px 0 0;

					.title_note {
						margin: 40px 0 0;
					}
				}
				.comment_inner {
					#total_comment_number {
						text-align: right;
					}
				}
			}
			display: block;
			width: 500px;
			max-height: 100%;
			.comment_content {
				height: 400px;
			}
			.comment_inner .reply_container {
				input {
					width: 350px;
				}
			}
			.closeBtn {
				text-shadow: none;
				i {
					color: var(--text--secondary);
				}
			}
			.loader_container {
				display: none !important;
			}
			.content_container {
				background: var(--channel--note-overlay-content-bg);
				display: block;
				overflow: visible;

				.title_container .time_container span {
					text-shadow: none;
				}

				#channel_prev_media,
				#channel_next_media {
					transition: none;
				}

				&:hover #channel_prev_media {
					left: -20%;
				}
				&:hover #channel_next_media {
					right: -20%;
				}

				.note_content {
					@include _flexbox;
					@include _flexbox-align(center);
					position: absolute;
					top: 0;
					bottom: 0;
					left: 0;
					right: 0;
					text-align: center;
					font-weight: 300;
					font-size: 16px;
					line-height: 20px;
					padding: 4rem;
					word-wrap: break-word;
					color: var(--channel--note-overlay-text);

					span {
						width: 100%;
						line-height: 1.2;
					}

					&[data-font-size="1"],
					&.note_content_size_1 {
						font-size: 60px;
					}

					&[data-font-size="2"],
					&.note_content_size_2 {
						font-size: 46px;
					}

					&[data-font-size="3"],
					&.note_content_size_3 {
						font-size: 34px;
					}

					&[data-font-size="4"],
					&.note_content_size_4 {
						font-size: 28px;
					}

					&[data-font-size="5"],
					&.note_content_size_5 {
						font-size: 24px;
					}

					&[data-font-size="6"],
					&.note_content_size_6 {
						font-size: 20px;
					}
				}

				.time_container {
					position: relative;
					bottom: 10px;
					display: block;
					text-align: center;
					color: var(--text--secondary);

					span {
						position: relative;
						display: inline;
						font-size: 12px;
						line-height: 20px;
						margin: 0 0 0 10px;

						&.timestamp.small {
							position: relative;
							display: inline-block;
							height: 14px;
							width: 14px;
							top: 3px;
						}
					}
				}

				.folder_info {
					text-shadow: none;
					color: var(--text--secondary);
				}
			}

			.comment_inner {
				display: block;
				width: 500px;
			}

			@include _on('mobile') {
				width: 100% !important;
				top: 0;

				.comment_content {
					height: auto;
				}

				.comment_inner {
					width: 100% !important;
					border-radius: 0;
				}

				.content_container {
					border-radius: 0!important;

					.content_viewer_navigation {
						text-shadow: none;

						i {
							color: var(--text--secondary);
						}
					}

					#channel_prev_media {
						left: 0 !important;
					}
					#channel_next_media {
						right: 0 !important
					}

					.note_content {
						&[data-font-size="1"],
						&.note_content_size_1 {
							font-size: 4.6rem;
						}

						&[data-font-size="2"],
						&.note_content_size_2 {
							font-size: 3.4rem;
						}

						&[data-font-size="3"],
						&.note_content_size_3 {
							font-size: 2.8rem;
						}

						&[data-font-size="4"],
						&.note_content_size_4 {
							font-size: 2.4rem;
						}

						&[data-font-size="5"],
						&.note_content_size_5 {
							font-size: 2rem;
						}

						&[data-font-size="6"],
						&.note_content_size_6 {
							font-size: 1.6rem;
						}
					}
				}
			}
		}

		.media_container {
			min-width: 200px;
			min-height: 200px;
		}

		.content_container {
			display: flex;
			overflow: hidden;
			width: auto;
			position: relative;

			img {
				display: block;
				max-width: 800px;
				max-height: 710px;
				margin: auto;
			}

			.picture_shadow {
				display: none;
				position: absolute;
				bottom: 0;
				left: 0;
				width: 100%;
				height: 157px;
				padding: 0 2%;
				opacity: 0;
				background: url('../Page/MyContent/channel_overlay_shadow.png');

				transition: all 0.4s ease;
				-webkit-transition: all 0.4s ease;
				-moz-transition: all 0.4s ease;
				-o-transition: all 0.4s ease;
			}

			.folder_info {
				font-size: 14px;
				line-height: 14px;
				display: block;
				opacity: 0;
				margin: 0;
				padding: 1.8rem 0;
				position: absolute;
				text-align: left;
				width: 100%;
				z-index: 10;
				overflow: hidden;
				left: 0;
				top: 0;
				color: var(--channel--gallery-overlay-tools);
				text-shadow: 0 0 0.4rem var(--channel--gallery-info-text-shadow);

				transition: all 0.4s ease;
				-webkit-transition: all 0.4s ease;
				-moz-transition: all 0.4s ease;
				-o-transition: all 0.4s ease;

				@include _on('mobile') {
					font-size: 1.2rem;
					font-weight: normal !important;
				}

				&.disabled {
					display: none;
				}

				strong {
					margin: 0 0.3rem 0 2rem;
				}

				span {
					margin: 0 2px;
				}
			}

			.comment_like_container {
				display: none;
				width: 100%;
				padding: 2rem;
				font-size: 1.4rem;
				font-weight: bold;
				position: absolute;
				bottom: -0.5rem;
				right: 0;
				text-align: right;
				opacity: 0;
				color: var(--channel--gallery-overlay-tools);
				text-shadow: 0 0 0.4rem var(--channel--gallery-info-text-shadow);

				span {
					margin-left: 0.5rem;
					font-size: 1.2rem;
				}

				div {
					line-height: 1.5;
				}

				.icon-comments {
					position: relative;
					top: 1px;
				}

				@include _on('mobile') {
					display: block;
				}
			}

			.title_container {
				position: absolute;
				bottom: 20px;
				left: 0;
				float: left;
				width: 100%;
				opacity: 0;
				color: var(--channel--gallery-overlay-tools);

				transition: all 0.4s ease;
				-webkit-transition: all 0.4s ease;
				-moz-transition: all 0.4s ease;
				-o-transition: all 0.4s ease;

				h2 {
					position: absolute;
					display: block;
					float: left;
					left: 20px;
					bottom: 30px;
					font-weight: bold;
					font-size: 20px;
					line-height: 20px;
					word-break: break-word;
					width: 95%;
					margin: 0;
					text-shadow: 0 0 0.4rem var(--channel--gallery-info-text-shadow);
				}

				.time_container {
					position: relative;
					display: block;
					float: left;
					left: 20px;

					span {
						position: relative;
						display: block;
						float: right;
						font-size: 12px;
						line-height: 20px;
						margin: 0 0 0 5px;
						text-shadow: 0 0 0.4rem var(--channel--gallery-info-text-shadow);

						&.timestamp.small {
							position: relative;
							display: inline-block;
							height: 14px;
							width: 14px;
							float: right;
							top: 3px;
						}
					}

					i {
						position: relative;
						top: 3px;
						font-size: 1.2rem;
					}
				}

				@include _on('mobile') {
					bottom: 1.3rem;

					h2 {
						width: 75%;
						font-size: 1.4rem;
						bottom: 2.5rem;
					}

					.time_container span {
						font-size: 1rem;
					}
				}
			}

			.content_viewer_navigation {
				display: block;
				top: 0;
				height: 100%;
				position: absolute;
				z-index: 1502;
				opacity: 0;
				text-shadow: 0 0 0.4rem var(--channel--gallery-info-text-shadow);

				transition: all 0.4s ease;
				-webkit-transition: all 0.4s ease;
				-moz-transition: all 0.4s ease;
				-o-transition: all 0.4s ease;

				i {
					font-size: 4rem;
					display: block;
					z-index: 1502;
					position: absolute;
					top: 50%;
					margin-top: -(vars.spacer(5));
					opacity: 0.8;
					color: var(--channel--gallery-overlay-tools);
				}

				&.disabled {
					display: none;
				}

				&:hover {
					i {
						opacity: 1;
					}
				}

				@include _on('mobile') {
					top: 25%;
					height: 50%;

					i {
						font-size: 3.2rem;
						margin-top: -1.6rem;
					}
				}
			}

			#channel_prev_media {
				left: 0;
				width: 20%;

				i {
					left: 3rem;

					@include _on('mobile') {
						left: 1.2rem;
					}
				}
			}

			#channel_next_media {
				left: unset;
				right: 0;
				width: 20%;

				i {
					right: 3rem;

					@include _on('mobile') {
						right: 1.2rem;
					}
				}
			}

			&:hover {
				#channel_next_media,
				#channel_prev_media,
				.title_container,
				.folder_info,
				.picture_shadow,
				.comment_like_container {
					opacity: 1;
					left: 0;
					transition-delay: 0.1s;
				}
				#channel_next_media {
					left: unset;
					right: 0;
				}
			}
		}

		.comment_inner {
			position: relative;
			display: table-cell;
			width: 350px;
			vertical-align: top;
			z-index: 10;
			background: var(--channel--comment-bg);
			font-size: 0;

			@include _on('mobile') {
				transition: none;
			}

			.title-wrapper {
				background: var(--channel--comment-title-bg);
				height: 5.6rem;
				border-bottom: 1px solid var(--channel--comment-border-color);
			}
			.title {
				position: relative;
				float: left;
				padding: 0 20px;
				height: 5.6rem;
				z-index: 15;

				.comment {
					position: relative;
					display: inline-block;
					top: 3px;
					height: 14px;
					width: 20px;
				}

				h3 {
					display: inline-flex !important;
					align-items: center;
					position: relative;
					height: 100%;
					margin: 0;
					padding: 0;
					font-weight: normal;
					font-size: 1.4rem;
					line-height: 5.5rem;
					color: var(--channel--comment-title-color);

					.msc-icon {
						margin-right: 0.4rem;
						font-size: 2.4rem;
					}
				}

				span {
					margin-left: 0.3rem;
				}
			}

			.comment_content {
				position: relative;
				width: 100%;
				overflow: hidden;
				transition: all 0.4s ease;

				&:hover {
					.scroll-pane {
						opacity: 1 !important;
					}
				}

				.scroll-pane {
					opacity: 0 !important;
					transition: opacity 0.4s ease;
					-webkit-transition: opacity 0.4s ease;
					-moz-transition: opacity 0.4s ease;
					-o-transition: opacity 0.4s ease;
				}

				.comment_wrapper {
					position: absolute;
					left: 0;
					height: auto;
					width: 100%;
					overflow-y: auto;
					overflow-x: hidden;
					z-index: 2;

					&.fake {
						z-index: 1;
						height: auto;
						overflow: visible;
					}

					#scrollable {
						width: 100%;
						bottom: 0;
					}

					@include _on('mobile') {
						overflow-y: auto !important;
					}
				}

				.comment_block {
					border-top: 1px solid var(--channel--comment-border-color);
					position: relative;
					left: 0;
					bottom: 0;
					padding: 1.8rem 2rem 0.5rem;
					width: 100%;
					z-index: 5;

					&.just_added {
						margin: -300px 0 0 0;
						visibility: hidden;
						z-index: 1;
					}

					.name {
						color: var(--channel--comment-name);
						font-weight: bold;
						font-size: 1.4rem;
						line-height: 1.4rem;
					}

					.timestamp {
						color: var(--channel--comment-text);
						float: right;
						font-size: 11px;
						line-height: 11px;
					}
					p {
						color: var(--channel--comment-text);
						font-size: 1.4rem;
						line-height: 1.4rem;
						margin: 1.1rem 0 1.5rem;
						word-wrap: break-word;
					}

					.report {
						font-size: 9px;
						line-height: 9px;
						float: right;
						top: -3px;
						position: relative;
						cursor: pointer;
						opacity: 0;
					}

					.reply {
						position: relative;
						display: inline-block;
						height: 12px;
						width: 14px;
						float: right;
						margin: 0 10px;
						cursor: pointer;
						opacity: 0.4;
						&:hover {
							opacity: 1;
						}
					}

					.trash {
						position: relative;
						float: right;
						width: 15px;
						top: -(vars.spacer(4));
						padding: 1rem;
						font-size: 2rem;
						cursor: pointer;
						color: var(--channel--comment-text);

						&.active,
						&:hover {
							color: var(--text--base);

							i::before {
								content: icons.get('trash-solid');
							}
						}
					}

					.retry {
						position: relative;
						top: -3px;
						height: 15px;
						width: 20px;
						display: block;
						float: right;
						opacity: 0.4;
						margin: 0 0 0 15px;
					}

					&:hover {
						.report {
							opacity: 1;
						}
					}

					.remove_comment {
						background: var(--channel--comment-remove-bg);
						position: absolute;
						height: 100%;
						left: 100%;
						top: -1px;
						padding: 0.5rem 2rem 0;
						text-align: center;

						.remove_wrap {
							position: relative;
							display: table;
							width: 100%;
							height: 100%;
							top: 0;

							.remove_cell {
								display: table-cell;
								vertical-align: middle;
								color: var(--common--alert);

								a {
									display: block;
									text-align: center;
									border-radius: 4px;
									font-weight: bold;
									font-size: 12px;
									line-height: 12px;
									padding: 10px 12px;
									cursor: pointer;

									&:first-child {
										margin: 0 0 0.5rem 0;
										height: auto;
									}
								}
							}
						}
					}

					&.show {
						left: -105px;
					}
				}
			}

			.reply_container {
				background: var(--channel--comment-bg);
				height: 6rem;
				padding: 1rem;
				font-size: 1.4rem;
				line-height: 1.4rem;
				width: 100%;
				z-index: 2000;
				border-top: 1px solid var(--channel--comment-border);

				.replay_form_container {
					border-radius: 4px;
					height: 40px;
					width: 100%;
					padding: 0 20px;
					background: var(--channel--comment-input-bg);
					border: 1px solid var(--channel--comment-border);
				}

				input {
					border: none;
					outline: none;
					background: none;
					padding: 0;
					width: 200px;
					box-shadow: none;
					border-radius: 0;
					height: 40px;
					font-size: 1.4rem;
				}

				button:focus,
				input:focus {
					outline: 0;
					border: none !important;
				}

				button {
					background: none;
					float: right;
					font-weight: bold;
					font-size: 1.4rem;
					line-height: 40px;
					cursor: pointer;
					color: var(--text--secondary);
					margin: 0;
					padding: 0;

					&:hover {
						color: var(--text--base);
					}
				}

				@include _on('mobile') {
					.replay_form_container {
						display: flex !important;
						padding: 0 0 0 1rem;

						input {
							width: auto;
							flex: 1;
						}

						button {
							padding: 0 1rem;
						}
					}
				}
			}

			dfn {
				position: absolute;
				top: 50px;
				left: 0;
				padding: 12px 18px;
				border-radius: 3px;
				-moz-border-radius: 3px;
				-webkit-border-radius: 3px;
				opacity: 0;
				font-weight: bold;
				font-size: 11px;
				line-height: 11px;
				background-color: var(--channel--dropdown--bg);

				transition: all 0.3s;
				-webkit-transition: all 0.3s;
				-moz-transition: all 0.3s;
				-o-transition: all 0.3s;
			}

			dfn:after {
				position: absolute;
				bottom: 100%;
				left: 11px;
				@include _css-triangle(0.5em, var(--channel--dropdown--bg), bottom);
			}
		}

		.closeBtn,
		.commentCloseBtn {
			top: 0;
			right: 0;
			position: absolute;
			display: block;
			height: 5.6rem;
			width: 5.6rem;
			font-size: 1.4rem;
			text-align: center;
			z-index: 1503;

			i {
				color: var(--channel--comment-title-color);
				transform: translateY(-50%);
				-webkit-transform: translateY(-50%);
				margin-top: 50%;
				display: inline-block;
				font-size: 2.4rem;
			}

			&:hover i::before {
				content: icons.get('close-solid');
			}
		}

		.commentCloseBtn {
			display: none;
		}

		.exclusive_content_wrapper {
			display: none;
			width: 100%;
			height: 100%;
			position: absolute;
			left: 0;
			top: 0;

			.exclusive_content {
				position: absolute;
				width: 100%;
				margin: -107px auto 0;
				top: 50%;
				z-index: 1501;
				text-align: center;

				span {
					display: block;
					width: 50px;
					height: 83px;
					margin: 0 auto;
				}

				p {
					opacity: .5;
					font-size: 20px;
					text-transform: uppercase;
					margin-bottom: 10px;
				}

				h2 {
					line-height: 20px;
				}
			}
		}

		#no_comments {
			height: 130px;
			width: 100%;
			position: absolute;
			left: 0;
			right: 0;
			top: 0;
			bottom: 0;
			margin: auto 0;
			text-align: center;
			font-size: 16px;
			color: var(--text--secondary-darker);

			span.icon-comments {
				display: inline-block;
				font-size: 60px;
				width: 90px;
				height: 60px;
				margin-bottom: 30px;
				position: relative;
			}
		}
	}

	@include _on('mobile') {
		.overlay_comment_container.fitscreen {
			width: 100% !important;
			max-width: 100% !important;
			height: 100%;
			top: 0 !important;
			left: 0 !important;
			transform: none !important;

			.comment_inner {
				left: 100%;
				width: 0;
				overflow: hidden;
				padding-bottom: env(safe-area-inset-bottom);
			}
		}

		.overlay_comment_container .content_container {
			width: 100% !important;
			margin: 0 !important;
			padding: 0 !important;

			#channel_next_media,
			#channel_prev_media,
			.title_container,
			.folder_info,
			.picture_shadow,
			.comment_like_container {
				left: 100%;
				transition: 0s left, 0s right;
			}

			#channel_next_media {
				right: 100%;
			}
		}

		.photo_table_frame {
			margin: 0 auto;

			img {
				top: 0 !important;
			}
		}

		.overlay_comment_container.fitscreen.picture_comment_container.showCommentOverlay,
		.overlay_comment_container.fitscreen.video_comment_container.showCommentOverlay {
			width: 100% !important;
			height: 100% !important;
			max-height: 100%;
			top: 0 !important;

			.comment_inner {
				left: 0;
				width: 100% !important;
			}

			.closeBtn {
				display: none;
			}

			.commentCloseBtn {
				display: block;
			}

			.content_viewer_navigation {
				display: none;
			}
		}
	}
}

:global .oranum-theme {
    :local(.switch) {
        box-shadow: none;
        border: 0;
        background: var(--toggle-on-off--bg);
    }

    @at-root .input:checked {
        + .switch::after {
            background: var(--toggle-on-off--knob-on-gr-start);
        }
    }
}
.oranum-theme {
	.step-select-account-type {

		form-selectorbox {
			.icon-wrapper {
				&__icon {
					&:before {
						background: var(--registration--step);
						-webkit-background-clip: text;
						-webkit-text-fill-color: transparent;
						background-clip: text;
						text-fill-color: transparent;
					}
				}
			}

			h4 {
				color: var(--box--header-text);
				white-space: pre-line;
				font-weight: bold;
			}

			p {
				color: var(--text--base);
				white-space: pre-line;
			}

			&:hover:not([disabled]) {
				border-color: var(--registration--step);
			}

			> div {
				opacity: 0.6;
				padding: 4rem 2rem 5rem;
			}

			&.selected > div {
				opacity: 1;
			}
		}
	}
}
@import '../../scss/shell';

// HTML5 VIDEO PLAYER
:global {
	.video-controls {
		@include transition(.2s, opacity);
		@include user-select(none);

		bottom: 0;
		height: 40px;
		line-height: 40px;
		padding: 0 20px 0 20px;
		left: 0;
		position: absolute;
		white-space: nowrap;
		right: 0;
		opacity: 0;
		z-index: 3;
		background-color: var(--video-player--controls-bg);

		i {
			position: absolute;
			color: var(--text--base);
		}

		> span {
			transition: opacity, .1s;

			position: absolute;
			display: inline-block;
			width: 18px;
			text-align: center;
			top: 0;
			bottom: 0;
			margin: auto 0;
			vertical-align: middle;
			opacity: 0.7;
			cursor: pointer;

			&:hover {
				opacity: 1;
			}
		}

		span.play-pause-btn {
			background: transparent;

			i {
				transform: translate(0, 0);

				position: absolute;
				font-size: 2rem;
				z-index: -1;
				display: none;
			}
			.icon-pause-solid {
				display: block;
			}
			&.play {
				i {
					display: none;
				}
				.icon-play-solid {
					display: block;
				}
			}
		}

		.progress-container {
			position: absolute;
			left: 95px;
			right: 173px;
			bottom: 16px;
			height: 8px;
			border-radius: 4px;
			background: RGBA(var(--text--base-rgb), 0.3);

			.progress-input {
				position: absolute;
				top: 0;
				width: 100%;
				height: 8px;
				border-radius: 4px;
				-webkit-appearance: none;
				background: transparent;
				cursor: pointer;
				z-index: 10;

				&::-webkit-slider-thumb {
					opacity: 0;
				}
				&::-moz-range-thumb {
					opacity: 0;
				}
				&::-ms-thumb {
					opacity: 0;
				}
			}

			.progress-buffer {
				position: absolute;
				top: 0;
				width: 0;
				height: 8px;
				border-radius: 4px;
				background: RGBA(var(--text--base-rgb), 0.6);
				transition: width 200ms ease;
			}

			.progress-current {
				position: absolute;
				top: 0;
				width: 0;
				height: 8px;
				border-top-left-radius: 4px;
				border-bottom-left-radius: 4px;
				background: -webkit-linear-gradient(left, var(--video-player--slider-gr-from) 0%, var(--video-player--slider-gr-to) 100%);
				box-shadow: 0 1px 1px RGBA(var(--text--base-rgb), 0.5) inset;
				transition: width 200ms ease;

				.progress-handle {
					position: absolute;
					top: -2px;
					right: -6px;
					width: 12px;
					height: 12px;
					border-radius: 6px;
					background: -webkit-linear-gradient(left, var(--video-player--slider-gr-from) 0%, var(--video-player--slider-gr-to) 100%);
					box-shadow: 0 1px 1px RGBA(var(--video-player--slider-shadow-from-rgb), 0.5) inset, 0 0 6px RGBA(var(--video-player--slider-shadow-to-rgb), 0.8);
					transition: transform, .2s;
					transform: scale(0);
					@include transform-origin(50% 50%);
				}
			}
		}

		&:hover .progress-container .progress-current .progress-handle {
			transform: scale(1);
		}

		.volume-container {
			position: relative;
			height: 100px;
			width: 8px;
			background: RGBA(var(--text--base-rgb), 0.3);
			border-radius: 4px;

			.volume-input {
				position: absolute;
				bottom: 45px;
				left: -48px;
				width: 100px;
				height: 8px;
				transform: rotate(-90deg);
				border-radius: 4px;
				background: transparent;
				cursor: pointer;
				z-index: 10;
				-webkit-appearance: none;

				&::-webkit-slider-thumb {
					opacity: 0;
				}
				&::-moz-range-thumb {
					opacity: 0;
				}
				&::-ms-thumb {
					opacity: 0;
				}
			}

			.volume-current {
				position: absolute;
				bottom: 0;
				left: 0;
				width: 8px;
				height: 0;
				border-bottom-right-radius: 4px;
				border-bottom-left-radius: 4px;
				background: -webkit-linear-gradient(bottom, var(--video-player--slider-gr-from) 0%, var(--video-player--slider-gr-to) 100%);
				box-shadow: 0 1px 1px RGBA(var(--text--base-rgb), 0.5) inset;
				transition: height 200ms ease;

				.volume-handle {
					position: absolute;
					top: -6px;
					right: -2px;
					width: 12px;
					height: 12px;
					border-radius: 6px;
					background: -webkit-linear-gradient(left, var(--video-player--slider-gr-from) 0%, var(--video-player--slider-gr-to) 100%);
					box-shadow: 0 1px 1px RGBA(var(--video-player--slider-shadow-from-rgb), 0.5) inset, 0 0 6px RGBA(var(--video-player--slider-shadow-to-rgb), 0.8);
				}
			}

			&.muted {
				.volume-current {
					height: 0 !important;

					.volume-handle {
						display: none;
					}
				}
			}
		}

		> span.play-pause-btn {
			left: 20px;
		}

		> span.video-fullscreen,
		> span.video-fillscreen {
			width: 20px;
			height: 40px;
			right: 20px;
			display: none;
		}

		> span.video-fillscreen {
			right: 55px;
		}

		.volume-controls {
			display: none;
			position: absolute;
			bottom: 0;
			right: 20px;

			&:hover .volume-control,
			.volume-control:hover {
				opacity: 1;
				pointer-events: auto;
			}
		}

		.volume-control {
			position: relative;
			padding: 12px 15px 0 15px;
			border-radius: 5px 5px 0 0;
			opacity: 0;
			@include transition(.2s, opacity);
			pointer-events: none;
		}

		span.volume-btn {
			transition: opacity, .2s;

			display: block;
			text-align: center;
			top: 0;
			bottom: 0;
			left: 2px;
			margin: 0 auto 10px;
			vertical-align: middle;
			opacity: 0.8;
			cursor: pointer;
			position: relative;
			width: 20px;
			height: 30px;
			padding: 0 1px;

			&:hover {
				opacity: 1;
			}

			i {
				transform: translate(0, 0);

				position: absolute;
				z-index: -1;
				font-size: 2rem;
				display: none;
			}

			.icon-volume-high-solid {
				display: block;
			}

			&[data-volume-level="1"] {
				i {
					display: none;
				}

				.icon-volume-low-solid {
					display: block;
				}
			}

			&.muted {
				i {
					display: none;
				}

				.icon-volume-low-solid {
					display: none;
				}

				.icon-volume-high-solid {
					display: none;
				}

				.icon-volume-mute-solid {
					display: block;
				}
			}
		}

		.current-time,
		.remaining-time {
			font-size: 12px;
			position: absolute;
			height: 40px;
			line-height: 40px;
			top: 0;
			cursor: default;
		}

		.current-time {
			left: 47px;
		}

		.remaining-time {
			right: 20px;
		}

		span.video-fullscreen {
			i {
				transform: translate(0, 0);

				font-size: 2rem;
				display: none;
			}
			.icon-screen-full-solid {
				display: block;
			}
		}
		span.video-fillscreen {
			i {
				transform: translate(0, 0);

				font-size: 2rem;
				display: none;
				z-index: -1;
			}

			.icon-zoom-in-solid {
				display: block;
			}

			&.on {
				i {
					display: none;
				}
				.icon-zoom-out-solid {
					display: block;
				}
			}
		}

		.volume-control {
			background: var(--video-player--controls-bg);
		}

		.current-time,
		.remaining-time {
			color: var(--text--base);
		}
	}

	.video_player_container {
		overflow: hidden;
		width: 100%;
		height: 100%;
		position: relative;

		@include _fullscreen {
			background-color: var(--video-player--fullscreen-bg);

			span.video-fullscreen {
				i {
					display: none;
				}
				.icon-screen-standard-solid {
					display: block;
				}
			}
		}

		&:hover .video-controls,
		.video-controls:hover {
			opacity: 1;
		}

		.video-controls:hover {
			opacity: 1 !important;
		}

		&.hide-controls .video-controls {
			opacity: 0;
			pointer-events: none;

			span {
				cursor: default;
			}
		}

		#ajaxIndicatorContainer.progressIndicator {
			position: absolute;
			z-index: 0;
			background: none;
		}

		.failure_container {
			display: none;
			position: relative;
			z-index: 4;
			width: 100%;
			height: 100%;
			background-color: var(--video-player--failure-bg);
			justify-content: center;
			align-items: center;
			text-align: center;

			p {
				font-size: 18px;
				font-weight: bold;

				&:nth-child(2) {
					margin-top: 18px;
					font-size: 14px;
					color: var(--video-player--failure-text);
				}
			}
		}

		&.loading-failed .failure_container {
			display: flex;
		}

		video {
			max-width: 100%;
			max-height: 100%;
			width: 100%;
			height: 100%;
			position: relative;

			&:after {
				content: "";
				z-index: 1;
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
			}
		}

		&.show-fill-button.show-volume-controls .video-controls,
		&.show-volume-controls.show-fullscreen-button .video-controls,
		&.show-fill-button.show-fullscreen-button .video-controls {
			.remaining-time {
				right: 95px;
			}
			.volume-controls {
				right: 50px;
			}
			.progress-bar {
				right: 140px;
			}
			> span.video-fillscreen {
				right: 55px;
			}
		}

		&.show-volume-controls.show-fill-button .video-controls > span.video-fillscreen {
			right: 20px;
		}

		&.show-volume-controls .video-controls,
		&.show-fullscreen-button .video-controls,
		&.show-fill-button .video-controls {
			.remaining-time {
				right: 67px;
			}
			.volume-controls {
				right: 20px;
			}
		}
		&.show-volume-controls .video-controls .volume-controls,
		&.show-fullscreen-button .video-controls > span.video-fullscreen,
		&.show-fill-button .video-controls > span.video-fillscreen {
			right: 20px;
			display: block;
		}

		&.show-fill-button.show-fullscreen-button.show-volume-controls .video-controls {
			.remaining-time {
				right: 130px;
			}
			.volume-controls {
				right: 85px;
			}
			.progress-bar {
				right: 173px;
			}
			> span.video-fillscreen {
				right: 55px;
			}
		}
	}
}

@mixin _fullscreen() {
	&:-webkit-full-screen {
		@content;
	}
	&:-moz-full-screen {
		@content;
	}
	&:-ms-fullscreen {
		@content;
	}
	&:fullscreen {
		@content;
	}
}


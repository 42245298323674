@import '../../../scss/shell';

:global .downloadAppGuideOverlay {

	.box_header {
		top: -38px;
		background: none;
		border-radius: 0;

		.close i {
			margin: 0;
			width: 17px;
			height: 17px;
			top: 4px;
			font-size: 16px;
			font-weight: bold;
			opacity: 1;
			color: var(--download-guide--close);
		}
	}

	input[type="radio"] {
		display: none;
	}

	.device-tab {
		position: absolute;
		top: 0;
		width: 50%;
		height: 34px;
		line-height: 34px;
		text-align: center;
		font-size: 14px;
		color: var(--download-guide--header-text);
		background: var(--download-guide--header-bg);
		border: 1px solid var(--download-guide--header-bg);
		border-radius: 5px 0 0 0;
		cursor: pointer;

		&-right {
			right: 0;
			border-radius: 0 5px 0 0;
		}
	}

	.device-input:checked {
		+ label {
			color: var(--text--base);
			background: var(--download-guide--bg);
			cursor: default;

			+ div {
				display: block;
			}
		}
	}

	.content {
		display: none;
		height: 590px;
		text-align: center;
		position: relative;

		h4 {
			margin: 33px 0 3px;
			font-size: 12px;
			font-weight: normal;
			text-transform: uppercase;
			color: var(--text--link);
			letter-spacing: 1.2px;
		}

		h2 {
			margin: 0 0 35px;
			font-size: 24px;
			font-weight: bold;
		}

		input:checked {
			+ label + section {
				display: block;
			}

			+ label + section + div {
				display: block;
			}

			+ label {
				width: 15px;
				height: 15px;
				margin: 0 1px;
				top: 484px;
				background: var(--download-guide--dot);
				cursor: default;
			}
		}

		label {
			position: relative;
			z-index: 1;
			display: inline-block;
			padding: 3px;
			top: 485px;
			border-radius: 100%;
			cursor: pointer;

			span {
				display: block;
				width: 10px;
				height: 10px;
				border-radius: 100%;
				background: RGBA(var(--download-guide--dot-rgb), 0.5);
			}
		}

		section {
			display: none;
			position: absolute;
			top: 80px;
			width: 100%;

			span {
				width: 30px;
				height: 30px;
				line-height: 1.5;
				display: inline-block;
				margin: 0 0 14px;
				padding: 0 0 0 1px;
				color: var(--text--link);
				font-size: 18px;
				font-weight: bold;
				border: 2px solid var(--text--link);
				border-radius: 100%;
			}

			h3 {
				font-size: 18px;
				text-transform: uppercase;
				margin: 0 0 23px;
			}

			p {
				margin: 0 auto 14px;
				font-size: 14px;
				line-height: 1.3;
				padding: 0 75px;
				color: var(--download-guide--text);

				strong {
					color: var(--text--base);
				}
			}
		}

		ul {
			text-align: left;
			margin: 0 0 0 112px;

			li {
				list-style-type: disc;
				color: var(--text--link);
				font-size: 14px;

				p {
					margin: 0 0 10px -5px;
					padding: 0;
				}
			}
		}

		.guide-image {
			display: none;
			position: absolute;
			bottom: 0;
			left: calc(50% - 133px);
			width: 266px;
			height: 295px;
			margin: 52px auto 0;
			background-repeat: no-repeat;
			background-position: top center;
		}
	}

	#androidContent {
		.tabContent1 + .guide-image {
			background-image: url('./android_step1.png');
		}

		.tabContent2 + .guide-image {
			background-image: url('./android_step2.png');
		}

		.tabContent3 {
			+ .guide-image {
				background-image: url('./android_step3.png');
			}

			ul {
				margin: 0 0 0 145px;
			}
		}
	}

	#iosContent {
		.guide-image {
			height: 340px;
		}

		.tabContent1 {
			+ .guide-image {
				background-image: url('./ios_step1.png');
			}
			ul {
				margin: 0 0 0 175px;
			}
		}

		.tabContent2 {
			+ .guide-image {
				background-image: url('./ios_step2.png');
			}

			ul {
				margin: 0 0 0 250px;
			}
		}

		.tabContent3 + .guide-image {
			background-image: url('./ios_step3.png');
		}

		.tabContent4 + .guide-image {
			background-image: url('./ios_step4.png');
		}
	}
}
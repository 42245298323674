@import '../../scss/shell';

.wrapper {
	display: inline-block;
}

.input {
	display: none;
}

.switch {
	position: relative;
	display: inline-block;
	background: var(--toggle-on-off--bg);
	width: 6rem;
	height: 2.6rem;
	border-radius: 5rem;

	-moz-user-select: none;
	-khtml-user-select: none;
	-webkit-user-select: none;
	-ms-user-select: none;
	user-select: none;

	// knob
	&::after {
		@include _vertical-gradient(
						var(--toggle-on-off--knob-off-gr-start),
						var(--toggle-on-off--knob-off-gr-end)
		);

		content: '';
		display: block;
		position: absolute;
		left: 0;
		top: 50%;
		width: 2rem;
		height: 2rem;
		border-radius: 6rem;
		transform: translate(0.3rem, -50%);
		transition: all 0.25s _easing('ease-out-back');
		backface-visibility: hidden;
	}

	.title {
		position: absolute;
		left: 0;
		top: 0;
		display: inline-block;
		line-height: 2.7rem;
		color: var(--toggle-on-off--on-text);
		font-size: 1.1rem;
		font-weight: bold;
		padding: 0 .8rem;
		text-transform: uppercase;
		opacity: 0;
		transition: opacity 0.15s ease-out;

		&.off {
			color: var(--toggle-on-off--off-text);
			right: 0;
			left: auto;
		}
	}

	&:hover {
		cursor: pointer;
	}

	@at-root .input:checked {
		+ .switch::after {
			@include _vertical-gradient(
							var(--toggle-on-off--knob-on-gr-start),
							RGBA(var(--toggle-on-off--knob-on-gr-end-rgb), 0.8)
			);
			transform: translate(3.6rem, -50%);
			background-color: var(--toggle-on-off--switch-bg);
		}

		+ .switch .on {
			opacity: 1;
		}
	}

	@at-root .input:not(:checked) {
		+ .switch .off {
			opacity: 1;
		}
	}

	@at-root .input[disabled] {
		+ .switch {
			opacity: 0.5;
			pointer-events: none;
		}
	}
}

:global {
	toggle-voice-control {
		.icon-alert {
			color: var(--text--link);
			position: relative;
			top: 1px;
			padding: 5px 5px 0;
		}

		.suspended {
			opacity: .5;
		}

		.tap-layer {
			display: none;
			position: fixed;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			z-index: 99;
		}

		&.open .tap-layer {
			display: block;
		}
	}

	.suspended-tooltip {
		padding: 20px;
		min-width: 320px !important;
		text-align: center;

		em {
			display: block;
			font-size: 14px;
			font-weight: bold;
			margin: 0 0 5px;

			&.suspended-time {
				color: var(--text--link);
			}
		}

		span {
			font-size: 12px;
			color: var(--text--tertiary);
			line-height: 1.3;
		}
	}
}

@import "ToggleOnOff.oranum";

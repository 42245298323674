@use 'components/Button/button';
@import '../../../scss/shell';

:global {
	/* protection oldal */
	.protectionContent {
		@include extendEarnMoreStatusHeader;
		text-align: center;

		.protectionBox {
			@include style('box', 'common', 'info');

			width: 100%;
			text-align: left;
			padding: 30px;
			min-height: 240px;

			.shieldQuestion,
			.shieldHuman,
			.shieldThunder {
				margin: 9px auto 35px auto;
			}

			h4 {
				margin: 0 0 14px 0;
			}

			p {
				@include style('text', 'normalSize', 'regularColor');
			}

			a {
				@include button.linkPrimary();
				font-size: 12px;
			}
		}
	}
}
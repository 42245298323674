@import '../../../scss/shell';

:global {

	.overlayContent {
		.promo-video-container {
			max-width: 96rem;
			text-align: initial;
		}

		.upload_now {
			display: none;
		}
	}

	#promoVideoDetailsContainer {
		.graph_icon {
			background-image: url('./graph_icon.png');
		}

		.money_icon {
			background-image: url('./money_icon.png');
		}

		.cover,
		.help {
			padding: 4rem 3rem;
		}

		.cover {
			background-image: url('./promo_video_cover_bg.jpg');

			.benefits {
				display: table;
				margin-bottom: 5rem;

				.benefit {
					margin-bottom: .8rem;
					em {
						display: table-cell;
						height: 3.7rem;
						width: 3.7rem;
					}

					span {
						padding-left: .9rem;
						font-size: 2rem;
						font-weight: 600;
						display: table-cell;
						vertical-align: middle;
					}
				}
			}
		}

		.step_1 {
			background-image: url('./promo_video_step_1.jpg');
		}

		.step_2 {
			background-image: url('./promo_video_step_2.jpg');
		}

		.step_3 {
			background-image: url('./promo_video_step_3.jpg');
		}

		.step_4 {
			background-image: url('./promo_video_step_4.jpg');
		}

		.steps {
			.step {
				position: relative;
				min-height: 26rem;
				padding: 7rem 0;
				background-position: 60%;

				.content {
					position: absolute;
					right: 3.5rem;
					max-width: 34rem;

					@include _on(1024, 1180) {
						right: 0.5rem;
						max-width: 30rem;
					}

					.step_number,
					.title {
						text-transform: uppercase;
					}

					.step_number,
					.description {
						font-size: 1.6rem;
					}

					.step_number {
						padding: 0 0 1rem 0;
						font-weight: 600;
						color: var(--promo-video--step-number);
					}

					.title {
						font-size: 2.2rem;
						line-height: 2.6rem;
					}

					.description {
						font-weight: 100;
						line-height: 1.3;
						color: var(--promo-video--step-description);
					}
				}
			}
		}

		.help {
			@include _vertical-gradient(var(--promo-video--help-section-gradient-from), var(--promo-video--help-section-gradient-to));

			.heading {
				font-size: 3rem;
			}

			.help-row {
				margin-bottom: 4rem;
			}

			.help-column {
				.list-heading {
					margin: 0;
					padding: 2rem 3.2rem;
					border-radius: 0.5rem 0.5rem 0 0;
					font-size: 1.6rem;
					text-transform: uppercase;
					background-color: var(--promo-video--help-heading-bg);
				}

				.list {
					min-height: 40.5rem;
					padding: 3rem 3.2rem 1.2rem 4.5rem;
					border-radius: 0 0 0.5rem 0.5rem;
					list-style: disc;
					background-color: var(--promo-video--list-container-bg);

					li {
						text-align: left;
						padding: 0 0 1.8rem 0;
						font-size: 1.4rem;
						line-height: 1.2;
						color: var(--promo-video--list-item);

						span {
							text-transform: uppercase;
						}
					}
				}
			}

			.help-footer {
				text-align: center;

				.button {
					margin-bottom: 2rem;
				}

				.more-info {
					font-size: 1.2rem;
					color: var(--promo-video--more-info-link);
				}
			}
		}
	}
}
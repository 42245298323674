@import '../../../../scss/shell';

$mobilePortraitMax: 600;
$mobileLandscapeMax: 660;
$iphoneLandscapeMax: 670;
$tabletLandscapeMin : 880;
$tabletLandscapeMax : 1280;
$fullHDWidth: 1921;

$ipadLandscapeMinWidth: 1000;
$ipadLandscapeMaxWidth: 1040;

$ipadPortraitMin: 760;
$ipadPortraitMax: 780;

:global {
    body#index_celebrity {

        #main_content {
            margin: auto;
        }

        .red-gold-gradient-bg {
            background: var(--celebrity--gradient-bg);
            background: -moz-linear-gradient(left, var(--celebrity--gradient-bg) 0%, var(--celebrity--gradient-bg-from) 68%, var(--celebrity--gradient-bg-to) 100%);
            background: -webkit-linear-gradient(left, var(--celebrity--gradient-bg) 0%, var(--celebrity--gradient-bg-from) 68%, var(--celebrity--gradient-bg-to) 100%);
            background: linear-gradient(to right, var(--celebrity--gradient-bg) 0%, var(--celebrity--gradient-bg-from) 68%, var(--celebrity--gradient-bg-to) 100%);
        }

        .header-block__inner {
            max-width: 130rem;
        }

        h1 {
            font-size: 52px;
            line-height: 1;
            margin-bottom: 10px;

            @include _on(0, $mobilePortraitMax) {
                padding-top: 10px;
                font-size: 40px;
            }
        }

        h2 {
            font-size: 30px;
            line-height: 1;
            padding: 20px 0;
            margin-bottom: 0;

            &.padded {
                padding: 60px 0;
            }

            @include _on(0, $tabletLandscapeMin) {
                font-size: 24px;
                padding-top: 35px;
            }

            @include _on(0, $mobilePortraitMax) {
                font-size: 25px;
                padding-top: 35px;
                line-height: 1.2;
            }
        }

        h3 {
            font-weight: 200;
            line-height: 1.2;

            @include _on(0, $mobilePortraitMax) {
                font-size: 18px;
            }
        }

        .text {
            font-size: 20px;
            font-weight: 200;
            line-height: 1.3;
            max-width: 100%;

            @include _on(0, $mobilePortraitMax) {
                font-size: 18px;
            }
        }

        section {
            width: 100%;
            background-color: var(--primary);
        }

        .wrapper {
            position: relative;
            max-width: 125rem;
            margin: 0 auto;

            &.tall {
                min-height: 500px;

                @include _on(0, $mobilePortraitMax) {
                    min-height: 628px;
                }
            }

            &.medium {
                min-height: 300px;
            }
        }

        .content {
            max-width: 775px;
            margin: 0 auto;

            @include _on('small', 'down') {
                max-width: 90%;
            }
        }

        .assetsSection {
            background-color: var(--text--base);
            color: var(--celebrity--money-section-text);

            h2 {
                color: var(--celebrity--money-section-heading);
                padding-bottom: 15px;
            }

            .button {
                margin-top: 50px;
            }

            .wrapper {
                @include _on(0, $mobilePortraitMax) {
                    min-height: 665px;
                    position: relative;
                }
            }

            .assets {
                width: 2000px;
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
                top: 0;
                overflow: hidden;
                bottom: 0;

                @include _on(0, $mobilePortraitMax) {
                    width: auto;
                    left: 0;
                    top: 0;
                    bottom: 0;
                    right: 0;
                    transform: none;
                }
            }
        }

        .liveTheLife {
            position: relative;
            overflow: hidden;

            h1 {
                @include _on(0, $tabletLandscapeMax) {
                    margin-bottom: 13px;
                    font-size: 39px;
                }
                @include _on(0, $tabletLandscapeMin) {
                    width: 245px;
                    margin: 0 auto 13px;
                }
                @include _on(0, $mobilePortraitMax) {
                    font-size: 38px;
                    line-height: 1.2;
                    margin: 0 auto 16px;
                }
            }

            .text {
                @include _on(0, $tabletLandscapeMax) {
                    width: 300px;
                    margin: auto;
                    font-size: 18px;
                }
            }

            .wrapper {
                @include _on(0, $tabletLandscapeMax) {
                    min-height: 385px;
                    margin: 0 auto 0 17px;
                }
                @include _on(0, $tabletLandscapeMin) {
                    min-height: 373px;
                }
                @include _on(0, $mobilePortraitMax) {
                    margin: 0;
                    min-height: 564px;
                }
            }

            .background {
                background-image: url('../images/girl.jpg');
                position: absolute;
                left: 0;
                bottom: 0;
                width: 100%;
                height: 1200px;
                background-position: 100% bottom;

                @include _on($fullHDWidth) {
                    background-image: url('../images/girl-uhd.jpg');
                }

	            @include _on($fullHDWidth) {
		            background-position: 100% 49%;
		            background-size: 112%;
                    top: auto;
	            }

                @include _on(0, $tabletLandscapeMax) {
                    background-size: 122%;
                    left: 0;
                    bottom: -24px;
                    width: 117%;
                }

                @include _on(0, $tabletLandscapeMin) {
                    background-size: 142%;
                    left: 0;
                    bottom: 0;
                    width: 126%;
                }

                @include _on(0, $mobilePortraitMax) {
                    right: auto;
                    transform: translate(-42%, -23%);
                    background-size: 104%;
                    left: -34%;
                    bottom: -49%;
                    top: auto;
                    width: 312%;
                }
            }

            .content {
                position: absolute;
                width: 651px;
                transform: translateY(50%);

                @include _on(0, $tabletLandscapeMax) {
                    width: 486px;
                }

                @include _on(0, $tabletLandscapeMin) {
                    width: 305px;
                    top: auto;
                    transform: translateY(26%);
                }

                @include _on(0, $mobilePortraitMax) {
                    height: 100%;
                    left: auto;
                    transform: none;
                    top: 3%;
                    width: 100%;
                    text-align: center;
                    max-width: 100%;
                    padding: 0 20px;
                }

                .buttons  {
                    font-size: 14px;

                    @include _on(0, $mobilePortraitMax) {
                        position: absolute;
                        bottom: 35px;
                        left: 50%;
                        transform: translateX(-50%);
                    }
                    @include _on(0, $mobilePortraitMax) {
                        bottom: 55px;
                        width: 80%;
                        p {
                            margin-top: 6px;
                            width: 100%;
                        }
                    }
                    .button {
                        margin-bottom: 14px;
                        padding: 13px 29px;

                        @include _on(0, $tabletLandscapeMin) {
                            margin: -7px 0 19px;
                        }
                        @include _on(0, $tabletLandscapeMax) {
                            margin-bottom: 20px;
                        }
                    }
                }
            }

            .fade {
                content: '';
                position: absolute;
                left: 0;
                right: 0;
                bottom: 0;
                height: 200px;
                background: linear-gradient(to top, var(--channel--note--overlay-bg), transparent);
                opacity: .5;
            }

            .title {
                margin: 0 0 77px 0;

                @include _on(0, $tabletLandscapeMax) {
                    margin: 0 0 23px;
                }

                @include _on(0, $tabletLandscapeMin) {
                    margin: 0 0 23px;
                }

                @include _on(0, $mobilePortraitMax) {
                    margin: 20px 0;
                }
            }
        }

        .startMakingMoney {
            position: relative;
            overflow: hidden;
            .wrapper {
                min-height: 0;
                @include _on(0, $tabletLandscapeMax) {
                    min-height: 374px;
                }
                @include _on(0, $mobilePortraitMax) {
                    min-height: 667px;
                }
            }

            .background {
                background-position: bottom;
                background-image: url('../images/items.jpg');
                position: absolute;
                left: 0;
                bottom: 0;
                width: 100%;
                height: 1200px;
                background-size: 100%;
                background-repeat: no-repeat;

                @include _on($fullHDWidth) {
                    background-size: 100%;
                    background-position: 0 bottom;
                    background-image: url('../images/items-uhd.jpg');
                }


                @include _on(0, $mobilePortraitMax) {
                    background-image: url('../images/items-mobile.jpg');
                    background-size: 100%;
                    bottom: -1%;
                }
            }

            h2 {
                @include _on(0, $mobilePortraitMax) {
                    padding-bottom: 11px;
                }
            }

            h2.padded {
                @include _on(0, $tabletLandscapeMax) {
                    font-size: 27px;
                    padding: 34px 0 17px;
                }
                @include _on(0, $tabletLandscapeMin) {
                    font-size: 25px;
                    padding: 37px 0 17px;
                }
            }

            .content {
                padding-bottom: 225px; // prevent content overlapping

                @include _on('small', 'down') {
                    padding-bottom: 85px;
                }

                @include _on(0, $tabletLandscapeMax) {
                    padding-bottom: 0;
                }

                @include _on(0, $mobilePortraitMax) {
                    padding-bottom: 420px;
                }
            }

            .text {
                @include _on(0, $tabletLandscapeMax) {
                    font-size: 19px;
                    line-height: 1.23;
                    width: 477px;
                    margin: auto;
                }
                @include _on(0, $tabletLandscapeMin) {
                    line-height: 1.2;
                }
                @include _on(0, $mobilePortraitMax) {
                    max-width: 100%;
                    font-size: 18px;
                    line-height: 1.3;
                    word-spacing: -1px;
                }
            }

            .button {
                @include _on(0, $tabletLandscapeMax) {
                    margin-top: 33px;
                    padding: 14px 30px;
                }
                @include _on(0, $tabletLandscapeMin) {
                    margin-top: 37px;
                }
                @include _on(0, $mobilePortraitMax) {
                    margin-top: 40px;
                }
            }

            .necklace {
                background-image: url('../images/necklace.png');
                position: absolute;
                left: -175px;
                top: 15px;
                width: 438px;
                height: 340px;

                @include _on('small', 'down') {
                    transform: scale(0.5) rotate(-30deg);
                    left: 230px;
                    top: 100px;
                }

                @include _on(0, $mobilePortraitMax) {
                    top: auto;
                    bottom: 210px;
                    left: -230px;
                }
            }

            .weight {
                background-image: url('../images/weight.png');
                position: absolute;
                right: 30px;
                bottom: 320px;
                width: 220px;
                height: 223px;

                @include _on('small', 'down') {
                    transform: scale(0.5);
                    right: 400px;
                    bottom: 100px
                }

                @include _on(0, $mobilePortraitMax) {
                    right: -100px;
                    bottom: 320px;
                }
            }

            .money {
                background-image: url('../images/money.png');
                position: absolute;
                right: 10px;
                bottom: -30px;
                width: 300px;
                height: 360px;
                transform: rotate(20deg);

                @include _on('small', 'down') {
                    transform: scale(0.5) rotate(20deg);
                    right: 390px;
                    bottom: -120px
                }

                @include _on(0, $mobilePortraitMax) {
                    right: -120px;
                    transform: scale(0.5) rotate(20deg);
                }
            }

            .iphone {
                background-image: url('../images/iphone.png');
                position: absolute;
                left: 280px;
                top: -120px;
                width: 296px;
                height: 336px;

                @include _on('small', 'down') {
                    transform: scale(0.5);
                    left: 380px;
                }

                @include _on(0, $mobilePortraitMax) {
                    top: auto;
                    left: auto;
                    right: -120px;
                    bottom: 120px;
                    transform: scale(0.5) rotate(90deg);
                }
            }

            .earrings {
                background-image: url('../images/earrings.png');
                position: absolute;
                right: 250px;
                top: 0;
                width: 224px;
                height: 170px;
                transform: rotate(-28deg);

                @include _on('small', 'down') {
                    transform: scale(0.5) rotate(-28deg);
                    right: 400px;
                    top: -35px;
                }

                @include _on(0, $mobilePortraitMax) {
                    top: auto;
                    bottom: 140px;
                    right: 17%;
                }
            }

            .sunglasses {
                background-image: url('../images/sunglasses.png');
                position: absolute;
                right: 390px;
                bottom: 70px;
                width: 400px;
                height: 263px;

                @include _on('small', 'down') {
                    transform: scale(0.5);
                    bottom: -20px;
                    left: 50%;
                    margin-left: 40px;
                }

                @include _on(0, $mobilePortraitMax) {
                    bottom: 200px;
                    margin-left: -200px;
                }
            }

            .headphone {
                background-image: url('../images/headphone.png');
                position: absolute;
                left: 200px;
                bottom: -115px;
                width: 250px;
                height: 473px;
                transform: rotate(-90deg);

                @include _on('small', 'down') {
                    transform: scale(0.5) rotate(-90deg);
                    bottom: -185px;
                    left: 485px;
                }

                @include _on(0, $mobilePortraitMax) {
                    transform: scale(0.5);
                    bottom: -30px;
                    left: -80px;
                }
            }

            .keyboard {
                background-image: url('../images/keyboard.png');
                position: absolute;
                right: 800px;
                bottom: -325px;
                width: 400px;
                height: 510px;

                @include _on('small', 'down') {
                    transform: scale(0.5);
                    right: 40%;
                    bottom: -310px;
                }

                @include _on(0, $mobilePortraitMax) {
                    right: 1%;
                    bottom: -300px;
                }
            }

            .lipstick {
                background-image: url('../images/lipstick.png');
                position: absolute;
                right: 320px;
                bottom: -60px;
                width: 205px;
                height: 213px;

                @include _on('small', 'down') {
                    transform: scale(0.5);
                    right: 605px;
                    bottom: -80px;
                }

                @include _on(0, $mobilePortraitMax) {
                    right: 1%;
                    bottom: 0;
                }
            }

            .watch {
                background-image: url('../images/watch.png');
                position: absolute;
                left: 610px;
                bottom: 70px;
                width: 170px;
                height: 191px;

                @include _on('small', 'down') {
                    transform: scale(0.5);
                    left: 685px;
                    bottom: 10px;
                }

                @include _on(0, $mobilePortraitMax) {
                    left: 10%;
                    bottom: 50px;
                }
            }

            .pen {
                background-image: url('../images/pen.png');
                position: absolute;
                left: 580px;
                bottom: -70px;
                width: 160px;
                height: 197px;

                @include _on('small', 'down') {
                    transform: scale(0.5);
                    left: 750px;
                    bottom: -100px;
                }

                @include _on(0, $mobilePortraitMax) {
                    left: -50px;
                    bottom: -70px;
                }
            }

        }

        .ownBoss {

            h2.padded {
                @include _on(0, $tabletLandscapeMax) {
                    padding: 36px 0 31px;
                    font-size: 27px;
                }
                @include _on(0, $tabletLandscapeMin) {
                    padding: 37px 0 34px;
                    font-size: 25px;
                }
            }

            ph-col[ph-col-30-on-mobile] {
                @include _on($mobileLandscapeMax, 700) {
                    width: 25%;
                }
            }

            .wrapper.medium {
                @include _on(0, $tabletLandscapeMax) {
                    min-height: 238px;
                }
                @include _on(0, $mobilePortraitMax) {
                    min-height: 370px;
                }
            }


            [ph-gutter-bottom-20] {
                @include _on(0, $tabletLandscapeMax) {
                    padding-bottom: 1rem;
                }
            }

            figcaption span {
                @include _on(0, $tabletLandscapeMin) {
                    font-size: 13px;
                }
                @include _on($mobileLandscapeMax, $tabletLandscapeMin) {
                    font-size: 11px;
                }
            }

            [ph-gutter-bottom-20] {
                @include _on(0, $mobilePortraitMax) {
                    padding-bottom: 0;
                }
            }
        }

        .safeAndSecure {

            h2.padded {
                @include _on(0, $tabletLandscapeMax) {
                    padding: 35px 0 24px;
                    font-size: 27px;
                }
                @include _on(0, $tabletLandscapeMin) {
                    padding: 35px 0 30px;
                    font-size: 24px;
                }
            }

            ph-col[ph-col-30-on-mobile] {
                @include _on($mobileLandscapeMax, 700) {
                    width: 25%;
                }
            }

            .wrapper.medium {
                @include _on(0, $tabletLandscapeMax) {
                    min-height: 236px;
                }
            }

            figcaption span {
                @include _on($mobileLandscapeMax, $tabletLandscapeMin) {
                    font-size: 11px;
                }
            }

            [ph-gutter-bottom-20] {
                @include _on(0, $tabletLandscapeMax) {
                    padding-bottom: 1rem;
                }
            }

            [ph-gutter-bottom-20] {
                @include _on(0, $mobilePortraitMax) {
                    padding: 0;
                }
            }
        }

        .categoryGallery {
            .wrapper {
                position: static;
            }

            h2 {
                padding: 24px 0 11px;

                @include _on(0, $tabletLandscapeMax) {
                    margin: 0;
                    padding: 20px 0 13px;
                    font-size: 26px;
                }
                @include _on(0, $tabletLandscapeMin) {
                    margin: 0;
                    padding: 26px 0 16px;
                    font-size: 24px;
                }
                @include _on(0, $mobilePortraitMax) {
                    padding: 35px 0 16px;
                }
            }

            .menu {
                margin: 24px -20px 61px;
                @include _on(0, $tabletLandscapeMax) {
                    margin: 0px -20px 16px;
                }
                @include _on(0, $tabletLandscapeMin) {
                    margin: 13px -20px 9px;
                    font-size: 38px;
                }
            }

            .slide {
                height: 500px;
                width: 100%;
                position: relative;
                background-position: 100% bottom;

                @include _on($fullHDWidth) {
                    background-size: 100%;
	                background-position: 0px -62%;
                }

                @include _on('medium') {
                    background-position-x: 60%;
                }

                @include _on('small') {
                    background-position-x: 45%;
                }

                @include _on(0, $tabletLandscapeMax) {
                    background-position: 8% 95%;
                    background-size: 117%;
                    height: 375px;
                }

                @include _on(0, $tabletLandscapeMin) {
                    background-position: 40% 100%;
                    background-size: 151%;
                }

                @include _on(0, $mobilePortraitMax) {
                    background-position-x: calc(50% - 225px);
                    height: 668px;
                    background-position: 71% 100%;
                    background-size: 394%;
                    background-repeat: no-repeat;
                }

                &.soulmate {
                    background-image: url('../images/soulmate.jpg');
                }

                &.dj {
                    background-image: url('../images/dj.jpg');
                }

                &.dancer {
                    background-image: url('../images/dancer.jpg');
                }

                &.fitness {
                    background-image: url('../images/fitness.jpg');
                }

                &.fashion {
                    background-image: url('../images/fashion.jpg');
                }

                &.advisor {
                    background-image: url('../images/advisor.jpg');
                }

                &.cosplay {
                    background-image: url('../images/cosplay.jpg');
                }
            }

            ul {
                @include _on(0, $tabletLandscapeMax) {
                    margin: 7px 0 13px;
                }
            }

            li {
                @include _on(0, $tabletLandscapeMax) {
                    font-size: 32px;
                    margin: 0 10px;
                }
                @include _on(0, $tabletLandscapeMin) {
                    font-size: 32px;
                    margin: 0 3px;
                }
            }

            h3 {
                @include _on(0, $tabletLandscapeMax) {
                    min-height: 0;
                    font-size: 19px;
                    margin: 0 0 10px;
                }
                @include _on(0, $tabletLandscapeMin) {
                    min-height: 0;
                    font-size: 16px;
                    width: 260px;
                    margin: auto;
                    max-width: 100%;
                }
                @include _on(0, $mobilePortraitMax) {
                    width: 80%;
                    margin: auto;
                    max-width: 100%;
                }
            }

            .content {
                position: absolute;
                width: 35%;
                max-width: 500px;
                top: 50%;
                transform: translateY(-50%);
                color: var(--text--secondary-darker);
                text-align: center;
                font-size: 16px;
                line-height: 1.2;

                @include _on('small') {
                    width: 360px;
                }

                @include _on(0, $tabletLandscapeMax) {
                    width: 535px;
                    max-width: 100%;
                    top: 48%;
                    padding: 0 20px;
                }

                @include _on(0, $tabletLandscapeMin) {
                    width: 380px;
                    max-width: 100%;
                    top: 49%;
                    padding: 0 20px;
                }

                @include _on(0, $mobilePortraitMax) {
                    height: 100%;
                    margin-left: 0;
                    transform: initial;
                    left: auto;
                    top: 0;
                    width: 100%;
                }
            }

            .button {
                margin-top: 61px;
                margin-bottom: 16px;
                padding: 15px 37px;

                @include _on(0, $tabletLandscapeMax) {
                    margin-top: 38px;
                }

                @include _on(0, $tabletLandscapeMin) {
                    margin-top: 23px;
                    padding: 15px 31px;
                }

                @include _on(0, $mobilePortraitMax) {
                    position: absolute;
                    left: 50%;
                    transform: translateX(-50%);
                    bottom: 23px;
                }
            }
        }

        .buildBrand {
            position: relative;
            overflow: hidden;

            h2 {
                padding: 56px 0 7px;
            }

            h2.padded {
                @include _on(0, $tabletLandscapeMax) {
                    font-size: 27px;
                    padding: 34px 0 17px;
                }
                @include _on(0, $tabletLandscapeMin) {
                    font-size: 24px;
                    padding: 37px 0 17px;
                }
            }

            .text {
                @include _on(0, $tabletLandscapeMin) {
                    font-size: 18px;
                }
            }

            .wrapper {
                @include _on(0, $tabletLandscapeMax) {
                    min-height: 375px;
                }
                @include _on(0, $mobilePortraitMax) {
                    min-height: 667px;
                }
            }

            .button {
                margin-top: 54px;
                padding: 14px 28px;
                @include _on(0, $tabletLandscapeMax) {
                    margin-top: 38px;
                }
            }

            .content {
                max-width: 500px;

                @include _on(0, $mobilePortraitMax) {
                    max-width: 90%;
                }
            }
        }

        #parallax {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            image-rendering: pixelated;
            -webkit-backface-visibility: hidden;
            -moz-backface-visibility: hidden;
            -ms-backface-visibility: hidden;
            backface-visibility: hidden;
        }

        .menu {
            position: relative;
            left: -2px;
            font-size: 40px;
            margin: 30px -20px 62px;
        }

        .menuItem {
            display: inline-block;
            color: var(--text--secondary-darker);
            cursor: pointer;
            transition: all .2s ease-in-out;
            margin: 0 3px;
            opacity: .4;

            @include _on(0, $mobilePortraitMax) {
                margin: 0;
            }

            &:hover {
                opacity: 1;
                transform: scale(1.4);
            }

            &.active {
                opacity: 1;
                color: var(--text--base);
            }
        }

        .timeToShine {
            position: relative;
            overflow: hidden;

            h2 {
                padding: 65px 0 10px;
                @include _on(0, $tabletLandscapeMax) {
                    padding: 32px 0 13px;
                    font-size: 27px;
                }
                @include _on(0, $tabletLandscapeMin) {
                    padding: 32px 0 15px;
                }
            }

            h2.padded {
                @include _on($ipadLandscapeMinWidth, $ipadLandscapeMaxWidth) {
                    padding: 35px 0 13px;
                }
            }

            .button {
                margin: 0 0 30px;
                @include _on($tabletLandscapeMax) {
                    padding: 14px 30px;
                }
                @include _on($ipadLandscapeMinWidth, $ipadLandscapeMaxWidth) {
                    padding: 14px 29px;
                    margin: 0 0 22px;
                }
                @include _on(0, $tabletLandscapeMin) {
                    padding: 14px 28px;
                }
            }

            .text {
                width: 508px;
                margin: auto;
                @include _on(0, $tabletLandscapeMax) {
                    width: 73%;
                    margin: auto;
                }
                @include _on($ipadLandscapeMinWidth, $ipadLandscapeMaxWidth) {
                    width: 53%;
                    font-size: 19px;
                }
                @include _on(0, $tabletLandscapeMin) {
                    width: 80%;
                    font-size: 19px;
                }
                @include _on(0, $mobilePortraitMax) {
                    width: 82%;
                    line-height: 1.2;
                }
            }

            .stepList {
                margin: 29px -50px 45px;
                @include _on(0, $tabletLandscapeMax) {
                    margin: 29px -50px 61px;
                    zoom: .7;
                }
                @include _on($ipadLandscapeMinWidth, $ipadLandscapeMaxWidth) {
                    zoom: 1;
                    margin: 18px -50px 41px;
                }
                @include _on(0, $tabletLandscapeMin) {
                    zoom: 1;
                    margin: 18px -50px 28px;
                    li {
                        margin: 0 -4px;
                    }
                    p {
                        font-size: 14px;
                        @include _on($ipadLandscapeMinWidth, $ipadLandscapeMaxWidth) {
                            font-size: 16px;
                        }
                    }
                }
                @include _on(0, $mobilePortraitMax) {
                    margin: 15px auto 0;
                    li {
                        margin: 0 auto 15px;
                    }
                }
                li {
                    @include _on($ipadLandscapeMinWidth, $ipadLandscapeMaxWidth) {
                        margin: 0 16px;
                        width: 152px;
                    }
                }
            }

            .step {
                @include _on($ipadLandscapeMinWidth, $ipadLandscapeMaxWidth) {
                    width: 98px;
                    height: 144px;
                    background-size: 100%;
                    background-repeat: no-repeat;
                    margin: 0 auto -29px auto;
                }
                @include _on(0, $tabletLandscapeMin) {
                    zoom: .7;
                }
            }

            .wrapper {
                @include _on(0, $tabletLandscapeMax) {
                    min-height: 375px;
                }
                @include _on(0, $mobilePortraitMax) {
                    min-height: 667px;
                }
            }

            button {
                margin: 14px 0;
                padding: 14px 30px;
            }

            .background {
                background-image: url('../images/time-to-shine-bg.jpg');
                position: absolute;
                left: 50%;
                transform: translate(-50%);
                bottom: 0;
                width: 100%;
                height: 1200px;
                background-position: 100% bottom;
                background-size: 100%;
	            @include _on(0, $mobilePortraitMax) {
		            left: 127%;
		            transform: translate(-51%);
		            bottom: 0;
		            width: 1166px;
		            height: 1208px;
		            background-position: 83% bottom;
		            background-size: 184%;
		            background-repeat: no-repeat;
	            }
            }
        }

        .stepList {
            font-size: 16px;
            font-weight: bold;
            margin: 40px -50px;

            li {
                width: 160px;
                display: inline-block;
                margin: 0 40px;

                &.dotted {
                    position: relative;

                    &:before,
                    &:after {
                        position: absolute;
                        color: var(--text--base);
                        content: '. . . . .';
                        font-size: 24px;
                        top: 40%;
                        white-space: nowrap;

                        @include _on(0, $tabletLandscapeMax) {
                            display: none;
                        }
                    }

                    &:before {
                        left: -74px;
                    }

                    &:after {
                        right: -66px;
                    }
                }

                @include _on(0, $mobilePortraitMax) {
                    display: block;
                    margin: 40px 0;
                }
            }

            @include _on(0, $mobilePortraitMax) {
                width: 160px;
                margin: 0 auto;
            }
        }

        .step {
            width: 130px;
            height: 196px;
            margin: 0 auto -40px auto;
        }

        .step-1 {
            background-image: url('../images/step-1.png');
        }

        .step-2 {
            background-image: url('../images/step-2.png');
        }

        .step-3 {
            background-image: url('../images/step-3.png');
        }

        .startOwnBusiness {
            padding-bottom: 75px;

            ph-col[ph-col-30] {
                @include _on($mobileLandscapeMax, $iphoneLandscapeMax) {
                    width: 50%;
                }
            }

            h3 {
                @include _on($mobileLandscapeMax, $iphoneLandscapeMax) {
                 margin-top: 16px;
                }
            }

            @include _on(0, $mobilePortraitMax) {
                padding-bottom: 60px;
            }

            h2 {
                padding: 55px 0 10px;
                @include _on(0, $tabletLandscapeMax) {
                    padding: 35px 0 14px;
                    font-size: 27px;
                }

                @include _on(0, $tabletLandscapeMin) {
                    font-size: 25px;
                }
                @include _on(0, $mobilePortraitMax) {
                    padding: 32px 0 6px;
                }
            }

            .text {
                @include _on($mobileLandscapeMax, $iphoneLandscapeMax) {
                    font-size: 18px;
                }
            }

            .wrapper {
                min-height: 0;
            }

            .box {

                @include _on($tabletLandscapeMax) {
                    margin-top: 22px;
                }

                @include _on(0, $tabletLandscapeMax) {
                    width: 86%;
                    height: 177px;
                    text-align: left;
                    padding-left: 133px;
                    margin: 22px 0 0 69px;
                    display: inline-block;
                    h3 {
                        font-size: 18px;
                        margin-top: 17px;
                    }
                    .description {
                        flex-grow: 0;
                    }
                }

                @include _on($mobileLandscapeMax, $iphoneLandscapeMax) {
                    height: 192px;
                }

                &.single {
                    @include _on($tabletLandscapeMax) {
                        background-image: url('../images/box-single-desktop.jpg');
                    }
                    @include _on($ipadLandscapeMinWidth, $ipadLandscapeMaxWidth) {
                        background-position: 0px -55px;
                        background-size: 34%;
                    }
                }

                @include _on($ipadLandscapeMinWidth, $ipadLandscapeMaxWidth) {
                    width: 87%;
                    height: 177px;
                    padding-left: 149px;
                    margin: 12px 0 0 56px;
                }

                &.studio {
                    @include _on($tabletLandscapeMax) {
                        background-image: url('../images/box-studio-desktop.jpg');
                        background-size: contain;
                    }
                    @include _on(0, $tabletLandscapeMax) {
                        margin: 22px 69px 0 0;
                    }
                    @include _on($ipadLandscapeMinWidth, $ipadLandscapeMaxWidth) {
                        margin: 12px 0 0 -58px;
                        background-position: 0px -11px;
                        background-size: 34%;
                    }
                    @include _on($ipadPortraitMin, $ipadPortraitMax) {
                        background-size: 30%;
                        background-position: 0 0;
                    }
                }

                @include _on(0, $tabletLandscapeMin) {
                    position: relative;
                    padding-left: 102px;
                    margin: 15px 0 0 0;
                    width: 100%;
                }

                @include _on($ipadPortraitMin, $ipadPortraitMax) {
                    padding-left: 104px;
                }

                &.studio {
                    @include _on(0, $tabletLandscapeMin) {
                        margin-top: 15px;
                    }
                }

                h2 {
                    @include _on(0, $tabletLandscapeMin) {
                        margin-top: 21px;
                    }
                }
                .createAccount {
                    @include _on(0, $tabletLandscapeMin) {
                        position: absolute;
                        bottom: 0;
                    }
                }
                a {
                    @include _on(0, $tabletLandscapeMin) {
                        margin-top: 10px;
                    }
                }
                @include _on(0, $mobilePortraitMax) {
                    padding-left: 111px;
                    width: 91%;
                    margin: -7px auto 5px;
                    height: 197px;
                    background-position: 0 bottom !important;
                    background-size: 37% !important;
                    &.studio {
                        margin: 15px auto -17px;
                    }
                }

                &.studio,
                &.single {
                    @include _on($ipadPortraitMin, $ipadPortraitMax) {
                        height: 176px;
                        margin-top: 12px;
                    }
                    @include _on($mobileLandscapeMax, $iphoneLandscapeMax) {
                        padding-left: 111px;
                    }
                }
            }

            .description p {
                @include _on($ipadLandscapeMinWidth, $ipadLandscapeMaxWidth) {
                    font-size: 16px;
                }
            }

            .button {
                @include _on($ipadLandscapeMinWidth, $ipadLandscapeMaxWidth) {
                    bottom: 7px;
                }
                @include _on($mobileLandscapeMax, $iphoneLandscapeMax) {
                    bottom: 6px;
                }
            }

            .createAccount {
                @include _on(0, $tabletLandscapeMax) {
                    margin: 11px 0 !important;
                    a {
                        padding: 14px 29px;
                    }
                }
            }

        }

        .box {
            width: 100%;
            height: 280px;
            text-align: left;
            padding-left: 250px;
            margin: 30px 0;
            border-radius: 2px;
            position: relative;

            @include _on('small', 'down') {
                padding-left: 120px;
                height: 240px;
                margin: 10px 0;
            }

            h3 {
                font-weight: bold;
                font-size: 22px;
                color: var(--primary);
                margin: 20px 0 10px;
            }

            .column {
                border-radius: 2px;
                @include _flexbox();
                @include _flexbox-direction(column);
                background-color: var(--text--base);
                height: 100%;
                padding: 0 30px;

                @include _on('small', 'down') {
                    padding: 0 15px;
                }

                @include _on($ipadPortraitMin, $ipadPortraitMax) {
                    padding: 0 25px;
                }
            }

            .createAccount {
                margin-bottom: 30px;

                .button {
                    text-transform: capitalize;
                }
            }

            .description {
                border-radius: 2px;
                @include _flexbox-grow(1);
                color: var(--box--secondary--text);
                font-size: 16px;
                line-height: 1.2;

                h3 {
                    @include _on($tabletLandscapeMax) {
                        margin-top: 27px;
                    }
                }

                @include _on('small', 'down') {
                    font-size: 14px;
                }
            }

            &.single {
                background: var(--text--base) url('../images/box-single.jpg') left center no-repeat;

                @include _on('small', 'down') {
                    background-position: 0 bottom;
                    background-size: contain;
                }

                @include _on($ipadPortraitMin, $ipadPortraitMax) {
                    background-position: 0 0;
                    background-size: 30%;
                }
            }

            &.studio {
                background: var(--text--base) url('../images/box-studio.jpg') left center no-repeat;

                @include _on('small', 'down') {
                    background-position: 0 bottom;
                    background-size: contain;
                }
            }

            .button {
                @include _on($ipadPortraitMin, $ipadPortraitMax) {
                    bottom: 8px;
                }
            }
        }

        .backToTop {
            transition: right, .2s;

            display: block;
            position: absolute;
            bottom: 165px;
            right: 0;
            width: auto;
            font-size: 0;
            background-color: var(--content-header-border);
            color: var(--text--base);
            border-radius: 3px;
            z-index: 3;

            @include _on('small', 'down') {
                visibility: hidden;
            }

            i {
                margin: 0 0 0 15px;
                font-size: 16px;
            }

            span {
                font-size: 16px;
                line-height: 40px;
                padding: 0 15px 0 0;
                margin: 0 0 0 15px;
            }

            &:hover {
                right: 0 !important;
            }
        }

        .supportChat {
            position: fixed;
            top: 100px;
            right: 0;
            width: 40px;
            height: 40px;
            padding: 8px;
            z-index: 1;
            font-size: 24px;
            border-radius: 6px 0 0 6px;
            background-color: var(--content-header-border);
            color: var(--text--base);

            @include _on('small', 'down') {
                display: none;
            }
        }

        // svg icons
        figure {
            position: relative;
            height: 100%;
            text-align: center;

            svg {
                pointer-events: none;
                height: 80%;
                max-width: 100%;
                overflow: visible;
                margin-bottom: 10px;

                @include _on('small') {
                    height: auto;
                }
            }

            figcaption {
                width: 100%;
                height: 20%;
                font-size: 16px;
                color: var(--text--secondary-darker);
                text-align: center;
                position: relative;
                line-height: 26px;

                @include _on('small', 'down') {
                    font-size: 14px;
                }

                span {
                    transition: transform 0.3s ease-out;
                }

                span:last-child {
                    transform: scale(0) skew(-78deg, -9deg);
                    visibility: hidden;
                    position: absolute;
                    width: 100%;
                    left: 0;
                    top: 0;
                    text-align: center;
                }
            }

            a {
                display: inline-block;
                height: 100%;
                width: 100%;

                svg {
                    max-width: 140px;
                }

                &.largeIcon svg {
                    max-width: 120px;
                }

                &:hover {
                    figcaption {
                        color: var(--text--link-hover);

                        span:last-child {
                            transform: scale(1) skew(0deg, 0deg);
                            visibility: visible;
                        }

                        span:first-child {
                            transform: scale(0) skew(-78deg, -9deg);
                            visibility: hidden;
                        }
                    }
                }
            }
        }
    }
    .createAccount {
        position: absolute;
        bottom: 0;
    }
}

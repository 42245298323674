@use 'global/icons';
@use 'global/typography';
@use 'global/vars';
@import '../../../scss/shell';

:global {

	/**
    * Dashboard Widgets
    */
	.dashboardRatingReasonTooltip {
		.tooltipTitle {
			display: block;
			margin: 12px 0 10px;
			color: var(--text--base);
			font-weight: bold;

			&:first-child {
				margin: 0 0 10px;
			}
		}

		.list {
			margin: 0 0 10px;
			display: block;

			&:last-child {
				margin: 0;
			}
			span {
				color: var(--text--link);
				margin: 0 10px 0 0;
			}
		}
	}

	.widget {
		// Main widgets, left side
		&-main {
			&-container {
				float: left;
				width: 580px;
				min-height: inherit;
				padding: 20px;
			}

			&-item {
				float: left;
				width: 100%;
				border-radius: 4px;
				margin-bottom: 20px;
				padding: 20px;
				background: var(--widget--main-bg);

				&:last-of-type {
					margin-bottom: 0;
				}

				&.feature-promo-video-widget {
					position: relative;
					min-height: 224px;
					padding: 0;
				}

				&.minimal {
					padding: 0;
					background: transparent;
					border-radius: 0;
					margin-bottom: 0;
				}
			}
		}

		// Secondary widgets, right side
		&-secondary {

			&-container {
				float: right;
				width: 370px;
				min-height: inherit;
				background: var(--widget--secondary-bg);
			}

			&-item {
				width: 100%;
				padding: 25px 30px;
				border-bottom: 1px solid var(--widget--secondary-bg-darken-3);

				&:last-of-type {
					border-bottom: 0;
				}
			}
		}

		&__title {
			@include typography.body2Bold;
			text-transform: uppercase;
			margin: 0 0 vars.spacer(6);
			color: var(--widget--title);

			.icon-info-circle {
				display: inline-block;
				margin: 0 0 0 10px;
				color: var(--text--secondary);
				font-size: 16px;
			}
		}

		&-avrating {
			padding: 0 20px;

			&-line {
				@include clear;

				padding: 10px 20px;
				position: relative;
				margin: 0 -20px;
				color: var(--text--secondary);

				&:first-child {
					border-bottom: 1px solid var(--primary);
				}

				&-left {
					float: left;
					font-weight: bold;
					font-size: 14px;
					line-height: 30px;
				}

				&-right {
					float: right;
					line-height: 30px;
				}

				&-value {
					text-transform: uppercase;
				}

				&-good {
					.widget-avrating-line-value {
						color: var(--common--success);
					}
					.widget-avrating-line-deduction {
						padding: 0 15px;
					}
				}

				&-bad {
					.widget-avrating-line-value {
						color: var(--common--error);
					}
					.widget-avrating-line-deduction {
						background: var(--common--error);
						color: var(--text--base);
					}
				}

				&-name {
					display: inline-block;
					width: 50px;
				}

				.icon-info-circle {
					display: inline-block;
					margin: 0 0 0 10px;
					color: var(--common--alert);
					font-size: 16px;
				}

				&-deduction {
					display: inline-block;
					font-weight: bold;
					font-size: 12px;
					line-height: 30px;
					padding: 0 10px;
					background: var(--widget--deduction-bg);
					border-radius: 3px;
					margin: 0 0 0 10px;
				}
			}
		}

		&-promotion {

			&-content {
				overflow: hidden; // :P
			}

			&-clock {
				float: left;
				width: 50%;
				text-align: center;
				font-size: 0;

				h5 {
					color: var(--text--secondary);
					margin: 10px 0 0 0;
					font-size: 14px;
					position: relative;
					z-index: 2;

					span {
						position: absolute;
						font-size: 12px;
						font-weight: normal;
						display: block;
						z-index: 1;
						width: 100%;
						left: 50%;
						top: 0;
						text-align: center;
					}
				}

				&-block {
					display: inline-block;
					width: 45px;
					height: 60px;
					background: var(--widget--promotion-block-bg);
					color: var(--text--secondary);
					overflow: hidden;

					&:first-of-type {
						border-radius: 4px 0 0 4px;
						margin: 0 1px 0 0;
					}

					&:last-of-type {
						border-radius: 0 4px 4px 0;
						margin: 0 0 0 1px;
					}

					&-num {
						font-family: "Courier New", "Courier", "monospace", serif;
						font-size: 22px;
						margin: 18px 0 6px;
						display: block;
					}

					&-title {
						font-size: 10px;
						text-transform: uppercase;
					}
				}
			}
		}

		&-goonline .widget__title {
			position: relative;
			top: 1.1rem;
		}

		&-goonline-subtitle {
			position: relative;
			top: 3px;
		}

		&-jasmincam {
			padding: 0;
		}

		&-promote-mobile-app {
			padding: 0;
		}

		&-statistics {
			padding: 0;

			.react-root {
				padding: 25px 30px;

				&:empty {
					display: none;
				}
			}
		}

		&-news {
			position: relative;
			overflow-wrap: break-word;

			h4 {
				color: var(--widget--news-header);
				font-weight: bold;
				font-size: 14px;
				line-height: 18px;
				margin: 0 0 2px;
			}

			p {
				font-size: 14px;
				line-height: 18px;
				color: var(--widget--news-text);
				margin: 0 0 21px;
			}

			&-link {
				position: relative;
				font-weight: bold;
				z-index: 3;
				font-size: 12px;
			}

			&-overlink {
				position: absolute;
				top: 0;
				bottom: 0;
				left: 0;
				right: 0;
				z-index: 2;
				background: url(data:image/gif;base64,R0lGODlhAQABAAAAACw=);
			}
		}

		&-promo-video {
			padding: 0;
		}

		&-jasmin-selection-tasklist-new {
			padding: 0;
		}

		&-jasmin-selection-tasklist {

			h2 {
				font-size: 14px;
				line-height: 14px;
				font-weight: bold;
				text-transform: uppercase;
				margin-bottom: 20px;
			}

			ul {
				width: 100%;
			}

			li {
				width: 100%;
				height: 50px;
				margin-bottom: 2px;
				cursor: pointer;
				border-radius: 4px;

				@include _on('small', 'down') {
					padding: 10px;

					> * {
						display: block;
						width: 100% !important;
						text-align: center !important;
						margin: 10px 0 0 0 !important;
						padding: 0 !important;

						&:first-child {
							margin: 0 !important;
						}
					}

					a {
						position: absolute !important;
						left: 0;
						top: 0;
						right: auto !important;
						width: 100%;
						text-align: center;
						display: block;
					}

					span {
						margin-left: 0 !important;
					}
				}
			}

			.min {
				width: 1px;
				white-space: nowrap;
			}

			.time {
				text-align: right;
				padding-right: 16px;
				position: relative;

				a {
					opacity: 1;
					position: relative;
					font-weight: bold;

					em {
						position: relative;
						top: -1px;
						font-size: 14px;
					}
				}

				.countdown {
					color: var(--text--base);
					display: inline-block;
					font-weight: bold;
				}

				.deadline {
					color: var(--text--secondary);
					font-weight: bold;
					display: none;

					&.show {
						display: inline-block;
					}
				}
			}

			&.dashboard {
				padding: 0;
				background-image: url('./livejasminselection_tasklist.png');
				background-repeat: no-repeat;
				background-position: 101% -60px;

				li {
					background-color: RGBA(var(--widget--selection-item-bg-rgb), 0.8);
					transition: background-color 0.2s;

					@include _on('small', 'down') {
						pointer-events: none;
					}

					@include _on('medium', 'up') {
						&:hover {
							background-color: var(--widget--selection-item-bg-hover);

							.time {
								a {
									opacity: 1;
									transform: translateY(0);
								}

								span {
									opacity: 0;
									transform: translateY(-20px);
								}
							}
						}
					}
				}

				.time {
					font-size: 12px;

					a {
						transition: all 0.2s;
						opacity: 0;
						transform: translateY(20px);
						position: absolute;
						right: 16px;
					}

					.countdown {
						transition: all 0.2s;
					}

					.deadline {
						transition: all 0.2s;
					}
				}
			}

			.item-icon {
				font-size: 16px;
				padding: 0 16px;
			}

			.title {
				color: var(--text--base);
				font-weight: bold;
				font-size: 14px;
			}

			.deduction span {
				font-size: 10px;
				font-weight: bold;
				color: var(--widget--selection-deduction-text);
				display: block;
				padding: 6px 10px;
				background: var(--widget--selection-deduction-bg);
				border-radius: 4px;
				margin-left: 10px;
			}

			input:checked + ul li {
				display: table !important;
			}

			input + ul li:nth-child(5) ~ li {
				display: none;
			}

			.show-more {
				display: none;
				padding-top: 20px;

				label {
                    display: flex;
                    align-items: center;
                    justify-content: center;
					margin-top: 20px;
					color: var(--text--link);
					font-weight: bold;
					cursor: pointer;

                    span {
                        height: 16px;
                    }

                    i {
                        font-size: 16px;
                        width: 16px;
                        height: 16px;
                    }

					&:hover {
						color: var(--text--base);

                        i::before {
                            content: icons.get('arrow-right-solid');
                        }
					}
				}
			}

			input:checked + ul .show-more {
				display: none !important;
			}

			input + ul li:nth-child(6) ~ .show-more {
				display: block;
			}

		}

		&-jasmin-selection {

			&-status {
				padding: 0;
			}

			&-container {
				overflow: hidden;
				position: relative;
				min-height: 156px;
				padding-left: 160px;

				&--inactive .active,
				&--active .inactive {
					display: none;
				}
			}

			&-badge {
				position: absolute;
				left: 0;
				top: 0;
				width: 165px;
				overflow: hidden;
				z-index: 1;

				img {
					max-width: 1000px;
				}
			}

			&-title {
				margin: 30px 20px 0 0;
				position: relative;

				i {
					font-size: 16px;
					position: absolute;
					left: 0;
					top: -2px;
				}
			}

			&-slideshow {
				overflow: hidden;
				position: relative;
				margin-left: -30px;
				box-sizing: content-box;

				ul {
					// Adjust seconds according to the total count of the items
					@include animation(20s selected-slide-animation linear infinite);
					transform: translate3d(0, 0, 0);
					backface-visibility: hidden;

					display: inline-block;
					white-space: nowrap;

					> div {
						display: inline-block;
						position: absolute;
					}

					&:hover {
						-webkit-animation-play-state: paused;
						animation-play-state: paused;
					}
				}
			}

			&-slide {
				display: inline-block;
				position: relative;
				padding-left: 40px;
				height: 48px;
				margin: 15px 0;

				&__badge {
					position: absolute;
					left: 0;
					top: 0;
					display: block;
					height: 40px;
					width: 40px;
					background: var(--widget--selection-badge-bg);
					text-align: center;
					line-height: 40px;
					font-size: 20px;
					border-radius: 50%;
					color: var(--widget--selection-badge-text);

					i {
						position: relative;
						font-size: 3.5rem;

						&.icon-promotion {
							font-size: 2.5rem;
						}

						&.icon-awards {
							font-size: 2.3rem;
						}
					}
				}

				&__title,
				&__desc {
					display: block;
					padding: 0 10px;
				}

				&__title {
					@include typography.captionBold;
					color: var(--text--base);
				}

				&__desc {
					@include typography.captionRegular;
					color: var(--text--paragraph);
				}

				&--inactive &__title,
				&--inactive &__desc {
					opacity: 0.5;
				}

				&--inactive &__badge {
					background: var(--widget--selection-badge-inactive-bg);
					border-color: var(--widget--selection-badge-inactive-text);
					color: var(--widget--selection-badge-inactive-text);

					&:before {
						transform: rotate(-45deg);
						@include _vertical-gradient(
								var(--widget--selection-badge-before-gr-from),
								var(--widget--selection-badge-before-gr-to)
						);

						content: '';
						position: absolute;
						top: 50%;
						left: 0;
						width: 140%;
						height: 3px;
						border: 2px solid var(--widget--selection-badge-before-border);
						z-index: 1;
						margin: -4px 0 0 -23%;
					}
				}
			}

			&-bottom {
				color: var(--text--secondary);
				padding-right: 20px;
				line-height: 15px;
				font-size: 12px;

				a {
					font-weight: bold;
				}
			}
		}

		&-exclusive-model {
			.widget__title {
				font-weight: 200;
			}

			p {
				color: var(--text--secondary);
				font-size: 12px;
			}

			ul {
				margin: 13px 0 18px;

				li {
					display: flex;
					align-items: center;
					justify-content: space-between;
					margin: 0 0 2px;
					padding: 13px 15px;
					font-size: 14px;
					line-height: 1.3;
					border-radius: 3px;
					color: var(--text--base);
					background: RGBA(var(--widget--exclusive-model--conditions-bg-rgb), .8);

					&.passed {
						color: var(--text--secondary);
					}

					i {
						font-size: 18px;
						height: 24px;
						margin-left: 5px;
					}

					.icon-ok {
						color: var(--icon--ok--green);
					}

					.icon-close-filled {
						color: var(--widget--exclusive-model--rejected);
					}
				}
			}
		}
	}
}

@keyframes :global(selected-slide-animation) {
	from {
		transform: translateX(0);
	}
	to {
		transform: translateX(-100%);
	}
}

@use 'global/icons';
@use 'global/vars';
@import '../../../../scss/shell';

:global {
	#react_overlay {
		display: none;

		&:not(:empty) {
			display: block;
		}
	}

	.closeButton {
		z-index: 1;

		i {
			color: var(--overlay--media-close-button);
            font-size: 2.4rem;
            margin: vars.spacer(3);
		}

		&:hover i {
			color: var(--overlay--media-close-button);

            &::before {
                content: icons.get('close-solid');
            }
		}
	}
}

.container {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 51;
	background: RGBA(var(--overlay-container-background-rgb), 0.8);

	svg {
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
	}

	.block video {
		max-height: 90vh;
		display: block;

		@include _on('mobile') {
			max-height: 100%;
		}
	}
}

.block {
	max-width: 90%;
	max-height: 90%;
	min-height: 10rem;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	background: var(--overlay-background);

	@include _on('mobile') {
		width: 100%;
		height: 100%;
		max-width: 100%;
		max-height: 100%;
	}

	&:hover {
		:global .videoPager {
			opacity: 1;
		}
	}

	:global .videoPager {
		opacity: 0;
	}
}

.header {
	height: 4.8rem;
	padding: 0 2rem;
	text-align: left;
	font-weight: bold;
	font-size: 1.6rem;
	line-height: 4.8rem;
	color: var(--box--header-text);
	background-color: var(--box--header-background);
	border-bottom: 0.1rem solid var(--box--header-border);
}

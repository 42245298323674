@use 'global/colors';

@mixin list() {
	li {
		color: var(--promo-tools--info-list-text);
		font-weight: 200;
		margin: 0 0 1.2rem 1.4rem;
		position: relative;
		line-height: 1.25;

		span,
		strong {
			color: var(--text--base);
			font-weight: bold;
		}

		&:last-child {
			margin-bottom: 0;
		}

		&:after {
			position: absolute;
			content: '\2022';
			color: var(--text--link);
			font-size: 2rem;
			left: -1.2rem;
			font-weight: bold;
			top: -0.4rem;
		}
	}
}

@mixin videoBox() {
	.box {
		border: 0.2rem solid var(--promo-tools--box-border);
		position: relative;
		border-radius: 0.4rem;
		padding: 0.6rem;

		@include _on('mobile') {
			height: 100%;
		}

		&:hover {
			.play {
				opacity: 1;
			}
		}

		.inner {
			position: relative;
			background-color: var(--promo-tools--box-bg);
			background-size: contain;
			background-repeat: no-repeat;
			background-position: center;
			height: 20.3rem;
			border-radius: 0.4rem;

			@include _on('mobile') {
				height: 100%;
			}
		}
	}
}

@mixin infoVideoPreview {
	.preview {
		position: relative;
		width: 100%;

		// Play icon
		i {
			font-size: 4.8rem;
			color: RGBA(var(--contest--icon-play-rgb), 0.7);
			box-shadow: 0 0 1.5rem RGBA(var(--contest--shadow-rgb), 0.5);
			cursor: pointer;
			transition: color 0.3s;
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
			background: #{colors.get(neutral, 900, 0.4)};
			border-radius: 50%;

			&:hover {
				color: RGBA(var(--contest--icon-play-rgb), 1);
			}
		}
	}
}

@mixin videoBox-oranum() {
	@include videoBox;
	.box {
		border: 0.2rem solid #{colors.get(purple, 100)};

		.inner {
			background-color: #{colors.get(purple, 100)};
		}
	}
}
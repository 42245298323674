@use 'components/Button/button';
@use 'sass:map';
@use 'global/vars';
@use 'global/icons';
@use 'global/colors';
@use 'global/typography';
@import '../../../scss/shell';

/*
* Income statistics aloldal ---------------------------------------------------------------------------------------------------------------------------------------------------------------
*/
:global {
	#incomestatistics_index {
		//Datumvaloszto fejlec
		.selectPeriodBox {
			@include style('box', 'common', 'skip');
			@include style('text', 'regularColor', 'hugeSize');

			padding: 3rem;
			line-height: 1.6rem !important;
			background: var(--payout-income--period-bg);
		}

		.choosePeriodContainer {
			margin: 1.8rem 0 0 0;
			line-height: 3rem;

			.inputs {
				float: none;
				display: inline-block;
				height: 3.4rem;
				margin: 0 0.1rem 0 0;
			}

			.icon-calendar {
				position: absolute;
				top: 0.1rem;
				left: 1rem;
				font-size: 1.6rem;
				color: var(--old-box--calendar--icon);
			}

			.button {
				position: relative;
				top: 0.1rem;
				vertical-align: middle;
			}

			em {
				display: inline-block;
				margin: 0 0.7rem;
				line-height: 3.2rem;
				vertical-align: top;
			}

			input {
				float: none;
				vertical-align: top;
				background-image: none;
			}

			.lowField {
				#statusFilter-component-container,
				#categoryFilter-component-container,
				#period-component-container {
					height: 3.4rem;

					input {
						height: 3.2rem;
						line-height: 3.2rem;
					}
				}
			}

			.selectArrow {
				top: 0.3rem;
				right: 0.3rem;
			}

			.scrollContainer {
				top: 3.3rem;
			}
		}

		.statInfoText {
			line-height: 2.2rem;
		}

		#salesDataTableWrapper {
			@include _flexbox;
			margin-right: 2.8rem;
		}

		#salesStatsTable {
			@include commonTable;

			margin: 2.8rem 0 0 2.8rem;
			padding: 0;
			width: 42.4rem;
			display: inline-block;

			.pretenceName {
				text-align: left;
			}
		}

		.tableSummarizeContentCell {
			color: var(--text--link);
		}

		.hideDetails {
			display: none;
		}

		.tableRow {

			.salesHeader,
			.tableRegularCell,
			.tableHighlightedCell,
			.incomeAmount {
				width: 10rem;
				text-align: center;
			}

			.modelHeader,
			.tableSummarizeContentCell {
				width: 16rem;
			}

			.incomeCategory {
				text-align: left;
			}

			.reArrangeDownArrow {
				margin: 0 0 0.2rem 0.4rem;
			}

			.tableSummarizeContentCell {
				display: inline-flex;
				min-width: 100%;
				align-items: center;
				cursor: pointer;
				text-align: left;

				&:hover {
					color: var(--text--base);
				}

				span {
					line-height: 1.2;
					text-overflow: ellipsis;
					overflow: hidden;
					white-space: nowrap;
				}
			}

			&.detailsRow {
				text-align: center;
			}

			.mainSum {
				@include style('table', 'highliteBg');

				font-weight: bold;
				font-size: 1.2rem;
				line-height: 4rem;
			}
		}
	}

	//Jobb tablazatresz
	.salesDataTable {
		@include commonTable;

		display: inline-block;
		text-align: center;
		margin: 2.6rem 0 0 -0.2rem;
		padding: 0;
		position: relative;
		flex: 1;
		-webkit-flex: 1;
		overflow: hidden;
	}

	#salesDataScrollContainer {
		width: 100%;
		overflow: hidden;

		&.scrollable--horizontal {
			overflow: visible;
		}
	}

	#salesDataContainer {
		margin: 0 0 1.5rem;

		.hideDetails {
			display: none;
		}

		.tableRow {

			.tableHeaderCell,
			.tableRegularCell,
			.tableHighlightedCell,
			.tableLighterRegularCell,
			.tableContentDetailsCell {
				padding: 1rem 3.1rem;
			}

			.tableLighterRegularCell {
				font-weight: bold;
			}
		}
	}

	//Lablec szoveg
	.statInfoText {
		@include style('text', 'smallSize', 'passiveColor');

		line-height: 2.5rem;
		text-align: center;
		margin: 4rem 0;
		padding: 0 3rem;

		a {
			@include button.linkPrimary();
		}
	}

	/*
	* Payment Overview aloldal ---------------------------------------------------------------------------------------------------------------------------------------------------------------
	*/
	#payoutoverview_index {
		.switchTogglesContainer {
			display: grid;
			grid-template-columns: 1fr 1fr;
			grid-column-gap: 30px;
			margin: 32px 30px 62px;

			@include _on('small', 'down') {
				display: block;

				& > :first-child {
					margin-bottom: 20px;
				}
			}
		}

		.statusHeader {
			padding: 16px;
			font-size: 14px;
			line-height: 16px;
			font-weight: bold;
			background: var(--box--header-background);

			.statusHeaderTitle {
				margin-bottom: 24px;
				text-transform: uppercase;
			}

			.statusHeaderBody {
				position: relative;
				display: grid;
				grid-template-columns: 1fr 60px;
				align-items: center;

				.icon-info-circle--common {
					right: auto;
					margin-left: 9px;
				}

				.toggleContainer {
					width: 60px;
					padding-top: 3px;
				}
			}
		}

		.switchPayoutOverview {
			position: absolute;
			top: calc(50% - 1.2rem);
			right: 4rem;

			.icon-info-circle {
				font-size: 1.8rem;
				top: -1.7rem;
				right: 3rem;
			}
		}

		.payout-period-info {
			position: relative;
			font-size: 1.2rem;
			line-height: 1.4;
			color: var(--text--base);
			padding: 0 3.5rem 3rem 7rem;

			.icon-info-circle {
				width: 1.8rem;
				top: 1.2rem;
				left: 3.4rem;
				cursor: default;
			}
		}
		.progressBar {
			width: 12rem !important;
		}
		.progressContainer {
			padding: 0.1rem 0;
		}
		.paymentsOverviewHeader {
			margin: 3rem 3rem 0 3rem;
			text-align: center;
			position: relative;
			min-height: 3.5rem;

			h4 {
				margin: 0;
				line-height: 3.2rem;
				font-size: 2rem;
			}

			i {
				line-height: 3.2rem;
			}
		}

		.prevYear,
		.nextYear {
			@include extendPageSwitchBox;

			&:hover {
				p,
				i {
					color: var(--payout-overview--link-hover);
				}
			}

			p {
				font-weight: bold;
				font-size: 1.4rem;
				line-height: 3.2rem;
				white-space: nowrap;
				position: absolute;
				top: 0;
				color: var(--payout-overview--link);
				padding: 0 1rem;
			}

			i {
				color: var(--payout-overview--link);
			}
		}

		.prevYear {
			position: absolute;
			left: 0;
			top: 0;

			.linkArrowLeft {
				margin: 1.2rem 1.4rem;
			}

			p {
				left: map.get(vars.$spacers, 7);
			}

			&:hover i::before {
				content: icons.get('arrow-left-solid')
			}			
		}

		.nextYear {
			position: absolute;
			right: -0.8rem;
			top: 0;

			.linkArrowRight {
				margin: 1.2rem 1.4rem;
			}

			p {
				right: map.get(vars.$spacers, 7);
			}


			&:hover i::before {
				content: icons.get('arrow-right-solid')
			}		
		}

		.paymentsOverviewTable {
			@include commonTable;

			width: calc(100% - 6rem);
			margin: 2rem 3rem;
			padding: 0;
			overflow-x: auto;

			.statusHeader {
				background: var(--table--header-bg);
				padding: 1rem;
			}
		}

		.tableRow {
			text-align: center;

			.dateHeader {
				width: 10%;
			}

			.tableLighterRegularCell {
				white-space: nowrap;
			}

			.tableLighterRegularCell,
			.tableLinkContentCell {
				font-weight: bold;
			}

			&.notAccepted {
				.tableLighterRegularCell,
				.tableHighlightedCell,
				.methodContent,
				.noteContent,
				.paymentTypeContent,
				.tableLinkContentCell,
				.statusContent {
					@include style('table', 'highliteBg');
				}

				i.icon-info-circle {
					position: relative;
					margin: 0 0 0 0.5rem;
					right: 0;
				}
			}

			.noteContent,
			.tableLinkContentCell,
			.statusContent {
				text-transform: uppercase;
			}

			.tableLinkContentCell,
			.statusContent {
				color: var(--text--secondary) !important;
			}

			.noteContent a,
			.tableLinkContentCell a,
			.statusContent a {
				text-transform: none;
			}

			.company_rejectedStatus {
				color: var(--common--error) !important;

				i.icon-alert {
					display: inline-block;
					position: relative;
					top: 0.4rem;
					left: 0.6rem;
					right: auto;
					float: none;
				}
			}

			.company_approvedStatus,
			.individual_acceptedStatus {
				color: var(--common--success) !important;
			}

			.reArrangeDownArrow {
				margin: 0 0 0.2rem 0.4rem;
			}

			.uploadBlock {
				overflow: hidden;
			}

			.uploader5 {
				&, * {
					margin: 0;
				}

				&__button {
					i + span {
						margin-left: 1.8rem;
					}
				}

				&__progress-bar {
					width: 6rem;

					span {
						width: 6rem;
						text-align: center;
						left: 0;
					}
				}
			}
		}

		.showMoreContainer {
			text-align: center;
			margin: 0 0 3rem 0;
		}

		/**
		Feature flag "enablePayoutRequests"
		 */
		.featureBlocksContainer {
			display: grid;
			grid-template-columns: 1fr 1fr;
			grid-column-gap: 8px;
			margin: 32px 30px;

			.subBlock {
				display: grid;
				grid-template-rows: 1fr 1fr;
			}

			@include _on('small', 'down') {
				display: block;

				& > :first-child {
					margin-bottom: 20px;
				}
			}

			.featureBlock {
				display: flex;
				flex-direction: column;
				padding: 16px;
				font-size: 14px;
				line-height: 16px;
				font-weight: bold;
				background: var(--box--header-background);
				border-radius: 4px;

				&.autoPayout,
				&.autoInvoice {
					margin-bottom: 8px;

					&:last-child {
						margin-bottom: 0;
					}
				}

				.icon-check {
					font-size: 2rem;
					color: var(--payout-icon-check);
				}

				.header {
					display: flex;
					margin-bottom: map.get(vars.$spacers, 3);

					.text {
						font-size: 14px;
						line-height: 14px;
						font-weight: bold;
						text-transform: uppercase;
						display: flex;
						align-items: center;

						.icon-warning {
							display: inline-block;
							font-size: 2rem;
							margin-left: map.get(vars.$spacers, 1);
							transform: translateY(-1px);
							color: var(--icon--alert--yellow);
						}
					}

					.tooltipContainer {
						margin-left: auto;

						.protip {
							font-size: 2.4rem;
							color: var(--text--secondary-darker);
							cursor: pointer;

							&:hover::before{
								content: icons.get('info-circular-solid');
								color: var(--payout-icon-info-hover)
							}
						}
					}
				}

				.body {
					display: flex;

					p {
						margin-bottom: 18px;
						font-size: 14px;
						line-height: 18px;
						font-weight: 400;
						color: var(--payout-overview-description);

						&:last-child {
							margin-bottom: 0;
						}

						.optionsLink {
							font-weight: bold;
						}
					}

					.toggleContainer {
						margin-left: 56px;
					}

					.autoInstantPayoutEnabledText {
						display: flex;

						.iconContainer {
							margin-right: map.get(vars.$spacers, 2);
						}

						strong {
							color: var(--payout-overview--strong);
						}
					}

					.instantPayoutConditionsText {
						p {
							margin-bottom: map.get(vars.$spacers, 1);
						}

						.subHeader {
							margin-bottom: map.get(vars.$spacers, 2);
							color: var(--payout-overview--strong);
						}
					}

					.enablePayoutText {
						display: flex;
						margin-top: map.get(vars.$spacers, 9);

						.icon-alert {
							margin-right: map.get(vars.$spacers, 1);
							transform: translateY(1px);
							font-size: 2rem;
							color: var(--icon--alert--yellow);
						}

						p {
							flex-grow: 1;
						}

						a {
							@include button.linkPrimary();
						}
					}
				}

				.footer {
					display: flex;
					margin-top: auto;

					.amountContainer {
						.text {
							margin-bottom: 7px;
							font-size: 12px;
							line-height: 12px;
							color: rgb(var(--color-primary-14));
							text-transform: uppercase;
						}

						.amount {
							font-size: 24px;
							line-height: 24px;
							font-weight: bold;
						}
					}

					.buttonContainer {
						align-self: end;
						margin-left: auto;
					}

					.processingTitle {
						@include typography.body2Bold;
						display: flex;
						margin-bottom: map.get(vars.$spacers, 1);

						.icon-check {
							margin-right: map.get(vars.$spacers, 1);
						}
					}

					.processingText {
						padding-left: map.get(vars.$spacers, 6);
						@include typography.captionRegular;
						color: rgb(var(--color-primary-14));
					}
				}
			}
		}

		/**
		Feature flag "enablePayoutRequests"
		 */
		.periodInfo {
			margin: 30px;

			p {
				margin-bottom: 16px;
				font-size: 12px;
				line-height: 16px;
				font-weight: 400;
				color: rgb(var(--color-primary-14));

				strong {
					color: #{colors.get(neutral, 100)};
				}
			}
		}
	}

	.newSharesContent {
		border-radius: 0 0 0.4rem 0;
		background-color: var(--box--info-bg);
		min-height: 90rem;

		.shareContent {
			background-color: var(--box--main-bg);
			padding: 4rem;

			> h2 {
				margin-bottom: 3.5rem;
			}
		}

		h4 {
			margin: 0 0 1.5rem 0;
			float: left;
			text-transform: uppercase;
		}

		h5 {
			color: var(--text--base);
			margin: 2.5rem 0 1.5rem;
			float: left;
		}

		ul {
			@include style('text', 'normalSize', 'regularColor');

			float: left;
			width: 100%;
			line-height: 20px;

			&.tableHelp {
				@include _on('mobile') {
					margin-bottom: 4rem;
				}
			}
		}

		.shareLeftContent {
			padding-right: 2.75rem;

			h6 {
				float: left;
				font-size: 1.2rem;
				font-weight: bold;
				margin: 0 0 1.5rem 0;
			}

			p {
				@include style('text', 'normalSize', 'regularColor');

				float: left;
				margin: 0 0 1.5rem 0;
			}

			hr {
				margin: 4rem 0;
			}
		}

		.shareRightContent {
			padding-left: 1.6rem;

			.compareTable {
				display: block;
				text-align: center;
				float: left;
				width: 100%;

				@include _on('mobile') {
					margin-top: 4rem;
				}

				.tableHeaderDesc {
					font-weight: normal;
					font-size: 1rem;
				}

				.tableHeaderCell {
					white-space: normal;
					line-height: 1.1 !important;
				}
			}

			.shareTable {
				@include commonTable;

				padding: 0;
				margin: 0 0 2rem 0;
				width: 100%;

				@include _on('mobile') {
					margin-top: 4rem;
				}

				.tableRow {

					.numberHeader,
					.tableRowNumberCell {
						min-width: 4rem;
						padding: 0;
					}

					.levelHeader,
					.tableHighlightedCell,
					.earningsHeader,
					.tableRegularCell {
						width: 12rem;
					}

					.percentageHeader,
					.tableSummarizeContentCell {
						width: 10rem;
					}

					.tableRowNumberCell,
					.tableRegularCell,
					.tableSummarizeContentCell {
						text-align: center;
					}
				}
			}
		}

		.faqContent {
			background-color: var(--box--bottom-bg);
			padding: 4rem;

			h4, h5 {
				float: none;
			}
		}

		.faqContentLeft,
		.faqContentRight {
			padding-right: 2.75rem;

			h4 {
				margin: 1.5rem 0;
			}

			p {
				@include style('text', 'normalSize', 'regularColor');

				margin: 0 0 2rem 0;
			}
		}

		.faqContentRight {
			padding-right: 0;
			padding-left: 2.75rem;
		}
	}

	/*
	* Payinfo aloldal ---------------------------------------------------------------------------------------------------------------------------------------------------------------
	*/
	.payInfoContent {
		text-align: center;
		margin: 1.5rem 0 4rem 0;
	}

	.payInfoLeftContent,
	.payInfoRightContent {
		float: left;
		text-align: left;

		p {
			@include style('text', 'normalSize', 'regularColor');

			margin: 0 0 1.5rem 0;
			float: left;
		}

		h4 {
			margin: 1.5rem 0;
			float: left;
			width: 100%;
		}

		h6 {
			position: relative;
			font-weight: bold;
			font-size: 1.4rem;
			line-height: 1.4rem;
			color: var(--text--base);
			margin: 1.2rem 0;
			float: left;

			&.new::after {
				display: block;
				content: attr(data-badge);
				position: absolute;
				left: calc(100% + 1rem);
				top: 0;
				text-transform: uppercase;
				font-size: 1rem;
				background-color: var(--button--primary--shadow);
				color: var(--button--primary--text);
				border-radius: 0.2rem;
				padding: 0.2rem 0.3rem 0.1rem;
				line-height: 1;
			}
		}
	}

	.payInfoLeftContent {
		margin: 0 0 0 3rem;
		width: 48rem;
	}

	.payInfoRightContent {
		margin: 0 0 0 4rem;
		width: 37rem;
	}

	.payInfoBottomText {
		@include style('text', 'regularColor', 'hugeSize');

		padding: 1rem 0 0 0;
	}

	/*
	* Payout Options aloldal ---------------------------------------------------------------------------------------------------------------------------------------------------------------
	*/
	.payoutTypesContainer {
		@include style('box', 'common');
		text-align: center;
		background: transparent;
		padding: vars.spacer(8) vars.spacer(12);
		display: flex;

		.payoutTypesInner {
			margin: auto;
			display: inline-block;
		}

		.infoBox {
			display: inline-block;
			margin: 0 1rem 0 0;
			width: 28.2rem;
			height: 32rem;
			text-align: center;
			padding: 0;
			background: transparent;
			border: 0.4rem solid var(--box--info-bg);
			border-radius: 0.6rem;

			> ul {
				width: 100%;
				list-style-type: none;
				margin: 0 0 vars.spacer(20);

				> li {
					@include typography.body1Bold;
					color: var(--text--base);
					text-align: left;
					width: 100%;
					padding: 0 vars.spacer(4) 0 vars.spacer(9);
					margin-bottom: vars.spacer(4);

					.icon {
						top: 0.7rem;
						left: -0.5rem;
					}

					ul {
						text-align: left;
						color: var(--text--secondary);
						font-weight: normal;
						list-style-type: circle;
						padding-left: 4rem;
					}
				}
			}
		}

		h3 {
			padding: 2.2rem 0;
			font-weight: bold;
			font-size: 2rem;
			line-height: 2rem;
			background: var(--payout-type--header-bg);
			color: var(--payout-type--header-text);

			span {
				position: relative;

				> .icon {
					position: absolute;
					margin: 0;
					top: 0.3rem;
				}
			}
		}

		.selectWrapper {
			position: absolute;
			left: 0;
			bottom: 2.5rem;
			width: 100%;
		}

		.helpTipContainer {
			float: left;
			width: auto;
		}

		.tooltip .arrow {
			left: -0.5rem;
		}
	}

	.payoutHelpTooltip {
		ul {
			list-style-type: none;
			margin: 0;

			li {
				white-space: nowrap;

				&.wrap {
					white-space: normal;
				}

				&.no-style {
					list-style-type: none;
				}

				span {
					display: inline-block;
					width: 8rem;
					position: absolute;
					right: 9rem;
					font-weight: bold;
					color: var(--text--base);

					&.euro {
						right: 0;
					}
				}
			}
		}

		> div > ul {
			list-style-type: disc;
			margin-left: 1.5rem;

			> li {
				font-weight: bold;
				font-size: 1.1rem;
				margin-right: -2rem;

				&.none {
					list-style-type: none;
				}

				ul {
					margin: 0.5rem 0 1.5rem 2rem;
					list-style-type: disc;
					color: var(--text--tertiary);
					position: relative;
					min-width: 38rem;
					font-size: 1.1rem;
				}

				li {
					font-weight: normal;
					line-height: 1.8rem;
					padding-right: 12rem;

					span {
						display: inline-block;
						width: 8rem;
						position: absolute;
						right: 9rem;
						font-weight: bold;
						color: var(--text--secondary-darker);
					}

					span:last-child {
						right: 0;
					}
				}

				p {
					font-weight: normal;
					margin: -0.6rem 0 2rem;
					color: RGBA(var(--text--tertiary-rgb), 0.8);
				}
			}

			&.none {
				list-style-type: none !important;
			}
		}

		h6 {
			font-size: 1.4rem;
			font-weight: bold;
			margin: 0.5rem 0 1.5rem;
		}

		td a {
			margin: 0 !important;
		}

		a {
			display: inline-block;
			padding: 0 0 0.5rem;
		}

		a span {
			font-size: 1.7rem;
			position: relative;
			top: 0.5rem;
		}

		&.small {
			ul {
				margin-bottom: 0 !important;

				li li {
					padding-right: 10rem;

					span {
						width: 8rem;
					}
				}
			}
		}
	}

	.guideContainer {
		text-align: center;
		margin: 0 0 3rem 0;

		p a {
			@include button.linkPrimary();
		}
	}

	.contactContainer {
		text-align: center;

		a {
			@include button.linkPrimary();
		}
	}

	#viewGuide {
		display: inline-block;
		font-weight: bold;

		span,
		p {
			float: left;
		}

		p {
			line-height: 1.9rem;
			margin: 0 0.8rem;

			&.hide {
				display: none;
			}
			&.show {
				display: block;
			}
		}

		&:hover {
			.info {
				@include style('icon', 'infoHover');
			}
		}
	}

	#showMore_payout_options {
		@include style('text', 'normalSize', 'regularColor');

		text-align: left;
		padding: 3rem 3rem 0 3rem;
		display: none;

		p {
			margin: 0 0 2rem 0;
		}

		ul {
			margin: 0 0 2rem 0;
		}

		.title {
			@include style('text', 'normalSize', 'passiveColor');

			font-weight: bold;
		}

		&.show {
			display: block;
		}
	}

	//A fizetesi modok kozos formazasai
	.payoneerPayoutContent,
	.payPalPayoutContent,
	.bankChexxPayoutContent,
	.chexxPayoutContent,
	.paperChexxPayoutContent,
	.paperCheckPayoutContent {
		form {
			width: 100%;
			padding: 3rem 0;
			text-align: center;
		}
	}


	#savePayoutChexxPaperContent.old-form {
		padding: 3rem;

		label {
			width: 20.5rem;
			display: flex;
			height: 38px;
			padding: 0;
			line-height: 19px;
			justify-content: flex-end;
			align-items: center;
		}
	}

	.bankChexxPayoutContent,
	.chexxPayoutContent,
	.paperChexxPayoutContent {
		width: 92rem;

		p.title {
			font-weight: bold;
			text-align: left;
			margin: 0 3rem 3rem;
		}

		p.footer {
			text-align: left;
			margin: 3rem 3rem 0;
		}
	}

	.skipBox {
		text-align: center;
		line-height: 7rem;

		.regularTextHuge,
		.currentPaymentMethod {
			line-height: 7rem;
		}

		.currentPaymentMethod {
			font-weight: bold;
			font-size: 1.4rem;
			line-height: 1.4rem;
			display: inline-block;
		}
	}

	@include _on('mobile') {
		#payoutoptionsgeneral_index {
				.confirmationContainer {
					.confirmationLabel {
						width: unset;
						text-align: left;
						margin-right: 2rem;
					}
					.confirmationInfo {
						clear: both;
						padding: 0 0.5rem 0 0;
					}
			}
		}
	}

	.confirmationContainer {
		@include style('box', 'common');
		text-align: center;
		padding: 0;
		position: relative;

		.confirmationData {
			padding: 1.1rem 0 1.3rem 0;
		}

		.confirmationLabel {
			@include style('text', 'hugeSize', 'labelColor');

			display: block;
			float: left;
			width: 48%;
			margin-right: 1.2rem;
			line-height: 3.3rem;
			text-align: right;

			&.settingsLabel {
				width: auto;
				text-align: left;
			}
		}

		.confirmationInfo {
			@include style('text', 'hugeSize', 'infoColor');

			position: relative;
			line-height: 3.3rem;
			text-align: left;
			padding: 0 0.5rem 0 2.5rem;
			word-wrap: break-word;

			[data-is="toggle-on-off"] {
				position: relative;
				top: .2rem;
			}

			.icon-info-circle {
				right: 0;
			}

			&.waiting {
				color: var(--text--waiting);

				.clock {
					margin: 0 vars.spacer(1) 0 0;
					float: left;
					font-size: 2rem;
				}

				.icon-info-circle {
					position: relative;
					left: vars.spacer(1);
					display: inline-block;
				}
			}

			&.boldText {
				font-weight: bold;
			}

			&.approved {
				color: var(--text--approved);

				.icon-ok {
					position: relative;
					display: block;
					margin: 0 vars.spacer(1) 0 0;
					right: 0;
					float: left;
				}
			}

			&.rejected {
				color: var(--text--rejected);

				i {
					color: inherit;
					position: relative;
					display: block;
					margin: 0 vars.spacer(1) 0 0;
					right: 0;
					top: 0;
					float: left;
					font-size: 2rem;
				}
			}
		}

		.primaryLarge {
			margin: 0 0 3rem 0;
		}

		.help {
			position: relative;
			float: none;
			display: inline-block;
			top: 0.5rem;
			right: -1rem;

			strong {
				color: var(--text--base);
				display: block;
				margin: 0 0 1rem 0;
			}
		}
	}

	#payoutoptions_index {
		.wire-transfer-tooltip {
			div ul li {
				span {
					width: 15rem;
					right: 18rem;
					&.euro {
						right: 0;
					}
				}
				li span {
					width: 18rem;
					right: 20rem;
					&:last-child {
						right: 0;
					}
				}
			}
		}

		.payoutTypesContainer {
			background: transparent;
			padding: 3rem 0;
			width: 90%;

			.infoBox {
				height: auto;
				border: 0;
				width: 100%;
				display: inline-block;
				margin: 0 1rem 2rem 0;
				padding: 0;
				border-radius: 0 0.3rem;
				background: var(--payout-options--box-bg);

				.titleContainer {
					position: absolute;
					height: 100%;
					width: 18rem;
				}

				.selectWrapper {
					position: relative;
					left: auto;
					bottom: auto;
					width: auto;
					float: right;
					padding: 2.5rem 3rem;
					right: 0;
					top: 0;
					border-left: 0.2rem solid var(--payout-options--box-border);

					button {
						padding: 0.9rem 2.9rem;
					}
				}

				h3 {
					font-weight: bold;
					font-size: 1.8rem;
					margin: 0;
					line-height: 2rem;
					text-align: left;
					color: var(--payout-options--box-title);
					border-radius: 0.3rem 0 0 0.3rem;
					display: table;
					width: 100%;
					height: 100%;
					vertical-align: middle;
					padding: 0;

					span {
						display: table-cell;
						padding: 0 3.2rem 0 2.8rem;
						margin: 0;
						height: auto;
						vertical-align: middle;
					}

					.icon-info-circle {
						right: 1.2rem;
					}
				}

				> ul {
					width: auto;
					list-style-type: none;
					margin: 0 0 0 18rem;
					float: left;

					> li {
						display: inline-block;
						width: auto;
						color: var(--payout-options--box-text);
						text-align: left;
						line-height: 6.4rem;
						font-size: 1.6rem;
						padding: 0 3rem;
						margin: 0.9rem 0;
						font-weight: bold;

						.icon {
							top: 0.7rem;
							left: -0.5rem;
						}

						ul {
							text-align: left;
							color: var(--text--secondary);
							font-weight: normal;
							list-style-type: circle;
							padding-left: 4rem;
						}
					}
				}
				@media all and (min-width: 1024px) and (max-width: 1240px), all and (max-width: 890px) {
					border-radius: 0.3rem;

					.titleContainer {
						position: relative;
						width: 100%;
						height: 6rem;
					}

					h3 {
						border-radius: 0.3rem 0.3rem 0 0;

						span {
							padding-left: 2rem;
						}
					}

					ul {
						margin: 0;
						width: 100%;
						padding: 0.8rem;

						li {
							display: block;
							text-align: center;

							&:first-child {
								margin: 0;
							}
						}
					}

					.selectWrapper {
						padding-bottom: 2rem;
						border: 0;
						text-align: center;
						float: none;
					}
				}
			}
		}

		.guideContainer hr {
			margin-top: 3rem;
		}

		.checkContainer {
			margin: 2.5rem 3rem 2rem;

			p {
				margin-bottom: 2rem;
				font-size: 1.5rem;
				line-height: 2.2rem;
			}

			.buttonContainer {
				position: relative;
			}

			.icon.help {
				margin-top: -0.2rem;
			}
		}

		.noteText {
			margin: 2.5rem 0 1rem;
			color: var(--text--secondary);
			line-height: 1.4;
			font-size: 1.4rem;
		}

		.confirmationLabelWrapper {
			display: flex;
			width: 100%;
			gap: 1.2rem;
			padding: 0 0.5rem;
		}

		.confirmationLabel,
		.confirmationInfo {
			float: none;
			width: auto;
			margin: 0;
			padding: 0;
		}

		.confirmationLabel{
			flex: 1;
		}

		.confirmationInfo {
			display: flex;
			align-items: center;
			flex: 1;
			word-break: break-word;
		}

		@include _on('mobile') {
			.confirmationLabelWrapper {
				display: grid;
				justify-content: left;
				gap: 0.8rem;
				padding: 0 4rem;
			}

			.confirmationLabel,
			.confirmationInfo {
				width: 100%;
				text-align: left;
				line-height: 1.4;
			}

			.confirmationInfo {
				word-break: break-word;
				padding: 0 0.5rem 0 0;
				margin-bottom: 2.7rem;
			}

			.noteText {
				margin: 1rem 2rem;
				text-align: left;
			}
		}
	}

	#payoutoptions_change {
		.hideOnMobile {
			@include _on('mobile') {
				display: none;
			}
		}
		.helpertext {
			margin-top: 4rem;
		}

		#signup_form {
			margin-left: 5rem;
		}

		hr,
		.hr {
			margin: 3rem 0 3rem -5rem;
		}

		input[type="submit"] {
			margin-left: -5rem;
		}
	}

	#billingaddress_index {
		h4 {
			margin-bottom: 4rem;
		}
	}

	#payoutoptions_index,
	#billingaddress_index,
	#payoutoptions_company,
	#payoutoptionsgeneral_index,
	#company_data,
	#company_finishregistration,
	#company_managingdirectorregistration,
	#company_addinformation {
		.hAligner {
			width: 90%;
			margin: 0 auto;

			h4 {
				font-size: 20px;
				text-align: left;

				i {
					color: var(--form--info-icon);
					cursor: pointer;
					font-size: 2.4rem;
					margin-left: vars.spacer(1);
					top: 6px;
					position: relative;
					right: unset;
				}
			}

		}

		[ref='alertHolder'] {
			transition: max-height, 1s, linear;
			overflow: hidden;
			max-height: 180px;

			&.empty {
				max-height: 0;
			}
		}
		.tabContainer,
		.filterBox {
			margin-bottom: 40px;
		}
		.tabContainer {
			background: var(--contest--tab-background);
			padding: 40px 0 0;
		}
		.billing-address-form {
			padding: 0 3rem;
		}
		.editLink {
			height: auto;
			width: auto;
			float: right;
			position: relative;
			max-height: 3.2rem;
			margin: 0 map.get(vars.$spacers, 14) 0 0;
			padding: map.get(vars.$spacers, 2);
			border-radius: 0.8rem;
			background: #{colors.get-gradient(yellow_500_400)};
			box-shadow: none;
			color: #{colors.get(neutral, 800)};

			@include _on('mobile') {
				position: absolute;
				left: unset;
				right: -.5rem;
			}

			i {
				font-size: 1.6rem;
				padding: 0;
				margin: 0;
				position: relative;
				transform: none;
				top: auto;
			}
		}
		.inlineAlertBoxContainer {
			height: auto;
		}

		@include _on('mobile') {
			.commonTabs>.tab a {
				border: 0;
			}
		}
	}

	#billingaddress_index {
		@include _on('small') {
			.hideOnMobile {
				display: block;
			}
			.showMobileOnly {
				display: none;
			}
			.ph-form textarea[disabled], .ph-form input[disabled] {
				opacity: 0.8;
			}
		}
		@include _on('mobile') {
			.ph-form textarea[disabled], .ph-form input[disabled] {
				opacity: 0.8;
			}
			.hideOnMobile {
				display: none;
			}
			.hAligner.showMobileOnly {
				width: 100%;
				display: flex;
				align-content: space-between;
				padding: 0 2rem;
				h4 {
					flex: 1;
					line-height: 2.2rem;
				}

				i.billingProtip {
					position: relative;
					cursor: pointer;
					margin-left: vars.spacer(2);
					transform: none;
					height: min-content;
					right: 0;
				}
			}
		}
	}

	.protip-content,
	.infoContent {
		p {
			padding: .5rem 0;
		}
		table {
			width: 100%;
			margin: 0 0 1rem 0;
		}
		table tr th,
		table tr td {
			width: 25%;
			min-width: 6rem;
			text-align: left;
		}
		table tr th {
			font-weight: bold;
		}
		table tr td:first-child,
		table tr th:first-child {
			width: 50%;
			min-width: 16rem;
			padding: 0 2rem 0 0;
		}
		a {
			margin: 2rem 0 0 0;
		}
	}

	.tripleaHelpTooltip {
		table tr th,
		table tr td {
			width: 15%;
			min-width: 4rem;
		}
		table tr td:first-child,
		table tr th:first-child {
			width: 70%;
		}
		table tr:last-child td {
			padding-top: 1rem;
		}
		a {
			margin-top: 0;
		}
	}


	.pagomundoHelpTooltip {
		table tr th:first-child {
			padding-bottom: 1rem;
		}

		a {
			margin-top: 0;
		}
	}

	.directaHelpTooltip{
		table tr:last-child td {
			width: 100%;
			min-width: 16rem;
			max-width: 22rem;
		}
	}

	.infoContent {
		font-size: 1rem;
		p {
			color: var(--payout-info-info)
		}
		table tr th,
		table tr td {
			color: var(--payout-info-detail-title);
		}
		table tr th {
			color: var(--payout-info-header);
		}
		table tr td:nth-child(3),
		table tr td:nth-child(2){
			color: var(--payout-info-detail-value);
		}
	}
	.protip-content {
		p {
			color: RGBA(var(--text--tertiary-rgb), 0.8);
		}
		table tr th,
		table tr td {
			color: var(--text--tertiary);
		}
		table tr th {
			color: var(--text--base);
		}
		table tr td:nth-child(3),
		table tr td:nth-child(2){
			color: var(--text--tertiary);
		}
	}

@media only screen and (max-width: 700px), (max-width: 1024px) and (orientation: landscape) {
	#payout_recipienttypeselector {
		p.regularTextNormal,
		p.regularTextLarge {
			padding: 0 2rem;
		}
	}
	#payoutoptionsgeneral_index,
	#payoutoptions_index {
		 .old-form input.disabled,
		 .old-form textarea.disabled,
		 .old-form .selectContainer.disabled {
			 opacity: 0.8;
		 }
		.showMobileOnly {
			display: block;
		}
		#overlayContainer {
			height: 100% !important;
		}

		.overlayContent:last-of-type {
			width: 100%;
		}

		.box_container.independentContainer.normal {
			width: 100vw !important;
			max-width: unset;
			height: fit-content ;
			min-height: 100vh;
			padding-left: env(safe-area-inset-left);
			padding-right: env(safe-area-inset-right);
		}

		p.regularTextNormal,
		p.regularTextLarge {
			padding: 0 2rem;
		}

		.companyIndividualInfo {
			display: none;
		}
		.payoutTypesContainer {
			width: 100%;
			padding: 3rem 2rem 0;
		}
	}
	#billingaddress_index .billing-address-form {
		padding: 0 2rem;
	}

	#overlayContainer .payoutInfoOverlay {
		display: flex;
		height: 100vh;
		.box_container {
			height: fit-content;
			position: relative;
			padding: 2rem;
			width: 90%;
			margin: auto;
		}
		.box_header {
			background-color: unset;
		}
	}
}

	#payoutoptionsgeneral_index {
		.confirmationContainer {
			padding: 1rem 5rem 0;

			@include _on('mobile') {
				padding: 2rem 2rem 0;
				.hideOnMobile {
					display: none;
				}
			}

			h5 {
				background-color: var(--primary);
				color: var(--text--secondary);
				float: left;
				position: relative;
				top: 0.8rem;
				padding: 0 3rem 0 0;
				font-size: 1.6rem;
				font-weight: normal;
			}

			.ph-hr {
				margin: 0;
			}

			.confirmationData {
				padding: 4.9rem 0 4rem;

				.rateInfo {
					color: var(--text--secondary);
					font-size: 1.2rem;
					margin: 0.6rem 0 0;
				}
			}
		}
		.payoutOptionTooltip {
			text-align: center;
			line-height: 1.4;
			padding: 2rem;

			br {
				line-height: 2;
			}
		}
	}

	/**
	* Company - common
	*/
	#companySteps {
		padding: 2rem 0;
		background: none;

		ul li {
			margin: 0;
			padding: 0 1.5rem 0.8rem 1.5rem;
			font-size: 1.3rem;
			color: var(--text--breadcrumb);

			&.active {
				color: var(--text--base);
			}
		}

		span {
			position: relative;
			top: 0.6rem;
		}
	}
	#companyStepsWrapper {
		color: var(--box--skip-bg);
		float: left;
		width: 100%;

		.regularTextNormal {
			display: block;
			text-align: center;
		}
	}

	.companyIndividualInfo {
		display: block;
		line-height: 3.4rem;
		text-align: center;
	}

	.companyInfo {
		display: block;
		position: absolute;
		width: 100%;
		line-height: 3.4rem;
		text-align: center;
	}
	#company_addinformation,
	#company_managingdirectorregistration {
		.hr.light span {
			text-transform: none;
		}
	}

	#company_addinformation,
	#company_managingdirectorregistration,
	#company_finishregistration {
		.hr.light + p {
			width: 80%;
			margin: 0 auto 2rem auto;
		}

		#pageContainer {
			position: relative;
		}
		.browseContainer {
			text-align: left;
		}
		.fileApiLoader {
			top: 0.6rem;
			left: 0.5rem;
			position: relative;
		}
		.progressBar {
			margin: 0.7rem 0 0;
		}
		.resultContainer {
			text-align: left;
			margin: 0.3rem 0 0;
			position: relative;

			.container {
				display: inline-block;
				width: 34rem;
				position: relative;
			}
			.icon-ok {
				margin-top: -1rem;
			}
			p {
				display: inline;
				float: right;
				margin-right: 4rem;
			}
			.uploadedFileName {
				float: left;
				margin: 0.5rem 0 0 0;
				width: 21.5rem;
				text-overflow: ellipsis;
				overflow: hidden;
				white-space: nowrap;
				font-size: 1.2rem;
			}
			.removeUploadedImage {
				color: var(--text--link);
				cursor: pointer;
				position: absolute;
				top: 50%;
				right: 4.2rem;
				margin-top: -0.7rem;
			}
		}

		.uploadBlock {
			display: inline-block;
			float: left;
			text-align: left;
			position: relative;

			.errorContainer {
				right: -13.5rem;
			}
		}
		.browseContainer {
			display: inline-block;
			position: relative;
			margin: 0.2rem 0 0;

			.help {
				margin-top: 0.8rem;

				.tooltip {
					p {
						margin: 0 !important;
					}
				}
			}
		}
		// Desription
		.formDesc {
			text-align: center;
			margin: 3rem 0;
			color: var(--text--base);
		}

		/* submit */

		.nextBtn {
			float: left;
			margin: 0 4rem 3rem 0;
		}
		.backBtn {
			float: left;
			margin: 0 0 30px 40px;
		}
		.nextBtn,
		.backBtn {
			z-index: 2;
			position: relative;
		}
	}

	/**
	* Company - Select recipient page
	*/
	#payout_recipienttypeselector {

		.payoutTypesContainer {
			@include _on('mobile') {
				padding: 3rem;
			}
		}

		.guideContainer hr {
			margin-top: 3rem;
		}

		.infoBox {
			margin-right: vars.spacer(3);
			max-width: 42.2rem;
			width: calc(50% - 20px);
			min-height: 37.8rem;
			height: auto;
			color: var(--payout-type--box-bg);
			border-color: var(--payout-type--box-border);

			@include _on('mobile') {
				width: 100%;
				margin: 0 auto 3rem auto;
				float: none;
			}

			.help {
				cursor: default;
			}

			> ul {
				> li {
					position: relative;
					color: var(--text--secondary);

					i {
						width: 0.8rem;
						height: 0.8rem;
						position: absolute;
						left: vars.spacer(5);
						font-size: 0.8rem;
						color: var(--payout-type--box-arrow);
					}
				}
			}
		}
	}

	/**
	* Company Add Information page
	*/
	#company_addinformation {
		form {
			text-align: left;

			hr {
				margin-top: 3rem;
			}
		}
		.formCaption {
			text-align: center;
			margin: 0 0 2.9rem 0;
			color: var(--text--base);
		}
		.termsAccept {
			width: auto;
			display: inline-block;
			margin: 0 0 0 41rem;

			p {
				margin: 0 0 0 1.5rem;
			}
		}
	}

	/**
	* Company registration form
	*/
	#pageContainer #companyRegisterForm.old-form {
		label {
			width: 29.2rem;
		}

		.termsAccept {
			margin: 0 0 0 30.9rem;

			span:before {
				font-size: 12px;
				top: 0;
			}
		}

		.nextBtn {
			float: right;
		}
	}

	/**
	* Company nanaging director common styles.
	*/
	#company_managingdirectorregistration {
		.formTypeSelector {
			text-align: left;

			label {
				width: 29.8rem;
			}
			span {
				margin: 0 1rem;
				font-size: 1.4rem;
				line-height: 1.4rem;
				color: var(--text--base);
			}
		}

		#addFormWrapper {
			margin: 4rem 0 0;
			min-height: 15rem;
			position: relative;
			font-size: 1.4rem;

			label {
				width: 29.8rem;
			}

			.progressIndicator {
				background: var(--box--main-bg);
				min-height: 15rem;
				position: absolute;
			}
			.inlineAlertBoxContainer {
				text-align: center;
			}
			.inlineAlertBox {
				width: 73rem;

				.icon-alert {
					float: left;
					top: 0.3rem;
					margin: 0 0 0 0.1rem;
					@include style('icon', 'yellowColor', 'normalSize');
				}
				.icon-ok {
					float: left;
				}

				/* Edit and delete links */
				a {
					float: right;
					line-height: 2rem;
					min-width: 4rem;
					text-align: center;
					padding: 0 1.5rem;
				}
			}
			button {
				margin: 1.5rem 0 1.5rem 0;
			}

			.uploader5 {
				&, &__container {
					margin: 0;
				}

				&__progress {
					margin: 0.5rem 0 0 0;
				}

				&__info {
					display: none;
				}

				&__button {
					margin-bottom: 1.5rem;

					a {
						min-width: 0;
					}
				}
			}

			.thumbnailPlaceHolder {
				float: left;
				margin-right: 1rem;

				img {
					width: 7rem;
				}
			}

			.resultContainer {
				padding: 0.5rem;
				border-radius: 0.4rem;
				box-shadow: 0 0.1rem 0 var(--company-reg--upload-result-shadow);
				background: var(--company-reg--upload-result-bg);
				border: 0.1rem solid var(--company-reg--upload-result-bg);
				float: left;
				margin-bottom: 2rem;

				.container {
					display: block;
					float: left;
				}

				h4 {
					font-size: 1.3rem;
					margin-bottom: 0;
					margin-top: 0.5rem;
				}
			}
		}

	}
	/**
	* Company managing director add page
	*/
	#company_managingdirectorregistration {

		label[for='existingPerson-component'] {
			display: inline-block;
			vertical-align: top;
			color: var(--text--base);
			margin-right: 1rem;
			float: none;
		}

		#select_existingPerson {
			float: none;
			display: inline-block;
			margin: 0;
			vertical-align: top;

			// We dont need the included clear
			+ .clear {
				display: none;
				// 'or' span
				+ span {
					vertical-align: top;
					line-height: 4rem;
					// add button
					+ a {
						margin-top: 0.3rem;
					}
				}
			}
		}
	}
	/**
	* Company Finish page
	*/
	#company_finishregistration {

		.finishCaption {
			text-align: center;
			color: var(--text--base);
			margin: 0 0 1.5rem 0;
		}

		table {
			table-layout: fixed;
		}

		// Info Text after the table
		table + p {
			text-align: center;
			margin: 3rem 0 0;
		}

		td {
			font-weight: bold;

			&.first {
				display: block;
				overflow: hidden;
				text-overflow: ellipsis;
				width: 50rem;
			}

			&.second {
				width: 22rem;
				text-transform: uppercase;
				text-align: center;
			}

			&.third {
				width: 37rem;
				text-transform: uppercase;
			}

			&.fourth {
				text-align: center;
				width: 17rem;

				i {
					position: relative;
					top: 0.2rem;
					color: var(--common--alert);
					display: inline-block;
					font-size: 1.8rem;
					margin: 0 0 0 0.5rem;
				}
			}

			&.approved {
				color: var(--text--approved) !important;
			}

			&.rejected {
				color: var(--text--rejected) !important;
			}
		}

		.hr.light {
			margin-top: 2.5rem;
			margin-bottom: 2.5rem;
		}
	}
	/**
	* Company Identification
	*/
	#identificationprocess_companydatachange form {
		padding: 1.8rem 0 0;
	}

	/**
	* Company Data overlay block
	*/
	#companyDataOverlay {
		text-align: center;
		margin: 2rem 0;

		label {
			color: var(--text--secondary);
			width: 34rem;
			margin: 0;
			padding-top: 0 !important;
			padding-bottom: 0 !important;
			line-height: 3rem !important;
		}
		.inputs {
			margin: 0;
			color: var(--text--base);
			line-height: 3rem !important;
		}

		.secondaryLarge {
			position: relative;

			margin: 1.5rem 0 3rem 0;
		}
		img {
			height: 3.4rem;
		}
		.help {
			display: inline-block;
			position: relative;
			float: none;
			top: 0.6rem;
			right: -0.7rem;
		}
	}

	#showMore_payout_overview {
		clear: both;
		float: left;
		font-size: 1.4rem;
		line-height: 2.2rem;

		.left, .right {
			float: left;
			width: 48%;
		}

		.right {
			float: right;
		}

		.downloadBtn {
			position: relative;
			padding-left: 3.3rem;

			.icon {
				left: 0.7rem;
				right: auto;
				top: 0.4rem;
			}
		}

		p, li {
			margin-bottom: 1rem;
		}

		p {
			color: var(--text--base);
		}
	}

	#paymentOverviewHR {
		float: left;
		width: 100%;
	}

	@media only screen and (max-width: 700px),
	(max-width: 1024px) and (orientation: landscape) {
		#payout_recipienttypeselector {
			.payoutTypesContainer {
				.hideOnMobile {
					display: none;
				}

				.centerOnMobile {
					margin: auto;
				}
			}
		}
	}

	@include _on('small', 'down') {
		#help_payinfo  {
			.payInfoLeftContent, .payInfoRightContent {
				width: auto;
				margin: 0 2rem;
			}
			.payInfoRightContent {
				margin-top: 2rem;
			}
			.payInfoBottomText {
				padding: 1rem 2rem 0;
			}
		}
	}

	@import 'Payout.oranum';
}

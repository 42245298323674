.oranum-theme {
	.ajaxHideContent .hr.light {
		border-top: 1px solid var(--hr-background);
	}

	#companySteps ul li.active {
		color: var(--stepcrumb--modelreg--active);
	}

	#companySteps ul li a {
		color: var(--common--success);
	}

	.confirmationContainer .confirmationLabel {
		color: var(--form-text);
	}

	.regularTextLarge,
	.regularTextNormal,
	.confirmationContainer .confirmationInfo,
	#showMore_payout_options,
	#showMore_payout_options .title,
	&#incomestatistics_index .statInfoText {
		color: var(--text--base);
	}

	.protip-content table tr td {
		color: var(--protip--text);
	}

	.protip-content table tr th {
		color: var(--payout-info-text);
	}

	&#billingaddress_index h4,
	&#payoutoptions_index h4,
	&#payoutoverview_index .statusHeader p,
	&#payoutoverview_index .paymentsOverviewHeader h4 {
		color: var(--payout-options--box-title);
	}

	&#payoutoverview_index .statusHeader {
		color: RGB(var(--color-primary-6));
		background: RGB(var(--color-background-widget));
	}

	&#incomestatistics_index .tableRow .tableSummarizeContentCell:hover {
		color: var(--text--link-hover);
	}

	&#payoutoptionsgeneral_index .confirmationContainer h5 {
		color: var(--box--separator-text);
	}

	&#incomestatistics_index .tableRow .tableRegularCell,
	&#incomestatistics_index .tableRow .tableLighterRegularCell,
	&#payoutoverview_index .tableRow .tableRegularCell,
	&#payoutoverview_index .tableRow .tableLighterRegularCell,
	&#company_finishregistration td.second {
		color: var(--text--tertiary);
	}

	&#incomestatistics_index .tableRow .tableHighlightedCell,
	&#payoutoverview_index .tableRow .tableHighlightedCell {
		color: var(--text--secondary);
	}

    &#payoutoverview_index .periodInfo p {
        color: RGB(var(--color-primary-3));
    }
    &#payoutoverview_index .periodInfo p strong {
        color: RGB(var(--color-primary-6));
    }
}
@import '../../../scss/shell';

:global {
	#index_faq {
		hamburger {
			display: none;
		}

		#main_content {
			padding: 40px;

			@include _on('mobile') {
				padding: 0;
			}
		}

		#pageContainer {
			height: auto;
			min-height: auto;
			padding: 0 30px 30px;
			border-radius: 4px;
			background-color: var(--page-background);

			@include _on('mobile') {
				border-radius: 0;
			}

			h2 {
				box-shadow: 0 1px 0 var(--hr-background-lighten-25);
				margin-top: 40px;
				border-bottom: 1px solid var(--hr-background);
				padding-bottom: 10px;

				&:first-child {
					margin-top: 50px;
				}
			}

			.showMoreLink {
				padding: 0;
				font-size: 14px;
				line-height: 1.3;
			}

			.showMoreContent {
				font-size: 14px;
				margin-bottom: 30px;
				padding: 20px;
				border-radius: 5px;
				color: var(--text--base);
				background: var(--background);
				line-height: 24px;
			}

			ul, ol {
				margin-bottom: 20px;

				&:last-of-type {
					margin-bottom: 0;
				}
			}
		}
	}

	.mainstream-theme #index_faq #pageContainer {
		box-shadow: none;
	}
}
@import '../../../scss/shell';

:global {
	#markAsPromoVideo {
		padding-top: 4rem;

		.promoVideoBlock {
			position: relative;
			width: 216px;
			min-height: 240px;
			display: inline-block;
			border-radius: 4px;
			color: var(--text--secondary-darker);
			border: 4px solid var(--channel--box--inner-bg);
			padding: .6rem;
			margin: 0 1rem 4rem;
			cursor: pointer;

			&.promoted {
				.thumb {
					background: url('./video-thumb-promoted.jpg') no-repeat top center;
				}

				&:after {
					position: absolute;
					content: url('./premium-ribbon.png');
					right: -5px;
					top: -5px;
				}

				@include _on('mobile') {
					margin-bottom: 2rem;
				}
			}

			&.premium .thumb {
				background: url('./video-thumb.jpg') no-repeat top center;
			}

			&:hover {
				border-color: RGBA(var(--promo-video--step-number-rgb), 0.4);

				.thumb {
					opacity: 1;
				}
			}
		}

		.thumb {
			width: 20rem;
			height: 12rem;
			opacity: 0.4;
		}

		.play {
			line-height: 12rem;
			font-size: 5rem;
			color: var(--text--base);
			opacity: 0.4;
		}

		.text {
			margin: 1.5rem 0;
		}

		p {
			font-size: 1.2rem;
		}

		.ph-hr {
			margin-bottom: 0;
		}

		.more {
			position: relative;
			font-size: 1.2rem;
			color: var(--text--secondary-darker);
			padding: 2rem 0;
		}

		.uploadButton {
			margin-bottom: 4rem;
		}

		h3 {
			color: var(--channel--info-title);
		}

		.info {
			margin-bottom: 2rem;
			font-size: 1.4rem;
			color: var(--channel--info-text);
		}
	}
}
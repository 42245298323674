@import '../../../scss/shell';

:global {

	[class$=PayoutContent] {

		.onYes,
		.onNo,
		#row_payout_username {
			display: none;
			font-size: 14px;
			text-align: center;

			// cuz no is the default here
			@at-root :global .webmoneyPayoutContent .onNo {
				display: block;
			}

			&.show {
				display: block;
			}
		}

		.desc {
			margin-top: 18px;

			&.first {
				margin-top: 30px;
			}
		}
	}

	.webmoneyPayoutContent,
	.cosmoPayoutContent {
		width: 90%;
		padding: 4rem 0;
		margin: 0 auto;

		@include _on('mobile') {
			width: 100%;
			padding: 4rem 2rem;
		}
	}

	.tripleaPayoutContent {
		padding: 4rem 6rem;
		p {
			font-size: 1.4rem;
			line-height: 1.4;
			color: var(--text--secondary);
		}
		#cryptoAgreement {
			color: var(--text--secondary);
			label {
				padding-right: 0;
			}
			.form-input-additions > * {
				top: 1.4rem;
				left: -2rem;
			}
		}
		a {
			cursor: pointer;
		}
		@include _on('mobile') {
			padding: 2rem;
			#cryptoAgreement {
				label {
					padding-right: 2.5rem;
				}
				.form-input-additions > * {
					top: 0;
					left: 0;
				}
			}
		}
	}

	.webmoneyPayoutContent,
	.payoneerPayoutContent,
	.payPalPayoutContent,
	.chexxPayoutContent,
	.paxumPayoutContent,
	.skrillPayoutContent,
	.directaPayoutContent,
	.cosmoPayoutContent,
	.tripleaPayoutContent,
	.pagomundoPayoutContent {
		p {
			text-align: left;
		}
	}

	.showMobileOnly {
		display: none;
	}

	#changePayoutTypeForm {
		.button {
			font-family: Roboto, Arial, sans-serif;
			font-size: 1.4rem;
		}
	}

	@include _on('mobile') {
		.checkContainer,
		.payoutTypesContainer {
			.hideOnMobile {
				display: none;
			}
			.showMobileOnly {
				display: block;
			}
		}

		div.skrillPayoutContent,
		div.directaPayoutContent,
		div.paperChexxPayoutContent,
		div.chexxPayoutContent,
		div.paxumPayoutContent,
		div.payPalPayoutContent,
		div.payoneerPayoutContent {
			width: 100%;
			max-width: 40rem;
			margin: auto;
			padding: 0 2rem;
			.tinyField,
			.selectContainer,
			.tinyField input {
				width: 100%;
			}
			.old-form label:not(.toggle-on-off) {
				text-align: left;
				justify-content: flex-start;
				em {
					position: absolute;
				}
			}
			#input_payout_username {
				width: 90%;
			}
			span.relative-left {
				width: 100%;
			}
			.largeField input,
			.smallField input {
				width: 100%;
			}
			.inputs.largeField {
				display: flex;
				flex-direction: column;
				align-items: center;
			}
			.hint{
				position: relative;
				text-align: right;
				line-height: unset;
			}
			form {
				padding: 4rem 0;
			}
			p.footer {
				margin: 2rem 0 0;
			}

			#row_beneficiaryDocumentId {
				label {
					width: unset;
				}
			}
		}

		div.directaPayoutContent,
		div.paperChexxPayoutContent,
		div.chexxPayoutContent {
			.smallField,
			.largeField {
				width: 90%;
			}

		}
		div.chexxPayoutContent {
			.scrollContainer {
				padding: 1.5rem 0 1.5rem 1.5rem;
			}
			.dropDownContainer,
			#bankCountry-component-slide.slideContainer,
			#beneficiaryState-component-slide.slideContainer {
				width: 100%;
			}
		}

		form#savePayoutSkrillContent.old-form,
		form#savePayoutChexxPaperContent.old-form {
			padding: 3rem 0;

			.title{
				margin: 0 0 3rem 0;
			}
			label{
				justify-content: flex-start;
				em{
					position: relative;
				}
			}
		}
		div.webmoneyPayoutContent,
		div.cosmoPayoutContent,
		div.tripleaPayoutContent {
			.ph-form label:not(.unstyle) {
				padding-right: 6.5rem;
				line-height: 2rem;
				text-align: left;
			}
		}

		div#payoutOptionsOverlay {
			margin: auto;
			max-width: 40rem;
			padding: 4rem 2rem;

			.inputContainer,
			.labelContainer {
				width: 100%;
				label {
					text-align: left;
				}
			}

			.button {
				margin: 0 1rem;
			}
		} 
	}

	@include _on('small') {
		div#beneficiaryState-component-slide.slideContainer,
		div#beneficiaryState-component-dropDown.dropDownContainer,
		div#bankCountry-component-dropDown.dropDownContainer,
		div#bankCountry-component-slide.slideContainer,
		div#bankState-component-dropDown.dropDownContainer,
		div#bankState-component-slide.slideContainer {
			width: 100%;
		}
		div#beneficiaryState-component-scrollContainer.scrollContainer,
		div#bankCountry-component-scrollContainer.scrollContainer,
		div#bankState-component-scrollContainer.scrollContainer {
			padding: 1.5rem 0 1.5rem 1.5rem;
		}

		div#payoutOptionsOverlay {
			padding: 4rem;
		}
		.hideOnMobile {
			display: none;
		}
		#overlayContainer .payoutInfoOverlay {
			.box_container {
				max-width: 50rem;
			}
		}
		.webmoneyPayoutContent{
			label:not(.unstyle){
				line-height: 2rem;
			}
		}
		div.chexxPayoutContent,
		div.paperChexxPayoutContent {
			width: unset;
			#savePayoutChexxPaperContent.old-form {
				padding: unset;
				label {
					width: 31%;
				}
				.inputs {
					width: 55%;
				}
				.smallField input,
				.selectContainer,
				.largeField input,
				.relative-left {
					width: 100%;
				}
			}
		}

		div.payoneerPayoutContent form,
		div.payPalPayoutContent form,
		div.chexxPayoutContent form,
		div.paxumPayoutContent form,
		div.skrillPayoutContent form,
		div.directaPayoutContent form {
			label:not(.toggle-on-off) {
				width: 31%;
			}
			.inputs {
				width: 55%;
			}
			.smallField input,
			.selectContainer,
			.largeField input,
			.tinyField input,
			.relative-left {
				width: 100%;
			}
		}
	}

	.payoneerPayoutContent,
	.payPalPayoutContent,
	.chexxPayoutContent,
	.paxumPayoutContent,
	.skrillPayoutContent,
	.directaPayoutContent {
		width: 700px;
		margin: 0 auto;

		form {
			padding: 5rem 0;

			label:not(.toggle-on-off) {
				width: 340px;
				display: flex;
				height: 38px;
				padding: 0;
				line-height: 19px;
				justify-content: flex-end;
				align-items: center;

				@include _on('mobile') {
					width: unset;
					display: block;
				}
			}
		}
	}

	.directaPayoutContent {
		width: 600px;

		form {
			padding: 3rem 0 4rem 0;

			label:not(.toggle-on-off) {
				width: 180px;
			}
		}

		p {
			margin: 40px 0;
			font-size: 12px;
			color: var(--text--paragraph);
		}
	}

	.pagomundoPayoutContent {
		padding: 4rem 6rem;
	
		p {
			margin: 2rem 0 4rem;
			font-size: 1.4rem;
			line-height: 1.6rem;
			color: var(--text--paragraph);
		}

		@include _on('mobile') {
			padding: 4rem 2rem;

			.ph-form label {
				line-height: 3rem;
				text-align: left;
			}
		}
	}

	.instantPayoutContent {
		padding: 48px 127px 48px;
		font-size: 14px;
		line-height: 24px;
		font-weight: 400;
		color: rgb(var(--color-primary-0));

		h2 {
			margin-bottom: 16px;
			font-size: 20px;
			line-height: 24px;
			font-weight: bold;
			text-align: center;
		}

		ul {
			margin-bottom: 32px;
			list-style-type: disc;

			li {
				&:last-child {
					margin-bottom: 0;
				}
			}
		}

		.buttonsContainer {
			text-align: center;

			form {
				display: inline;
				margin-right: 8px;
			}
		}
	}
}

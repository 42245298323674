@use 'global/colors';

/**
 * CSS from the package
 */
:global {
	.Dropdown-root {
		position: relative;
	}

	.Dropdown-control {
		position: relative;
		overflow: hidden;
		background-color: white;
		border: 1px solid #{colors.get(neutral, 400)};
		border-radius: 2px;
		box-sizing: border-box;
		color: #{colors.get(neutral, 800)};
		cursor: default;
		outline: none;
		padding: 8px 52px 8px 10px;
		transition: all 200ms ease;
	}

	.Dropdown-control:hover {
		box-shadow: 0 1px 0 #{colors.get(neutral, 900, 0.06)};
	}

	.Dropdown-arrow {
		border-color: #{colors.get(pink, 500)} transparent transparent;
		border-style: solid;
		border-width: 0.6rem 0.6rem 0;
		content: ' ';
		display: block;
		height: 0;
		position: absolute;
		right: 1.2rem;
		top: 1.7rem;
		width: 0;
	}

	.is-open .Dropdown-arrow {
		border-color: transparent transparent #{colors.get(pink, 500)};
		border-width: 0 0.6rem 0.6rem;
	}

	.Dropdown-menu {
		background-color: white;
		border: 1px solid #{colors.get(neutral, 400)};
		box-shadow: 0 1px 0 #{colors.get(neutral, 900, 0.06)};
		box-sizing: border-box;
		margin-top: -1px;
		max-height: 200px;
		overflow-y: auto;
		position: absolute;
		top: 100%;
		width: 100%;
		z-index: 1000;
		-webkit-overflow-scrolling: touch;
	}

	.Dropdown-menu .Dropdown-group > .Dropdown-title {
		padding: 8px 10px;
		color: #{colors.get(neutral, 800, 1)};
		font-weight: bold;
		text-transform: capitalize;
	}

	.Dropdown-option {
		box-sizing: border-box;
		color: #{colors.get(neutral, 800, 0.8)};
		cursor: pointer;
		display: block;
		padding: 8px 10px;
	}

	.Dropdown-option:last-child {
		border-bottom-right-radius: 2px;
		border-bottom-left-radius: 2px;
	}

	.Dropdown-option:hover {
		background-color: #f2f9fc;
		color: #{colors.get(neutral, 800)};
	}

	.Dropdown-option.is-selected {
		background-color: #f2f9fc;
		color: #{colors.get(neutral, 800)};
	}

	.Dropdown-noresults {
		box-sizing: border-box;
		color: #{colors.get(neutral, 400)};
		cursor: default;
		display: block;
		padding: 8px 10px;
	}
}

/**
 * Style overrides
 */

:global {
	.is-open .Dropdown-arrow {
		border-color: transparent transparent var(--form--dropdown--button-caret);
	}

	.Dropdown-control {
		border-radius: 0.4rem;
		font-size: 1.4rem;
		color: var(--form--input-text);
		line-height: 4rem;
		padding: 0 5rem 0 1.6rem;
		background: var(--form--input-background);
		border: none;

		&:before {
			content: '';
			position: absolute;
			width: 2.4rem;
			height: 2.4rem;
			background: var(--form--dropdown--button-background);
			top: 0.8rem;
			right: 0.8rem;
			border-radius: 0.4rem;
			cursor: pointer;
		}
	}

	.Dropdown-arrow {
		border-color: var(--form--dropdown--button-caret) transparent transparent;
		margin-right: 2px !important;
	}
	.Dropdown-menu {
		background: var(--form--input-background);
		border: none;
		margin-top: 2px;
		border-radius: 0.4rem;
		padding: 0.8rem 0.6rem;
	}

	.Dropdown-option {
		font-size: 1.4rem;
		color: #{colors.get(pink, 600)};
	}
	.Dropdown-option:hover {
		background-color: transparent;
		color: var(--text--link);
	}
	.Dropdown-option.is-selected {
		background-color: transparent;
		color: var(--text--link);
	}
}

.oranum-theme {
    .statisticContent .statisticsTabs .tabContainer .commonTabs .tab a {
        border-color: var(--common-tabs--link-border);
    }

    .statisticContent .statisticsTabs .tabContainer .commonTabs .tab.active a {
        border-color: var(--common-tabs--link-active-border);
    }

	.statisticContent .periodCoinDollarEarnings .earningsBlock:first-of-type em,
	.statisticContent .statContentBlock .showMoreContent p {
		color: var(--text--base);
	}


	.statisticContent .transactionsIncomeContent .transactionsIncomeTitle h4 {
		color: var(--statistics-income--list-header);
	}
}
@if _module('box') {

	$_box-main-selector: '.' +_format-selector(_box('selector.box'), false, false, true);
	$radius: _rem(_dim('global-radius'));
	$gutter: _rem(_dim('global-gutter'));

	.mainstream-theme,
	.bimbim-theme,
	.oranum-theme,
	.docler-theme {
		#{$_box-main-selector} {
			&__header {
				position: relative;
				background-color: var(--box--header-background);
				font-weight: 600;
				border-bottom: 1px solid var(--box--header-border);
			}
		}

		.ph-box__content {
			background-color: var(--registration--white);
			box-shadow: 0 0 10px 0 RGBA(0,0,0,0.1);
		}
	}

	.oranum-theme,
	.docler-theme {
		.ph-box__content {
			border-radius: 0 0 0.3rem 0.3rem;
		}
	}

	#{$_box-main-selector} {

		$selector: &;

		&__header {
			@include _tableize;

			table-layout: fixed;
			height: 5rem;
			font-size: 1.6rem;
			font-weight: bold;
			background-color: var(--box--header-background);
			color: var(--box--header-text);

			// Should be a col inside the header
			> * {
				padding: 0 $gutter;
				white-space: nowrap;
				text-overflow: ellipsis;
				overflow: hidden;
			}
		}

		&__content {
			padding: $gutter;
			background-color: var(--box--content-background);
		}

		&--radius {
			> *:first-child {
				border-radius: $radius $radius 0 0;
			}
			> *:last-child {
				border-radius: 0 0 $radius $radius;
			}
		}

		&--inline {
			width: 100%;
			display: table;

			> * {
				display: table-cell;
				vertical-align: middle;
			}
		}

		&--secondary {
			background-color: var(--box--secondary--bg);
			color: var(--box--secondary--text);

			#{$selector}__content {
				background-color: transparent;
				font-size: 1.4rem;
			}

			strong {
				color: var(--text--base);
				font-weight: normal;
			}
		}

		// Not used anymore, leaving here as placeholder.
		//&__figure {
			//width: 7.5rem;
			//color: #202020;
			//text-align: center;
			//font-size: 2rem;

			//&--info {
				//background-color: #55ACEE;
			//}
		//}
	}
}
@use 'global/icons';
@import '../../scss/shell';

:global {
	.uploader5 {
		position: relative;
		width: auto;
		height: auto;
		text-align: center;
		margin: 15px 0 0;

		&__container {
			display: inline-block;
			margin: 30px 0 0;
			text-align: center;

			i.icon-info-circular-outline {
				position: absolute;
				color: var(--text--secondary);
				padding: 5px;
				right: -30px;
				font-size: 18px;
				top: 50%;
				cursor: pointer;
				@include translate(0, -50%);

				&.infoOverlayButton {
					z-index: 9 !important;
				}
			}

			i.icon-upload {
				font-size: 22px;
			}
		}

		&__progress {
			position: relative;
			display: inline-block;
			margin: 1rem 0 0 0;

			&-bar {
				border-radius: 12px;
				box-shadow: 0 1px 0 var(--uploader5--progress-shadow);
				width: 300px;
				max-width: 100%;
				height: 23px;
				padding: 2px;
				margin: 0 auto;
				background-color: var(--background);
				overflow: hidden;

				.bar {
					position: relative;
					transition: width 0.3s linear;
					width: 100%;
					max-width: 100%;
					border-radius: 10px;
					height: 20px;
					float: left;
					background: url('./progress.gif');
				}

				.noProgressTransition {
					transition: all, 0s;
				}

				span {
					position: absolute;
					left: 10px;
					white-space: nowrap;
					top: 0;
					line-height: 20px;
					font-weight: bold;
					text-transform: uppercase;
					color: var(--uploader5--progress-text);
					font-size: 11px;
				}
			}
			&-info {
				position: absolute;
				width: 100%;
				margin-top: .5rem;
			}
		}

		&__button {
			position: relative;
			display: inline-block;

			a {
				position: relative;
				overflow: hidden;
				cursor: pointer;

				input {
					width: 100%;
					height: 100%;
					cursor: pointer;
					font-size: 0;

					&::-webkit-file-upload-button {
						cursor: pointer;
					}
				}
			}
		}

		&__info {
			color: var(--text--base);
			font-size: 13px;
			line-height: 13px;
			margin: 27px 0 0;
		}

		&__tooltip {
			width: auto;
			max-width: 500px;

			&-filename {
				display: block;
				width: 100%;
				padding-bottom: 2px;
				opacity: 0.7;
				text-overflow: ellipsis;
				overflow: hidden;
				font-weight: bold;
			}

			li {
				margin-top: 10px;

				&:first-child {
					margin-top: 0;
				}
			}
		}

		&__underlay {
			background: RGBA(var(--uploader5--underlay-rgb), 0.5);
			position: fixed;
			z-index: 5;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			display: none;
		}

		&--is-dragging {
			.uploader5__underlay {
				display: block;
			}

			.uploader5__dropzone {
				position: relative;
				z-index: 10;
			}
		}

		&--is-drag-over {
			// Placeholder
		}
	}

	.uploadInfoText {
		p {
			font-size: 12px;
			line-height: 12px;
			text-align: left;
			color: var(--protip--text);
			margin: 0 0 15px;

			strong {
				color: var(--protip--text);
				font-size: 14px;
			}
		}

		ul {
			margin: 8px 0 0 14px;

			li {
				margin: 0 0 3px;
				font-size: 8px;
				list-style-type: disc;
				color: var(--protip--text);

				p {
					font-size: 12px;
					line-height: 18px;
					color: var(--protip--text);
					margin: 0;
				}
			}
		}

		.wikiLink {
			display: block;
			font-weight: bold;
			font-size: 1.2rem;
			line-height: 16px;
			margin: 15px 0 7px;
			text-align: left;

			i.icon-info-circular-outline {
				display: inline-block;
				position: relative;
				margin: 0 4px 0 0;
				font-size: 16px;
				color: var(--protip--link);
			}

			&:hover i.icon-info-circular-outline {
				color: var(--protip--link-hover);

                &::before {
                    content: icons.get('info-circular-solid');
                }
			}

			.linkArrowRight {
				display: inline-block;
				margin: 0 0 0 6px;
				position: relative;
				font-size: 12px;
				line-height: 11px;
			}

			&:hover .linkArrowRight {
				background-position: -85px -279px;
			}
		}
	}

	@import 'Uploader5.oranum';
}
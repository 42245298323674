@import '../../../scss/shell';

:global {

	.step-select-account-type {

		form-selectorbox {

			.icon-wrapper {
				height: 6.8rem;
				width: 100%;
				position: relative;
				overflow: hidden;
				text-align: center;

				&__icon {
					font-size: 6.4rem;
					z-index: 2;
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);

					&:before {
						color: var(--account-selector--icon-unselected);
					}
				}
			}

			h4 {
				margin: 2rem 0 0;
				white-space: pre-line;
				font-weight: normal;
			}

			p {
				margin: 1.5rem 0 0;
				font-size: 12px;
				color: var(--text--secondary);

				&:first-of-type {
					margin: 0.5rem 0 0;
					font-size: 1.4rem;
				}
			}

			@include _on('mobile') {
				border-width: 0.2rem;

				> div {
					padding: 1.8rem 2rem !important;
				}
				.icon-wrapper {
					float: left;
					width: 8.5rem;
					margin: 1rem 2rem 1rem 0;
				}

				h4 {
					margin: 0;
				}

				.text-wrapper {
					text-align: left;
				}
			}

            &.selected {
                .icon-wrapper {
                    &__icon {
                        &:before {
                            @include commonTextGradient;
                            color: var(--account-selector--icon);
                        }
                    }
                }
            }
		}
	}

	@import 'SelectAccountType.oranum';
}
@use 'global/colors';
@use 'global/icons';
@use 'global/typography';
@use 'global/vars';
@import '../../../scss/shell';

:global {
	#snapshot_index,
	#snapshot_gallery {
		$placeholderIconSize: 5.2rem;

		#showMoreHolder {
			margin: 3rem 0 0;
		}

		.firstRow {
			margin: 0 0 -4px;
		}

		.snapshot {
			margin: 54px 0 27px 0;
		}

		.activateLink {
			margin: 39px 0 0;
		}
		.incomeText {
			color: var(--text--secondary);
			font-size: 14px;
			line-height: 20px;

			&.overall {
				margin: 0 0 0 16px;
			}
		}

		.actualIncome,
		.overallIncome {
			text-align: right;
			margin: 0.3rem 0;

			p {
				display: inline-block;
			}
		}

		.incomeAmount {
			color: var(--text--base);
			font-weight: bold;
			font-size: 16px;
			line-height: 20px;
			margin: 0 0 0 4px;
		}

		.infoBox {
			width: 100%;
			padding: 25px 0;
			margin: 30px 0 0;
			position: relative;

			p {
				display: inline-block;
			}

			i {
				right: 10px;
				top: 32px;
			}
		}

		.snapshotPageContainer {
			text-align: left;
			padding: 0 0 45px 0;
		}

		.snapshotsContainer {
			@include extendProfileGalleryGeneralContainer;

			margin: 0 40px;
			position: relative;
			text-align: left;
		}

		.snapshotsHeader {
			@include _flexbox();
			@include _flexbox-align(center);

			margin-bottom: 3rem;

			.memberPhotoAmount {
				@include _flexbox-grow(0);
			}
		}

		.snapshotListBlock {
			margin: 0 -.5rem;
		}

		.galleryManageText {
			margin: 0 0 30px 0;
		}

		.backToAllSnapshots {
			@include extendPageSwitchBox;
			@include _flexbox-grow(0);
			text-align: center;

			i {
				font-size: 2.4rem;
				position: relative;
				top: vars.spacer(1);
			}
		}

		.snapshotItem {
			display: block;
			position: relative;
			border: .2rem solid var(--earn-more--snapshot--member-album-border);
			border-radius: .6rem;
			background: var(--earn-more--snapshot--member-album-bg);
			background-clip: content-box;
			padding: 1rem;
			backface-visibility: hidden;
			min-height: auto;
			height: initial;
			cursor: pointer;
			overflow: hidden;

			&.emptyPictureBox {
				cursor: default;
			}

			// To keep dimensions within fixed aspect ratio

			&::before {
				display: block;
				content: "";
				padding-top: 56.25%;
			}
			&:hover, &:focus {
				.visibilityOverlay p,
				.camera {
					display: none;
				}

				.snapshotInfoText {
					display: block;
					opacity: 0.5;
				}

				.settingsOverlay {
					opacity: 1;
					pointer-events: all;
				}
				.snapshotImage::after {
					opacity: .8;
				}
			}
		}

		.snapshotImage {
			display: block;
			position: absolute;
			left: 1rem;
			top: 1rem;
			right: 1rem;
			bottom: 1rem;
			overflow: hidden;
			z-index: 1;

			&::before, &::after {
				display: block;
				position: absolute;
				left: 0;
				right: 0;
				bottom: 0;
				background: linear-gradient(to bottom, transparent, var(--snapshot--camera-bg));
				pointer-events: none;
				opacity: .8;
				z-index: 1;
			}
			&::after, &--not-empty::before {
				content: "";
				height: 50%;
			}
			&::after {
				height: 100%;
				transition: opacity .1s ease-out;
				opacity: 0;
			}

			&.convertingPicture {

				.clockConverting {
					margin: 29px auto 9px;
				}

				p {
					color: var(--text--base);
					opacity: 0.5;
				}
			}

			&.waitingApproval {
				.settingsOverlay {
					a {
						display: none;
					}

					> p {
						position: absolute;
						top: 80px;
					}

					p {
						width: 100%;
						color: var(--text--base);
						opacity: 0.5;
					}
				}
			}

			&.rejected {
				.settingsOverlay {
					z-index: 8;

					.icon-error {
						@include style('icon', 'redError', 'normalSize');

						display: inline-block;
						position: relative;
						margin: 49px 0 0 0;
						top: 3px;
						right: 3px;
					}

					p {
						color: var(--text--base);
						font-weight: bold;
						display: inline-block;
					}

					&:hover {
						.icon-error,
						p {
							display: none;
						}
					}
				}
			}

			.visibilityOverlay {
				display: block;
				position: absolute;
				bottom: 0;
				left: 0;
				height: 4rem;
				width: 100%;
				font-weight: bold;
				font-size: 1.2rem;
				line-height: 4rem;
				color: var(--text--base);
				z-index: 9;

				p {
					margin: 0;
				}

				.tooltipHelp {
					top: 3px;
					right: 3px;
				}

				.visibility {
					top: 2px;
					right: 3px;
				}

				.clockSmall {
					top: 3px;
					right: 3px;
				}

				.published {
					top: 2px;
					right: 3px;
				}

				.camera {
					top: 2px;
					right: 3px;
				}

				.icon-alert {
					@include style('icon', 'yellowColor');

					position: relative;
					display: inline-block;
					left: 0;
					top: 3px;
				}

				p {
					&.visibilityName {
						-webkit-touch-callout: none;
						-webkit-user-select: none;
						-khtml-user-select: none;
						-moz-user-select: none;
						-ms-user-select: none;
						user-select: none;
					}

					display: inline-block;
					font-weight: bold;
					margin: 4px 0 0 0;
				}

				p,
				.visibility,
				.clockSmall,
				.published,
				.camera {
					opacity: 0.5;
				}

				&.setVisibilityBtn {
					cursor: pointer;

					&:hover,
					&.emphasized {
						p,
						.visibility,
						.clockSmall,
						.published,
						.camera {
							opacity: 1;
						}
					}
				}
			}

			.settingsOverlay {
				@include _flexbox();
				@include _flexbox-justify-content(center);
				@include _flexbox-align(center);

				position: absolute;
				left: 0;
				top: 0;
				right: 0;
				bottom: 0;
				background: linear-gradient(to bottom, transparent, var(--snapshot--camera-bg));
				opacity: 0;
				transition: opacity .2s ease-out;
				z-index: 2;
				pointer-events: none;

				.imageEnlarge,
				.imageProfilePicture {
					@include extendSquareImageButton;

					&:hover {
						border-color: var(--text--base);
						.enlarge {
							@include style('icon', 'enlargeHovered');
						}

						.trashCanLarge {
							@include style('icon', 'trashCanLargeHovered');
						}
						.humanFigureBright {
							@include style('icon', 'humanFigureBrightHovered');
						}
					}
				}

				hr {
					margin: 0;
					border: 1px solid var(--gallery--settings-overlay-border);
				}
			}
		}

		.snapshotAction {
			font-size: 3.2rem;
			color: var(--snapshot--action-icon);
		}

		.snapshotImage {
			text-align: center;
			background: no-repeat scroll center center / cover;

			.visibilityOverlay {
				cursor: default;

				.snapshotInfoText {
					display: none;
					font-weight: normal;
				}
			}
		}

		.snapshotsContainer .snapshotItem .photoPlaceholder {
			font-size: 4rem;
			color: var(--gallery--icon-photo-placeholder);
		}

		.snapshotPlaceholder {
			position: absolute;
			left: 50%;
			top: 50%;
			width: $placeholderIconSize;
			height: $placeholderIconSize;
			margin: -.5 * $placeholderIconSize;
			color: var(--earn-more--snapshot--member-album-icon);
			font-size: $placeholderIconSize;
			line-height: $placeholderIconSize;
			z-index: 2;
		}

		.memberAlbum {
			text-align: left;

			.photoPlaceholder {
				display: inline-block;
				margin: vars.spacer(2);
			}

			.snapshotPlaceholder {
				color: RGBA(var(--earn-more--snapshot--member-album-user-rgb), .4);
			}
		}

		.memberAlbumInfo {
			position: absolute;
			left: 0;
			right: 0;
			bottom: 0;
			text-align: left;
			padding: 1rem;
			z-index: 3;
		}

		.memberOwnerName {
			@include _flexbox-grow(1);

			display: block;
			margin: 0 2rem;
		}

		.memberName,
		.memberPhotoAmount {
			display: block;
			color: var(--text--base);
			transform: translateY(0);
			transition: .1s ease-out;
		}

		.memberName {
			display: block;
			margin: 0;
			font-size: 1.4rem;
			font-weight: 700;
			line-height: 2rem;
		}

		.memberPhotoAmount {
			@include style('text', 'breadCrumb');

			display: block;
			font-size: 1.1rem;
			font-weight: 400;
			color: var(--earn-more--snapshot--member-album-small-text);
			transition-delay: .05s;
		}
	}

	.payingSnapshotsContent {
		@include extendEarnMoreStatusHeader;

		text-align: center;

		.statusHeader {
			background: #{colors.get(red, 600)};
			border-radius: vars.border-radius(1);
    		margin: vars.spacer(10) vars.spacer(10) 0 vars.spacer(10);
			padding: vars.spacer(4);
			width: auto;

			.msc-icon {
				top: -0.1rem;
				font-size: 2.4rem;

				&.iconCheck {
					color: var(--gallery--icon-check);
				}

				&.iconWarning {
					color: var(--gallery--icon-warning);
				}
			}

			.clear {
				height: vars.spacer(6);
			}
		}

		.payingSnapshotsTextContainer {
			padding: vars.spacer(7) vars.spacer(10) 0;
			float: left;
			text-align: left;
			width: 100%;

			hr {
				margin-top: vars.spacer(7);
			}

			h3 {
				display: flex;
				align-items: center;
				@include typography.body1Bold;
				margin: 0;
				float: left;
				position: relative;

				@include _on('mobile') {
					margin: 0 0 vars.spacer(4);
				}
			}

			.icon-info-circle {
				margin-left: 1rem;
				font-size: 2.4rem;
				color: var(--gallery--icon-info);

				&:hover {
					color: var(--gallery--icon-info-hover);

					&::before {
						content: icons.get('info-circular-solid');
					}
				}
			}

			a {
				display: inline-block;
				float: right;
			}
		}

		.leftContent {
			@include extendEarnMoreTextContentElements;

			float: left;
			width: 480px;
			padding: 0 40px 15px 40px;

			ul {
				margin: 22px 0 23px;
			}

			i.icon-help {
				position: relative;
				top: 3px;
				right: -5px;
			}
		}

		.rightContent {
			@include extendEarnMoreIncomeInfoBox;
		}

	}

	.payingVideosContent {
		@include extendEarnMoreStatusHeader;

		img {
			display: block;
			width: 950px;
			height: 230px;
		}

		text-align: center;

		.payingVideosTextContainer {

			padding: 30px 0 0 0;
			text-align: left;

			.leftContent {
				@include extendEarnMoreIncomeInfoBox;

				margin: 0 0 0 40px;
				width: 260px;
				padding: 40px;
				height: auto;
			}

			.infoStep {
				float: left;
				display: block;

				&.hideArrow {
					visibility: hidden;
				}
			}

			.infoStepArrowLeft {
				margin: 38px 40px 31px 16px;
			}

			.infoStepArrowRight {
				margin: 38px 16px 31px 40px;
			}

			.stepNumber {
				border-radius: 42px;
				border: 5px solid var(--text--link);
				width: 76px;
				height: 76px;
				float: left;
				font-weight: bold;
				font-size: 50px;
				line-height: 76px;
				color: var(--text--link);
				text-align: center;
				margin: 7px 16px 0 16px;
			}

			.stepText {
				@include style('text', 'hugeSize', 'highliteColor');

				font-weight: bold;
				text-align: center;
				margin: 24px 0 0 0;

				&.hideStepText {
					display: none;
				}
			}

			.rightContent {
				@include extendEarnMoreTextContentElements;

				float: left;
				width: 480px;
				padding: 0 40px 15px 40px;
			}

			.payingVideosText {
				@include style('text', 'regularColor', 'hugeSize');

				margin: 19px 0 15px 0;
			}

			.payingVideosNote {
				color: var(--text--breadcrumb);
				font-size: 12px;
				line-height: 16px;
			}
		}
	}
	#snapshotOverlayContainer {
		color: var(--text--base);
		position: relative;
		margin: auto;
		display: inline-block;

		.closeButton {
			i {
				color: var(--overlay--media-close-button);
				opacity: 1;
			}
			&:hover i {
				color: var(--overlay--media-close-button);
			}
		}
	}
	// Tooltips
	.snapshotInfoTooltip {
		padding: 14px 14px 14px 34px;

		ul li {
			margin: 0 0 6px;
			font-size: 12px;
			line-height: 20px;
			list-style-type: disc;
			color: var(--text--base);
			padding: 0 0 0 5px;
		}
	}

}
@import '../../../scss/shell';

.content {
	text-align: center;
}

.section1 {
	padding: 5.2rem 2rem 4rem;
	background-image: url('./section1_bg.jpg');
	background-size: cover;

	h2 {
		font-size: 3.6rem;
		line-height: 1.2;
		margin: 0 2rem 0.2rem;
	}

	h3 {
		padding: 0 2rem;
		font-size: 2.2rem;
		font-weight: 600;
		line-height: 1.2;
	}

	p {
		font-size: 1.8rem;
		font-weight: 200;
		line-height: 1.4;
		padding: 1.8rem 6rem 0;
		color: var(--earn-more--text);

		strong {
			color: var(--text--base);
		}
	}

	ul {
		margin: 5rem 0 0;
	}

	li {
		display: inline-block;
		margin: 0 0 1rem;
		font-size: 1.6rem;
		font-weight: 600;

		&:nth-child(2) {
			margin: 0 5rem 1rem;
		}

		span {
			display: inline-block;
			width: 3.6rem;
			height: 3.6rem;
			margin: 0 1rem 0 0;
			border: 0.2rem var(--text--link) solid;
			border-radius: 50%;
			color: var(--text--link);
			text-align: center;
			line-height: 3.1rem;
			font-size: 2rem;
			vertical-align: middle;

			i {
				color: var(--text--link);

				@include _vertical-gradient(
					var(--earn-more--text-gradient-start),
					var(--earn-more--text-gradient-stop),
					0%, 100%,
					true
				);
			}
		}
	}

	.growth {
		font-size: 1.8rem;
	}

	.membersPlus {
		font-size: 1.3rem;
	}
}

.section2,
.section3 {
	h4 {
		margin: 0 0 1rem;
		font-size: 2.2rem;
		line-height: 1.2;
		text-transform: uppercase;
	}

	p {
		color: var(--earn-more--text);
		font-size: 1.6rem;
		font-weight: 200;
		line-height: 1.3;

		strong {
			color: var(--text--base);
		}
	}
}

.section2 {
	padding: 4.2rem 0 0;
	background-image: url('./section2_bg.jpg');
	background-repeat: repeat-x;

	h4 {
		display: inline-block;
		margin: 0 0 0.2rem;
	}
}

.section3 {
	display: flex;
	width: 100%;
	min-height: 36.5rem;
	background: var(--earn-more--section-bg);

	.sharingImage {
		width: 53%;
		background-image: url('./section3-bg.png');
		background-repeat: no-repeat;
		background-position: 4.2rem 3.6rem;
		background-size: 87%;
	}

	.sharingContent {
		width: 47%;
		padding: 5.4rem 4rem 4rem 0;
		text-align: left;

		ul {
			position: relative;
			text-align: left;

			li {
				padding: 0 0 1.6rem 1.2rem;
				font-size: 1.6rem;
				font-weight: 200;
				line-height: 1.5;
				color: var(--earn-more--text);

				&:before {
					position: absolute;
					content: "\2022";
					color: var(--text--base);
					left: 0;
				}
			}
		}

		p {
			margin: 0 0 4.2rem;
		}
	}
}

.section4 {
	padding: 4rem 2rem;
}

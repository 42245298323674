@import '../../../scss/shell';

.kingOfTheRoom {
	.section1 {
		position: relative;
		text-align: center;
		padding: 0 40px 48px;
		background: url('./king_section1.jpg') center no-repeat;

		.crown {
			position: relative;
			margin: -48px 0 -8px;
		}

		p {
			font-size: 18px;
			margin: 0 0 1px;
		}

		h1 {
			margin: 0 0 24px;

			strong {
				position: relative;
				font-size: 46px;
				color: var(--earn-more--king-of-the-room--title-gradient-from);
				@include _vertical-gradient(
						var(--earn-more--king-of-the-room--title-gradient-from),
						var(--earn-more--king-of-the-room--title-gradient-to),
						0%, 100%,
						true
				);
			}
		}
	}

	.section2 {
		display: flex;
		padding: 56px 20px 0 0;
		@include _vertical-gradient(
			var(--earn-more--king-of-the-room--section-bg),
			var(--earn-more--section-bg)
		);

		div {
			padding: 0 0 0 20px;
		}

		img {
			width: 50%;
			height: 50%;
			max-width: 443px;
			max-height: 312px;
			align-self: flex-end;
		}

		ul {
			margin: 35px 0;
		}

		li {
			position: relative;
			margin: 0 0 15px;
			padding: 8px 0 8px 46px;
			text-align: left;
			color: var(--earn-more--text);

			p {
				font-size: 16px;
			}

			span {
				position: absolute;
				top: 0;
				left: 0;
				display: inline-block;
				width: 36px;
				height: 36px;
				border: 2px var(--text--link) solid;
				border-radius: 50%;
				color: var(--text--link);
				text-align: center;

				i {
					position: relative;
					top: 7px;
					font-size: 17px;
					color: var(--text--link);

					@include _vertical-gradient(
						var(--earn-more--text-gradient-start),
						var(--earn-more--text-gradient-stop),
						0%, 100%,
						true
					);

					&.star {
						top: 5px;
						font-size: 20px;
					}

					&.money {
						top: 5px;
						font-size: 22px;
					}
				}
			}
		}
	}

	.section3 {
		display: flex;
		padding: 0 0 60px 38px;
		@include _vertical-gradient(
			var(--earn-more--king-of-the-room--section-bg-gradient),
			var(--earn-more--king-of-the-room--section-bg)
		);

		div {
			padding: 56px 0 0;
			width: 50%;
		}

		img {
			width: 50%;
			height: 50%;
		}

		ul {
			margin: 42px 0 0;
		}

		li {
			list-style: disc;
			margin: 0 0 24px 18px;
			text-align: left;
			font-size: 14px;
			color: var(--text--link);

			p {
				font-size: 16px;
			}
		}
	}

	.section1,
	.section2,
	.section3 {
		h2 {
			font-size: 22px;
			text-transform: uppercase;
		}

		p {
			font-weight: 200;
			line-height: 1.3;
			color: var(--earn-more--text);

			strong {
				color: var(--text--base);
			}
		}
	}
}
/* Tables CSS */
table {
	border-collapse:separate;
	border-spacing:2px 2px;

	tbody {
		display: table;
		width: 100%;
	}

	tr {
		width: 100%;
	}
}

.table {
	@include commonTable;
}

// Konkret cellaformazo classok //

.tableHeaderCell {
	@include style('table', 'cell', 'headerBg', 'headerText');
	white-space: nowrap;
}

.tableRowNumberCell {
	@include style('table', 'cell', 'highliteBg', 'summarizeSmall');
}

.tableRegularCell {
	@include style('text', 'normalSize');
	@include style('table', 'cell', 'regularBg', 'summarizeSmall', 'normalText');
}

.tableLighterRegularCell {
	@include style('table', 'cell', 'regularBg');
	@include style('text', 'normalSize', 'regularColor');
}

.tableHighlightedCell {
	@include style('table', 'cell', 'highliteBg');
	@include style('text', 'normalSize', 'highliteColor', 'bold');

	a {
		@include style('text', 'smallSize');
		@include style('table', 'smallTextColor');

		line-height: 40px;
	}
}

.tableSummarizeContentCell {
	@include style('table', 'cell', 'regularBg', 'summarizeSmall', 'summarizeColor');

	&.selected {
		@include style('text', 'normalSize', 'highliteColor', 'bold');
	}
}

.tableLinkContentCell {
	@include style('table', 'cell', 'regularBg');
}

.tableContentDetailsCell {
	@include style('text', 'normalSize');
	@include style('table', 'cell', 'highliteBg', 'normalTextColor');
}

.tableSummarizeDateCell {
	@include style('table', 'cell', 'highliteBg');
	@include style('text', 'normalSize', 'highliteColor', 'bold');
}

.tableSummarizeLastCell {
	@include style('text', 'normalSize', 'highliteColor', 'bold');
	@include style('table', 'cell', 'highliteBg', 'summarizeColor', 'summarizeNormal');
}
/** BEM global reusable styles that implement flexbox.
 * TODO: maybe after migrating to Phrame 2 we don't need them
 * or we can refactor them
 */

.gdh {
	&-flex--row {
		@include _flexbox();
		@include _flexbox-direction(row);
		@include _flexbox-wrap(wrap);
		@include _flexbox-justify-content(space-between);
		@include _flexbox-align(center);
	}

	&-flex--col {
		@include _flexbox();
		@include _flexbox-direction(column);
		@include _flexbox-align(stretch);
	}

	&-flex__item {
		@include _flexbox-grow(1);
	}
}

@import '../../scss/shell';

:global {

	// Crop overlay
	#cropOverlayContainer {
		user-select: none;
		max-width: 100vw;
		min-width: 92rem;
		width: auto !important;
		margin: 0;
		padding: 0 0 2rem;
		text-align: center;

		button {
			margin: 2rem auto 0;
			display: block;
		}

		@include _on('small', 'down') {
			width: 100vw !important;
			min-width: auto;
			min-height: 100vh;
			border-radius: 0;
		}

		.stepsBlock {
			margin: 2.2rem 2rem;

			@include _on('small', 'down') {
				margin: 1.3rem 2rem 5.8rem;
			}

			span {
				display: none;
				width: 2.1rem;
				height: 2.1rem;
				line-height: 2rem;
				margin-right: 0.8rem;
				text-align: center;
			}

			span.step-rank {
				display: inline-block;
				font-size: 1.2rem;
				border-radius: 50%;
				border: 0.1rem solid var(--registration--step);
				color: var(--registration--step);
			}

			ul {
				display: flex;
				justify-content: center;

				@include _on('small', 'down') {
					min-width: auto;
					position: relative;
				}

				li {
					flex: 1;
					display: flex;
					font-weight: bold;
					font-size: 1.6rem;
					line-height: 2.5rem;
					margin: 0 2rem 0 0;
					color: var(--common--success);
					text-align: left;
					max-width: 20rem;

					@include _on('small', 'down') {
						min-width: auto;
						margin: 0;
						flex: 0;
					}

					&.active {
						color: var(--stepcrumb--modelreg--active);

						span.step-rank {
							background: var(--registration--step-active-bg);
							color: var(--registration--step-active);
							border-color: var(--registration--step-active-border);
						}

						& ~ li {
							color: var(--registration--step-text);
							opacity: 1;
						}
					}

					&.done,
					&.stepDone {
						span {
							display: inline-block;

							i {
								position: relative;
								top: 0.3rem;
								font-size: 2.1rem;
							}
						}

						.step-rank {
							display: none;
						}

						em {
							color: var(--icon--ok--green);
						}
					}

					&:nth-child(2) {
						justify-content: center;
					}

					&:last-child {
						margin: 0;
						justify-content: flex-end;
					}

					.step-info {
						padding-top: 0.1rem;
					}

					em {
						line-height: 1.6rem;
						display: inline-block;
					}

					p {
						color: var(--registration--step-text);
						font-size: 1.4rem;
						line-height: 1.6rem;
						font-weight: normal;
					}

					@include _on('small', 'down') {
						&:first-child {
							margin-left: 1rem;
						}

						.step-info {
							display: none;
						}

						p {
							left: 0;
						}

						&.active .step-info {
							display: inline;
							position: absolute;
							top: 3.1rem;
							left: 50%;
							transform: translate(-50%, 0);
							width: 100%;
							text-align: center;
						}

						&:after {
							content: '';
							display: inline-block;
							position: relative;
							width: 1rem;
							height: 0.1rem;
							top: 1.3rem;
							margin: 0 1.3rem 0 0.5rem;
							background: var(--stepcrumb--dash);
						}

						&.done:after,
						&.stepDone:after {
							background: var(--icon--ok--green);
						}

						&:last-child:after {
							display: none;
						}
					}
				}
			}
		}
	}

	.bimbim-theme,
	.mainstream-theme,
	.docler-theme {
		#cropPicture.window-resizing {
			background: url('./crop_loader_jasmin.svg') no-repeat center center !important;
		}
	}

	.oranum-theme {
		#cropPicture.window-resizing {
			background: url('./crop_loader_oranum.svg') no-repeat center center !important;
		}
	}

	#cropPicture {
		@include _flexbox-inline();
		@include _flexbox-justify-content(center);

		align-items: center;
		-webkit-align-items: center;
		position: relative;
		overflow: hidden;
		z-index: 1;

		&.window-resizing {
			background: url('./crop_loader.gif') no-repeat center center !important;

			img, canvas {
				opacity: 0.3 !important;
			}
		}

		img, canvas {
			opacity: 0;
			display: block;
			margin: 0 auto;
			height: auto;
			max-height: calc(100vh - 23rem);
			min-height: 200px;
			position: relative;
			z-index: -1;
			pointer-events: none;

			@at-root :global .ext-gecko #cropPicture img {
				width: 100%;
			}

			@include _on('small', 'down') {
				max-height: calc(100vh - 32rem);
				max-width: 100vw;
				min-height: 0;
			}

			&.loading {
				opacity: 0;
			}
		}

		canvas {
			display: none;

			&.show {
				display: block;
			}
		}

		// Crop tool
		.tool {
			box-shadow: 0 0 0 2000px RGBA(var(--gallery--shadow-rgb), 0.9);
			position: absolute;
			left: 0;
			top: 0;
			width: 320px;
			height: 180px;
			z-index: 2;
			cursor: move;
			border: 2px dashed var(--gallery--crop-tool);
			display: none;
			background: transparent;

			&.resizingTransition {
				transition: height, 0.2s, linear;
			}
			&.show {
				display: block;
				border-color: var(--gallery--crop-tool);
			}

			&:active {
				cursor: move;
			}
		}

		.resize {
			display: block;
			position: absolute;
			width: 12px;
			height: 12px;
			right: -7px;
			bottom: -7px;
			background: var(--gallery--crop-tool);
			border-radius: 20px;
			cursor: se-resize;
			pointer-events: all;
			z-index: 2;

			@include _on('small', 'down') {
				width: 25px;
				height: 25px;
			}

			&.down {
				right: 50%;
				cursor: s-resize;

				&:active {
					cursor: s-resize;
				}
			}

			&:active {
				cursor: se-resize;
			}
			&:before {
				display: block;
				position: relative;
				content: " ";
				width: 200%;
				height: 200%;
				left: -50%;
				top: -50%;
				border-radius: 50%;
				opacity: 0;
			}
		}
	}
}
@use 'global/colors';
@use 'global/vars';
@import '../../../../scss/shell';
@import 'common';
@include videoBox;

.container {
	position: relative;
}

.listContainer {
	padding-top: vars.spacer(9);
}

.moreInfoWrapper {
	text-align: center;
	padding: 0 2.4rem;
	margin-top: 4rem;

	@include _on('mobile') {
		margin-top: 0;
	}
}

.moreInfo {
	display: inline-block;
	font-size: 1.6rem;
	margin: 1.7rem 0 4rem;
}

.filterWrapper {
	display: flex;
	justify-content: flex-end;
	position: absolute;
	right: 0;
	margin: 1.5rem 2.4rem 0;

	@include _on('mobile') {
		display: block;
		position: relative;
		width: calc(100% - 3.2rem);
		margin: 1.6rem;
	}

	:global {
		.Dropdown-root {
			width: 22rem;

			@include _on('mobile') {
				width: 100%;
			}
		}
	}
}

:global .promotionToolsUpdateOverlay {
	.breadCrumbsTitle {
		color: var(--box--header-text);
	}

	@include _on('mobile') {
		.button,
		form-submit div {
			width: 100%;
		}

		.button {
			margin-bottom: 1rem;
		}
	}
}

.loadingWrapper {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 30vh;
}

.tipSlidesContainer {
	width: 100%;
	padding: 3.2rem 0 2.6rem;
	background: var(--promo-tools--tipslides-bg);

	@include _on('mobile') {
		padding: 2.4rem 0;
	}

	h2 {
		font-size: 2.4rem;
		line-height: 2.8rem;
		margin: 0 2.4rem;
	}
}

.tipSlides {
	margin: 2.4rem 1.3rem 0;

	@include _on('mobile') {
		margin: 2.4rem 1.1rem 0;
	}
}

.oranum {
	.filterWrapper {
		.txt {
			font-size: 1.4rem;
			color: #{colors.get(purple, 600)};
			font-weight: normal;
		}
	}
	:global {
		.Dropdown-control {
			cursor: pointer;
			border: 0.1rem solid #{colors.get(purple, 300)};
			border-radius: 0.4rem;
		}
		.Dropdown-control:before {
			border-radius: 0.3rem;
			background: #{colors.get(purple, 500)};
		}
		.Dropdown-menu {
			box-shadow: 0 0.2rem 0.4rem #{colors.get(neutral, 900, 0.3)};
			border-radius: 0;
		}
		.Dropdown-option {
			color: #{colors.get(purple, 500)};
		}
		.Dropdown-option.is-selected {
			color: #{colors.get(decorative, violet_500)};
		}

		.Dropdown-option:hover {
			color: #{colors.get(purple, 700)};
			background: #{colors.get(purple, 100)};
		}
		.Dropdown-arrow {
			border-color: white transparent transparent;
		}

		.is-open .Dropdown-arrow {
			top: 1.5rem;
			border-color: transparent transparent white;
		}
	}
}

@use 'components/Button/button';
@use 'global/colors';
@use 'global/icons';
@use 'global/typography';
@use 'global/vars';
@import '../../../scss/shell';

:global {
	#modelFilterContainer li,
	#studioFilterContainer li {
		a {
			color: var(--models--filter-text);
			text-transform: uppercase;
			padding: 29px 25px 25px 17px;
			font-size: 12px;
			line-height: 21px;

			&:hover,
			&.active {
				color: var(--text--base);

				span {
					color: var(--text--base);
				}
			}

			&.active {
				font-weight: normal;
				background-color: var(--box--main-bg);

				span {
					box-shadow: 0 1px 0 RGBA(var(--primary-rgb), 0.3);
					background-color: var(--background);
				}
			}

			&.allModels,
			&.allStudios {
				padding: 29px 25px 25px 30px;
			}
		}
	}

	#modelEmptyContainer,
	#studioEmptyContainer {
		@include style('box', 'common');
		padding: 60px 30px;
		text-align: center;

		.figure i {
			display: block;
			margin: 30px 0 20px;
			font-size: 133px;
			color: var(--models--empty-figure);
		}

		h1 {
			font-weight: bold;
			font-size: 30px;
			line-height: 40px;
			color: var(--text--base);
		}

		> p {
			@include style('text', 'hugeSize', 'passiveColor');

			font-size: 18px;
			line-height: 38px;
			margin: 0 0 13px 0;
		}

		#addNewModel {
			position: relative;
		}

		.tooltip p {
			text-shadow: none !important;
		}

		#guide2Holder {
			position: relative;
			display: inline-block;
			width: 1px;
			vertical-align: middle;
			height: 34px;
		}
	}

	#studioListContainer,
	#modelListContainer {
		@include style('box', 'common');

		background: transparent;
		text-align: center;
		padding: 0 2rem 2rem 2rem;

		//start - order by select ikonja, legorduloje
		.selectArrow {
			top: 3px;
			right: 3px;
		}

		.scrollContainer {
			top: 38px;
		}

		//end
		.inputs {
			margin: 0 10px 0 0;
		}

		.stickyElementWrapper {
			z-index: 46;
		}

		.filterStickWrapper {
			background: var(--box--main-bg);
			padding: 20px 0;
		}

		form {
			.inputs {
				width: 16rem;
				margin-bottom: 0.5rem;

				&.filterDropdown {
					width: 17rem;
				}
				&.smallField .dropDownContainer {
					min-width: 130px;
					width: auto;
					.slideContainer {
						width: auto;
						.listContainer {
							width: auto;
							li {
								white-space: nowrap;
							}
						}
					}
				}
				.scrollContainer {
					width: auto;
				}
				.simplebar-scroll-content {
					overflow: hidden;
				}
				.simplebar-track.horizontal {
					display: none;
				}
			}

			.selectContainer {
				float: none;
			}

			input {
				width: 100%;
			}
		}

		@include _on('small', 'down') {
			form .inputs {
				width: 100%;

				&.filterDropdown {
					width: 100%;
				}
			}
		}

		#addNewModel {
			position: relative;
			float: right;

            i {
                font-size: 20px;
            }
		}
		.primaryLargeWithIcon {
			float: right;
		}

		#showMoreHolder {
			margin: 3rem 0 0 0;
		}

		.manageBox {
			@include style('box', 'common', 'info', 'manageBox');
			display: flex;
			height: 60px;
			margin: 5px 0 0 0;

			/*Ha a model statusza barmelyik passziv allapot*/
			&.passive {
				.modelDataBox .modelText,
				.studioDataBox .studioText {
					opacity: 0.6;
				}
				.modelStatusBox,
				.studioStatusBox {
					color: var(--text--secondary);
				}
			}

			/*Ha a model statusza aktiv*/
			&.active {
				.modelStatusBox,
				.studioStatusBox {
					color: var(--models--manage-active-status);
				}
			}

			/*Ha a model statusza online*/
			&.online {
				.modelDataBox,
				.studioDataBox {
					img {
						border-left: 2px solid var(--models--manage-image-online-border);
					}
				}
				.modelStatusBox,
				.studioStatusBox {
					color: var(--models--manage-active-status);
				}
			}
		}

		.modelStatusDetails {
			flex: 1;
		}

		/* Model adatai doboz */
		.modelDataBox,
		.studioDataBox {
			display: flex;
			align-items: center;
			flex-basis: 35%;
			border-right: 1px solid var(--box--main-bg);
			padding: 0.2rem 1rem;
			text-align: left;

			@include _on('mobile') {
				min-width: 130px;
				padding: 9px 4px;
			}

			.emptyProfileImage {
				float: left;
				width: 40px;
				height: 40px;
				margin-top: 2px;
				text-align: center;
				border-radius: 50%;
				background: var(--members--empty-profile-bg);

				i {
					position: relative;
					font-size: 40px;
					color: var(--members--empty-profile-icon);
				}
			}

			.profileImage {
				float: left;
				width: 40px;
				height: 40px;
				margin-top: 2px;
				border-radius: 50%;
				background-position: center;
			}
		}

		.modelText,
		.studioText {
			display: flex;
			flex-direction: column;
			float: none;
			padding: 0 0 0 1rem;

			a, span {
				display: inline-block;
				font-weight: bold;
				font-size: 15px;
				line-height: 1.2;
				width: 100%;
				height: auto;
				overflow: hidden;
				word-break: break-word;
			}

			span {
				color: var(--text--link);
			}
			.screenName {
				font-weight: 100;
				display: inline;
				@include _on('mobile') {
					font-size: 11px;
				}
			}

			p {
				@include style('text', 'normalSize', 'highliteColor');

				max-height: 16px;
				overflow: hidden;
				margin-top: 0.3rem;
			}

			@include _on('mobile') {
				a {
					font-size: 13px;
				}
				p {
					font-size: 10px;
				}
			}
		}

		/* Statusz doboz */
		.modelStatusBox,
		.studioStatusBox,
		.exclusiveModelBox {
			float: left;
			border-right: 1px solid var(--box--main-bg);
			padding: 0 12px;
			text-align: center;
			font-weight: bold;
			font-size: 10px;
			overflow: hidden;
			line-height: 60px;
			text-transform: uppercase;

			.innerBox {
				display: table-cell;
				vertical-align: middle;
				width: 100%;

				@include _on('small', 'up') {
					min-width: 70px;
				}
			}

			@include _on('mobile') {
				border-right: 0;
			}

            i {
                font-size: 2rem;

                @include _on(0, 340) {
                    font-size: 1.6rem;
                }
            }
		}

		.exclusiveModelBox {
			float: none;
			height: 6rem;
			max-width: 14rem;
			display: flex;
			align-items: center;
			line-height: 1.3;
		}

		.exclusiveModelBox .innerBox {
			color: var(--text--base);
			min-width: auto;
			text-transform: none;
			font-weight: normal;
			font-size: 12px;
		}

		/* Jobb oldali dobozok */
		.modelManageBox,
		.studioManageBox {
			display: block;
			float: right;
			border-left: 1px solid var(--box--main-bg);
			padding: 20px;
			text-align: center;
			font-size: 11px;
			line-height: 20px;
			height: 60px;
			color: var(--models--icon);

			@include _on('mobile') {
				padding: 2rem 0.8rem;
			}

			@include _on(0, 340) {
				padding-left: 0.3rem;
				padding-right: 0.3rem;
			}

			&.cancel {
				color: RGBA(var(--text--link-rgb), 0.4);

				&:hover {
					color: var(--text--base);
				}
			}

			&.selected {
				background-color: var(--form--input-background);
				border-bottom: 1px solid var(--form--input-background);
			}

			i {
                color: var(--models--icon);
				display: block;
                font-size: 2.4rem;
				width: 2.4rem;

                @include _on('mobile') {
                    font-size: 2rem;
                    width: 2rem;
				}

				@include _on(0, 340) {
                    font-size: 1.6rem;
                    width: 1.6rem;
                }
			}

			&:hover {
                i {
                    color: var(--models--icon-hover);
                }

                .icon-info-circular-outline::before {
                    content: icons.get('info-circular-solid');
                }
                .icon-chart-lines-outline::before {
                    content: icons.get('chart-lines-solid');
                }
                .icon-settings-outline::before {
                    content: icons.get('settings-solid');
                }
                .icon-eye-on-outline::before {
                    content: icons.get('eye-on-solid');
                }
                .icon-close-outline::before {
                    content: icons.get('close-solid');
                }
                .icon-play-outline::before {
                    content: icons.get('play-solid');
                }
			}

            &.selected {
				
                i {
                    color: var(--models--icon-hover);

                    &.icon-info-circular-outline::before {
                        content: icons.get('info-circular-solid');
                    }
                    &.icon-chart-lines-outline::before {
                        content: icons.get('chart-lines-solid');
                    }
                    &.icon-settings-outline::before {
                        content: icons.get('settings-solid');
                    }
                }
			}

			&:active {
				color: var(--models--icon-active);
			}

			&.disabledIcon {
				pointer-events: none;

				i {
					opacity: 0.3;
				}
			}
		}

		.viewBoxContainer {
			float: right;
		}

		.extendedModelSettingsBlock {
			flex-direction: column;

			.modelSettingsRow {
				display: flex;

				@include _on('mobile') {
					flex-direction: column;
				}

				&:first-of-type {
					margin-bottom: 1rem;
				}
			}
		}

		/* Lenyilo panel */
		.modelDataContainer,
		.studioDataContainer {
			@include style('box', 'common', 'info');

			.shadowBox {
				background: url('../../App/gfx_gradients_sprite.png') repeat-x 0 -672px;
			}

			width: 100%;
			padding: 0;
			border-radius: 5px;
			background-color: var(--background);
			display: none;
			margin: 0;
			float: none;

			&.activeDataCt {
				display: block;
				overflow: hidden;
			}

			.progressIndicator {
				background: none;
				width: 100%;
				height: 200px;
			}
			.modelDataInnerCt,
			.studioDataInnerCt {
				display: none;
				float: none;

				&.activeDataInnerCt {
					display: block;

					&.flexDataInnerCt {
						display: flex;
						margin: 2rem;

						@include _on('mobile') {
							flex-direction: column;
						}
					}
				}

				.infoDetailsLargeBox,
				.infoDetailsBox,
				.statDetailsBox,
				.settingsDetailsBox {
					border-radius: 5px;
					border: 2px solid var(--models--info-box-border);

					//A doboz neve
					.detailsHeaderText {
						@include style('text', 'normalSize', 'passiveColor');

						text-transform: uppercase;
						font-weight: bold;
					}

					//A doboz kozepso fo szovege
					.detailsMainData {
						display: inline-block;
						position: relative;

						h4 {
							margin: 0;
							text-transform: uppercase;
						}

						.help {
							margin: -3px 0 0 0;
						}
					}
				}

				// Az info ful szeles dobozai
				.infoDetailsLargeBox {
					height: 120px;
					background-color: var(--models--quality-info-bg);
					padding: 20px 10px;

					&.bad {
						border: 2px solid var(--models--bad-rating-border);
					}

					.detailsHeaderText {
						@include style('text', 'hugeSize', 'passiveColor');
					}

					.detailsMainData {
						margin: 15px 0 17px 0;
					}

					h3 {
						text-transform: uppercase;
						margin: 0;
						float: left;
					}

					.ok,
					.icon-ok {
						position: relative;
					}

					.icon-alert {
						top: 0;
						position: relative;
						@include style('icon', 'yellowColor', 'normalSize');
					}

					.ok,
					.icon-ok,
					.help {
						margin: 0;
					}

					.ok,
					.icon-ok,
					.icon-alert,
					.icon-minus-solid {
						right: 8px;
						float: left;
					}

					.icon-minus-solid {
						position: relative;
						top: 0.1rem;
						font-size: 1.8rem;
						color: var(--models--no-rating-text);
					}

					.infoDetailsBottomText {
						display: block;
						font-weight: bold;
						font-size: 14px;
						line-height: 14px;
					}

					.infoDetailsAlert {
						display: inline-block;
						color: var(--models--bad-rating-text);
						margin: 0 5px 0 0;
					}

					.infoDetailsNoRate {
						color: var(--models--no-rating-text);
					}
				}

				// Az info ful normal dobozai
				.infoDetailsBox {
					padding: 25px 0;

					.detailsMainData {
						margin: 16px 0 0 0;
					}

					h4 {
						float: left;
					}

					.ok,
					.icon-ok,
					.icon-alert,
					.icon-minus-solid {
						top: -3px;
						right: 8px;
						float: left;
					}

					.icon-minus-solid {
						position: relative;
						top: 0.1rem;
						font-size: 1.8rem;
						color: var(--models--no-rating-text);
					}

					&.bad {
						padding: 23px 0;
						border: 2px solid var(--models--bad-rating-border);
						background-color: var(--box--header-background);

						.icon-alert {
							position: relative;
							@include style('icon', 'yellowColor', 'normalSize');
							left: -8px;
							top: -2px;
						}
					}
				}

				// Az stat ful dobozai
				.statDetailsBox {
					padding: 20px 0;
					height: 122px;

					i {
						font-size: 2.4rem;
						top: 40%;
					}

					.detailsMainData {
						margin: 16px 0 14px 0;
					}

					.detailCounterTitle {
						color: var(--text--secondary);
						margin-bottom: 10px !important;

						span {
							text-transform: lowercase;
							color: var(--text--base);
						}
					}

					.detailCounterRow {
						width: 100%;
						height: 40px;
						text-align: center;

						hr {
							display: inline-block;
							float: none;
							height: 100%;
							width: 2px;
							margin: 0 20px;
							background: var(--models--info-box-border);
							border: none;
						}
					}

					.detailCounterItem {
						display: inline-block;
						text-align: left;
						position: relative;
						top: -10px;

						&:first-child {
							text-align: right;
						}

						span {
							color: var(--text--base);
							font-weight: bold;
							font-size: 12px;
							line-height: 12px;
						}
					}

					.showMoreStatsLink {
						font-size: 12px;
						display: block;
						margin: 2px 0;
					}
				}

				.settingsDetailsBox {
					flex: 1;
					margin-left: 1rem;
					padding: 2.1rem 1.6rem 2.4rem;
					position: relative;

					@include _on('mobile') {
						margin: 1rem 0 0;
					}

					&:first-of-type {
						margin: 0;
					}

					.detailsMainData {
						margin: 17px 0 0 0;
					}

					input {
						position: absolute;
						bottom: 40px;
						left: 92px;
					}

					.highlightedNormalText {
						margin: 14px 0 16px 0;
					}

					[data-is=toggle-on-off] {
						margin-top: 1.5rem;
					}

					.icon-info-circular-outline {
                        color: var(--models--icon);
                        position: absolute;
                        right: -3rem;
                        top: 50%;
                        transform: translateY(-50%);
                        z-index: 3;
						top: 45%;
						font-size: 2.4rem;

                        &:hover {
                            color: var(--models--icon-hover);

                            &::before {
                                content: icons.get('info-circular-solid');
                            }
                        }
					}
				}
				.settingsDetailsBox-resetPromo {
					.resetsUsed {
						color: var(--promo-period-reset--used);
						font-size: 1.6rem;
						font-weight: bold;
						margin: -1.1rem 0 0.5rem;

						&.resetsLimitReached {
							color: var(--promo-period-reset--reached);
						}
						&.resetsLimitOverflowed {
							color: var(--promo-period-reset--overflowed);
						}
					}

					.button {
						margin-top: 0.8rem;
					}

					.highlightedNormalText {
						position: relative;
						font-weight: normal;
						display: inline-block;
					}

					.icon-info-circular-outline {
						position: initial;
						margin-left: 0.5rem;
						vertical-align: text-bottom;
					}
				}
			}
		}
	}

	.unfinishedContent {
		padding: 30px 50px;
		text-align: center;

		@include _on('mobile') {
			padding-left: 15px;
			padding-right: 15px;
		}
	}

	.unfinishedSelectorBox {
		height: 320px;
		margin: 5px;
		padding: 0;
		overflow: hidden;
		position: relative;

		&.hideAjaxCt {
			overflow: hidden;

			.statusTypeContainer {
				z-index: 5;
			}
		}

		&.addSwitch {
			height: 39rem;
		}

		&.single {
			float: none;
			margin: 0 auto;
		}

		.scroll-pane {
			padding: 0 5px;
		}

		.statusTypeContainer {
			border-radius: 4px;
			width: 100%;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
			padding: vars.spacer(12) 2rem;
			background-color: var(--box--info-bg);

			&.rejected {
				border: 2px solid var(--text--rejected);
			}

			i {
				color: var(--box--status-type--icon);
			}

			.icon-person {
				font-size: 7rem;
				margin: 0 auto 2rem;
				display: block;
			}

			.icon-profile-add-solid {
                color: var(--models--profile-add-icon);
				font-size: 6.4rem;
				margin: 0 auto vars.spacer(6);
				display: block;
			}

			.icon-time {
				display: block;
				margin: 2rem auto;
				font-size: 7rem;
			}

			.icon-error {
				display: block;
				margin: 2rem auto;
				font-size: 7rem;
			}

			.icon-alert {
				display: inline-block;
				font-size: 7rem;
				margin: 2rem auto;
				color: var(--common--warning);
			}

			.alertText {
				font-size: 1.2rem;
				margin: 2rem 0;

				.icon-alert {
					font-size: 2rem;
					margin: 0 0.5rem 0 0;
					vertical-align: bottom;
				}
			}

			h3 {
                @include typography.h1Bold;
				margin: 0;
				color: var(--box--status-type--gender);
			}

			.personName {
				h3 {
					font-weight: bold;
					font-size: 18px;
					line-height: 18px;
					display: inline-block;
				}

				p {
					font-size: 18px;
					line-height: 18px;
					color: var(--box--status-type--person);
					display: inline-block;
					margin: 0;
				}
			}

			.waitingApprovalText {
				font-weight: bold;
				font-size: 12px;
				line-height: 1.4;
				color: var(--text--waiting);
				margin: 2rem 0;
			}

			.noRegisteredText {
				@include style('text', 'largeSize', 'regularColor');
                color: var(--models--choose-type-text);
                @include typography.body1Regular;
				margin: 10px 0 vars.spacer(6) 0;
			}

			.button {
				width: auto;
			}

			.rejectedText {
				font-weight: bold;
				font-size: 12px;
				line-height: 14px;
				color: var(--text--rejected);
				margin: 2rem 0;
			}

			.emailAddressText {
				@include style('text', 'smallSize', 'passiveColor');
				overflow: hidden;
				text-overflow: ellipsis;
				margin: 9px 0 30px 0;
				&.new {
					margin: 9px 0;
				}
			}

			.linkText {
				font-weight: bold;
				font-size: 14px;
			}

			.ok,
			.icon-ok {
				position: relative;
				display: inline-block;
				margin: 0;
				right: 3px;
				top: 2px;
			}

			.choose {
				position: relative;
				display: inline-block;

				&:hover {
					.helpTipContainer {
						display: block;
					}
				}

				.helpTipContainer {
					margin: 0;
					width: 236px;
					right: 0;
					top: 34px;

					.tooltip {
						margin: 10px 0 0 0;
						padding: 0;
						width: 236px;

						.arrow {
							width: 10px;
							height: 6px;
							top: 4px;
							left: 160px;
							background-position: -152px -41px;
						}

						p {
							margin: 10px !important;
						}
					}
				}
			}
		}

		.statusInfoContainer {
			border-radius: 4px;
			width: 100%;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
			z-index: 1;
			background-color: var(--box--info-bg);
		}

		//A status info tartalom elemei
		.statusInfoRow {
			@include style('text', 'hugeSize');

			background-color: var(--models--status-info-bg);
			color: var(--models--status-info-text);
			height: 65px;
			width: 100%;
			border-bottom: 1px solid var(--box--main-bg);
			line-height: 65px;
			text-align: left;
			float: left;
			clear: both;

			&.first {
				border-radius: 4px 4px 0 0;
				padding: 1px 0 0 0;
			}
			&.last {
				border-radius: 0 0 4px 4px;
				padding: 0 0 1px 0;
				border: 0;
			}
			.icon-ok {
				position: relative;
				float: left;
				margin: 0 2rem;
			}
			.clock {
				margin: 0 1.6rem 0 2rem;
				display: inline-block;
				top: 0.5rem;
			}
			.icon-alert {
				position: relative;
				float: left;
				margin: 0 2rem;
				@include style('icon', 'yellowColor', 'normalSize');
			}
			.statusInfoView {
				@include style('text', 'smallSize', 'passiveColor');

				position: relative;
				top: 25px;
				font-weight: bold;
				float: right;
				margin: 0 2rem 0 0;

				&:hover {
					color: var(--text--base);
				}
			}
			.secondarySmall {
				float: right;
				margin: 13px 11px 0 0;
			}

			a {
				margin-left: 10px;
			}
		}
	}

	//az uj model ablak formazasai
	#selectIndividual,
	#newModelContent {
		padding: 30px;
		text-align: center;

		@include _on('mobile') {
			padding: 2rem;
		}

		@include _on('small', 'down') {
			.snss__cell {
				display: block;
			}
		}

		.category-select {
			position: relative;
			z-index: 5;
		}

		.selectPersonExplanationText {
			@include style('text', 'text.white', 'hugeSize');
			text-align: left;
			line-height: 32px;
		}

		.errorMessageBox {
			position: absolute;
			width: 100%;
			left: 0;
			top: 66px;
			text-align: center;
			font-weight: bold;
			font-size: 12px;
			line-height: 12px;
			color: var(--models--choose-existing-alert-text);

			.icon-alert {
				color: var(--models--choose-existing-alert-icon);
			}

			.error {
				display: inline-block;
				margin: 0 3px 0px -21px;
				top: 3px;
				position: relative;
				left: 0;
			}
		}

		form-selectorbox {
			.humanFigureLarge {
				margin: 0 auto 30px;
			}

			h3 {
				margin-top: 0;
			}

			.typeText {
				margin: 2px 0 0;
			}

			.addBioData {
				margin: 15px 0 0 0;
			}

			.emailAddressText {
				@include style('text', 'smallSize', 'passiveColor');
				overflow: hidden;
				text-overflow: ellipsis;
				margin: 17px 0 0;
			}
		}

		.personSearchBox {
			width: 100%;

			.searchInput {
				width: 100%;
				background-color: var(--form--input-background);
				background-image: url(data:image/svg+xml;base64,CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB3aWR0aD0iNTEyIiBoZWlnaHQ9IjUxMiI+PHBhdGggZmlsbD0iI0NBNDY0NiIgZD0iTTQ5Ni4xMzEgNDM1LjY5OEwzNzQuODU1IDMzMi41NTFjLTEyLjUzNy0xMS4yODMtMjUuOTQ1LTE2LjQ2My0zNi43NzYtMTUuOTYzQzM2Ni43MDcgMjgzLjA1NCAzODQgMjM5LjU0OSAzODQgMTkyIDM4NCA4NS45NjEgMjk4LjAzOSAwIDE5MiAwUzAgODUuOTYxIDAgMTkyczg1Ljk2MSAxOTIgMTkyIDE5MmM0Ny41NDkgMCA5MS4wNTQtMTcuMjkzIDEyNC41ODgtNDUuOTIyLS41IDEwLjgzMSA0LjY4IDI0LjIzOSAxNS45NjMgMzYuNzc2TDQzNS42OTggNDk2LjEzYzE3LjY2MSAxOS42MjMgNDYuNTExIDIxLjI3NyA2NC4xMSAzLjY3OHMxNS45NDYtNDYuNDQ5LTMuNjc3LTY0LjExek0xOTIgMzIwYy03MC42OTIgMC0xMjgtNTcuMzA4LTEyOC0xMjhTMTIxLjMwOCA2NCAxOTIgNjRzMTI4IDU3LjMwOCAxMjggMTI4LTU3LjMwNyAxMjgtMTI4IDEyOHoiLz48L3N2Zz4=);
				background-repeat: no-repeat;
				background-size: 15px;
				background-position: 10px;
				padding: 0 25px 0 35px;
			}

			.clearSearch {
				display: none;
				color: var(--form--input-placeholder-clear-search);
				position: absolute;
				top: 0;
				right: 0;
				cursor: pointer;
				background: transparent;
				padding: 10px;
				margin: 0;
			}
		}

		.personListBox {
			width: 100%;
			border-radius: .5rem;
			border: .1rem solid var(--box--info-bg);
			background: var(--box--info-bg);

			.addBioData {
				margin: 0;
			}

			&:hover {
				border-color: var(--models--choose-existing-selected-border);
				background: var(--form--input-background);

				button,
				.button {
					visibility: visible;
				}

				.personTextBox {
					h3 {
						color: var(--models--choose-existing-name);
					}

					.emailAddressText {
						color: var(--text--secondary)
					}
				}
			}
		}

		.personDataBox {
			width: 54%;
			padding: .9rem 1rem;
			border-right: .1rem solid var(--box--main-bg);

			@include _on('mobile') {
				width: auto;
			}
		}

		.personIconBox {
			float: left;
			position: relative;
			margin-right: 1rem;
			padding: 10px 5px 0;
			background: var(--members--empty-profile-bg);

			i {
				font-size: 30px;
				color: var(--members--empty-profile-icon);
			}

			.errorMessageBox {
				font-size: 2rem;
				top: 1rem;
			}
		}

		.personTextBox {
			text-align: left;

			h3 {
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
				margin: 0 0 .5rem;
				font-size: 1.5rem;
				color: var(--text--secondary)
			}

			.emailAddressText {
				overflow: hidden;
				text-overflow: ellipsis;
				margin: 0;
				font-size: 1.2rem;
				color: var(--text--secondary-darker)
			}

			.errorMessageBox {
				position: inherit;
				text-align: left;
			}
		}

		.personCategoryBox {
			width: 15.4rem;
			padding: 0 1rem;
			border-right: .1rem solid var(--box--main-bg);
			font-size: 1.2rem;

			.errorMessageBox {
				position: inherit;
			}

			p {
				overflow: hidden;
				text-overflow: ellipsis;
			}
		}

		.personSelectBox {
			text-align: center;
			line-height: 5.8rem;
			padding: 0 2rem;

			button,
			.button {
				visibility: hidden;

				@include _on('mobile') {
					visibility: visible;
				}
			}
		}

		.moreInfo {
			display: inline-block;
			margin: 20px 0;

			.info {
				display: inline-block;
				margin: -2px 5px 0 0;
				vertical-align: middle;
			}

			&:hover .info {
				background-position: -78px -186px;
			}
		}
	}

	.switchPageContainer {
		height: 35px;
		margin: 20px 0 0 0;
		display: inline-block;

		a {
			@include extendPageSwitchBox;

			float: left;
			line-height: 33px;
			margin: 0 5px 0 0;

			&:hover {
				background-color: transparent;
				color: var(--text--link);
				box-shadow: 0 1px 0 RGBA(var(--switch-box--shadow-rgb), 0.3);

				.linkArrowLeft {
					@include style('icon', 'linkArrowHorizontal', 'linkArrowLeftActive');
				}

				.linkArrowRight {
					@include style('icon', 'linkArrowHorizontal', 'linkArrowRightActive');
				}
			}

			.linkArrowLeft,
			.linkArrowRight {
				margin: 12px 14px;
			}
		}
	}

	// A statisztika ablak formazasai
	#statistics_transactions {
		.backToTransactions {
			display: block;
		}

		.periodEarnings:first-of-type,
		.statisticsTabs {
			display: none;
		}

		.protip.discount {
			margin: 0 0 0 0.5rem;
		}
	}


	.statisticContent {
		text-align: center;

		//Tabs
		.tabContainer {
			background: var(--common-tabs--container-bg);
			padding: 40px 0 0;

			* a span {
				overflow: hidden;
				text-overflow: ellipsis;
				max-width: 90%;
				white-space: nowrap;
				display: inline-block;
			}
		}

		.statisticsTabs {
			h4 {
				margin: 3.5rem 2rem 0;
				padding-bottom: 1.4rem;
				font-size: 2rem;
				border-bottom: 0.1rem solid var(--statistics-income--border);
			}

			.tabContainer {
				background: none;
				margin-top: 2rem;
				padding: 0 0 0.2rem;

				.commonTabs {
					width: calc(100% - 6rem);
					margin: 0 3rem;

					@include _on('mobile') {
						width: 100%;
						margin: 0;

						.tab {
							width: 50%;
						}
					}

					.tab {
						position: relative;
					}

					.tab a {
						line-height: 2.4;
						display: flex;
						padding-bottom: 0.1rem;
						justify-content: center;
						align-items: center;
						background: none;
						border-right: none;
						border-bottom: 0.1rem solid var(--common-tabs--link-bg);
						border-radius: 0;

						&:hover {
							color: var(--common-tabs--link);
							font-weight: bold;
						}

						span {
							padding: 0.4rem 1rem 0;
							white-space: initial;
							max-width: 100%;
						}
					}

					.tab.active {
						cursor: default;

						a {
							color: var(--common-tabs--link-active-secondary);
							background: none;
						}

						&::after {
							content: '';
							position: absolute;
							left: 0;
							bottom: -0.2rem;
							width: 100%;
							height: 0.2rem;
							background-color: var(--common-tabs--link-active-border);
							z-index: 10;
						}
					}
				}
			}
		}

		//A felso blokk formazasa a szintlepo csikkal, es szovegekkel
		.levelBlock {
			width: 100%;
			border-bottom: 1px solid var(--models--next-level-border);
			float: left;
			position: relative;
		}

		.nextLevelText {
			display: inline-block;
			font-weight: bold;
			font-size: 16px;
			line-height: 16px;
			margin: 30px 0 48px 0;
			color: var(--text--base);
			padding: 0 10px;
		}

		h2 {
			position: absolute;
			top: 40px;
			left: 262px;
			text-align: left;
			line-height: 24px;

			@include _on('mobile') {
				position: static;
				text-align: center;
				margin: 25px 20px;
			}
		}

		h3 {
			position: absolute;
			top: 90px;
			left: 263px;

			@include _on('mobile') {
				position: static;
				margin: 0 20px;
			}
		}

		.nextLevelBarContainer {
			box-shadow: 0 1px 0 var(--models--next-level-bar-shadow);
			height: 36px;
			width: 100%;
			background-color: var(--models--next-level-bar-bg);
			position: relative;
			z-index: 1;
			margin: 0 0 60px 0;

			&.small {
				margin: 58px 0 56px 0;
				width: 200px;

				@include _on('mobile') {
					width: 141px;
				}
			}
		}

		$levelSignSize: 110px;
		$levelSignSizeOnMobile: 77px;
		$levelSignLeft: 10%;
		$levelSignLeftOnMobile: 5%;

		// A szintlepes allasat mutato csik
		.nextLevelBar {
			position: absolute;
			top: 4px;
			left: 0;
			width: 100%;
			z-index: 2;
			padding-right: calc(#{$levelSignLeft} + #{$levelSignSize} - 10px);
			padding-left: calc(#{$levelSignLeft} + #{$levelSignSize} - 10px);
			box-shadow: inset 200px 0 0 var(--models--next-level-bar-line-start-bg);

			@include _on('mobile') {
				padding-right: calc(#{$levelSignLeftOnMobile} + #{$levelSignSizeOnMobile} - 8px);
				padding-left: calc(#{$levelSignLeftOnMobile} + #{$levelSignSizeOnMobile} - 8px);
				box-shadow: inset 120px 0 0 0 var(--models--next-level-bar-line-start-bg);
			}

			.nextLevelBarLine {
				position: relative;
				min-width: 80px;
				height: 28px;
				float: left;
				background: var(--models--next-level-bar-line-bg);
				text-align: right;
				font-weight: bold;
				font-size: 12px;
				line-height: 28px;
				color: var(--models--next-level-bar-line-text);
				padding: 0 9px 0 0;

				@include _on('mobile') {
					font-size: 10px;
					min-width: 65px;
				}

				&:after {
					@include css-triangle(14px, var(--models--next-level-bar-line-end-bg), left);
					position: absolute;
					top: 0;
					right: -28px;
				}
			}
		}

		.levelSign {
			transform: translateY(-50%);
			z-index: 3;
			position: absolute;
			width: $levelSignSize;
			height: $levelSignSize;
			top: 50%;
			font-size: 60px;
			line-height: 77px;

			left: 10%;

			@include _on('mobile') {
				left: 5%;
				width: $levelSignSizeOnMobile;
				height: $levelSignSizeOnMobile;
				font-size: 40px;
				line-height: 55px;
			}

			// Second child
			~ .levelSign {
				left: auto;
				right: $levelSignLeft;

				@include _on('mobile') {
					right: $levelSignLeftOnMobile;
				}
			}

			> * {
				transform: translate(-50%, -50%);
				top: 50%;
				left: 50%;
			}
		}

		// a szintet mutato kor kulso arnyeka
		.levelSignOuterShadow {
			border-radius: 53px;
			position: relative;
			z-index: 3;
			width: 100%;
			height: 100%;
			background-color: var(--models--level-sign-outer-shadow);
		}

		// a szintet mutato kor kulso kore
		.levelSignOuterRound {
			border-radius: 48px;
			position: absolute;
			z-index: 4;
			width: 90%;
			height: 90%;

			&.current {
				background: var(--models--current-level-sign-outer-bg);
			}

			&.next {
				background-color: var(--models--next-level-sign-outer-bg);
			}
		}

		// a szintet mutato kor belso arnyeka
		.levelSignInnerShadow {
			border-radius: 38px;
			position: absolute;
			z-index: 5;
			width: 72%;
			height: 72%;

			&.current {
				background-color: var(--models--current-level-sign-inner-shadow);
			}

			&.next {
				background-color: var(--models--next-level-sign-inner-shadow);
			}
		}

		// a szintet mutato kor belso tartalma
		.levelSignInnerRound {
			border-radius: 36px;
			position: absolute;
			z-index: 6;
			width: 69%;
			height: 69%;
			text-align: center;
			font-weight: bold;
			color: var(--models--current-level-sign-inner-text);

			&.current {
				background: var(--models--current-level-sign-inner-bg);
				text-shadow: 0 2px 0 var(--models--current-level-sign-inner-text-shadow);
			}

			&.next {
				text-shadow: 0 2px 0 var(--models--next-level-sign-inner-text-shadow);
			}
		}

		// a szint neve
		.levelName {
			position: absolute;
			top: -20px;
			font-weight: bold;
			font-size: 12px;
			line-height: 1;
			color: var(--models--filter-text);

			@include _on('mobile') {
				display: none;
			}
		}

		&.newStatisticContent {
			hr {
				display: none;
			}

			.statContentBlock {
				padding: 4rem 0 5rem;

				.showMoreLink {
					margin-top: 1.5rem;
				}
			}

			.noTransactions {
				display: inline-block;
				margin-top: 1.6rem;
				font-size: 1.4rem;
				font-weight: bold;
				line-height: 1.3;
				color: var(--statistics-income--no-data);
			}

			.backToTransactions {
				display: none;
				width: 100%;
				height: 5rem;
				line-height: 4.8rem;
				padding-left: 2rem;
				font-size: 1.4rem;
				font-weight: bold;
				text-align: left;
				color: var(--statistics-income--back-text);
				background: var(--statistics-income--back-bg);

				i {
					position: relative;
					top: 0.2rem;
					font-size: 1.6rem;
				}

				&:hover {
					color: var(--statistics-income--back-text-hover);
				}
			}
		}

		.periodEarnings {
			display: flex;
			align-items: center;
			max-width: 46rem;
			min-height: 5.6rem;
			line-height: 1.2;
			margin: 2rem auto 0;
			border-radius: 0.3rem;
			background: var(--statistics-income--current-earnings-bg);

			@include _on('mobile') {
				display: block;
				max-width: 100%;
				margin: 2rem;
			}

			.earningsBlock {
				display: flex;
				flex: 1;
				min-height: 5.6rem;
				align-items: center;
			}

			p {
				padding: 1rem 1rem 1rem 2rem;
				flex: 1;
				text-align: left;
				font-size: 1.6rem;
				color: var(--statistics-income--current-earnings-text);

				@include _on('mobile') {
					font-size: 1.4rem;
				}
			}

			span {
				padding: 0 2rem 0 1rem;
				font-size: 2rem;
				font-weight: bold;
				color: var(--statistics-income--current-earnings-value);
			}
		}

		.periodCoinDollarEarnings {
			display: flex;
			align-items: stretch;
			line-height: 1.2;
			margin: 4rem 3rem 4.3rem;

			@include _on('mobile') {
				display: block;
				max-width: 100%;
				margin: 4rem 2rem 4.3rem;
			}

			.earningsBlock {
				flex: 1;
				min-height: 9.8rem;
				padding: 1.2rem 2rem 1rem;
				text-align: left;
				border-radius: 0.4rem;
				background: var(--statistics-income--current-earnings-bg);

				@include _on('mobile') {
					min-height: 7.2rem;
					padding: 1.2rem 1.6rem 1rem;
				}
			}

			p {
				margin-bottom: 0.6rem;
				font-size: 1.4rem;
				line-height: 1.4;
				color: var(--statistics-income--current-earnings-text);

				@include _on('mobile') {
					font-size: 1.4rem;
				}
			}

			em {
				display: block;
				font-size: 2.4rem;
				font-weight: bold;
				color: var(--statistics-income--current-earnings-value);

				@include _on('mobile') {
					display: inline;
					margin-right: 1rem;
					font-size: 2rem;
				}
			}

			span {
				display: block;
				margin-top: 0.2rem;
				font-size: 1.2rem;
				color: var(--statistics-income--current-earnings-coin);

				@include _on('mobile') {
					display: inline;
					position: relative;
					top: -0.2rem;
				}
			}

			img {
				width: 1.6rem;
				margin-right: 0.2rem;
				vertical-align: bottom;

				@include _on('mobile') {
					vertical-align: text-bottom;
				}
			}

			.earningsBlock:first-of-type {
				em {
					color: var(--statistics-income--current-earnings-text);
				}
			}

			.earningsBlock:nth-child(2) {
				margin: 0 0.4rem;

				@include _on('mobile') {
					margin: 0.4rem 0;
				}
			}
		}

		.selectedPeriodEarnings {
			max-width: 60rem;
			margin: 3.8rem auto;

			@include _on('mobile') {
				display: flex;
				max-width: 100%;
				margin: 3.8rem 2rem 0;
			}

			p {
				font-size: 1.6rem;
			}
		}

		.transactionsIncomeContent {
			margin: 3rem;
			padding: 0 0 0.8rem;

			&:last-of-type {
				padding: 0;
			}

			h4 {
				margin-bottom: 2.2rem;
				text-align: left;
				font-size: 1.8rem;
				line-height: 1;
				color: var(--statistics-income--list-header);
			}

			.transactionsIncomeTitle {
				display: flex;

				h4 {
					flex: 1;
				}

				span {
					position: relative;
					top: -0.4rem;
					height: 2.6rem;
					margin-left: 2rem;
					padding: 0.6rem 0.8rem 0.4rem;
					font-size: 1.4rem;
					font-weight: normal;
					border-radius: 0.3rem;
					color: var(--statistics-income--share-text);
					background: var(--statistics-income--share-bg);
				}
			}

			@include _on('mobile') {
				margin: 2.6rem 2rem 3rem;
			}

			.transactionsIncomeRow {
				width: 100%;
				min-height: 5.6rem;
				display: flex;
				align-items: center;
				margin-bottom: 0.5rem;
				padding: 0.5rem 0;
				border-radius: 0.3rem;
				background: var(--statistics-income--list-bg);

				.incomeIcon {
					width: 3.8rem;
					margin-top: -0.2rem;
					padding: 0 0 0 2.4rem;

					@include _on('mobile') {
						padding: 0 0 0 1.5rem;
					}

					i {
						color: var(--statistics-income--list-icon);
						width: 2.4rem;
						height: 2.4rem;
					}
				}

				p {
					padding: 0 2rem;
					flex: 1;
					font-size: 1.6rem;
					font-weight: bold;
					line-height: 1.2;
					text-align: left;
					color: var(--statistics-income--list-text);

					@include _on('mobile') {
						padding: 0 1rem;
					}
				}

				.value {
					padding: 0 2rem;
					text-align: right;
					font-size: 1.6rem;
					font-weight: bold;
					color: var(--statistics-income--list-value);

					&.nullValue {
						color: var(--statistics-income--list-null-value);
					}

					@include _on('mobile') {
						padding: 0 1rem;
					}
				}

				.showDetailsLink {
					width: 3.6rem;
					padding-right: 1.8rem;
					font-size: 1.8rem;
					font-weight: bold;
					color: var(--statistics-income--list-arrow);
				}

				&:hover {
					.showDetailsLink {
						color: var(--statistics-income--list-arrow-hover);

                        i::before {
                            content: icons.get('arrow-right-solid');
                        }
					}
				}

				.new {
					margin-left: 1rem;
					padding: 0.2rem 0.4rem;
					position: relative;
					top: -0.2rem;
					text-transform: uppercase;
					font-size: 0.8rem;
					font-weight: 700;
					border-radius: 0.2rem;
					background: var(--menu--new-bg);
					color: var(--menu--new-text);
				}
			}

			.transactionsIncomeList {
				margin-bottom: 1.6rem;
				padding: 1.6rem;
				border-radius: 0.3rem;
				background: var(--statistics-income--list-container-bg);

				&:last-of-type {
					margin-bottom: 0;
				}

				h5 {
					margin: -0.1rem 0 1.3rem;
					text-align: left;
					font-size: 1.6rem;
					font-weight: bold;
					color: var(--statistics-income--list-text);
				}

				.listTotal {
					margin: 1.4rem 0 -0.2rem;
					padding-right: 5.6rem;
					text-align: right;
					font-size: 1.6rem;
					color: var(--statistics-income--list-data);

					@include _on('mobile') {
						padding-right: 4.6rem;
					}
				}

				.transactionsIncomeRow {
					background: var(--statistics-income--list-item-bg);
				}
			}
		}

		.transactionsDetailsTable .transactionsDetailsDate {
			color: var(--statistics-income--list-date) !important;

			&.mobileCell {
				display: none;
				text-transform: uppercase;
				font-size: 1.2rem;
				font-weight: bold;
				line-height: 1.6rem;
				margin: 2.4rem 0 1.6rem;
				padding: 0;

				@include _on('mobile') {
					display: block;
				}
			}
		}

		.transactionsDetailsLevel {
			display: flex;
			align-items: center;
			margin: 2rem 0;
			min-height: 2.8rem;

			p {
				font-size: 1.2rem;
				padding: 0.5rem 2rem;
				color: var(--statistics-income--list-data);

				@include _on('mobile') {
					padding-left: 0;
				}

				span {
					padding: 0 0.8rem 0 0;
					font-weight: bold;
					color: var(--statistics-income--level-achieved);
				}
			}

			div {
				flex: 1;
				height: 0.1rem;
				background: var(--statistics-income--border);
			}

			.share {
				padding: 0 2rem;
				flex-basis: 25.5%;
				font-size: 1.2rem;
				font-weight: bold;
				text-align: left;
				color: var(--statistics-income--level-share);

				@include _on('mobile') {
					padding-right: 0;
					text-align: right;
					flex-basis: initial;
				}
			}
		}

		.transactionsDetailsTable {
			margin: 3.2rem 3rem;

			@include _on('mobile') {
				margin: 3.2rem 2rem 1.6rem;
			}

			.dateCell {
				flex-basis: 16%;
				color: var(--statistics-income--list-data);
				font-size: 1.2rem;

				@include _on('mobile') {
					display: none;
					margin-top: -0.2rem;
					padding-bottom: 0.4rem;
				}
			}

			span {
				padding: 0 1rem;
				font-size: 1.4rem;
				line-height: 1.8rem;
				text-align: left;

				&.transactionCell {
					flex: 1;
					padding-left: 0;
					color: var(--statistics-income--list-details);

					@include _on('mobile') {
						padding-left: 1rem;
					}

					i {
						cursor: pointer;
					}
				}

				&.chargeCell {
					text-align: right;
					flex-basis: 40%;

					@include _on('mobile') {
						flex-basis: 35%;
						text-align: left;
						padding-left: 0;
					}
				}

				&.timeUsedCell {
					text-align: right;
					flex-basis: 40%;

					@include _on('mobile') {
						padding: 0;

						em {
							display: inline;
							font-weight: bold;
						}
					}
				}

				&.shareCell {
					text-align: right;
					flex-basis: 30%;
					padding-right: 0;

					@include _on('mobile') {
						flex: 1;
						text-align: left;
						padding-right: 0;
					}
				}

				&.payoutCell {
					flex-basis: 11%;
					text-align: right;

					@include _on('mobile') {
						display: none;
					}
				}
			}

			.mergedCell {
				display: flex;
				flex-basis: 27%;
				align-items: flex-start;
				justify-content: flex-end;

				span em {
					display: block;
					margin-bottom: 0.5rem;
					font-weight: 700;
					font-size: 12px;
					line-height: 16px;
					text-transform: uppercase;
					color: var(--statistics-income--list-header);
				}

				@include _on('mobile') {
					margin: 0.2rem 1rem 0;
					padding-top: 0.9rem;
					flex: 1;
					flex-basis: 100%;
					justify-content: space-between;

					.desktopCell {
						display: none;
					}
				}
			}
		}

		.transactionsDetailsHeader {
			display: flex;
			align-items: center;
			margin: 2.4rem 0 1.4rem;
			padding: 0 0.6rem;
			min-height: 2.1rem;

			@include _on('mobile') {
				display: none;
			}

			span {
				font-size: 1.2rem;
				line-height: 1.6rem;
				font-weight: bold;
				text-transform: uppercase;
				color: var(--statistics-income--list-header);
			}
		}

		.transactionsDetailsRow {
			display: flex;
			align-items: start;
			margin-bottom: 0.8rem;
			padding: 1.6rem 0.6rem;
			min-height: 5rem;
			border-radius: 0.3rem;
			background: var(--statistics-income--list-bg);

			@include _on('mobile') {
				flex-wrap: wrap;
			}

			&.hasMoreDetails {
				margin-top: -2.1rem;

				@include _on('mobile') {
					margin-top: -0.9rem;
				}

				.mergedCell,
				.transactionCell {
					padding-top: 1.6rem;
					border-top: 0.1rem solid var(--statistics-income--border-details);
				}

				.transactionCell {
					font-weight: bold;
					color: var(--statistics-income--list-text);

					em {
						font-weight: normal;
						color: var(--statistics-income--list-details);
						line-height: 1.6rem;
					}
				}

				@include _on('mobile') {
					padding-top: 0.9rem;
					border-top: 0.1rem solid var(--statistics-income--border-details);

					.transactionCell.dateCell,
					.mobileCell.payoutCell {
						display: none;
						flex-basis: initial;
					}

					.transactionCell {
						border: 0;
						padding-top: 0.8rem;
					}

					.mergedCell {
						padding: 0.6rem 0 0;
						border: 0;

						span {
							padding-top: 0.3rem;
						}
					}
				}
			}

			span {
				color: var(--statistics-income--list-text);

				&.transactionCell {
					span {
						padding: 0;
						font-weight: bold;
						color: var(--statistics-income--list-text);
					}
				}

				&.payoutCell {
					font-size: 1.4rem;
					font-weight: bold;
					color: var(--statistics-income--list-payout-plus);

					&.minus {
						color: var(--statistics-income--list-payout-minus);
					}
				}
			}
		}

		.transactionsDetailsTable .transactionsDetailsRow .mobileCell {
			display: none;

			@include _on('mobile') {
				display: block;

				&.payoutCell {
					flex-basis: 20%;
				}

				em {
					font-size: 1.2rem;
					font-weight: normal;
					color: var(--statistics-income--list-data);
				}
			}
		}

		// a fulvalaszto blokk formazasa
		.statTabBlock {
			height: 75px;
			background-color: var(--box--skip-bg);

			.statTab {
				display: block;
				height: 75px;
				font-size: 14px;
				line-height: 75px;
				padding: 0 30px;
				overflow: hidden;

				@include _on('mobile') {
					padding: 0 15px;
				}

				&.active {
					background-color: var(--box--main-bg);
					font-weight: bold;
					color: var(--text--base);
				}
			}
		}

		// az adatokat megjelenito blokk formazasa
		.statContentBlock {
			width: 100%;
			padding: 30px 0;

			.payouts {
				font-weight: bold;
				font-size: 20px;
				line-height: 1.2;
				color: var(--text--secondary);
				margin: 0 0 28px;

				@include _on('mobile') {
					font-size: 16px;
					margin: 0 20px 28px;
				}

				span {
					color: var(--models--total-payouts);
				}
			}

			// Az idoszak kivalasztasahoz szukseges selectek, csik, es szovegek formazasai
			.periodSelectorContainer {
				position: relative;

				form {
					width: 350px;
					max-width: 95%;
					margin: 0 auto;
					@include _on('mobile') {
						width: calc(100% - 100px);
					}
				}
			}

			//datumvalaszto select
			.dateSelectContainer {
				display: inline-block;
				margin: 0 auto;

				.midsizeField {
					margin: 0 5px 0 0;
				}
			}

			//periodus valaszto bal-jobb nyilak
			.previousPeriod,
			.nextPeriod {
				position: absolute;
				top: 0;
				display: inline-block;
				opacity: 0.3;

				p {
					font-weight: bold;
					font-size: 13px;
					line-height: 34px;
					white-space: nowrap;
					position: absolute;
					top: 0;
				}

				&.active {
					opacity: 1;
				}
			}

			.previousPeriod,
			.nextPeriod {
				i {
					line-height: 3.4rem;
					@include _on('mobile') {
						font-size: 1.6rem;
					}
				}
			}

			.previousPeriod {
				left: 30px;
				@include _on('mobile') {
					left: 1.8rem;
					top: 0.2rem;
				}

				p {
					left: 2.4rem;
				}

				&.active {
                    &:hover {
                        i::before {
                            content: icons.get('arrow-left-solid');
                        }
                    }
				}
			}

			.nextPeriod {
				right: 30px;
				@include _on('mobile') {
					right: 1.8rem;
					top: 0.2rem;
				}

				p {
					right: 2.4rem;
				}

                &.active {
                    &:hover {
                        i::before {
                            content: icons.get('arrow-right-solid');
                        }
                    }
                }
			}

			// tablazatok altalanos formazasai
			.table {
				@include commonTable;

				padding: 0;
				margin: 0 0 28px;
			}

			// A valasztott periodus osszefoglalo tablazatai
			.selectedPeriodDependentSum,
			.selectedPeriodIndependentSum {
				width: 100%;
				padding: 0 28px;
			}

			.tableHeaderCell {
				text-align: center;
			}

			.tableHighlightedCell {
				position: relative;
				text-align: left;
				width: 80%;

				a {
					float: right;
					line-height: 10px;
					font-size: 12px;

					@include _on('mobile') {
						opacity: 0;
						position: absolute;
						top: 0;
						left: 0;
						bottom: 0;
						right: 0;
					}
				}
			}
			// A valasztott periodus reszletes tablazata
			.selectedPeriodDetails {
				width: 100%;
				margin: 28px 0;
				padding: 0 28px;
			}

			.title {
				text-align: left;
			}

			.tableSummarizeLastCell {
				font-size: 12px;
				color: var(--models--summarize-text);
			}

			.tableRegularCell,
			.tableSummarizeLastCell {
				@include _on('mobile') {
					padding: 10px;
				}
			}

			.tableSummarizeDateCell {
				text-align: left;
			}

			.tableSummarizeContentCell {

				&.pointer {
					cursor: pointer;
				}

				.icon-angle-down,
				.icon-angle-up {
					color: var(--text--link);
					font-size: 22px;
					position: relative;
					display: inline-block !important;
					margin: 0 0 0 8px;
				}

				&.selected,
				&:hover {
					.icon-angle-down,
					.icon-angle-up {
						color: var(--text--link-hover);
					}
				}
			}

			td {
				white-space: nowrap;

				@include _on('mobile') {
					white-space: normal;
				}
			}

			.charge,
			.share,
			.time,
			.timeUsed {
				width: 10%;
				overflow: hidden;

				@include _on('mobile') {
					width: auto;
				}
			}

			.name {
				text-align: left;
				color: var(--text--secondary);
				font-weight: normal !important;
				overflow: hidden;
				text-overflow: ellipsis;
				width: 100%;
				max-width: 0;

				&.showMoreJs {
					cursor: pointer;
				}

				> div {
					white-space: nowrap;
				}

				span {
					color: var(--text--base);
				}

				i {
					transform: none;
					float: none;
					position: relative;
					right: auto;
					display: inline-block;
					top: 3px;
					left: 5px;
				}
			}

			.loyaltyMask {
				width: 2rem;
				margin: 0 0.2rem;
				vertical-align: bottom;
			}

			hr {
				margin-top: 30px;
			}

			// A tobb infot megmutato link formazasai
			.moreInfoLink,
			.showMoreLink {
				display: inline-block;
				font-weight: bold;
				position: relative;
				margin: 20px 0 0 0;
				font-size: 14px;

				.info-icon {
					transform: translateY(-1px);
				}

				.icon-angle-down {
					left: unset;
					right: -25px;
				}

				.showText {
					display: inline-block;
				}

				.hideText {
					display: none;
				}

				.icon-angle-right {
					font-size: 10px;
					position: relative;
					top: -1px;
					left: 3px;
				}

				&:hover {
					.info {
						@include style('icon', 'infoHover');
					}
				}
			}

			.showMoreContent {
				padding: 30px 30px 0;
				text-align: left;
				display: none;

				p {
					@include style('text', 'largeSize', 'regularColor');

					margin: 0 0 15px 0;
				}

				a {
					@include button.linkPrimary();
				}

				&.show {
					display: block;
				}
			}
		}
	}

	// Reset model password overlay
	#resetModelPassword {
		.selectPersonExplanationText {
			@include style('text', 'hugeSize');
			margin: 30px 0 0;
		}

		input[type=submit],
		button[type=submit] {
			margin: 0 0 30px 0;
		}
	}

	#personDetailsContainer {
		padding: 40px 0 30px 0;

		.skipBox {
			color: var(--text--link);
			text-align: center;
		}

		.readOnlyLabel {
			width: 421px;
		}
	}

	@import 'Models.oranum';
}

&.oranum-theme {

	&#personCreationStepsBlock {

		@include _on('mobile') {
			background: var(--stepcrumb--background-overlay-mobile);
		}

		ul li {
			font-weight: bold;

			&.active {
				color: var(--stepcrumb--modelreg--active);
				border-bottom: none;
			}
		}
	}

	&#registrationStepsBlock {

		ul li.done {
			color: var(--registration--step);
		}

		ul li.active {
			color: var(--stepcrumb--modelreg--active);
			border-bottom: none;
		}
	}
}

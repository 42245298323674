@import '../../scss/shell';

.dropdown {
    position: relative;
    display: block;

    triggerable {
        display: none;
    }
    [triggered] {
        display: block;
    }
}
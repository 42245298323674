@import '../../../scss/shell';

:global {
	.firstLoginOverlayBox {
		.box_header {
			background: var(--box--main-bg);
		}
	}

	#firstLoginOverlay {
		text-align: center;

		@include _on('mobile') {
			h2, h3, p {
				padding: 0 2rem;
			}
		}

		h3 {
			text-transform: uppercase;
			line-height: 32px;
			font-size: 20px;
			color: var(--text--secondary);
			font-weight: 300;
			margin: 0;
		}

		h2 {
			font-size: 24px;
			line-height: 1.4;
			margin: 0 0 15px 0;
		}
		p {
			font-size: 15px;
			line-height: 18px;
			color: var(--text--secondary);
			margin: 0 auto;

			em {
				font-weight: bold;
				color: var(--text--base);
			}
		}

		.steps {
			@include _flexbox;
			@include _flexbox-justify-content(space-around);

			@include _on('mobile') {
				@include _flexbox-direction(column);
			}

			background-color: var(--first-login-steps-bg);
			width: 100%;
			margin: 25px 0;
			-webkit-align-items: center;
			align-items: flex-start;
			padding: 3rem 10%;

			> div {
				width: 33.33%;

				@include _on('mobile') {
					width: 100%;
					margin: 2em;
				}
			}

			i {
				font-size: 44px;

				@include _on('mobile') {
					font-size: 4.8rem;
					float: left;
					margin-left: 3rem;
					margin-right: 2rem;

					&.icon-people-0 {
						margin-left: 0.6rem;
					}
				}

				// Icon hack
				&.icon-people-0 {
					font-size: 52px;
					display: block;
					height: 44px;

					@include _on('mobile') {
						font-size: 72px;
						height: 64px;
					}

					&:before {
						position: relative;
						top: -5px;
					}
				}

				&:before {
					background: -webkit-linear-gradient(var(--text--link), var(--text--link-darker));
					-webkit-background-clip: text;
					-webkit-text-fill-color: transparent;
					background-clip: text;
					text-fill-color: transparent;
					color: var(--text--link-darker);
				}
			}

			p {
				font-size: 14px;
				font-weight: bold;
				color: var(--text--base);
				margin-top: 24px;

				@include _on('mobile') {
					text-align: left;
					margin-top: 0;
				}
			}

			p + p {
				font-size: 12px;
				text-transform: uppercase;
				font-weight: normal;
				color: var(--text--secondary);
				margin-top: 5px;

				@include _on('mobile') {
					margin-top: 0.5rem;
				}
			}
		}

		a.button {
			margin: 28px 0 48px 0;

			@include _on('mobile') {
				width: calc(100% - 4rem);
				margin: 2.8rem 2rem 4.8rem;
			}
		}
	}
}

@import 'FirstLogin.oranum';

@use 'global/colors';
@import '../../../scss/shell';

.exclusiveModel {
    --earn-more-exclusive-model-check-success-icon: #{colors.get(alert, success_500)};
    --earn-more-exclusive-model-close-rejected-icon: #{colors.get(alert, danger_500)};

	.title {
		display: flex;
		align-items: center;
		padding: 22px 30px;
		font-size: 18px;
		background: var(--earn-more--exclusive-model--title);

		i {
			margin-right: 10px;
			font-size: 26px;
		}

		span {
			font-weight: bold;
		}

		p {
			flex: 1;
			text-align: right;
			font-weight: 300;
		}

        &.active i {
            color: var(--earn-more-exclusive-model-check-success-icon);
        }

		&.pending,
		&.disabled {
			color: var(--text--secondary);
		}

		&.rejected i {
			color: var(--earn-more-exclusive-model-close-rejected-icon);
		}

		.error {
            display: flex;
            justify-content: end;
            align-items: center;
			color: var(--text--secondary);

			i {
				font-size: 24px;
				color: var(--icon--alert--yellow);
			}
		}
	}

	.section1 {
		position: relative;
		padding: 55px 40px;
		background: url('./section1-bg.jpg') no-repeat;
		background-size: cover;

		h2 {
			margin: 0 0 16px;
			font-size: 36px;
			font-weight: 300;
			line-height: 1;
			text-transform: uppercase;
		}

		h3 {
			margin: 0 0 34px;
			font-size: 20px;
			font-weight: 300;
			text-transform: uppercase;
		}

		p {
			max-width: 545px;
			font-size: 18px;
			font-weight: 300;
			line-height: 1.5;
			color: var(--earn-more--text);

			span {
				color: var(--text--base);
			}

			strong {
				font-weight: bold;
				color: var(--text--base);
			}
		}
	}

	.section2 {
		padding: 55px 40px 55px;
		text-align: center;

		@include _vertical-gradient(
				var(--earn-more--exclusive-model--gradient-from),
				var(--earn-more--exclusive-model--gradient-to),
				0%, 100%
		);

		h2 {
			font-size: 22px;
			text-transform: uppercase;
		}

		ul {
			display: flex;
			margin: 60px 0 0;

			@include _on('mobile') {
				margin: 30px 0 0;
				flex-direction: column;
			}
		}

		li {
			margin: 0 20px;
			text-align: left;
			color: var(--text--secondary);

			@include _on('mobile') {
				margin: 20px;
			}

			&:nth-child(3) {
				flex-basis: 435px;

				@include _on('mobile') {
					flex-basis: auto;
				}
			}

			p {
				font-size: 18px;
				font-weight: 300;
				line-height: 1.2;

				strong {
					font-weight: bold;
					color: var(--text--base);
				}

				&:last-of-type {
					padding-left: 62px;
				}
			}

			span {
				float: left;
				width: 48px;
				height: 48px;
				margin: 0 14px 0 0;
				border: 3px var(--text--link) solid;
				border-radius: 50%;
				color: var(--text--link);
				text-align: center;

				i {
					position: relative;
					top: 8px;
					font-size: 24px;
					color: var(--text--link);

					@include _vertical-gradient(
							var(--earn-more--text-gradient-start),
							var(--earn-more--text-gradient-stop),
							0%, 100%,
							true
					);

					&.people {
						top: 8px;
						font-size: 24px;
					}

					&.eye {
						font-size: 24px;
						top: 9px;
					}
				}
			}
		}
	}

	.section3 {
		padding: 57px 40px 0;
		text-align: center;

		h3 {
			font-size: 16px;
			text-transform: uppercase;
		}

		ul {
			margin: 36px 0 38px;
		}

		li {
			display: flex;
			align-items: center;
			justify-content: space-between;
			margin: 0 0 2px;
			padding: 15px 20px 13px;
			font-size: 16px;
			line-height: 1.4;
			text-align: left;
			border-radius: 3px;
			color: var(--text--base);
			background: var(--earn-more--exclusive-model--conditions-bg);

			&.passed {
				color: var(--text--secondary);
			}

			i {
				font-size: 24px;
                width: 24px;
				height: 24px;
                line-height: 24px;
                margin-left: 5px;
			}

			span {
				font-size: 12px;
				font-weight: bold;
				margin-left: 5px;
                margin-right: 2px;
				color: var(--text--secondary-darker);
			}

			:global .icon-check-circular-solid {
				color: var(--earn-more-exclusive-model-check-success-icon);
			}

			:global .icon-close-circular-solid {
                color: var(--earn-more-exclusive-model-close-rejected-icon);
			}
		}

		button {
			display: block;
			margin: 20px auto;
		}

		.error {
			position: relative;
			padding-left: 28px;
			text-align: left;
			font-size: 14px;
			line-height: 1.4;
			color: var(--earn-more--text);

			i {
				position: absolute;
				top: -2px;
				left: 0px;
				font-size: 24px;
				color: var(--icon--alert--yellow);
			}
		}

		.checkbox {
			width: 20px;
			height: 20px;
			min-width: 20px;
			float: right;
			position: relative;
			top: -2px;
			cursor: pointer;
			border-radius: 3px;
			color: var(--form--input-text);
			background: var(--form--input-background);
			border: 1px solid transparent;
			box-shadow: 0 1px 0 var(--form--input-border);
			user-select: none;

			&:before {
				font-size: 14px;
				left: 2px;
				top: 2px;
				transition: none;
				color: var(--form--radio-check);
				overflow: hidden;
				text-align: left;
				display: inline-block;
				position: absolute;
				visibility: hidden;
			}

			&.checked:before {
				opacity: 1;
				visibility: visible;
			}
		}
	}

	.moreInfo {
		display: block;
		margin: 60px 0 40px;
		text-align: center;
	}
}

.exclusiveModelOverlay {
	width: 830px;
	padding: 58px 140px;
	margin: 0 auto;
	position: relative;
	text-align: center;
	border-radius: 5px;
	background: var(--overlay-background);

	h4 {
		margin: 0 0 30px;
		font-size: 22px;
	}

	p {
		margin: 0 0 40px;
		font-size: 14px;
		line-height: 1.4;
		color: var(--earn-more--text);

		&:nth-child(2) {
			padding: 0 30px;
			margin: 0 0 20px;
		}

		strong {
			color: var(--text--base);
			display: block;
		}
	}
}
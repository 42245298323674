@use 'components/Button/button';
@use 'global/icons';

.box_container {
	@include style('box', 'common');

	p a {
		@include button.linkPrimary();
	}
}

.box_header {
	display: block;
	background-color: var(--box--header-background);
	color: var(--box--header-text);
	text-align: left;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	padding-right: 45px !important;
	border-bottom: 1px solid var(--box--header-border);
}

.notifBox {
	@include style('box', 'common', 'notify');
}

.infoBox, //kis info doboz
.infoBoxFull { //teljes szelessegu info doboz
	@include style('box', 'common', 'info');
}

.scrollBox {
	overflow: auto;
	background-color: var(--box--info-bg);
}

.skipBox {
	@include style('box', 'common', 'skip');
}

.showMoreContent {
	display: none;

	&.show {
		display: block;
	}
}

.box_header {
	@include style('text', 'pageTitle');
	color: var(--box--header-text);

	padding: 19px 19px 18px;

	.pathArrow {
		margin: 0 3px 0 5px;
	}

	.onlineSupport {
		padding: 9px 20px;
		float: right;
		position: relative;
		top: -10px;
	}
}

.closeButton,
.close.overlayBtn {
	position: absolute;
	top: 0;
	right: 0;
	display: block;

	i {
		color: var(--text--base);
		opacity: 0.6;
		font-size: 20px;
		display: block;
		margin: 15px;

		&:before {
			vertical-align: top;
		}

		@include _on('mobile') {
			font-size: 24px;
			margin: 13px;
		}
	}

	&:hover i {
		color: var(--text--base);
		opacity: 1;
	}
}

.infoBox,
.infoBoxFull {
	position: relative;

	/*Arnyekcsik elvalasztashoz*/
	.shadowBox {
		top: 0;
		left: 0;
		width: 100%;
		height: 6px;
		position: absolute;
	}
}

.inlineAlertBox {
	position: relative;
	padding: 30px;
	margin: 0 auto 16px 0;
	min-height: 20px;
	max-height: 98px;
	text-align: left;

	i {
		position: absolute !important;

		&.icon-ok {
			top: -2px;
			left: -30px;
		}

		&.icon-alert {
			@include style('icon', 'yellowColor', 'normalSize');
			top: -2px;
			left: -30px;
		}
	}

	strong, p {
		display: inline;
		font-weight: bold;
		line-height: 17px;
	}

	p {
		position: relative;
		display: block;
		margin: 0 0 0 25px;
		font-size: 14px;
		color: var(--old-box--alert-text);
	}

	&.alert-big {

		[class^=icon] {
			font-size: 85px;
			top: -13px;
			left: -100px;
		}

		strong {
			display: block;
			font-size: 26px;
			line-height: 26px;
		}

		p {
			margin: 0 0 0 100px;
			display: block;
			font-size: 17px;

			span {
				display: inline-block;
				background: RGBA(var(--old-box--alert-highlite-text-bg-rgb), 0.4);
				padding: 8px;
				border-radius: 5px;
				color: var(--old-box--alert-highlite-text);
				margin: 0 3px;
			}
		}
	}
}

.alertBox {
	width: 100%;

	div {
		background: var(--old-box--alert-bg);
		display: table;
		width: 100%;
		height: 60px;

		&.ok {
			span {
				background: var(--common--success);

				i {
					color: var(--old-box--success-icon);
				}
			}

			strong {
				color: var(--common--success);
			}
		}

		&.alert {
			span {
				background: var(--common--alert);

				i {
					color: var(--old-box--alert-icon);
				}
			}

			strong {
				color: var(--alertbox--alert-color);
			}
		}

		&.error {
			span {
				background: var(--old-box--error-bg);

				i {
					color: var(--old-box--error-icon);
				}
			}

			strong {
				color: var(--common--error);
			}
		}
	}

	span {
		display: table-cell;
		width: 60px;
		vertical-align: middle;
		text-align: center;

		i {
			font-size: 20px;
		}
	}

	p {
		display: table-cell;
		font-size: 14px;
		line-height: 16px;
		vertical-align: middle;
		padding: 15px 20px;
		color: var(--old-box--error-description);
		text-align: left;

		a {
			@include button.linkPrimary();
		}

		strong {
			font-weight: normal;
		}
	}

	@include _on('small', 'down') {
		&.displayNameChangeAlertBox {
			div {
				height: 94px;
			}

			a {
				display: block;
			}
		}
	}
}

.inlineAlertBoxContainer {
	width: 100%;
	height: 50px;
	margin: 10px 0 0 0;

	&.hide {
		display: none;
	}
}

.inlineAlertBox {
	display: inline-block;
	margin: 0 auto;
	padding: 15px;
	border-radius: 4px;
	font-size: 12px;

	.close {
		margin: 4px;
		cursor: pointer;
	}

	.ok, .icon-ok {
		top: 0!important;
	}

	.ok, .icon-ok, strong, p {
		float: left;
		line-height: 20px;
	}

	p {
		margin: 0 20px 0 30px;
	}
}
/*	A signup single account/profile category-hoz tartozo dobozok */

.stickyElementWrapper {
	box-shadow: 0 5px 5px -5px var(--old-box--sticky-wrapper-shadow);
	position: fixed;
	top: 0;
	z-index: 5;
	margin-top: 5rem;
}
.promotion-banner-on .stickyElementWrapper {
	margin-top: 13.7rem;
}

ul.choosePerson {
	margin: 0 30px;
	font-size: 0;
	text-align: center;

	input {
		display: none;
	}

	li + li {
		margin-left: 20px;
	}
	li {
		box-sizing: border-box;
		display: inline-block;
		vertical-align: top;
		width: 190px;
		min-height: 100px;
		height: 275px;
		border-radius: 10px;
		background-color: var(--migration--choose-box-bg);
		position: relative;

		.transparentBox {
			top: 0;
			left: 0;
			z-index: 3;
			opacity: 0.4;
			width: 100%;
			height: 100%;
			background: var(--box--main-bg);
			position: absolute;
			cursor: pointer;
		}

		a {
			position: relative;
			padding: 10px;
			display: block;
			height: 270px;
		}

		.imgWrap{
			display: block;
			width: 170px;
			height: 170px;
			text-align: center;
			border: none;
			border-radius: 5px;
			background-color: var(--members--empty-profile-bg);

			i {
				position: relative;
				top: 20px;
				font-size: 130px;
				color: var(--members--empty-profile-icon);
			}
		}

		.mask {
			background: RGBA(var(--migration--choose-box-mask-rgb), 0.7);
			display: none;
			position: absolute;
			top: 7px;
			left: 7px;
			width: 170px;
			height: 170px;
			text-align: center;

			strong {
				padding: 0 20px;
				display: inline-block;
				border-radius: 5px;
				background: var(--migration--choose-box-mask-strong);
				color: var(--common--alert);
				font-weight: bold;
				font-size: 12px;
				line-height: 33px;
				margin: 30px 0 0;
				margin-top: 80px;
			}
		}

		.name {
			display: block;
			font-size: 16px;
			line-height: 18px;
			color: var(--text--base);
			margin: 18px 0 8px 0;
			text-align: center;
		}

		em {
			display: block;
			color: var(--text--secondary);
			text-align: center;
			font-size: 12px;
			line-height: 12px;
			white-space: nowrap;
			overflow: hidden;
			position: relative;

			&:after {
				content: '';
				position: absolute;
				background: var(--migration--choose-box-bg);
				width: 20px;
				height: 12px;
				right: 0;
			}
		}

		// LI hover
		&:hover,
		&.selected {
			border: 3px solid RGBA(var(--box--border-rgb), 0.6);

			a {
				padding: 7px;
			}
		}

		&:hover {
			border: 3px solid RGBA(var(--box--border-rgb), 1);

			.mask {
				display: block;
			}
		}
	}
}


.selectorBox {
	@include selectorBox;
}

.scroll-pane {
	cursor: pointer;
	top: 0;
	right: 0;
	width: 5px;
	z-index: 10;
	position: absolute;
	padding: 5px 10px;
	min-height: 30px;

	.scrollbar {
		border-radius: 3px;
		background: var(--scrollpane--scrollbar-bg);
		height: 100%;
		width: 5px;

		@include _on('small', 'down') {
			display: none;
		}
	}
}

.noBar .scroll-pane {
	display: none;
}

.selectContainer .mask,
.inputs .mask {
	position: absolute;
	background: var(--box--main-bg);
	opacity: 0;
	z-index: 1;
}

.selectorBox:not(.selected) .selectContainer .mask {
	opacity: 0;
}

.page-row {
	margin-left: -40px;
	margin-right: -40px;

	img {
		display: block;
	}
}

//Oldal vagy lapvaltoshoz kis meretu doboz (a tag)
.box_container {
	&.independentContainer {
		padding: 0;
		margin: 40px auto;
		max-width: 90vw;

		&.tiny {
			width: 570px !important;
		}

		&.recordVideo {
			width: 700px !important;
		}

		&.setProfilePicture {
			width: 740px !important;
		}

		&.photosVideosForm {
			width: 820px !important;
		}
		&.migrationChoosePerson {
			width: 880px !important;
		}
		&.channelInfo {
			width: 64rem !important;
			margin: 80px auto !important;

			@include _on('mobile') {
				margin: 0 auto !important;
			}
		}
		&.normal {
			width: 920px !important;
		}
		&.narrow {
			width: 690px !important;
		}
		&.pageSize {
			width: 950px !important;
		}
		&.large {
			width: 980px !important;
		}
		&.snapshooterSize {
			width: 830px;
		}

		.box_header {
			border-radius: 4px 4px 0 0;
			position: relative;

			.closeButton {
				display: block;

                &:hover i::before {
                    content: icons.get('close-solid');
                }
			}
		}
	}
	&.small {
		width: 640px !important;
	}

	&.medium {
		width: 830px !important;
	}

  	&.guideSize {
	  	width: 808px !important;
	    max-width: 808px !important;

		.box_header {
			height: 60px;
		}
	}

	&.no-bg .box_header {
		background-color: transparent;
	}
}

.box_header {
	border-radius: 0 4px 0 0;
}

.infoBox {
	width: 340px;
	float: left;

	.shadowBox {
		background: url('./gfx_gradients_sprite.png') repeat-x 0 -672px;
	}
}

.infoBoxFull {
	width: 100%;
	padding: 0;

	.shadowBox {
		background: url('./gfx_gradients_sprite.png') repeat-x 0 -672px;
	}
}

.filterBox {
	border-radius: 0;
	padding: 0;
}

.inlineAlertBox {
	background: url('./gfx_gradients_sprite.png') repeat-x 0 -982px;
}

.dropDownInfoBox {
	@include style('box', 'common', 'info');

	width: 100%;
	padding: 0;

	.shadowBox {
		background: url('./gfx_gradients_sprite.png') repeat-x 0 -672px;
	}

	border-radius: 0 0 5px 5px;
	background-color: var(--form--input-background);
}

.unfinishedSelectorBox {
	@include style('box', 'common', 'info');
}

.selectorBox {
	input[type="radio"] {
		left: 108px;
	}
	p {
		margin: 15px 0 17px 0;
	}

  &.accountSelector {
		margin: 0 0 15px 20px;
		padding: 0 0 20px 0;
		width: 200px;
		min-height: 0;
		float: none;
		display: inline-block;

	    &:first-child {
		    margin-left: 0;
	    }

        h3 {
			text-overflow: ellipsis;
			overflow: hidden;
			white-space: nowrap;
			width: 100%;
			margin: 10px auto 0;
        }

        p {
			text-overflow: ellipsis;
			overflow: hidden;
			white-space: nowrap;
			width: 100%;
			margin: 5px auto 0;
        }

        input[type="radio"] {
          	float: none;
          	left: 0;
        }

        .radio-inactive,
        .radio-active {
          top: auto;
          left: auto;
        }

        .transparentRoundedBox {
          position: relative;
          width: 110px;
          height: 110px;
          display: block;
          margin: 0 auto;
        }

        .round {
		  border-radius: 60px;
          background-clip: padding-box;
          border: 7px solid RGBA(var(--box--round-border-rgb), 0.4);
          display: block;
          height: 95px;
          left: 1px;
          position: absolute;
          width: 95px;
        }

		img {
			border-radius: 60px;
			height: 99px;
			left: 6px;
			position: absolute;
			top: 5px;
			width: 99px;
		}
	}
}

#overlayContainer {
	display: none;

	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 10030;
	overflow-y: auto;
	overflow-x: hidden;
	position: absolute;
	background: RGBA(var(--overlay-container-background-rgb), 0.8);

	line-height: 100vh;
	text-align: center;

	@include _on('small', 'down') {
		position: fixed;
	}

	@include _on('mobile') {
		background: var(--overlay-container-background-mobile);
	}

	// Its a fix for android chrome browser's viewport height handling
	@at-root :global .is-android-device.ext-chrome {
		#overlayContainer {
			line-height: calc(100vh - 56px);
		}
	}

	@at-root :global body.no-overscroll {
		@include _on('small', 'down') {
			position: fixed;
		}
	}

	.overlayHeadline {
		position: absolute;
	  	top: -32px;
	}

	.overlayContent,
	#overlayContent {
        position: relative;
		display: inline-block;
		text-align: left;

		vertical-align: middle;
		line-height: 15px;
	}

	.overlayContent {
		display: none;

		&:last-of-type {
			display: inline-block;
		}

		.box_container {
			margin: 0 auto;

			@include _on('mobile') {
				width: 100vw !important;
				max-width: 100vw;
				min-height: 100vh;
				border-radius: 0;
			}
		}
	}
}

#overlay {
    top: 0;
    left: 0;
    width: 100%;
    //z-index: -1;
    display: none;
    position: absolute;
	background: transparent;
}

.imageInfoBox {
	width: 340px;
	float: left;

	@at-root :global #models_index .imageInfoBox {
		margin-left: 45px;
	}
}

/*Slideres tartalom doboza */
#slideContent {
	background-color: var(--box--info-bg);
	float: none;
	overflow: hidden;
	position: relative;
	margin: 0 30px 25px 30px;
	padding: 20px;
}

#slideContainer {
	width: 840px;
	height: 315px;
}

#slideText {
	text-align: left;
	width: 800px;

	p {
		@include style('text', 'normalSize', 'regularColor');

		margin: 0 0 20px 0;
	}
}

.dialogBlock {
	padding: 28px 30px 40px 30px;

	p {
		margin: 0 0 22px 0;

		&.error {
			text-align: center;
			margin: 0;
		}
	}

	.dialogDetails {
		color: var(--text--secondary);
		font-size: 14px;
		line-height: 16px;
	}

	.primaryLarge {
		margin: 2px 4px 0 0 !important;
	}

	.secondaryLarge {
		position: relative;
		top: -1px;

		/*webkit hack*/
		@media screen and (-webkit-min-device-pixel-ratio:0) {
			top: 0;
		}
	}
}

//horizontal scroll pane
.scroll-pane-horizontal {
	border-radius: 8px;
	cursor: pointer;
	bottom: 26px;
	height: 18px;
	z-index: 10;
	position: relative;
	padding: 0 2px;
	margin: 3px 0;
	border-bottom: 1px solid var(--box--main-bg);
	background-color: var(--table--highlight-bg);

	.scrollbar {
		border-radius: 6px;
		position: absolute;
		margin: 2px;
		height: 13px;
		min-width: 30px;
		width: 100%;
		border-top: 1px solid var(--scrollpane--horizontal--border);
		background: var(--scrollpane--horizontal--bg);
	}
}

//Naptar doboz formazasai
.calendarLayer {
	position: absolute;
	width: 122px;
	height: 32px;
	top: 0;
	left: 0;
	z-index: 1;
	display: block;
	cursor: pointer;
}

.calendarBox {
	border-radius: 3px;
	display: none;
	position: absolute;
	top: 41px;
	left: 0;
	width: 204px;
	background: var(--old-box--calendar--bg);
	z-index: 5000;

	&.show {
		display: block;
	}

	.selectorLine {
		width: 204px;
		height: 30px;
		position: relative;
		z-index: 5002;
		background: var(--old-box--calendar--bg);

		&.invoiceRejectReason {
			border-radius: 3px 3px 0 0;
			border-bottom: 1px solid var(--old-box--calendar--border-passive);
		}

		&.month {
			padding: 0 0 3px;
		}
	}

	.calendarText {
		display: inline-block !important;
		color: var(--text--base);
		font-weight: bold;
		font-size: 12px;
		line-height: 20px;
		cursor: pointer;
		padding: 0 10px;
		position: relative;

		.calendarMonth,
		.calendarYear {
			margin: 0 5px 0 0;
		}

		.calendarDownArrow {
			margin: 0 0 2px 1px;
		}

		&:hover {
			color: var(--text--link);

			.calendarDownArrow {
				@include style('icon', 'calendarDownArrowActive');
			}
		}
	}

	.prevPeriod {
		left: 0;
	}

	.nextPeriod {
		right: 0;
	}

	.prevPeriod,
	.nextPeriod {
		border-radius: 3px;
		width: 36px;
		height: 30px;
		position: absolute;
		top: 0;
		display: block;
		font-size: 10px;

		&:hover {
			.stepperArrowLeft {
				@include style('icon', 'linkArrowHorizontal', 'linkArrowLeft');
			}

			.stepperArrowRight {
				@include style('icon', 'linkArrowHorizontal', 'linkArrowRight');
			}
		}

		&.hideArrow {
			display: none;
		}

		.stepperArrowLeft,
		.stepperArrowRight {
			top: 11px;
		}

		.stepperArrowLeft {
			left: 15px;
		}

		.stepperArrowRight {
			right: 15px;
		}
	}

	.daysContainer {
		border-radius: 0 0 3px 3px;
		position: relative;
		z-index: 5002;
		background: var(--old-box--calendar--bg);
		padding: 0 3px 4px 3px;

		.days {
			border-radius: 5px;
			width: 24px;
			height: 24px;
			margin: 0 2px 2px 0;
			font-size: 12px;
			line-height: 24px;
			border: 1px solid var(--old-box--calendar--border-passive);
			color: var(--old-box--calendar--text-passive);

			&.valid {
				border: 1px solid var(--old-box--calendar--border-active);
				cursor: pointer;
				color: var(--text--base);

				&:hover {
					color: var(--text--link);
				}
			}

			&.selected {
				color: var(--text--link);
				font-weight: bold;
				border: 1px solid var(--text--link);
			}

			&.today {
				background: var(--old-box--calendar--border-active);
			}
		}
	}

	.selectData {
		border-radius: 3px;
		position: absolute;
		display: none;
		z-index: 5003;
		top: 0;
		left: 0;
		width:100%;
		height: 100%;
		background: var(--old-box--calendar--bg);
		box-shadow: 0 5px 10px RGBA(var(--box--shadow-rgb),0.3);

		overflow: hidden;

		&.show {
			display: block;
		}

		.scrollData{
			height: 100%;

			.simplebar-scrollbar {
				right: 5px;
			}
		}

		.listContainer{
			text-align: center;
			width: 204px;
			font-size: 13px;
			line-height: 26px;

			li {
				cursor: pointer;
				color: var(--text--base);

				&:hover {
					color: var(--text--link);
				}
			}
		}
	}
}

#galleryLeftStep,
#galleryRightStep {
	display: none;
	width: 240px;
	height: 100%;
	position: absolute;
	top: 0;
}

#galleryLeftStep {
	left: -240px;
}

#galleryRightStep {
	right: -240px;
}

@import "boxes.oranum";

.oranum-theme {
	#account-settings {
		.box_header {
			background: initial;
		}
		.menu-content {
			.ajax-checkbox {
				label {
					top: -5px;
				}
			}

			&[data-content=private-data] {
				.details p {
					margin-right: 20px;

					&.info-box {
						color: var(--account-settings--info-box-color);
					}
				}

				small {
					width: 95%;
				}
			}

			.privacy-rights {
				label.accountSettingsLabel {
					min-width: auto;
					margin: 0;

					& + .inputs {
						bottom: 10px;
					}
				}
			}
		}

		.menu {
			li {
				background: var(--account-settings--list-inactive-background);

				&.active {
					font-weight: bold;
					background: var(--account-settings--list-active-background);
				}
			}
		}
	}

	#accountSettingsContent {
		&.sensitive-data-confirmation {
			.icon-alert {
				color: var(--account-settings--sensitive-data-confirmation-header-icon);
			}
		}
	}
}

@use "sass:math";

////
/// @group Form
/// @author Viktor Vincze
////

/// Input focus style
/// @access private
%_form-focus {
	color: var(--form--input-focus-text);
	background: var(--form--input-focus-background);
	border-color: var(--form--input-focus-border);
	border-width: 1px;
	box-shadow: none;
}

/// Default input style
/// @access private
%_form-input-style {
	@include _rem-each((
		height: _form('dimension.input-height'),
		line-height: _form('dimension.line-height'),
		font-size: _form('font-size.input'),
		padding: _form('dimension.input-padding'),
		border-radius: _dim('global-radius')
	));

	display: block;
	outline: none;
	color: var(--form--input-text);
	caret-color: var(--form--input-caret);
	background: var(--form--input-background);
	border: 1px solid transparent;
	box-shadow: 0 0.1rem 0 var(--form--input-background-lighten-15);
	width: 100%;
	font-family: _typo('font-family.sans');

	&.need-bottom-space {
		margin-bottom: 2.5rem;
	}
}

@import 'form.module.oranum';

input::-ms-clear,
input::-ms-reveal {
	display: none;
}

.#{_format-selector('form', false, false, true)} {

	&-row {
		@include _rem(margin-bottom, _form('gutter.form-row-bottom'));
		position: relative;
	}

	&--disabled {
		label {
			opacity: .5;
		}
	}

	label:not(.unstyle) {
		@include _rem-each((
			padding-right: _form('gutter.label-right'),
			line-height: _form('dimension.input-height'),
			font-size: _form('font-size.label'),
		));

		display: block;
		width: 100%;
		text-align: right;
		position: relative;
	}
	// Hide spinner buttons from number input
	input[type=number]::-webkit-inner-spin-button,
	input[type=number]::-webkit-outer-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}

	input,
	input[type="checkbox"] + label,
	input[type="radio"] + label,
	textarea {
		@extend %_form-input-style;

		&:focus,
		&.input-focus {
			@extend %_form-focus;
		}

		&::placeholder {
			color: var(--form--input-placeholder-text);
		}
	}

	@at-root :global .ph-form-input {
		@extend %_form-input-style;

		&:focus {
			@extend %_form-focus;
		}

		&::placeholder {
			color: var(--form--input-placeholder-text);
		}
	}

	input[type="checkbox"],
	input[type="radio"]{
		opacity: 0;
		width: 0;
		height: 0;
		display: none;
		margin: 0;
		padding: 0;

		& + label {
			@include _rem-each((
				width: 24,
				height: 24
			));
			user-select: none;
			display: inline-block;
			padding: 0;
			position: relative;
			cursor: pointer;
			z-index: 1;
			vertical-align: middle;

			&:before {
				display: inline-block;
				position: absolute;
				visibility: hidden;
			}
		}

		&:checked + label:before {
			opacity: 1;

			visibility: visible;
		}

		& + label + label {
			@include _rem-each((
				padding-left: 10
			));

			width: calc(100% - 3rem);
			text-align: left;
			display: inline;
			position: relative;
			line-height: 2.8rem;
			vertical-align: top;
		}

		&[disabled] {
			&,
			& + label,
			& + label + label {
				opacity: 0.5;
				cursor: not-allowed;
			}
		}

		&:focus + label {
			@extend %_form-focus;
		}
	}

	input[type="checkbox"] {
		& + label {
			&:before {
				@include _rem-each((
					width: 0,
					font-size: 24,
					left: -1,
					top: -1
				));
				transition: none;

				text-shadow: 0 1px 0 RGBA(0, 0, 0, 0.17);
				color: var(--form--radio-check);
				overflow: hidden;
				text-align: left;
			}
		}

		&:checked + label:before {
			@include _rem-each((
				width: 24
			));
			transition: all 0.2s ease-out 0s;
		}
	}

	input[type="radio"] {
		& + label {
			@include _rem(border-radius, 50);

			background-color: var(--form--radio-background);
			border: 0.1rem solid var(--form--radio-border);;

			&:before {
				@include _rem-each((
					width: 12,
					height: 12,
					border-radius: 50,
					left: 5,
					top: 5
				));
				opacity: 0;
				transform: scale(0);
				transition: all, 0.2s, cubic-bezier(0.175, 0.885, 0.320, 1.8);
				content: '';
				background-color: var(--form--radio-check);
			}
		}

		&:checked + label {
			border: 0.2rem solid var(--form--radio-check);
		}

		&:checked + label:before {
			transform: scale(0.8);
			left: 0.4rem;
			top: 0.4rem;
		}
	}

	input[type="select"] {
		cursor: pointer;
	}

	textarea {
		@include _rem-each((
			min-height: _form('dimension.input-height') * 2,
			padding-top: math.div(_dim("global-gutter"), 2),
			padding-bottom: math.div(_dim("global-gutter"), 2)
		));

		resize: none;
		line-height: _typo('line-height.root');
		max-width: 100%;
		font-family: _typo('font-family.sans');
	}

	textarea[disabled],
	input[disabled]{
		opacity: 0.5;
		cursor: not-allowed !important;
	}

	.#{_format-selector('input', false, false, true)} {

		&-disabled {
			opacity: 0.5;
			cursor: not-allowed !important;
		}

		&-has-icon {
			position: relative;

			input {
				@include _rem(padding-left, _form('font-size.input') * 3);
			}

			input:focus + .input-icon,
			textarea:focus + .input-icon {
				color: var(--form--input-text);
			}

			textarea {
				@include _rem-each((
					padding-left: math.div(_dim(global-gutter), 2) + (_form('font-size.input') * 2),
				));
			}
		}

		&-required {
			label {
				&:after {
					content: '*';
					color: var(--form--required-asterix);
					font-style: normal;
					font-family: _typo('font-family.serif');
					position: absolute;
					top: 50%;
					transform: translate(0, -50%);
					@include _rem-each((
						font-size: 18,
						right: 10
					));
				}
			}

			[type='checkbox'] + label,
			[type='radio'] + label {
				&:after {
					display: none;
				}
			}
		}

		&-icon {
			position: absolute;
			color: var(--form--input-placeholder-icon);

			@include _rem-each((
				font-size: _form('font-size.input') + math.div(_form("font-size.input"), 2),
				top: math.div(_form('dimension.input-height'), 2),
				margin-top: math.div(((_form('font-size.input') + math.div(_form("font-size.input"), 2)) * -1), 2),
				left: _form('font-size.input')
			));

			&-textarea {
				@include _rem-each((
					top: math.div(_dim(global-gutter), 2),
					left: math.div(_dim(global-gutter), 2)
				));

				margin: 0;
			}
		}

		&-state {
			&-error,
			&-error + label, // Checkbox / radio
			&-error:focus {
				border-color: var(--form--input-state--error-border) !important;
				color: var(--form--input-state--error-text);
				box-shadow: none !important;
			}
		}

		&-merge {
			border-radius: 0;
			border-left-color: var(--form--input-background);
			border-right-color: var(--form--input-background);

			&-first {
				@include _rem-each((
					border-top-left-radius: _dim(global-radius),
					border-bottom-left-radius: _dim(global-radius)
				));
				border-left-color: var(--form--input-border);
			}

			&-last {
				@include _rem-each((
					border-top-right-radius: _dim(global-radius),
					border-bottom-right-radius: _dim(global-radius)
				));
				border-right-color: var(--form--input-border);
			}
		}
	}
}

:global .oranum-theme {
	.old-form {
		.inputs {
			.searchBar {
				background-image: url(data:image/svg+xml;base64,PCEtLSBHZW5lcmF0ZWQgYnkgSWNvTW9vbi5pbyAtLT4KPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjMyIiBoZWlnaHQ9IjMyIiB2aWV3Qm94PSIwIDAgMzIgMzIiPgo8dGl0bGU+bWFnbmlmaWVyPC90aXRsZT4KPHBhdGggZmlsbD0iI0QzQzJFNSIgZD0iTTMxLjcxOSAyOS4wNjlsLTguNDg4LTguNDI1YzAuMjM4LTAuMzE5IDAuNDA2LTAuNTQ0IDAuNTI1LTAuNzA2bDAuMDEyLTAuMDE5YzAuMDk0LTAuMTI1IDAuMTg4LTAuMjUgMC4yNzUtMC4zNzVsMC4wMDYtMC4wMTJjMy4zMjUtNC43MTkgMi41ODctMTEuMzk0LTEuODA2LTE1LjczOC0yLjQ1Ni0yLjQyNS01LjcyNS0zLjc2My05LjItMy43NjNzLTYuNzQ0IDEuMzM3LTkuMiAzLjc2M2MtMi40NTYgMi40MjUtMy44MTMgNS42NS0zLjgxMyA5LjA4OCAwIDMuNDMxIDEuMzU2IDYuNjU2IDMuODEzIDkuMDg4IDIuNDUgMi40MTkgNS43MTMgMy43NSA5LjE4MSAzLjc1djBjMi4wODEgMCA0LjE1LTAuNDk0IDYtMS40NDQgMC4yMDYtMC4xMTkgMC44MjUtMC40NjkgMS4zMTktMC44NjNsOC42MTkgOC4zMTljMC4wNTAgMC4wNTAgMC4zMzEgMC4zMDYgMC43MzggMC4zMDYgMC4xNzUgMCAwLjQ0NC0wLjA1MCAwLjctMC4zbDAuNDMxLTAuNDEzYzAuMzMxLTAuMzEzIDAuNjc1LTAuNjQ0IDAuOTI1LTAuODg3IDAuNDgxLTAuNDc1IDAuMjQ0LTEuMDc1LTAuMDM4LTEuMzY5ek0xMi45ODEgMy40ODdjNS4yMzggMCA5LjUgNC4yMDYgOS41IDkuMzgxIDAgNS4xNjktNC4yNjIgOS4zODEtOS41IDkuMzgxcy05LjUtNC4yMDYtOS41LTkuMzgxYzAtNS4xNzUgNC4yNjMtOS4zODEgOS41LTkuMzgxeiI+PC9wYXRoPgo8L3N2Zz4K);
				background-repeat: no-repeat;
				background-size: 15px;
				background-position: 10px center;
			}
		}
	}

	.form-input-additions {

		form-mixin-select {
			width: 2.6rem;
			height: 2.6rem;

			i {
				top: 36%;

				&.icon-caret-old-up {
					top: 32%;
				}
			}
		}

		> * {
			i {
				&.icon-alert {
					color: var(--registration--icon-warning);
				}
			}
		}
	}

	form-mixin-eye {
		color: var(--form--input-placeholder-icon);
		opacity: 1;
	}

	form-selectorbox {
		background-color: var(--registration--account-type-bg);
		border-width: 0.2rem;

		&.selected {
			border-color: var(--text--link) !important;
		}
	}

	form-mixin-captcha {
		.captcha {
			display: inline-block;
			margin: 0 1rem 0 0;
			border: 1px solid var(--form--input-border);
			background-color: var(--captcha-background);
		}

		img {
			margin: 0;
			height: 3.8rem;
			width: 13.3rem;
		}
	}

	.old-form {
		input,
		textarea {
			background: var(--form--input-background);
			border: 1px solid var(--form--input-border);
			box-shadow: none;
		}

		.icon-caret-old-down {
			z-index: 1;
		}

		&#countryBan,
		&#guestBan {
			.icon-caret-old-down {
				padding-left: 1px;
			}
		}
	}

	#modelListContainer {
		.icon-caret-old-down {
			top: 6px;
		}
	}

	.scrollContainer {
		padding: 0;
		box-shadow: 0 0.4rem 1rem RGBA(var(--form--shadow-rgb), 0.2);
		border-radius: 0.4rem;
		border: 0.1rem solid var(--form--input-focus-border);
		user-select: none;
		overflow: hidden;

		.dropDownContainer {
			width: 100%;

			.slideContainer {
				width: 100%;

				.listContainer {
					width: 100%;
					max-width: 100%;
					li {
						padding: 0 1.5rem;
						color: var(--scrollable--item-list);
						&:hover {
							&.enabled {
								color: var(--scrollable--item-list-hover);
								background: var(--scrollable--item-list-hover-bg);
							}
						}
						&:focus {
							&.enabled {
								color: var(--scrollable--item-list-selected);
							}
						}
					}
				}
			}
		}
	}

	input[disabled="disabled"] + div[ref="form-input-mobile-tap"] + form-mixin-datatable + div[ref="inside"] form-mixin-select {
		opacity: 0.6;
	}
}
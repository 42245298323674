@import '../../scss/shell';

:global {
	.commonProgressBarWrapper {
		width: 75%;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		position: absolute;
		text-align: center;
	}

	.commonProgressBar {
		background: var(--progress-bar--bg);
		height: 0.8rem;
		border-radius: 0.4rem;

		.progressContainer {
			height: 100%;
			border-radius: 0.4rem;
			overflow: hidden;
			width: 0; /* Increase this for progress */
			margin-bottom: 1rem;
			transform: translateZ(0);

			&.ph-full-width {
				width: 100%;
			}
		}

		.progressLine {
			height: 100%;
			width: 2000%;
			background: var(--progress-bar--line);
			overflow: hidden;

			@include transition(0.1s);
			@include animation(commonProgressBarStripeLeftToRight 1s linear infinite);

			&.stripe > b {
				transform: skew(-45deg, 0deg);

				width: 9px;
				margin-left: 9px;
				height: 100%;
				display: block;
				background: var(--progress-bar--stripe);
				float: left;
			}
		}
	}

	.mainstream-theme {
		.commonProgressBar {
			padding: 2px;
		}
	}
}

@keyframes :global(commonProgressBarStripeLeftToRight) {
	from {
		transform: translate(-36px,0);
	}
	to {
		transform: translate(-18px,0);
	}
}
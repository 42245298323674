@use 'global/colors';
@use 'global/icons';
@use 'global/vars';
@import '../../../../scss/shell';
@import 'common';

@mixin box-overlay {
	position: absolute;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
	opacity: 0.8;
	background: var(--promo-tools--layer-bg);
	border-radius: 0.4rem;
}

@mixin text-in-center {
	position: absolute;
	z-index: 1;
	top: 50%;
	transform: translateY(-50%);
	font-size: 1.2rem;
	font-weight: bold;
	width: 100%;
	text-align: center;
}

.icon-delete,
.settings-icon {
	position: absolute;
	bottom: 0.7rem;
	font-size: 1.8rem;
	padding: 1rem;
	cursor: pointer;
	z-index: 10;
	opacity: 1;

	&:active {
		color: var(--promo-tools--icon-active);
	}
}

.icon-delete {
	left: vars.spacer(3);
	bottom: vars.spacer(3);
	padding: 0;
	font-size: 2.4rem;
	color: var(--promo-tools--icon-active);

	&:hover::before {
		content: icons.get('trash-solid');
	}
}

:global .controls-icon-delete:hover i::before {
	content: icons.get('trash-solid') !important;
}

.settingsIconContainer {
	margin-bottom: 0.6rem;

	&[triggered] i {
		color: var(--promo-tools--icon-active);
	}
}

.settings-icon {
	right: 1rem;
	margin-right: -2rem !important;
	font-size: 2.4rem !important;
	top: 0.5rem !important;
	line-height: 1;
}

.dropdownContainer {
	right: 0;
}

.dropdownList {
	top: 1.5rem !important;
	right: -1.2rem !important;
}

.container {
	@include _on('mobile') {
		&.status-active,
		&.status-inactive,
		&.status-rejected {
			margin-bottom: 7.2rem;
			height: calc(50vw - 2rem) !important;
		}
	}
	/**
	 * Active Box styles
	 */
	&.status-active {
		.shadow {
			position: absolute;
			bottom: 0;
			left: 0;
			width: 100%;
			height: 0;
			opacity: 0.8;
			border-radius: 0.4rem;

			@include _vertical-gradient(transparent, var(--promo-tools--layer-bg));
		}

		.box {
			border-bottom: 0;
			padding-bottom: 0;
		}

		.box:hover {
			.shadow {
				height: 100%;
			}
		}
	}

	/**
	 * Inactive Box styles
	 */
	&.status-inactive {
		.inner:after {
			content: '';
			@include box-overlay;
		}

		.box {
			border-bottom: 0;
			padding-bottom: 0;
		}

		.box:hover {
			.play {
				opacity: 0.6;
			}
		}
	}

	/**
	 * Failed Box styles
	 */
	&.status-failed {
		.inner:after {
			content: '';
			@include box-overlay;
		}

		p {
			@include text-in-center;
		}

		:global .icon-info-circle {
			cursor: pointer;
		}
	}

	:global .icon-info-circular-outline {
		color: var(--promo-tools--icon-active);
		bottom: 0.7rem;
		right: 0.7rem;
		font-size: 2.4rem;
		position: absolute;
		z-index: 1;
		cursor: pointer;

		&:hover::before {
			content: icons.get('info-circular-solid');
		}

		&:active {
			color: #{colors.get(neutral, 100)};
		}
	}

	/**
	 * Rejected Box styles
	 */
	&.status-rejected {
		.box {
			border-bottom: 0;
		}

		p {
			@include text-in-center;
		}

		.inner {
			&:after {
				content: '';
				@include box-overlay;
			}
		}
	}

	/**
	 * Waiting for Approval Box styles
	 */
	&.status-waiting {
		.inner:after {
			content: '';
			@include box-overlay;
		}

		.waitingForApprovalCaption {
			@include text-in-center;
			font-weight: normal;
			color: var(--promo-tools--title-active);
		}
	}

	/**
	 * Uploading/Converting Box styles
	 */
	&.status-uploading,
	&.status-converting {
		.inner {
			background-image: none !important;
		}

		:global .progressContainer {
			width: 100%;
		}

		:global .progressTitle {
			font-size: 1.4rem;
			text-transform: capitalize;
			color: var(--promo-tools--title-active);
		}
	}

	.date {
		color: var(--promo-tools--date);
		font-size: 1.2rem;
		padding: 0.5rem 0;
		line-height: 1.2;

		& + .box {
			border-radius: 0.4rem 0.4rem 0 0 !important;
		}
	}

	@include videoBox;

	.statusWrapper {
		display: flex;
		justify-content: space-between;
		align-items: center;
		font-size: 1.2rem;
		font-weight: bold;
		color: var(--promo-tools--info);
		padding: 0.9rem 0.8rem;
		white-space: nowrap;
		text-overflow: ellipsis;
		border: 0.2rem solid var(--channel--box--inner-border);
		border-top: 0;
		border-radius: 0 0 0.4rem 0.4rem;

		span {
			flex: 1;
		}

		i {
			font-size: 1.2rem;
			margin-right: 0.6rem;
			top: 0;
			position: relative;
		}

		.icon-delete {
			margin-right: 0;
			top: 0;
			left: 0;
			font-size: 1.8rem;
			color: var(--promo-tools--info);
		}

		em {
			font-size: 1.6rem;
		}

		.conversion {
			display: flex;
			flex-direction: column;

			span {
				font-weight: normal;
				flex: unset;
				margin-bottom: 0.2rem;
			}

			&-excellent {
				em,
				strong {
					color: #{colors.get(alert, success_500)};
				}
			}

			&-good {
				em,
				strong {
					color: var(--text--paragraph);
				}
			}

			&-low {
				em,
				span {
					color: #{colors.get(neutral, 100)};
				}
			}
		}

		.dropdown-list {
			position: absolute;
			top: 0.5rem;
			right: -1.7rem;
			background: var(--channel--dropdown--bg);
			z-index: 11;
			border-radius: 0.5rem;
			padding: 1rem 0;

			a {
				color: var(--channel--dropdown--link);
				display: block;
				padding: vars.spacer(3) vars.spacer(4) vars.spacer(3) vars.spacer(10);
				font-size: 1.4rem;
				font-weight: normal;
				line-height: 1.1;
				position: relative;

				&:hover {
					background: var(--channel--dropdown--bg-hover);
					color: var(--channel--dropdown--link-hover);
				}
			}

			i {
				position: absolute;
				top: vars.spacer(2);
				left: vars.spacer(2);
				font-size: 1.2rem;
				width: 2.8rem;
				text-align: center;
			}

			&:after {
				@include css-triangle(0.5rem, var(--channel--dropdown--bg), bottom);

				position: absolute;
				top: -0.9rem;
				right: vars.spacer(6);
				border-radius: 0.4rem;
			}
		}
	}

	.play {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		color: var(--promo-tools--play);
		cursor: pointer;
		z-index: 1;
	}
}

.gracePeriodBadge {
	position: absolute;
	top: 1.4rem;
	right: 1.4rem;
	z-index: 1;
	font-size: 0.8rem;
	font-weight: bold;
	line-height: 1rem;
	padding: 0.2rem 0.4rem;
	text-transform: uppercase;
	border-radius: 0.2rem;
	cursor: pointer;
	color: var(--promo-tools--grace-period-badge-text);
	background: var(--promo-tools--grace-period-badge-bg);

	&:hover {
		color: #{colors.get(neutral, 100)};
		background: var(--promo-tools--grace-period-badge-hover-bg);
	}
}

.waitingForApprovalCaption, .failedCaption {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	font-size: 1.4rem !important;

	i {
		font-size: 2.4rem;
		margin-bottom: 0.8rem;
	}
}

.failedCaption {
	.failIcon {
		color: #{colors.get(alert, danger_500)};
	}

	span {
		color: var(--text--paragraph);
	}

	.container.oranum {
		.inner:after {
			background-color: #{colors.get(neutral, 800)};
		}

		&.status-failed {
			p {
				color: white;
			}
		}
		&.status-rejected {
			.statusWrapper {
				i {
					color: #ff3333;
				}
			}
		}

		&.status-active {
			.shadow {
				background: -webkit-linear-gradient(top, #{colors.get(neutral, 900, 0)} 0%, #{colors.get(neutral, 900, 0.4)} 100%);
			}
		}

		.statusWrapper {
			background: #{colors.get(purple, 100)};
			color: #{colors.get(purple, 500)};
			i {
				position: static;
				top: 0;
				vertical-align: bottom;
			}
		}

		:global .icon-alert {
			color: #{colors.get(alert, progress_500)};
		}

		:global .icon-info-circle {
			color: white;
		}

		:global .progressTitle {
			color: #{colors.get(purple, 700)};
		}

		:global .progressLine {
			background: #7f33a2;
		}

		:global .commonProgressBar .progressLine.stripe > b {
			background: #{colors.get(decorative, violet_500)};
		}

		:global .commonProgressBar {
			height: 1rem;
			padding: 0.2rem;
		}
	}


	.container.oranum {
		@include videoBox-oranum;
	}
}

:global .rejection-promo-item-icon {
	color: #{colors.get(yellow, 500)};
	font-size: 1.6rem;
	float: left;
	margin: -0.6rem 0.5rem 1rem 0;
}

:global .protip-content em {
	font-family: Roboto, Arial, sans-serif;
}
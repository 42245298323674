@use 'global/colors';
@use 'global/icons';
@import '../../../../scss/shell';
@import 'common';

.expand-box {
	background-color: var(--promo-tools--info-list-bg);
	padding: 0 2.4rem;
	font-size: 1.6rem;
	margin: 2.4rem 2.4rem 0;
	border-radius: 0.4rem;
	position: relative;

	@include _on('mobile') {
		margin: 1.6rem;
		padding: 0 1.6rem;
	}

	header {
		position: relative;
		display: flex;
		min-height: 5.2rem;
		align-items: center;

		@include _on('mobile') {
			display: block;
			padding-top: 2.4rem;
			align-content: center;
		}

		strong {
			text-transform: uppercase;
		}

		.arrowUp,
		.arrowDown {
			position: absolute;
			color: var(--text--link);
			font-weight: bold;
			top: 1.4rem;
			right: 0;
			cursor: pointer;

			&:hover {
				color: #{colors.get(neutral, 100)};
			}
		}

		.arrowUp:hover::before {
			content: icons.get('arrow-up-solid');
		}

		.arrowDown:hover::before {
			content: icons.get('arrow-down-solid');
		}

		.randomTip {
			color: var(--promo-tools--info-list-text);
			font-size: 1.4rem;
			margin-left: 1.6rem;
			line-height: 2rem;
			font-weight: 200;
			padding-top: 0.1rem;

			strong {
				color: var(--text--base);
				font-weight: bold;
				text-transform: none;
			}

			@include _on('mobile') {
				margin-left: 0;
				padding-bottom: 2.4rem;
				margin-top: 0.4rem;
			}
		}
	}
}

.expand-content {
	display: flex;
	width: 100%;
	justify-content: space-between;
	@include list;

	@include _on('mobile') {
		flex-direction: column;
	}

	ul {
		margin: 0 0 2.5rem;
	}

	li {
		font-size: 1.4rem;
		margin-bottom: 0.8rem;
	}

	.video-wrapper {
		@include infoVideoPreview;
		box-shadow: none;
		margin: 0 auto;

		.preview {
			margin-bottom: 1.6rem;

			img {
				width: 38.2rem;
				height: 21.5rem;
				border-radius: 0.4rem;

				@include _on('mobile') {
					width: 100%;
					height: auto;
				}
			}

			&.portrait {
				img {
					width: auto;
					@include _on('mobile') {
						height: 38.2rem;
					}
				}

				.previewText {
					padding: 0.4rem 0.7rem;
					width: 80%;
					font-size: 1rem;

					@include _on('mobile') {
						font-size: 1.5rem;
						padding: 0.8rem 1.5rem;
						bottom: 3.2rem;
					}
				}
			}
		}

		.promoTeaserPreview {
			position: relative;

			&::before {
				content: '';
				position: absolute;
				width: 13rem;
				height: 16rem;
				left: 50%;
				top: 50%;
				transform: translate(-50%, -50%);
				background: var(--promo-tools--preview-decoration);
				filter: blur(6.5rem);
				z-index: 1
			}

			img {
				position: relative;
				z-index: 2;
				width: auto;
			}

			&.portrait {
				img {
					@include _on('mobile') {
						height: 28.2rem;
					}
				}
			}
		}

		.previewText {
			position: absolute;
			bottom: 1.4rem;
			left: 0;
			padding: 0.8rem 1.5rem 0.6rem;
			text-transform: uppercase;
			font-size: 1.5rem;
			font-weight: bold;
			line-height: 1.2;
			color: var(--promo-tools--preview-text);
			background: var(--promo-tools--preview-bg);
		}
	}
}

.oranum {
	--primary: rgb(var(--color-primary));
	--text--secondary: rgb(var(--color-text));
	.expand-box {
		font-size: 1.4rem;

		header strong {
			font-size: 1.6rem;
			color: var(--primary);
		}

		.randomTip strong {
			font-size: 1.4rem;
		}
	}
	.expand-content {
		.video-wrapper {
			display: none;
		}
	}
}

.videoCallTeaser {
	ul {
		width: 52%;

		@include _on('mobile') {
			width: 100%;
		}
	}
	.video-wrapper {
		text-align: center;

		.preview {
			img {
				width: 87%;
				height: auto;
			}

			.previewText {
				width: 14.5rem;
				bottom: 8%;
				left: unset;
				right: 7%;
				font-size: 1.4rem;
				text-align: left;
				text-transform: none;
				color: var(--promo-tools--preview-text-inverse);
				background: var(--promo-tools--preview-bg-inverse);
			}
		}
	}
}
@import '../../../scss/shell';

:global {
	#payoutOptionsOverlay {
		padding: 4rem 14rem;
		text-align: center;

		@include _on('mobile') {
			padding: 4rem 1rem;
		}

		&.payoutCurrencyOverlay {
			padding: 4rem;
		}

		.ph-form-row {
			margin: 1rem 0 3rem;
		}

		.ph-p {
			margin: 0 0 2rem;
		}

		.confirmText {
			margin: 4rem 0 2rem;
		}

		.button {
			margin: 0 2rem;
		}

		form-mixin-info {
			margin: 0;
		}

		form-submit {
			.ph-relative {
				display: contents;
			}
		}
		@include _on('mobile') {
			.button{
				margin: 0 .5rem;
			}

			form-input{
				width: 90%;
			}
			#changePayoutTypeForm
			.form-input-container {
				position: relative;
			}
		}

		&.payoutTypeOverlay {
			padding: 4.8rem 5rem 1.8rem;

			h2 {
				margin-bottom: 1.6rem;
			}

			.ph-p {
				max-width: 39rem;
				margin: 0 auto;
				text-align: left;
			}

			.ph-form-row {
				margin-top: 3.2rem;
			}

			.button {
				margin: 0 0.4rem;
			}
		}
	}

	.currencyId-info,
	.payoutType-info,
	.payoutLimit-info,
	.autoInvoice-info {
		max-width: 54.5rem !important;
		text-align: center;
		line-height: 1.4rem;
		padding: 2rem;

		br {
			line-height: 2;
		}
	}

	@include _on('mobile') {
		.currencyId-info,
		.payoutType-info,
		.payoutLimit-info {
			max-width: 60vw !important;
		}
	}
}
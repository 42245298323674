@use 'global/icons';

/*
 * Site specific default formatting.
 */

#main_container {
	min-height: 100vh;
	position: relative;
	width: 100%;
	background: var(--background);
	overflow: hidden;
	display: flex;
	flex-direction: column;

	&.overlayed {
		position: fixed;

		@include _on('medium', 'up') {
			left: -8px;
		}
	}
	& > div {
		width: 100%;
	}
}

#main_content {
	flex-grow: 1;
}

.capitalize {
	text-transform: capitalize;
}

.layout-modelcenterbasic #main_content {
	max-width: 1250px;
	padding-left: 2.2rem;
	padding-right: 2.2rem;
	width: 100%;
	margin: 4.8rem auto 0 auto;
	padding-bottom: 4.8rem;

	@include _on('small', 'down') {
		margin-top: 0;
		padding-left: 0;
		padding-right: 0;
		padding-bottom: 0;
	}

	@include _on('medium', 'up') {
		@include _flexbox;

		> input, > ph-clear {
			display: none !important;
		}
	}

	&.small {
		width: 920px;

		#pageContainer {
			width: 920px;
			height: 100%;
			min-height: 0 !important;
			text-align: center;
		}

		.box_container {
			padding: 30px 0;
			width: 935px;
		}

		.box_header {
			margin: 0 0 30px 0;
		}

	}
}
.layout-modelcenterbasic .protip-container {
	z-index: 45;
}

#inner_main_content {
	border-radius: 4px;
}

#pageContainer {
	border-radius: 4px 4px 0 0;
	float: left;
	width: 100%;
	min-height: 1074px;
	height: 100%;

	@include _on('small', 'down') {
		min-height: 0;
	}
}

#showMoreHolder {
	clear: both;
	text-align: center;
}

// A tobb infot megmutato link formazasai
.showMoreLink {
	display: inline-block;
	font-weight: bold;
	position: relative;
	padding-left: 28px;
	padding-right: 28px;
	font-size: 12px;
	color: var(--text--link-new);

	.info-icon {
		display: inline-block;
	}

	.showText {
		display: inline-block;
	}

	.hideText {
		display: none;
	}

	.linkArrowRight {
		top: 2px;
		right: -20px;
	}

	.icon-down {
        font-size: 19px;
		display: inline-block;

        &.icon-up {
            i::before {
                content: icons.get('arrow-up-outline');
            }
        }
	}

	&:hover {
		.info-icon {
			i::before {
				content: icons.get('info-circular-solid');
			}
		}

		.icon-down {
			i::before {
				content: icons.get('arrow-down-solid');
			}

			&.icon-up {
				i::before {
					content: icons.get('arrow-up-solid');
				}
			}
		}

		.linkArrowRight {
			@include style('icon', 'linkArrowHorizontal', 'linkArrowRightActive');
		}
	}
}

.showMoreContent {
	@include style('text', 'regularColor', 'largeSize');

	display: none;
	padding: 30px;
	text-align: left;

	&.show {
		display: block;
	}
}

#confirmOverlay {

	.box_header {
		padding: 0;
		height: 0;
	}
}

#confirmOverlayContent {
	text-align: center;
	padding: 40px 20px;

	p {
		margin: 0 0 40px 0;
	}

	.primaryLarge {
		margin: 0 10px 0 0;
	}

	.actionConfirmForm {
		display: inline;
		margin-right: 8px;
		position: relative;
	}
	&.promo-tool {
		width: 80%;
		margin: auto;

		@include _on('mobile') {
			width: 100%;
			padding: 4rem 1.6rem;

			button {
				width: 100%;
				margin: 1rem 0;
			}
		}
		h2 {
			font-size: 20px;
		}
		.overlayBlock {
			width: 80%;
			margin: auto;
		}
		form-submit {
			margin-right: 0;
		}
		form {
			margin-right: 0;
		}
		a, button {
			margin-right: 5px;
			margin-left: 5px;
		}
		p {
			color: var(--text--paragraph);
		}
	}
	&.promo-delete {
		p {
            font-size: 1.4rem;
			color: var(--text--paragraph);
		}
	}
}

h1 {
	font-weight: bold;
	font-size: 36px;
	line-height: 36px;
	color: var(--text--base);
	margin: 0 0 30px 0;
}

h2 {
	font-weight: bold;
	font-size: 26px;
	line-height: 26px;
	color: var(--h2-color);
	margin: 0 0 25px 0;
}

h3 {
	font-weight: bold;
	font-size: 20px;
	line-height: 20px;
	color: var(--text--base);
	margin: 0 0 20px 0;
}

h4 {
	font-weight: bold;
	font-size: 16px;
	line-height: 16px;
	color: var(--text--base);
	margin: 0 0 10px 0;
}

h5 {
	font-weight: bold;
	font-size: 14px;
	line-height: 16px;
	color: var(--text--rejected);
}

a {
	color: var(--text--link);
	text-decoration: none;

	&:hover {
		color: var(--text--link-hover);
	}
}

.breadCrumbsTitle {
	@include style('text', 'breadCrumb');

	.pathArrow {
		margin: 0 0.5rem;
	}
}
.breadCrumbsTitleLast {
	font-weight: 700;
	color: var(--text--breadcrumb-last);
}


.highlightedNormalText {
	font-weight: bold;
	font-size: 12px;
	line-height: 16px;
	color: var(--text--base);
}

.highlightedHugeText {
	@include style('text', 'hugeSize', 'highliteColor');
}

.regularTextNormal {
	@include style('text', 'normalSize', 'regularColor');
}

.regularTextNormalList {
	@include style('text', 'normalSize', 'regularColor');
	@include style('list', 'normal');
}

.regularTextLarge {
	@include style('text', 'largeSize', 'regularColor');
}

.regularTextLargeList {
	@include style('text', 'largeSize', 'regularColor');
	@include style('list', 'large');
}

.regularTextHuge {
	font-size: 14px;
	line-height: 16px;
	color: var(--text--secondary);
}
.regularTextGiga {
	font-size: 16px;
	line-height: 20px;
	color: var(--text--secondary);
}

.passiveTextNormal {
	@include style('text', 'passiveColor');

	font-size: 12px;
	line-height: 14px;
}

.passiveTextHuge {
	@include style('text', 'hugeSize', 'passiveColor');
}

.hide {
	display: none !important;
}

.hide-fl, .hide-fl * {
	float: left;
	opacity: 0;
	display: none;
}

.hidden {
	overflow: hidden;
	width: 1px;
	height: 1px;
	// Makes IE to force render flash
	opacity: 0.01;
}

.textBorder {
	@include textBorder();
}

body.noselect > *,
.noselect {
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	-o-user-select: none;
	user-select: none;
}

body {
	background: var(--background);
	@media all and (orientation: landscape) {
		padding-left: env(safe-area-inset-left);
		padding-right: env(safe-area-inset-right);
	}
	padding-bottom: env(safe-area-inset-bottom);
}

body.loading {
	cursor: wait;
}

.hr.light {
	width: 80%;
	height: 0;
	position: relative;
	text-align: center;
	margin: 20px auto 30px auto;
	overflow: visible;
	border-top: 2px solid var(--hr--light-background);

	span {
		color: var(--text--emperor);
		background-color: var(--box--main-bg);
		font-weight: bold;
		font-size: 14px;
		position: relative;
		top: -10px;
		display: inline-block;
		text-transform: uppercase;
		line-height: 18px;
		padding: 0 20px;
	}
}

.pointerEventsNone {
	pointer-events: none;
}

.blockCenter {
	margin-right: auto;
	margin-left: auto;
}

.textCenter {
	text-align: center;
}

.yellow {
	color: var(--common--alert);
}

.highlighted {
	position: relative;
	z-index: 2;
}

.chaosError {
	padding: 1rem 2rem;
	font-size: 1.4rem;
}

@import '../../../scss/shell';

:global {
	#hugeTrafficOverlay {
		background-image: url('./huge-traffic.jpg');
		background-position: center center;
		background-size: cover;
		padding: 50px 0 90px 30px;
		@include _on('mobile', 'down') {
			background-image: url('./huge-traffic-without-girl.jpg');
			text-align: center;
			padding: 50px 0 50px 0;
		}
		.content {
			width: 330px;
			@include _on('mobile', 'down') {
				width: 80%;
				margin: auto;
			}
		}
		h3 {
			font-size: 22px;
			margin: 0;
			padding: 0;
			font-weight: 500;
			line-height: 1;
			span {
				color: var(--huge-traffic-overlay--title);
			}
		}
		p {
			font-size: 16px;
			margin-top: 15px;
			color: var(--huge-traffic-overlay--text);
			line-height: 1.3;
			strong {
				color: var(--huge-traffic-overlay--bold);
			}
		}
		.button {
			margin-top: 85px;
			@include _on('mobile', 'down') {
				margin-top: 40px;
			}
		}
	}
}
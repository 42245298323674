@import '../../scss/shell';

:global {
	/* DEFAULT OVERLAY BOX */
	body[id^=channel].overlay {
		overflow: hidden !important;
	}
	#mvc_overlay {
		background: RGBA(var(--overlay-container-background-rgb), 0.8);
	}
	#mvc_overlay,
	#mvc_overlay_background {
		display: none;
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 9999 !important;
		overflow: hidden;
	}
	#mvc_overlay_container {
		display: none;
		height: 100%;
		width: 100%;
		z-index: 10000;
		position: fixed;
		overflow-y: auto;
		overflow-x: hidden;
		top: 0;
		left: 0;

		.popup_box {
			position: relative;
			z-index: 100021;
			top: 80px;
			margin: 0 auto 150px;
			border-radius: 5px;
			-webkit-radius: 5px;
			-moz-border-radius: 5px;
		}

		.popup_box_header {
			height: 52px;
			line-height: 52px;
			padding: 0 0 0 20px;
			overflow: hidden;
			border-radius: 5px 5px 0 0;
			-webkit-radius: 5px 5px 0 0;
			-moz-border-radius: 5px 5px 0 0;
			position: relative;
			z-index: 10;

			h2, a.header_link {
				display: block;
				float: left;
				font-size: 18px;
				line-height: 52px;
			}

			.lock_icon {
				display: inline-block;
				width: 9px;
				height: 12px;
				margin: 20px 0 0 8px;
			}

			a.second_button {
				position: absolute;
				right: 48px;
				top: 12px;
				margin: 0;
				z-index: 100;
			}
		}
	}

	#overlay_message .popup_box_header .arrow {
		display: block;
		float: left;
		width: 5px;
		height: 9px;
		margin: 22px 10px 0 9px;
	}

	#mvc_overlay_container {

		.popup_box_header a.close_button {
			display: block;
			width: 40px;
			height: 40px;
			position: absolute;
			right: 3px;
			top: 6px;
			z-index: 100;
			span {
				display: block;
				width: 8px;
				height: 8px;
				margin: 16px;
			}
		}

		.popup_box_container {
			border-radius: 0 0 5px 5px;
			-webkit-radius: 0 0 5px 5px;
			-moz-border-radius: 0 0 5px 5px;
			position: relative;
			width: 100%;

			p {
				font-size: 14px;
				line-height: 14px;
			}

			&.signup {
				min-height: 548px;
			}
		}

		span.boxbg {
			display: block;
			width: 435px;
			height: 535px;
			position: absolute;
			right: 0;
			bottom: 0;
		}

		.popup_box_footer {
			text-align: center;
			position: relative;
			border-radius: 0 0 5px 5px;
		}

		.popup_box .popup_box_bottom {
			position: relative;
			height: 67px;

			div {
				display: table;
				position: absolute;
				height: 100%;
				width: 100%;

				p {
					display: table-cell;
					vertical-align: middle;
					width: 498px;
					text-align: center;
					font-size: 12px;
					line-height: 12px;
					padding: 0 15px;
				}
			}
		}
	}

	/* REFRESH WINDOW */

	#refresh_window {
		width: 640px;
		height: 220px;

		.popup_box_header {
			height: 50px;
		}

		.popup_box_container {
			text-align: center;

			p {
				padding: 45px 0 5px;
				font-size: 14px;
				line-height: 14px;
			}
		}
	}

	/* OVERLAY LOADER */

	#overlay_loader {
		display: none;
		margin: 0 auto;
		position: relative;
		top: 40%;
		text-align: center;
	}

	/* OVERLAY ERROR */

	#overlay_error {
		font-size: 18px;
		text-align: center;
		margin-top: 100px;
	}
}
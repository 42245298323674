@import '../../../scss/shell';

:global {

	.mycontent-landing {
		min-height: 100rem;
		background-image: url('mycontent-background.jpg');
		background-repeat: no-repeat;
		background-position: center top;
		background-size: contain;
		margin: 0 -4rem;
		padding: 3rem 0;

		h4, h3 {
			color: var(--channel--landing-title);
			margin-bottom: 1rem;
			font-size: 2rem;
			line-height: 1.2;
		}

		h2 {
			margin-bottom: 1rem;
			text-transform: uppercase;
			font-size: 3.2rem;
			line-height: 1;

			&:first-of-type {
				margin: -0.3rem 0 0.5rem;
			}
		}

		.margin-idk {
			margin: 1.7rem 2rem 2rem;
		}

		.showMoreLink {
			font-size: 1.4rem;

			i {
				display: inline-block;
				margin-right: 0.4rem;
				transform: translateY(0.1rem);
			}
		}

		// Simple goto link after h4 or h3 or h2
		a.goto {
			display: inline-block;
			font-size: 1.4rem;
			font-weight: bold;
			margin-top: 2rem;
			color: var(--card--default--link);

			&:hover {
				color: var(--card--default--link-hover);
			}
		}

		&__card {
			text-align: center;

			h2 {
				color: var(--card--default--title);
			}

			h3 {
				color: var(--card--default--subtitle);
			}
		}

		&__image-wrapper {
			position: relative;

			img {
				position: relative;
				top: 1.6rem;
			}
		}

		&__ribbon {
			width: 5.2rem;
			height: 5.2rem;
			top: -0.1rem;
			right: 1.9rem;

			&::after {
				top: .8rem;
				right: -2.5rem;
				width: 8.6rem;
				height: 2.1rem;
				font-size: .7rem;
				line-height: 2.1rem;
			}
		}

		&__list {
			text-align: left;
			margin-top: 2.8rem;

			li {
				position: relative;
				color: var(--channel--landing-list);
				font-size: 1.6rem;
				line-height: 1.2;
				padding-left: 3rem;
				margin-bottom: 3rem;
			}

			i {
				position: absolute;
				left: 0;
				top: 0.3rem;
				font-size: 1.2rem;
				color: var(--icon--tick--yellow);
			}
		}

		&__button {
			margin-top: 1rem !important;
			padding-left: 4rem;
		}

		.card-divider {
			height: 10rem;

			em {
				height: 4.5rem;
				margin: 1rem;
				display: inline-block;
				border-left: 0.2rem solid var(--card--divider);
			}
		}

		@include _on('mobile') {
			margin: 0;

			h2 {
				margin: 0 2rem 1rem;
			}

			&__card h2 {
				margin: 0 0 1rem;
			}
		}
	}

	.oranum-theme {
		.mycontent-landing {
			background-image: url('mycontent-background-oranum.jpg');
		}
	}
}

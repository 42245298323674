@use 'global/colors';
@use 'global/icons';
@use 'global/vars';
@import '../../scss/shell';

:global {
    form-input {
        position: relative;
        display: block;
        text-align: left;

        .form-input {
            &-container {
                &--with-addons {
                    @include _flexbox();
                    @include _flexbox-align(stretch);
                    @include _flexbox-wrap(wrap);

                    input {
                        @include _flex(1 1 auto);
                        width: 1%;
                    }
                }

                &--labeltop {
                    .form-input-additions {
                        &--outside,
                        &--inside {
                            bottom: 0;
                            top: auto;
                            max-height: 3.5rem;
                        }

                        &--top {
                            i {
                                font-size: 1.5rem;
                            }
                        }
                    }
                }
            }

            &-label {
                &--top {
                    &:not(.unstyle) {
                        text-align: left;
                        line-height: 1.5;
                        margin: 0.6rem 0 0.9rem;
                        padding-right: 0;
                        @include _flexbox-basis(100%);
                    }

                    .form-input-additions {
                        position: relative;
                        right: auto;
                        left: auto;
                        display: inline;
                        height: auto;
                        line-height: 1;
                        font-size: 1rem;
                        top: auto;

                        &-mixin {
                            top: auto;
                            position: relative;
                            margin-left: 1rem;
                            vertical-align: middle;
                            display: inline;
                            transform: none;
                        }

                        form-mixin-error,
                        form-mixin-ok {
                            @include _on('mobile') {
                                float: none;
                            }
                        }
                    }
                }
            }

            &--has-right-addon {
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
            }
            &--has-left-addon {
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
            }

            &-addon {
                @include _flexbox();
                @include _flexbox-align(center);

                background: var(--form--addon-bg);
                color: var(--form--addon-text);
                font-size: 1.3rem;
                white-space: nowrap;
                padding: 0 1rem;

                &--left {
                    border-right: 0.1rem solid var(--form--addon-border);
                    border-top-left-radius: 0.3rem;
                    border-bottom-left-radius: 0.3rem;
                }

                &--right {
                    border-left: 0.1rem solid var(--form--addon-border);
                    border-top-right-radius: 0.3rem;
                    border-bottom-right-radius: 0.3rem;
                }
            }
        }

        .protip-container:not(.protip-show) {
            z-index: -1;
        }

        &[type='select'] {
            font-size: 0;

            &.ready {
                font-size: inherit;
            }

            input {
                text-transform: capitalize;
            }
        }

        &[type='checkbox'] {
            label + label {
                text-align: left;

                ~ .form-input-additions--outside {
                    @include _on('mobile') {
                        left: auto;
                        right: 0;
                        top: 2px;

                        > * {
                            transform: none;
                            text-align: left;
                            top: 0;
                        }
                    }
                }
            }
        }
    }

    form-date {
        position: relative;
        display: block;

        .form-input-additions + .form-input-additions {
            i.icon-calendar {
                font-size: 2.2rem;
                color: var(--form-date--dropdown-icon);
            }
        }

        .apply-date {
            color: var(--form-date--apply-text);
        }

        form-input.iconed {
            input {
                padding-left: 5rem;
            }
        }

        .dh-calendar {
            display: none;
            position: absolute;
            top: 5rem;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 2;

            &.visible {
                display: block;
            }
        }

        .apply-date {
            display: block;
            text-transform: uppercase;
            line-height: 5rem;
            background: var(--form-date--apply-bg);
            text-align: center;
            font-size: 1.4rem;
        }
    }

    form-mixin {
        &-charcounter {
            transition: all 0.2s ease-out 0s;

            width: 100%;
            display: block;
            text-align: right;
            position: absolute;
            font-size: 1.1rem;
            opacity: 0;
            line-height: 2.4rem;
            color: var(--text--secondary);
            top: 100%;

            &.show {
                opacity: 1;
            }
        }

        &-highlight {
            position: absolute !important;
            top: 0 !important;
            left: 0 !important;
            width: 100% !important;
            height: 100% !important;
            pointer-events: none !important;
            line-height: #{_rem(_form('dimension.input-height')-2)} !important;

            span {
                background: var(--common--alert);
                color: var(--form--shadow) !important;
                -webkit-text-fill-color: var(--form--shadow) !important;
            }
        }

        &-eye {
            transition: opacity, 0.2s;
            font-size: 2rem;
            padding: 0 1.5rem 0 0.5rem;
            cursor: pointer;

            i {
                color: var(--form--input-eye-icon);
            }

            &:hover {
                opacity: 1;

                i {
                    color: var(--form--input-eye-icon-hover);

                    &::before {
                        content: icons.get('eye-on-solid');
                    }
                }

                i.icon-eye-off-outline {
                    &::before {
                        content: icons.get('eye-off-solid');
                    }
                }
            }
        }

        &-ok {
            color: var(--form--ok-icon);

            @include _on('mobile') {
                float: right;
            }
        }

        &-info {
            i {
                color: var(--form--info-icon);
                cursor: pointer;

                &:hover::before {
                    content: icons.get('info-circular-solid');
                    color: var(--form--info-icon-hover);
                }
            }
        }

        &-hint {
            transition: all, 0.2s;
            display: none;

            @at-root :global {
                form-input input:focus ~ .form-input-additions form-mixin-hint {
                    display: inline-block;
                }
            }
        }

        &-rejectedmodule,
        &-rejectedfield {
            i {
                color: var(--form--alert-icon);
            }
        }

        &-error {
            position: relative;
            z-index: 1;

            i {
                color: var(--form--alert-icon);
            }

            @include _on('mobile') {
                float: right;
            }
        }

        &-select {
            position: relative;
            margin: 0 0.5rem 0 0;
            width: _rem(_form('dimension.input-height')-10);
            height: _rem(_form('dimension.input-height')-8);
            background-color: var(--form--dropdown--button-background);
            border-radius: 0.3rem;
            border-bottom: 1px solid var(--form--dropdown--button-background-darken);
            cursor: pointer;

            i {
                transform: translate(0, -50%);
                position: absolute;
                top: 50%;
                width: 100%;
                text-align: center;
                pointer-events: inherit;
                font-size: 15px;
                color: var(--form--dropdown--button-caret);
                height: 100%;
                line-height: _rem(_form('dimension.input-height')-8) !important;
            }

            .form-mixin-select--open {
                display: none;
            }
            .form-mixin-select--closed {
                display: inline-block;
            }

            @at-root :global {
                form-input.focus,
                form-input input[type='select']:focus {
                    & ~ .form-input-additions form-mixin-select {
                        .form-mixin-select--open {
                            display: inline-block;
                        }
                        .form-mixin-select--closed {
                            display: none;
                        }
                    }
                }
            }
        }

        &-datatable {
            overflow: hidden;
            max-height: 30rem;
            position: absolute;
            top: 100%;
            z-index: 3;
            min-width: 100%;
            margin-top: 0.2rem;
            box-shadow: 0 0.5rem 1rem RGBA(var(--form--shadow-rgb), 0.35);
            background-color: var(--form--input-background);
            border: 0.1rem solid var(--form--input-focus-border);
            border-radius: 0.3rem;

            .simplebar-track.horizontal {
                display: none;
            }

            // Mobile tap area for datatable over the select
            @at-root :global .is-mobile-device {
                [type='select'] [name='form-input-mobile-tap'] {
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    z-index: 1;
                }

                [type='select'].disabled {
                    pointer-events: none;
                }

                .ph-form form-input form-mixin-datatable {
                    position: absolute;
                    z-index: 1;
                    top: 0;
                    left: 0;
                    bottom: 0;
                    right: 0;
                    opacity: 0;
                    padding: 0;

                    > div {
                        display: none;
                    }

                    display: block !important;
                    -webkit-transform-origin: center top;
                    transform-origin: center top;

                    select {
                        display: block;
                        top: 0;
                        left: 0;
                        z-index: 1;
                        bottom: 0;
                        right: 0;
                        width: 100%;
                        height: 100%;

                        position: absolute;

                        option {
                            text-transform: capitalize;
                        }
                    }
                }

                .ph-form form-input [type='select'][disabled='disabled'] + div + form-mixin-datatable {
                    display: none !important;
                }
            }

            > div {
                margin: 0 -1.5rem;
            }

            select {
                display: none;
            }

            li {
                width: 100%;
                text-transform: capitalize;

                span {
                    padding: 0 1.5rem;
                    line-height: 2;
                    font-size: 1.4rem;
                    display: block;
                    width: 100%;
                    cursor: pointer;
                }

                &.hover span {
                    color: var(--form--dropdown--item-hover-text);
                }

                &:hover {
                    span {
                        background-color: var(--form--dropdown--item-hover-background);
                        color: var(--form--dropdown--item-hover-text);
                    }
                }

                &.disabled {
                    span {
                        pointer-events: none;
                        opacity: 0.4;
                    }
                    &:hover span {
                        background-color: initial;
                        color: var(--form--dropdown--item-text);
                    }
                }
            }
        }

        &-captcha {
            display: inline-block;
            position: relative;

            + input[type='text'] {
                display: inline-block;
                vertical-align: top;
                width: calc(100% - 14.8rem);
                padding: 0 1rem;
            }

            img {
                border-radius: 0.4rem;
                float: left;
                height: 4.1rem;
                width: 13.5rem;
                padding: 0 1.5rem 0 0.5rem;
                margin: 0 1rem 0 0;
                background-color: var(--captcha-background);
            }

            a {
                position: absolute;
                right: 1.8rem;
                top: 50%;
                margin-top: -1rem;

                i {
                    font-size: 2rem;
                }
            }
        }

        &-loading {
            svg {
                width: 3.2rem;
                margin-top: 0.1rem;
            }
            path {
                fill: var(--form--input-loading);
            }
        }
    }

    form-submit {
        > div {
            display: inline-block;
        }
    }

    form-selectorbox {
        transition: border-color, 0.2s;
        @include _flexbox;
        position: relative;
        width: 100%;
        text-align: center;
        border: 0.3rem solid var(--form--selectorbox-border);
        border-radius: 0.6rem;
        cursor: pointer;
        z-index: 2;

        &:not([disabled]) * {
            cursor: pointer;
        }

        > div {
            transition: opacity;
            padding: 3rem;
            opacity: 0.7;
            width: 100%;
        }

        &:hover:not([disabled]) {
            transition: border-color, 0s;
            border-color: var(--form--selectorbox-border-hover);
        }

        &.selected {
            border-color: var(--form--radio-check) !important;

            > div {
                opacity: 1;
            }
        }

        .form-input-additions--outside {
            display: none;
        }

        form-input {
            &[type='radio'] {
                position: absolute;
                top: 1rem;
                left: 0;
                width: 100%;

                label {
                    display: none !important;
                }
            }
        }

        form-mixin-error {
            position: absolute;
            top: 50%;
            right: -1rem;

            i {
                pointer-events: none;
                visibility: hidden;
            }
        }
    }

    .ph-form {
        .protip-container {
            white-space: normal;
        }

        &-tooltip-error-list {
            ul li {
                margin-bottom: 0.5rem;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }

    .form-input-additions {
        position: absolute;
        white-space: nowrap;
        height: 100%;
        top: 0;
        display: block;
        width: auto;
        text-align: left;

        &--inside {
            right: 0;
        }

        &--outside {
            left: 100%;
            z-index: 1;

            > * {
                margin-left: 1.5rem;
            }
        }

        > * {
            transform: translate(0, -50%);
            display: inline-block;
            position: relative;
            top: 50%;
            margin-left: 1.6rem;
            vertical-align: top;

            i {
                font-size: 2.4rem;
            }
        }
    }

    .ph-form-mobile .form-input-additions--outside {
        @include _on('mobile') {
            left: auto;
            right: 0;
            top: -4rem;
            height: 4rem;
        }
    }

    /**
	 * DBC Style Form structure
	 */
    .dh-form {
        &__field {
            @include _flexbox();
            @include _flexbox-align(flex-end);
            margin-bottom: 0.6rem;

            > form-input {
                @include _flex(1);
            }

            .need-bottom-space {
                margin-bottom: 0;
            }
        }

        &__time-range {
            @include _flexbox();
            @include _flex(1);
            position: relative;
        }

        &__time-range-container {
            @include _flexbox();
            @include _flex(1);
            position: relative;
        }

        &__time-range-error {
            height: 100%;
            position: relative;
            top: 3.5rem;
            right: 0;

            i {
                height: 3.4rem;
                display: inline-block;
            }
        }

        &__time-selector {
            @include _flex(1);
            @include _flexbox();

            > form-input {
                margin-right: 1.5rem;
            }
        }

        &__hidden {
            // using visibility to not affect the protip
            visibility: hidden;
            position: relative;
            top: 5.2rem;
            right: 3.4rem;

            i {
                height: 3.4rem;
                display: inline-block;
            }
        }

        &__time-hours {
            @include _flex(1);
            label {
                position: relative;

                &:before {
                    content: ':';
                    position: absolute;
                    bottom: -3.2rem;
                    right: -1rem;
                }
            }
        }

        &__time-minutes {
            @include _flex(1);
        }

        &__duration-selector {
            @include _flex(1);

            > form-input {
                margin-left: 1.5rem;
            }
        }

        &__price {
            @include _flexbox-grow(0);
            @include _flexbox-basis(50%);

            > form-input {
                margin-right: 1.5rem;
            }

            form-mixin-info {
                margin-left: 1rem;
            }

            @include _on('mobile') {
                @include _flex(1);

                > form-input {
                    margin-right: 0;
                }
            }
        }
    }

    /**
	 * OLD FORM ELEMENTS, LEGACY, DO NOT USE!
	 */

    .old-form {
        input,
        textarea {
            border-radius: 4px;
            box-shadow: 0 1px 0 var(--form--input-shadow);
            caret-color: var(--form--input-caret);
            font-family: Roboto;

            @include placeholder {
                color: var(--form--input-placeholder-text);
                /*webkit hack*/
                @media screen and (-webkit-min-device-pixel-ratio: 0) {
                    color: var(--form--input-placeholder-text);
                }
            }

            background: var(--form--input-background);
            border: 1px solid var(--form--input-border);

            &.okBehind {
                position: relative;
                z-index: 1;
            }

            &:focus,
            &.focus {
                border: 1px solid var(--form--input-focus-border) !important;
            }
        }

        /* no SlidePane anymore, still the component needs the markup */
        .txt_pane_container,
        .txt_fakecontent {
            display: none;
        }

        textarea {
            resize: none;
        }

        input[type='radio'],
        input[type='checkbox'] {
            margin: 0;
            padding: 0;
            background: url('../App/gfx_icons_sprite.png') no-repeat;
        }

        input[type='submit'],
        button[type='submit'] {
            box-shadow: 0 0 0 transparent;
            width: auto;
            cursor: pointer;
            float: none;

            @at-root body:not(.mainstream-theme) .old-form {
                input[type='submit'],
                button[type='submit'] {
                    border: 0;
                }
            }
        }

        .inputs {
            .holdLeft {
                margin-left: 5px;
            }
        }

        select {
            display: none !important;
        }
    }

    /* form elem formazasai */
    .old-form {
        margin: 0 auto;
        text-align: center;
        color: var(--text--base);

        label:not(.toggle-on-off) {
            @include style('form', 'titleLabel');
            @include user-select(none);
            font-size: 14px;

            em {
                color: var(--text--link);
                margin: 0 0 0 5px;
            }
        }

        [data-is='toggle-on-off'] {
            margin-top: 0.5rem;

            label {
                width: 6rem;
                height: 2.6rem;
                display: inline-block;
            }
        }

        .positionalLabel {
            @include style('form', 'clearLabel');
        }

        &.editable {
            display: none;

            &.show {
                display: inherit;
            }

            .inputs {
                margin-bottom: 6px;
            }

            .primaryLarge {
                margin: 0;
            }
        }

        .notice {
            position: relative;
            top: 12px;
            left: 15px;
        }

        .captchaField {
            .captcha {
                float: left;
                position: relative;
            }
            img {
                border-radius: 4px;
                float: left;
                height: 38px;
                width: 135px;
                padding: 0 15px;
                margin: 0 10px 0 0;
                background: var(--captcha-background);
            }
            #refresh_captcha {
                right: 15px;
                top: 10px;
                position: absolute;

                i {
                    font-size: 20px;
                }
            }
            input {
                width: 115px;
                float: left;
                margin: 0 10px 0 0;
            }
        }

        .labelText {
            @include style('text', 'normalSize', 'highliteColor');

            margin: 0 auto;
            position: relative;
            text-align: left;
            float: left;
            display: inline-block;
            font-size: 14px;

            p {
                margin: 0 0 15px 10px;
                padding: 3px 0 0 0;
                float: left;
            }

            .errorContainer {
                left: 100% !important;
                top: -10px;
                pointer-events: none;
                min-width: 350px;
            }

            input + span + p + i {
                top: 10px;
            }
        }
    }

    /* inputok altalanos formazasai */
    .old-form {
        input,
        textarea,
        .selectContainer {
            outline: 0;
            float: left;

            &:focus {
                color: var(--form--input-focus-text) !important;
            }

            &.error {
                color: var(--form--input-state--error-text) !important;
                border: 1px solid var(--form--input-state--error-border);
            }

            &.disabled {
                opacity: 0.4;
            }

            &.enabled {
                opacity: 1;
            }
        }

        /* moz placeholder color hack */
        input:-moz-placeholder,
        textarea:-moz-placeholder {
            color: var(--form--input-placeholder-text);
        }
        /*IE 10 placeholder color hack*/
        input:-ms-input-placeholder,
        textarea:-ms-input-placeholder {
            color: var(--form--input-placeholder-text);
        }

        /* Inputok melletti info szoveg a validitasrol*/
        .hint {
            opacity: 0;
            color: var(--form--input-text);
            min-width: 220px;
            width: auto;
            font-size: 11px;
            line-height: 40px;
            margin: 0 0 0 10px;
            position: absolute;
            z-index: 0;
            text-align: left;

            &.info {
                margin: 0 0 0 40px;
            }
            @media (max-width: 700px) {
                bottom: -3rem;
                left: 0;
            }
        }
        .icon.rejected {
            z-index: 90;
            top: 13px;
            right: -30px;
            left: auto;
            position: absolute;
            display: inline-block;
        }

        // Dropdown icon
        .icon-caret-old-down,
        .icon-select {
            position: absolute;
            top: 6px;
            right: 6px;
            width: 26px;
            height: 26px;
            line-height: 26px;
            border-radius: 3px;
            background-color: var(--form--dropdown--button-background);
            color: var(--form--dropdown--button-caret);
            font-size: 2.4rem;
            text-align: center;
            z-index: 2;
            cursor: pointer;
        }

        .lowField {
            + .icon-caret-old-down,
            .icon-caret-old-down {
                width: 20px;
                height: 20px;
                line-height: 21px;
                font-size: 15px;
            }
        }

        .lowField {
            + .icon-select,
            .icon-select {
                width: 20px;
                height: 20px;
                line-height: 21px;
                font-size: 20px;
            }
        }

        // If there is a
        // - warning (rejected) icon
        // - help icon
        // after the form element
        .rejected + .help + .hint {
            margin: 0 0 0 70px;
        }

        // If there is a
        // - warning (rejected) icon
        // after the form element
        .rejected + .hint {
            margin: 0 0 0 40px;
        }

        input {
            color: var(--form--input-text);
            /*webkit hack*/

            height: 38px;
            line-height: 38px \9;
            padding: 0 33px 0 15px;
        }

        input[type='radio'],
        input[type='checkbox'] {
            opacity: 0;
            width: 15px;
            height: 15px;
            height: 10px \9;
            width: 10px \9;
            margin: 2px 0 0;
        }

        /*OPERA merete hack*/
        x:-o-prefocus,
        input[type='radio'],
        input[type='checkbox'] {
            height: 12px;
            width: 12px;
        }

        input[type='submit'] {
            margin: 0 auto;
        }

        textarea {
            color: var(--form--input-text);
            /*webkit hack*/
            @media screen and (-webkit-min-device-pixel-ratio: 0) {
                color: var(--form--input-text) !important;
            }
            padding: 10px 30px 10px 15px;
            height: 90px;
            max-width: 100%;
            width: 340px;
            font-size: 13px;
            line-height: 20px;
        }
    }

    // For HTML5 Uploaders
    .fileApiLoader {
        display: none;
        width: 15px;
        height: 15px;
        border-radius: 100%;
        margin: 2px;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
        border: 2px solid var(--text--base);
        border-bottom-color: transparent;
        height: 15px;
        width: 15px;
        background: transparent !important;
    }
    .fileApiLoading .fileApiLoader {
        display: inline-block;
    }
    input[type='file'] {
        position: absolute;
        top: 0;
        right: 0;
        margin: 0;
        opacity: 0;
        font-size: 200px;
        direction: ltr;
        cursor: pointer;
        width: auto;
        height: auto;
    }

    // Icon ok positioning
    .inputs {
        float: left;
        margin: 0 0 20px 0;
        position: relative;
    }

    .inputs,
    .uploadBlock {
        .icon-ok {
            margin: 10px 15px 0 0;
            right: -5px;
            position: absolute;
        }

        .icon-alert {
            position: absolute;
            right: 20px;
            top: 63px;
            font-size: 20px;
            color: var(--common--alert);

            .protip-container {
                white-space: nowrap;
            }
        }
    }

    #company_managingdirectorregistration {
        .uploadBlock .icon-alert {
            right: -30px;
            top: 7px;

            .protip-container {
                white-space: normal;
            }
        }
    }

    .selectContainer {
        .icon-ok {
            &.hide {
                // override common hide functionality
                display: block !important;
                opacity: 0;
            }
        }

        &.okBehind {
            input {
                position: relative;
                z-index: 1;
            }
            .selectArrow {
                z-index: 1;
            }
            .icon-ok {
                transition: all 0.2s ease-out 0s;

                &.hide {
                    right: 0;
                    transform: rotate(0deg);
                    opacity: 0;
                }
            }
        }

        input.focus + i {
            transform: rotate(180deg);
        }
    }

    .okBehind .icon-ok {
        right: -45px;
        transform: rotate(360deg);
    }

    #select_memberName .selectContainer .icon-ok {
        right: 0;
    }

    .dateInput {
        .selectContainer {
            margin-right: 5px;

            &:last-child {
                margin-right: 0;
            }
        }
    }

    .nameInput {
        span {
            display: block;
            float: left;
            margin-right: 5px;

            &:last-child {
                margin-right: 0;
            }
        }
    }

    .selectContainer {
        position: relative;

        select {
            visibility: hidden;
        }

        input[readonly] {
            cursor: pointer;
        }

        .simplebar-track.horizontal {
            display: none;
        }
    }

    .tinyField {
        // width: 100
        input {
            width: 100px;
        }

        /*Lenyilo, gorgovel ellatott kontenerhez meeretformazas*/
        .dropDownContainer {
            width: 90px;

            .slideContainer {
                width: 80px;

                .listContainer {
                    width: 142px;
                }
            }
        }
    }

    .miniField {
        input {
            width: 130px;
        }

        /*Lenyilo, gorgovel ellatott kontenerhez meeretformazas*/
        .dropDownContainer {
            width: 98px;

            .slideContainer {
                width: 98px;

                .listContainer {
                    width: 152px;
                }
            }
        }
    }

    .smallField {
        // width: 140
        input {
            width: 190px;
        }

        /*Lenyilo, gorgovel ellatott kontenerhez meeretformazas*/
        .dropDownContainer {
            width: 160px;

            .slideContainer {
                width: 160px;

                .listContainer {
                    width: 162px;
                }
            }
        }
    }

    .midsizeField {
        // width: 190
        input {
            width: 190px;
        }

        /*Lenyilo, gorgovel ellatott kontenerhez meeretformazas*/
        .dropDownContainer {
            min-width: 160px;

            .slideContainer,
            .listContainer {
                width: 100%;
            }
        }
    }

    .normalField {
        // width: 240
        input {
            width: 240px;
        }

        /*Lenyilo, gorgovel ellatott kontenerhez meeretformazas*/
        .dropDownContainer {
            width: 260px;

            .slideContainer {
                width: 260px;

                .listContainer {
                    width: 272px;
                }
            }
        }
    }

    .largeField {
        // width: 340
        input {
            width: 340px;
        }

        /*Lenyilo, gorgovel ellatott kontenerhez meretformazas*/
        .dropDownContainer {
            width: 310px;

            .slideContainer {
                width: 310px;

                .listContainer {
                    width: 322px;
                }
            }
        }

        #ethnicity-component-slide {
            max-height: 100px;
        }
    }

    .fluidField {
        // width: 340
        input {
            width: 100%;
        }

        .selectContainer {
            width: 100%;
        }

        /*Lenyilo, gorgovel ellatott kontenerhez meretformazas*/
        .dropDownContainer {
            width: 100%;

            .slideContainer {
                width: 100%;

                .listContainer {
                    width: 100%;
                }
            }
        }
    }

    #newModelContent #nationality-component-slide {
        max-height: 160px;
    }
    #newModelContent #module-expiry-date .slideContainer {
        max-height: 100px;
    }

    .tinyPopUpFullSizeField {
        // width: 510
        input {
            width: 510px;
        }

        /*Lenyilo, gorgovel ellatott kontenerhez meretformazas*/
        .dropDownContainer {
            width: 510px;

            .slideContainer {
                width: 510px;

                .listContainer {
                    width: 508px;
                }
            }
        }
    }

    .highlighted {
        position: relative;
        z-index: 2;
    }

    .highlightLayer {
        position: absolute !important;
        color: transparent !important;
        z-index: 1 !important;
        overflow: hidden !important;
        top: 0 !important;
        width: 100% !important;
        height: 100% !important;
        border: 0 !important;

        span {
            padding: vars.spacer(1) 0;
            background: var(--form--highlight) !important;
            color: var(--form--input-text) !important;
        }
    }

    .dateInput .scrollContainer {
        padding: 16px !important;
    }

    .scrollContainer {
        box-shadow: 0 2px 4px RGBA(var(--form--shadow-rgb), 0.3);
        background: var(--form--input-background);
        top: 4rem;
        left: 0;
        padding: 15px;
        position: absolute;
        z-index: 5;
        width: 100%;

        .dropDownContainer {
            position: relative;
            overflow: hidden;

            .slideContainer {
                position: relative;
                max-height: 260px;
                overflow-x: hidden;

                scrollable {
                    max-height: 260px;
                }

                .listContainer {
                    float: left;
                    text-align: left;
                    max-width: 90%;

                    li {
                        color: var(--text--base);
                        font-size: 13px;
                        line-height: 26px;
                        outline: none;

                        &:hover,
                        &:focus {
                            &.enabled {
                                cursor: pointer;
                                color: var(--text--link);
                            }
                        }

                        &.disabled {
                            opacity: 0.4;
                        }

                        &.indented {
                            padding-left: 15px;
                        }
                    }
                }
            }
        }
    }

    /*ModelCenter oldal specifikus formazasok*/
    #input_screenName {
        margin: 0 0 10px 0;
    }

    //Formon kivuli elemek
    .readOnlyLabel {
        @include style('text', 'hugeSize', 'highliteColor');
        @include style('form', 'titleLabel');

        line-height: 34px !important;
        padding: 2px 14px 6px 0 !important;
        width: 267px;
    }

    .readOnlyContent {
        @include style('text', 'regularColor', 'hugeSize');

        line-height: 38px !important;
        overflow: hidden;
        min-width: 1px;
        max-width: 430px;
        min-height: 38px;
        max-height: 38px;
    }

    //Altalanos checkbox kontener  (checkbox+szoveg)
    .checkBoxContainer {
        float: left;
        position: relative;
        margin: 0 0 20px 0;
        padding: 1px 0 0 1px;

        label[for] {
            height: 20px;
            margin: 0;
            padding: 0 0 0 12px;
            line-height: 21px !important;
            text-align: left;

            &::first-letter {
                text-transform: uppercase;
            }
        }

        span {
            top: 2px;
            left: 0;

            &.checkbox-disabledInactive,
            &.checkbox-disabledActive {
                cursor: default;
            }
        }

        input {
            position: relative;
            top: 3px;
        }
    }

    .inputs {
        .lowField {
            height: 32px;
            line-height: 32px \9;
        }

        .searchBar {
            background-image: url(data:image/svg+xml;base64,CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB3aWR0aD0iNTEyIiBoZWlnaHQ9IjUxMiI+PHBhdGggZmlsbD0iI0NBNDY0NiIgZD0iTTQ5Ni4xMzEgNDM1LjY5OEwzNzQuODU1IDMzMi41NTFjLTEyLjUzNy0xMS4yODMtMjUuOTQ1LTE2LjQ2My0zNi43NzYtMTUuOTYzQzM2Ni43MDcgMjgzLjA1NCAzODQgMjM5LjU0OSAzODQgMTkyIDM4NCA4NS45NjEgMjk4LjAzOSAwIDE5MiAwUzAgODUuOTYxIDAgMTkyczg1Ljk2MSAxOTIgMTkyIDE5MmM0Ny41NDkgMCA5MS4wNTQtMTcuMjkzIDEyNC41ODgtNDUuOTIyLS41IDEwLjgzMSA0LjY4IDI0LjIzOSAxNS45NjMgMzYuNzc2TDQzNS42OTggNDk2LjEzYzE3LjY2MSAxOS42MjMgNDYuNTExIDIxLjI3NyA2NC4xMSAzLjY3OHMxNS45NDYtNDYuNDQ5LTMuNjc3LTY0LjExek0xOTIgMzIwYy03MC42OTIgMC0xMjgtNTcuMzA4LTEyOC0xMjhTMTIxLjMwOCA2NCAxOTIgNjRzMTI4IDU3LjMwOCAxMjggMTI4LTU3LjMwNyAxMjgtMTI4IDEyOHoiLz48L3N2Zz4=);
            background-color: var(--form--input-background);
            background-position: 10px;
            background-repeat: no-repeat;
            background-size: 15px;
            padding: 0 25px 0 35px;
        }

        .calendar {
            @include sprite;

            background-image: url('../App/gfx_icons_sprite.png');
            background-color: var(--form--input-background);
            background-position: 10px -1186px;
            padding: 0 15px 0 35px;
        }

        &.profileCheckBox {
            float: none;
            display: inline-block;

            p {
                display: inline-block;
                margin: 3px 0 0 10px;
            }
        }

        .holdLeft {
            margin: 0 0 0 5px;
        }

        //Nyelvvalaszto checkbox kontener
        &.languagesContainer {
            padding: 10px 0;

            .checkBoxContainer {
                label {
                    @include style('text', 'normalSize', 'highliteColor');

                    width: 97px !important;
                }
            }
        }
    }

    //Modell tulajdonsagok checkbox kontenere
    #modelNature {
        label {
            @include style('text', 'normalSize', 'highliteColor');

            width: 156px !important;
        }
    }

    .smallField {
        // width: 140
        /*Kereso mezo ikonnal*/
        .searchBar,
        .calendar {
            width: 120px;
        }
    }

    .midsizeField {
        // width: 180
        /*Kereso mezo ikonnal*/
        .searchBar,
        .calendar {
            width: 180px;
        }
    }

    .normalField {
        // width: 240
        /*Kereso mezo ikonnal*/
        .searchBar,
        .calendar {
            width: 190px;
        }
        /*Kereso mezo ket ikonnal*/
        .searchBar {
            width: 180px;
        }
    }

    .largeField {
        // width: 340
        /*Kereso mezo ikonnal*/
        .searchBar,
        .calendar {
            width: 290px;
        }
    }

    .tinyPopUpFullSizeField {
        // width: 510
        /*Kereso mezo ikonnal*/
        .searchBar,
        .calendar {
            width: 460px;
        }
    }

    .idNotice {
        margin: 0 0 20px 0;
        display: block;
    }

    //Attaching files button style
    .attachFiles {
        @include extendPageSwitchBox;

        float: left;
        margin: 0 0 0 10px;
        width: auto;
        padding: 8px 11px 0 11px;
        height: 27px;
    }
}

@import 'Form.oranum';

@import '../../../scss/shell';

:global {
	.videoVoiceCallIntroduction {
		input[type="radio"] {
			display: none;
		}
	}
}

.content {
	text-align: center;
}

.section1 {
	position: relative;
	padding: 0.5rem 0 0;
	background-image: url('./section1_bg.jpg');
	background-repeat: repeat-x;
}

.section2 {
	padding: 4rem 0;
	background: var(--earn-more--section-bg);

	ul {
		margin: 4rem 1.5rem 2.5rem;

		li {
			display: inline-block;
			min-width: 16rem;
			margin: 0 0.5rem 1.5rem;
			font-size: 1.4rem;

			span {
				display: block;
				width: 8.8rem;
				height: 8.8rem;
				margin: 0 auto 1.5rem;
				overflow: hidden;
				border: 0.6rem solid var(--earn-more--video-voice-call--badge-border);
				border-radius: 50%;
				background: var(--earn-more--video-voice-call--badge-bg);

				i {
					display: block;
					position: relative;

					&.mobile {
						font-size: 7rem;
						top: 1.3rem;
					}
					&.people {
						font-size: 3.8rem;
						top: 2rem;
					}
					&.traffic {
						font-size: 4.4rem;
						top: 1.4rem;
					}
					&.earnings {
						font-size: 3.8rem;
						top: 2.1rem;
						left: 0.3rem;
					}
				}
			}
		}
	}
}

.section4 {
	position: relative;
	min-height: 65rem;
	padding: 3.6rem 0 5rem;
	@include _vertical-gradient(
			var(--earn-more--video-voice-call--section-bg-gradient),
			var(--earn-more--section-bg),
			0%, 20%
	);

	input:checked {
		+ label {
			color: var(--text--base);
			font-weight: bold;
			border-bottom: 0.2rem solid var(--text--base);
			cursor: default;

			+ section {
				display: block;
			}
		}
	}

	label {
		display: inline-block;
		margin: 0 1.2rem 1.4rem;
		padding: 1.3rem 1rem;
		font-size: 1.4rem;
		color: var(--earn-more--text);
		cursor: pointer;
	}

	section {
		display: none;
		position: absolute;
		min-height: 45rem;
		background: url('./step1.png') 8rem 0 no-repeat;
		background-size: contain;

		&.tabContent2 {
			background-image: url('./step2.png');
		}

		&.tabContent3 {
			background-image: url('./step3.png');
		}

		div {
			margin: 15.2rem 10rem 4rem 38.2rem;
			text-align: left;
		}

		h3 {
			margin: 0 0 0.6rem;
			font-size: 1.6rem;
			text-transform: uppercase;
			color: var(--text--link);
		}

		h4 {
			margin: 0 0 2.8rem;
			font-size: 1.8rem;
			text-transform: uppercase;
		}

		p {
			margin: 0 0 0.9rem;
			font-size: 1.6rem;
			font-weight: 200;
			line-height: 1.2;
			color: var(--earn-more--text);
		}
	}
}

.section5 {
	padding: 4rem 0;
}

.section2,
.section4 {
	h2 {
		font-size: 2.2rem;
		text-transform: uppercase;
	}
}

@include _on('mobile', false, 'landscape') {
	.section2 ul li {
		min-width: 14.5rem;
	}
}

@include _on('mobile', false, 'portrait') {
	.section2,
	.section5 {
		padding: 3rem 2rem;

		a {
			width: 100%;
		}
	}

	.section4 {
		min-height: 95rem;
		padding: 3rem 2rem;

		label {
			display: block;
		}

		section {
			top: 25rem;
			background-position: 50% 0;

			div {
				margin: 50rem 0 4rem;
			}
		}
	}
}
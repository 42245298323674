@import '../../scss/shell';

:global {
	@import "Scrollable.mainstream";

	scrollable,
	[data-is=scrollable],
	.riot-mount[data-is=scrollable] {
		position: relative !important;
		display: block;
		scroll-behavior: smooth;
	}

	.simplebar-track.horizontal {
		visibility: hidden;
	}

	.simplebar-scroll-content {
		max-height: 100%;
		margin-bottom: 0 !important;
	}

	.simplebar-content {
		overflow-x: hidden !important;
		padding-bottom: 0 !important;
	}

	.simplebar-track {
		cursor: pointer;
		pointer-events: auto !important;
		z-index: 10;
		background-color: var(--scrollable--default--track-bg);

		.simplebar-scrollbar.visible,
		.simplebar-scrollbar.visible:hover {
			opacity: 1 !important;
		}
	}

	.scrollable {

		&--horizontal {
			.simplebar-content {
				overflow-x: visible !important;
			}
			.simplebar-track.horizontal {
				visibility: visible;
			}
		}

		&--light {
			.simplebar-track.horizontal {
				height: 1.6rem;
				background-color: var(--scrollable--light--track-bg);
				border-radius: 1rem;

				.simplebar-scrollbar {
					height: calc(100% - 0.4rem);
					background-color: var(--scrollable--light--bar-bg);

					&::after {
						content: '';
						display: block;
						position: absolute;
						height: 50%;
						top: 25%;
						left: 50%;
						width: 1px;
						background: var(--scrollable--light--bg);
						box-shadow:
							-1px 0 0 0 transparent,
							-2px 0 0 0 var(--scrollable--light--track-lines),
							1px 0 0 0 transparent,
							2px 0 0 0 var(--scrollable--light--track-lines);
					}
				}
			}
		}

		&--secondary {
			.simplebar-track {
				.simplebar-scrollbar {
					background-color: RGBA(var(--scrollable--secondary--bar-bg-rgb), 0.8);
				}
			}
		}
	}
}
@use 'global/colors';
@use 'global/icons';
@import '../../scss/shell';

// TODO: Figure out why this is the only way @font-face declaration works in a production build!
$icomoonFontFilePath: '../../icomoon/fonts/icomoon' !default;
@font-face {
    font-family: 'icomoon';
    src:
        url('#{$icomoonFontFilePath}.woff2') format('woff2'),
        url('#{$icomoonFontFilePath}.woff') format('woff'),
        url('#{$icomoonFontFilePath}.ttf') format('truetype'),
        url('#{$icomoonFontFilePath}.svg#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
}

$fontFilePath: '../../msc-icons' !default;
@font-face {
    font-family: 'msc-icons';
    src:
        url('#{$fontFilePath}.woff2') format('woff2'),
        url('#{$fontFilePath}.ttf') format('truetype'),
        url('#{$fontFilePath}.svg#msc-icons') format('svg');
    font-weight: normal;
    font-style: normal;
}

:global {
    @import '../../icomoon/style';
    @import '../../../msc_modules/src/global/icons_classes';
    @import 'reset';
    @import '~phrame2/module/basic';
    @import '~phrame2/module/expose';
    @import '~simplebar/dist/simplebar';

    @import '../../scss/module/box.module';
    @import '../../scss/module/common.module';
    @import '../../scss/module/form.module';
    @import '../../scss/module/grid.module';
    @import '../../scss/module/legacy.grid.module'; // TODO: remove
    @import '../../scss/module/loader.module';
    @import '../../scss/module/ribbon.module';
    @import '../../scss/module/screen.module';
    @import '../../scss/module/typography.module';
    @import '../../scss/module/overlay.module';

    @import 'animations';
    @import 'base';
    @import 'boxes';
    @import 'buttons';
    @import 'icons';
    @import 'lists';
    @import 'no-script';
    @import '~protip/css/protip';
    @import 'protip';
    @import 'protip.elements';
    @import 'tables';
    @import 'tooltip';
    @import 'flexbox';

    $radius: _dim('global-radius');
    $radius: _rem($radius);

    .msc-icon {
        font-family: 'msc-icons' !important;
        font-weight: normal;
    }

    .page {
        border-radius: 0 $radius $radius 0;
        width: calc(100% - #{_rem(_dim('menu-width'))});
        background-color: var(--page-background);

        @include _on(small, down) {
            width: 100%;
            float: none;
            margin: 0 auto;
            min-height: 0 !important;
        }
    }

    .riot-mount {
        visibility: hidden !important;
        position: absolute !important;
    }

    [data-react-component='Header'] {
        height: var(--header-height);
        background: var(--header-bg);
        border-bottom: var(--header-border-bottom);
    }

    * {
        -webkit-tap-highlight-color: transparent;
    }

    .browserFailMessage {
        display: none;
        z-index: 9999;
        position: fixed;
        color: #{colors.get(neutral, 100)};
        background: black;
        width: 100%;
        padding: 1rem 5rem 1rem 1rem;
        font-size: 1.2rem;
        text-align: center;

        span {
            position: relative;
            top: -0.5rem;
        }

        i.icon-alert {
            margin-right: 0.8rem;
            color: #{colors.get(yellow, 500)};
            font-size: 2.4rem;
            position: relative;
            top: 0.7rem;
        }

        i.icon-close {
            position: absolute;
            color: #{colors.get(neutral, 100)};
            font-size: 2.4rem;
            right: 2rem;
            top: calc(50% - 1.2rem);
            cursor: pointer;

            &:hover::before {
                content: icons.get('close-solid');
            }
        }
    }
}

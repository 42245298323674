.oranum-theme {
	.uploader5 {
		--registration--progress-bar: rgb(var(--color-primary-2));
		&__progress {
			&-bar {
				box-shadow: 0 1px 0 var(--registration--progress-bar);
				background-color: var(--registration--progress-bar);

				.bar {
					background: url('./progress_oranum.gif');
				}
			}
		}
	}

	.uploadInfoText {
		.wikiLink {
			i.icon-info-circle {
				color: var(--text--link);
			}
		}

		.protip-content {
			.wikiLink {
				i.icon-info-circle {
					color: var(--protip--link);
				}
				&:hover {
					i.icon-info-circle {
						color: var(--protip--link-hover);
					}
				}
			}
		}
	}
	.uploader5 {
		&__container i.icon-info-circle {
			color: var(--uploader5--progress-text);
			&:hover{
				color: var(--text--secondary);
			}
		}
	}
}

@import '../../../scss/shell';

:global {
	.myStoryIntroduction {
		input[type="radio"] {
			display: none;
		}
	}
}

.content {
	text-align: center;
}

.section1 {
	position: relative;
	padding: 40px 0;

	h1 {
		margin: 0 0 25px;
	}

	h2 {
		margin: 0 0 20px;
		font-size: 22px;
	}
}

.section2 {
	padding: 40px 0;
	background: var(--earn-more--section-bg);

	ul {
		margin: 40px 15px 0;

		li {
			display: inline-block;
			min-width: 160px;
			margin: 0 5px 10px;
			font-size: 14px;

			span {
				display: block;
				width: 88px;
				height: 88px;
				margin: 0 auto 15px;
				overflow: hidden;
				border: 6px solid var(--earn-more--my-story--badge-border);
				border-radius: 50%;
				background: var(--earn-more--my-story--badge-bg);

				i {
					display: block;
					position: relative;
					top: 14px;
					font-size: 48px;

					&.share {
						top: 16px;
						font-size: 44px;
					}
				}
			}
		}
	}
}

.section4 {
	position: relative;
	padding: 35px 80px 15px;
	overflow: hidden;

	@include _vertical-gradient(
		var(--earn-more--my-story--section-bg-gradient),
		var(--earn-more--my-story--section-bg)
	);

	.shadow {
		position: absolute;
		left: 0;
		bottom: 0;
		width: 100%;
		height: 245px;
		@include _vertical-gradient(
			transparent,
			var(--earn-more--my-story--shadow)
		);
	}

	input:checked {
		+ label + section {
			display: block;
		}

		+ label {
			top: 175px;
			padding: 0;

			img {
				width: 100%;
				opacity: 1;
			}
		}
	}

	label {
		position: relative;
		z-index: 1;
		float: left;
		width: 33%;
		padding: 0 0 22%;
		top: 225px;
		transition : top 0.2s linear, padding 0.2s linear;

		img {
			width: 90%;
			opacity: 0.6;
			cursor: pointer;
			transition : width 0.2s linear, opacity 0.2s linear;
		}
	}

	section {
		display: none;
		position: absolute;
		top: 95px;
		left: 0;
		width: 100%;
		min-height: 175px;

		span {
			width: 30px;
			height: 30px;
			line-height: 1.5;
			display: inline-block;
			margin: 0 0 14px;
			padding: 0 0 0 1px;
			color: var(--text--link);
			font-size: 18px;
			font-weight: bold;
			border: 2px solid var(--text--link);
			border-radius: 100%;
		}

		h3 {
			font-size: 18px;
			text-transform: uppercase;
			margin: 0 0 18px;
		}

		p {
			margin: 0 auto 7px;
			padding: 0 20px;
			max-width: 690px;
			line-height: 1.3;
		}
	}
}

.section5 {
	padding: 4rem 0;
	background: var(--earn-more--my-story--more-info-bg);
}

.section2,
.section4 {
	h2 {
		font-size: 22px;
		text-transform: uppercase;
	}

	p {
		font-size: 16px;
		font-weight: 200;
		line-height: 1.5;
		color: var(--earn-more--text);

		strong {
			color: var(--text--base);
		}
	}
}

.button {
	margin: 28px auto 0;
	padding: 13px 30px;
}

@include _on('mobile') {
	.section4 {
		section {
			top: 65px;
		}
	}
}

@include _on('mobile', false, 'landscape') {
	.section2 ul li {
		min-width: 145px;
	}
}

@include _on('mobile', false, 'portrait') {
	.section2,
	.section5 {
		padding: 30px 20px;

		a {
			width: 100%;
		}
	}
}
.oranum-theme {
	%_form-input-style {
		background: var(--form--input-background);
		border-color: var(--registration--input-border);
		box-shadow: none;

		&:focus {
			border-color: var(--form-text);
			color: var(--registration--input-text);
		}

		&.toggle-on-off {
			background: var(--toggle-on-off--bg);
			border: 0;
		}
	}

	form {
		color: var(--form-text);

		.icon-info-circle--common {
			color: var(--form--info-icon);
			&:hover {
				color: var(--form--info-icon-hover);
			}
		}
	}

	.#{_format-selector('form', false, false, true)} {
		input {
			color: var(--registration--input-text);

			&.ph-input-state-error {
				border-color: var(--registration--error) !important;
				color: var(--registration--input-text);
			}
		}

		input[type="checkbox"] + label {
			width: 1.8rem;
			height: 1.8rem;
		}

		input[type="checkbox"] {
			&:checked + label:before {
				top: 2px;
				left: 2px;
				font-size: 1.2rem;
			}
		}
	}
}

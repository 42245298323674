.protip-container {
	&.protip-common-close {
		.protip-close {
			display: flex;
			transform: scale(0);
			transition: all 0.2s ease-out 0s;
			position: absolute;
			right: -0.5rem;
			top: -0.5rem;
			padding: 0.2rem;
			border-radius: 50%;
			background-color: var(--protip--bg);
			cursor: pointer;
			border: 0.2rem solid var(--protip--text);
			font-size: 1.4rem;
			outline: 0;
			color: var(--protip--text);
		}
	}

	&.protip-mixin--over-menu {
		z-index: 200;
	}

	&:hover {
		.protip-close {
			transform: scale(1);
		}
	}
}
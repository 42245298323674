@use 'global/colors';

.oranum-theme {
	.icon {
		background: none;

		&.checkbox-inactive {
			border: 1px solid var(--form--checkbox-border);
			background: var(--form--checkbox-bg);
			border-radius: 0.4rem;

			&.focus {
				box-shadow: none;
			}

			&:before {
				display: none;
			}
		}

		&.checkbox-disabledInactive,
		&.checkbox-disabledActive {
			border: 1px solid var(--form--checkbox-border-disabled);
			border-radius: 0.4rem;

			& + label {
				opacity: 0.4;
			}
		}

		&.checkbox-disabledActive {
			&:before {
				display: inline;
				position: relative;
				top: 2px;
				left: 1px;
				color: var(--form--checkbox-check);
				opacity: 0.4;
			}
		}

		&.checkbox-active {
			border: 1px solid var(--form--checkbox-border);
			background: var(--form--checkbox-bg);
			text-align: center;
			border-radius: 0.4rem;

			&.focus {
				box-shadow: none;
			}

			&:before {
				display: inline;
				position: relative;
				top: 2px;
				left: 1px;
				color: var(--form--checkbox-check);
			}
		}
	}

	.galleryArrow {
		opacity: 1;
		color: var(--overlay--with-header-close-btn);

		&:hover {
			color: var(--overlay--arrow-hover);
		}
	}

	.icon.periodSliderLineEnd {
		width: 12px;
		height: 22px;
		top: -5px;
		background: url('./slider_handler.png') no-repeat;
	}

	.msc-icon.icon-info-circle--common {
		&:hover::before {
			color: #{colors.get(purple, 700)};
		} 
	}
}
@use 'global/colors';
@use 'global/vars';
@import '../../../../scss/shell';

.container {
	position: relative;
	width: 100%;
	padding: 0 vars.spacer(6);

	&.loading {
		min-height: 15rem;
	}

	.content {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		margin: 0 vars.spacer(1) * -1;

		> * {
			width: 25%;
			padding: 0 vars.spacer(1) vars.spacer(2);

			@include _on('mobile') {
				width: 50%;
				height: calc(50vw - 1.2rem);
			}
		}
	}
}

.title {
	margin: vars.spacer(7) 0 vars.spacer(3) vars.spacer(6);
	color: #{colors.get(neutral, 100)};
	font-size: 2.4rem;

	@include _on('mobile') {
		margin: vars.spacer(7) vars.spacer(6) -0.6rem;
	}
}

.description {
	margin: vars.spacer(3) vars.spacer(6) vars.spacer(5);
	font-size: 1.4rem;
	line-height: 1.8rem;
	color: var(--promo-tools--info);
}

.orientationErrorOverlay {
	width: 64rem;
	@include _on('mobile') {
		width: 100%;
	}
}

.orientationErrorContainer {
	padding: vars.spacer(12);
	text-align: center;

	p {
		font-size: 1.4rem;
		color: var(--text--paragraph);
		margin-bottom: vars.spacer(8);
	}

	@include _on('mobile') {
		padding: vars.spacer(8) vars.spacer(4);

		a {
			width: 100%;
		}
	}
}

.oranum {
	--promo-tools--box-bg: rgb(var(--color-primary-4));
	--promo-tools--box-border: rgb(var(--color-primary-2));
	--promo-tools--title-active: #{colors.get(neutral, 100)};
	--progress-bar--stripe: rgb(var(--color-primary));
	--progress-bar--line: rgb(var(--color-primary-5));
	--progress-bar--bg: transparent;
}
@import '../../scss/shell';

:global {
	@include _on('small', 'down') {
		.hamburger-checkbox + * {
			transition: left 0.2s ease-out 0s;
			left: -100%;
		}

		.hamburger-checkbox:checked + * {
			left: 0;
			z-index: 200;
		}
	}
}
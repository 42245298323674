@use 'global/colors';
@use 'global/vars';
@import '../../../../scss/shell';
@import 'common';
@include videoBox;

.box {
	text-align: center;

	:global .icon-add-video {
		font-size: 6.4rem;
		padding: 0;
		opacity: 0.4;
	}
}

.wrapper {
	@include _on('mobile') {
		width: 100% !important;

		div {
			height: 100% !important;
		}

		:global .protip-container {
			height: auto !important;
		}
	}

	:global .protip-container {
		display: none;
	}

	&.uploading:hover {
		:global .protip-container {
			display: block;
		}
	}
}

:global .uploadInfoText {
	ul {
	 	margin-bottom: 1.2rem;

		li {
			margin: 0;
		
			p {
				padding: 0;
			}
		}
	}

	@include _on('mobile') {
		padding: 1.6rem;

		a {
			font-size: 1.2rem;
		}

		ul {
			margin-bottom: 2rem;
		}

		ul li {
			font-size: 1.2rem;
			color: var(--text--link);
		}
	}
}

:global {
	.no-margin {
		margin: 0 !important;
	}
}

:global #promotionToolsUploader {
	z-index: 2;
	margin: 0;

	&.uploader5 {
		position: initial;
	}

	.uploader5 {
		&__container {
			width: 100%;
		}
		&__button {
			position: initial;

			input {
				font-size: 0;
				z-index: 2;
				width: 100%;
				height: 100%;
			}
		}

		&__progress {
			display: none;
		}
	}
}

:global .teasersContainer .mediaBox.video .boxInner {
	padding: 0.6rem;

	>div>div {
		height: 20.3rem;
	}

	i.icon-info-circle {
		display: inline;
		left: unset;
		right: vars.spacer(0.5);
		margin-bottom: vars.spacer(0.5);
		padding: vars.spacer(1);
		font-size: 2.4rem !important;
		color: var(--promo-tools--info-icon);

		&:active {
			color: #{colors.get(neutral, 100)};
		}
	}

	.hideIconOnMobile {
		display: block;
	}
	.showIconOnMobile {
		display: none;
	}

	@include _on('mobile') {
		.hideIconOnMobile {
			display: none !important;
		}
		.showIconOnMobile {
			display: block !important;
		}
		.boxInnerIconContent {
			height: auto !important;
		}
	}

	.boxInnerIconContent .add_media_item_title {
		padding: 0 1rem;
		font-size: 1.4rem;
	}

	.boxInnerIconContent .add_media_item_icon {
		font-size: 4.8rem;
		display: inline-block;
	}
}

.oranum {
	:global {
		.boxInner {
			--channel--box--inner-bg: rgb(var(--color-text-muted));
			--text--secondary: #{colors.get(neutral, 100)};
		}

		.boxInnerIconContent .add_media_item_title,
		.boxInnerIconContent .add_media_item_icon {
			color: #{colors.get(neutral, 100)} !important;
		}
	}
}
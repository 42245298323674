@import '../../../scss/shell';

:global {
    .immediate-payout-confirm-overlay {
        text-align: center;
        padding: 40px 20px;

        p {
            margin-bottom: 2rem;
            font-size: 1.6rem;
            color: var(--text--paragraph);

            &:first-of-type {
                margin-top: 3rem;
            }

            strong {
                color: var(--text--base);
            }
        }

        .closeButton {
            position: static;
            margin-top: 2rem;
            font-size: 1.4rem;
        }
    }
}
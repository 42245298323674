@import '../../../scss/shell';

:global {

	/* The content is padded in the HelpInfo section*/
	#help_agreement .helpInfoPageContainer .agreementContent {
		padding: 30px 30px 10px 30px;
	}

	.agreementContent {
		text-align: left;

		.highlightedText {
			@include style('text', 'hugeSize', 'highliteColor');

			margin: 0 0 24px 0;
		}

		.agreementText {
			font-size: 13px;
			line-height: 16px;
			color: var(--text--secondary);
			margin: 0 0 18px 0;

			&.lastItem {
				margin: 0
			}

			& + h5 {
				color: var(--text--base);
				margin: 0 0 10px;
			}
		}

		ul.agreementText {
			list-style-type: upper-roman;
			margin-left: 37px;
			li {
				margin-bottom: 10px;
				font-size: 13px;
				line-height: 16px;
			}
		}

		ul {
			@include style('text', 'largeSize', 'regularColor');
			@include style('list', 'large');

			color: var(--text--secondary);
			margin: 30px 0;
		}

	}

	.termsPageContainer {
		margin-top: 40px;
	}

	.agreementContent,
	.termsPageContainer,
	.policy-page,
	#privacyPolicyOverlay,
	#cookiePolicyOverlay,
	#softwareLicenceAgreementOverlay,
	#termsAndConditionsOverlay {
		padding-bottom: 50px;
		text-align: left;

		.box_header {
			margin-bottom: 30px;
			padding: 19px !important;

			em {
				float: right;
				font-weight: normal;
				font-size: 12px;
				color: var(--policy--side-note);
				@media only screen and (max-width: 760px) {
					width: 100%;
					text-align: left;
					margin: 10px 0 0;
				}
			}
		}

		p {
			margin: 0 20px 16px;
			color: var(--text--secondary);
			font-size: 1.4rem;
			line-height: 1.8rem;
		}

		h2 {
			margin: 50px 0 30px 0;
			display: block;
			background-color: var(--box--header-background);
			color: var(--box--header-text);
			text-align: left;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			font-weight: bold;
			font-size: 16px;
			line-height: 12px;
			padding: 13px 19px;
			padding-right: 45px !important;
		}

		h3 {
			margin: 0 2.2rem 2rem 2rem;
		}

		h4 {
			font-weight: bold;
			font-size: 14px;
			line-height: 14px;
			margin: 0 22px 20px 20px;
			padding: 20px 0 0;
		}

		h5 {
			margin: 0 2.2rem 2rem 2rem;
			color: var(--text--base);
		}

		a {
			@media only screen and (max-width: 760px) {
				word-break: break-word;
			}
		}

		ul {
			color: var(--text--secondary);

			&.dotted {
				li {
					position: relative;

					&::before {
						position: absolute;
						content: "\2022";
						color: var(--text--link);
						left: 30px;
					}
				}
			}

			li {
				font-size: 14px;
				line-height: 16px;
				margin: 0 40px 22px 0;
				padding-left: 45px;

				@media only screen and (max-width: 760px) {
					margin-right: 20px
				}

				ul {
					margin: 20px 0 0 -20px;
				}
			}
		}

		sup {
			font-size: 10px;
			vertical-align: super;

			@media only screen and (max-width: 760px) {
				font-size: 8px;
			}
		}

		hr {
			margin: 30px 20px 30px;
			border: 1px solid var(--text--secondary);
		}

		.awardsTable {
			margin: 0 20px 20px;
			width: calc(100% - 40px);
			border-collapse: collapse;

			tr:first-of-type {
				background-color: var(--box--info-bg);
			}

			td {
				padding: 6px 10px;
				border: 1px solid var(--table--regular-bg);
				text-align: center;
				font-size: 14px;
				color: var(--text--secondary);

				@media only screen and (max-width: 760px) {
					padding: 3px 1px;
					font-size: 10px;
				}
			}
		}
		@media only screen and (max-width: 760px) {
			padding: 0 0 25px;
			margin: 25px auto;
		}
		h2 {
			white-space: initial !important;
			line-height: 1.5 !important;
		}
	}
	.policy-page h4 {
		color: var(--text--secondary);
	}
	#privacyPolicyOverlay,
	#cookiePolicyOverlay,
	#softwareLicenceAgreementOverlay,
	#termsAndConditionsOverlay {
		@media only screen and (min-width: 760px) {
			margin: 40px auto !important;
		}
		.box_header em {
			@media only screen and (min-width: 760px) {
				margin: 0 40px;
			}
		}
	}

	@import 'Terms.oranum';
}

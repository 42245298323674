@import '../../../scss/shell';

:global {
	.clubElite {
		input[type="radio"] {
			display: none;
		}
	}
}

.clubElite {
	text-align: center;

	.section1 {
		position: relative;
		padding: 0 75px 52px;
		background: url('./clubElite_section1.jpg') center no-repeat;

		.masks {
			margin: -54px auto 0;
		}

		.logo {
			width: 325px;
			height: 35px;
			margin: -42px auto 35px;
			background: url('./clubElite_Logo.png') no-repeat center;
		}
	}

	.section2 {
		padding: 45px 0 60px;
		background: var(--earn-more--jasmin-elite--section-bg);

		ul {
			display: flex;
			margin: 44px 30px 0;
		}

		li {
			flex-basis: 235px;
			margin: 0 25px;
			text-align: left;
			color: var(--earn-more--text);

			&:nth-child(3) {
				flex-basis: 280px;
			}

			p {
				margin: 4px 0;

				strong {
					font-weight: bold;
					color: var(--text--base);
				}
			}

			span {
				float: left;
				width: 48px;
				height: 48px;
				margin: 0 14px 0 0;
				border: 3px var(--text--link) solid;
				border-radius: 50%;
				color: var(--text--link);
				text-align: center;

				i {
					position: relative;
					top: 7px;
					font-size: 27px;
					color: var(--text--link);

					@include _vertical-gradient(
							var(--earn-more--text-gradient-start),
							var(--earn-more--text-gradient-stop),
							0%, 100%,
							true
					);

					&.mask {
						top: 14px;
						font-size: 15px;
					}

					&.diamond {
						top: 11px;
						font-size: 23px;
						font-weight: bold;
					}
				}
			}
		}
	}

	.section3 {
		position: relative;
		min-height: 400px;
		background: url('./clubElite_section3.jpg') center no-repeat;
		text-align: right;

		label {
			display: inline-block;
			position: relative;
			z-index: 1;
			top: 296px;
			right: 128px;
			width: 41px;
			height: 41px;
			margin: 0 8px;
			cursor: pointer;
		}

		section {
			display: none;
			position: absolute;
			top: 0;
			width: 100%;
			padding: 0 82px;
			text-align: center;
		}

		.mask {
			flex-basis: 328px;
			max-width: 328px;
			height: 328px;
			margin: 28px 0 0;
			background-size: 100%;
			background-repeat: no-repeat;

			&.crush {
				background-image: url('./mask_crush.png');
			}

			&.lover {
				background-image: url('./mask_lover.png');
			}

			&.sweetheart {
				background-image: url('./mask_sweetheart.png');
			}

			&.lancelot {
				background-image: url('./mask_lancelot.png');
			}

			&.romeo {
				background-image: url('./mask_romeo.png');
			}

			&.casanova {
				background-image: url('./mask_casanova.png');
			}
		}

		.content {
			max-width: 410px;
			padding: 48px 0 0 60px;

			&.lastContent {
				max-width: 468px;
				padding: 48px 0 0 20px;
			}

			h4 {
				margin: 0 0 2px;
				color: var(--text--link);
				font-size: 14px;
				font-weight: normal;
				text-transform: uppercase;
			}

			h2 {
				margin: 0 0 14px;
			}

			h3 {
				margin: 33px 0 20px;
				font-size: 18px;
			}

			span {
				color: var(--text--secondary-darker);
				font-size: 12px;
			}
		}

		input:checked {
			+ label {
				+ section {
					display: flex;
				}
			}
		}

		.progressBlock {
			position: absolute;
			width: 358px;
			height: 64px;
			top: 286px;
			right: 126px;

			.progressBar {
				margin: 25px 39px;
				width: 280px;
				height: 14px;
				background: var(--earn-more--jasmin-elite--progress-bar);

				span {
					position: absolute;
					top: 19px;
					left: 0;
					display: inline-block;
					width: 0;
					max-width: 280px;
					height: 26px;
					margin: 0 39px;
					background: url('./progress.png') repeat-x;

					&:after {
						content: '';
						display: inline-block;
						position: absolute;
						width: 10px;
						height: 26px;
						top: 0;
						right: -10px;
						background: url('./progress_end.png');
					}
				}
			}

			.masks {
				position: absolute;
				width: 358px;
				height: 64px;
				top: 0;
				left: 0;
				background-image: url('./masks.png');
			}
		}
	}

	.section4 {
		position: relative;
		min-height: 470px;
		padding: 47px 0 50px;
		@include _vertical-gradient(
			var(--earn-more--section-bg),
			var(--earn-more--jasmin-elite--section-bg),
			0%, 40%
		);

		input:checked {
			+ label {
				color: var(--text--base);
				font-weight: bold;
				border-bottom: 2px solid var(--text--base);
				cursor: default;

				+ section {
					display: block;
				}
			}
		}

		label {
			display: inline-block;
			margin: 0 38px 14px;
			padding: 11px 10px 13px;
			font-size: 14px;
			color: var(--earn-more--text);
			cursor: pointer;
		}

		section {
			display: none;
			position: absolute;
			top: 123px;
			min-height: 300px;
			background: url('./clubElite_step1.png') 53px 54px no-repeat;

			&.tabContent2 {
				background: url('./clubElite_step2.png') 43px 51px no-repeat;

				div {
					margin: 50px 60px 0 422px;
				}
			}

			&.tabContent3 {
				background: url('./clubElite_step3.png') 38px -17px no-repeat;

				div {
					margin: 95px 85px 0 422px;
				}
			}

			div {
				margin: 84px 85px 0 422px;
				text-align: left;

				p {
					margin: 0 0 20px;
				}
			}
		}
	}

	.section1,
	.section2,
	.section3,
	.section4 {
		h2 {
			font-size: 22px;
			text-transform: uppercase;
		}

		p {
			margin: 0 0 10px;
			font-size: 16px;
			font-weight: 200;
			line-height: 1.3;
			color: var(--earn-more--text);

			strong {
				color: var(--text--base);
			}
		}
	}
}

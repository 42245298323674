////
/// @group Common
/// @author Viktor Vincze
////
/// Generates common selectors
/// @access private
/// @param {String} $suffix [''] Suffix will be appended to the selector
@mixin _common-rules($suffix: ''){

	@if $suffix != '' {
		$suffix: '-' + unquote($suffix);
	}

	@if _common('float') {
		.#{_format-selector('float-left' + $suffix, false, false, true)}   			  	  {float: left !important}
		.#{_format-selector('float-right' + $suffix, false, false, true)}  			  	  {float: right !important}
		.#{_format-selector('float-none' + $suffix, false, false, true)}				  {float: none !important}
		.#{_format-selector('row-clear' + $suffix, false, false, true)}                   {display: table; clear: both; float: left; width: 100%}
		.#{_format-selector('clear-after' + $suffix, false, false, true)}                 {&::after{display: table; clear: both; float: left; width: 100%}}
	}

	@if _common('display') {
		.#{_format-selector('hide' + $suffix, false, false, true)}         			  {display: none !important}
		.#{_format-selector('show' + $suffix, false, false, true)}         			  {display: inherit !important}
		.#{_format-selector('inline' + $suffix, false, false, true)}		  			  {display: inline !important}
		.#{_format-selector('inline-block' + $suffix, false, false, true)} 			  	  {display: inline-block !important}
		.#{_format-selector('block' + $suffix, false, false, true)} 					  {display: block !important}
		.#{_format-selector('table' + $suffix, false, false, true)} 					  {display: table !important; table-layout: fixed !important; > * {display: table-cell !important; float: none !important; vertical-align: top;} &.unfixed{table-layout: auto}}
		.#{_format-selector('cell' + $suffix, false, false, true)} 					  {display: table-cell !important}
		.#{_format-selector('flex' + $suffix, false, false, true)} 					  {display: flex !important;display: -webkit-flex !important}
	}

	@if _common('overflow') {
		.#{_format-selector('overflow-hidden' + $suffix, false, false, true)}			  {overflow: hidden !important}
		.#{_format-selector('overflow-visible' + $suffix, false, false, true)}			  {overflow: visible !important}
	}

	@if _common('visibility') {
		.#{_format-selector('visible' + $suffix, false, false, true)}                     {visibility: visible}
		.#{_format-selector('invisible' + $suffix, false, false, true)}                   {visibility: hidden}
		.#{_format-selector('invisible-overflow' + $suffix, false, false, true)}          {visibility: hidden !important; overflow: hidden !important; height: 0 !important; width: 0 !important; padding: 0 !important; margin: 0 !important}
	}

	@if _common('dimension') {
		.#{_format-selector('vertical-middle' + $suffix, false, false, true)}             {display: table; table-layout: fixed; > * {display: table-cell; vertical-align: middle; float: none !important} &.unfixed{table-layout: auto} &:after{display: none}}
		.#{_format-selector('align-top' + $suffix, false, false, true)}                   {vertical-align: top !important}
		.#{_format-selector('align-middle' + $suffix, false, false, true)}                {vertical-align: middle !important}
		.#{_format-selector('align-bottom' + $suffix, false, false, true)}                {vertical-align: bottom !important}
		.#{_format-selector('full-height' + $suffix, false, false, true)}                 {height: 100% !important}
		.#{_format-selector('full-width' + $suffix, false, false, true)}                  {width: 100% !important}
		.#{_format-selector('full-all' + $suffix, false, false, true)}                    {width: 100% !important; height: 100% !important}
		.#{_format-selector('null-height' + $suffix, false, false, true)}                 {height: 0 !important}
		.#{_format-selector('auto-height' + $suffix, false, false, true)}                 {height: auto !important}
		.#{_format-selector('px-height' + $suffix, false, false, true)}                    {height: 1px !important}
		.#{_format-selector('null-width' + $suffix, false, false, true)}                  {width: 0 !important}
		.#{_format-selector('px-width' + $suffix, false, false, true)}                     {width: 1px !important}
		.#{_format-selector('null-all' + $suffix, false, false, true)}                    {width: 0 !important; height: 0 !important}
		.#{_format-selector('screen-middle' + $suffix, false, false, true)}               {position: absolute; top: 0; right: 0; bottom: 0; left: 0; margin: auto}
	}

	@if _common('position') {
		.#{_format-selector('relative' + $suffix, false, false, true)}                   {position: relative}
		.#{_format-selector('absolute' + $suffix, false, false, true)}                   {position: absolute}
		.#{_format-selector('fixed' + $suffix, false, false, true)}                      {position: fixed}
		.#{_format-selector('static' + $suffix, false, false, true)}                     {position: static}
		.#{_format-selector('no-margin' + $suffix, false, false, true)}                   {margin: 0 !important}
		.#{_format-selector('no-margin-top' + $suffix, false, false, true)}               {margin-top: 0 !important}
		.#{_format-selector('no-padding' + $suffix, false, false, true)}                  {padding: 0 !important}
	}

	@if _common('font') {
		.#{_format-selector('s-font' + $suffix, false, false, true)}                {font-size: 0.9em}
		.#{_format-selector('x-s-font' + $suffix, false, false, true)}                {font-size: 0.8em}
		.#{_format-selector('xx-s-font' + $suffix, false, false, true)}               {font-size: 0.7em}
		.#{_format-selector('xxx-s-font' + $suffix, false, false, true)}              {font-size: 0.6em}
		.#{_format-selector('xxxx-s-font' + $suffix, false, false, true)}             {font-size: 0.5em}
		.#{_format-selector('xxxxx-s-font' + $suffix, false, false, true)}            {font-size: 0.4em}
		.#{_format-selector('l-font' + $suffix, false, false, true)}                    {font-size: 1.1em}
		.#{_format-selector('x-l-font' + $suffix, false, false, true)}                 {font-size: 1.2em}
		.#{_format-selector('xx-l-font' + $suffix, false, false, true)}                  {font-size: 1.3em}
		.#{_format-selector('xxx-l-font' + $suffix, false, false, true)}                 {font-size: 1.4em}
		.#{_format-selector('xxxx-l-font' + $suffix, false, false, true)}                {font-size: 1.5em}
		.#{_format-selector('xxxxx-l-font' + $suffix, false, false, true)}               {font-size: 1.6em}

		.#{_format-selector('bold' + $suffix, false, false, true)}                       {font-weight: bold}
		.#{_format-selector('italic' + $suffix, false, false, true)}                     {font-style: italic}
		.#{_format-selector('uppercase' + $suffix, false, false, true)}                  {text-transform: uppercase}
		.#{_format-selector('lowercase' + $suffix, false, false, true)}                  {text-transform: lowercase}

		.#{_format-selector('no-decoration' + $suffix, false, false, true)}               {text-decoration: none !important}
		.#{_format-selector('underline' + $suffix, false, false, true)}               {text-decoration: underline}
		.#{_format-selector('no-wrap' + $suffix, false, false, true)}               {white-space: nowrap}
	}

	@if _common('align') {
		.#{_format-selector('align-center' + $suffix, false, false, true)}                {text-align: center!important}
		.#{_format-selector('align-left' + $suffix, false, false, true)}                  {text-align: left!important}
		.#{_format-selector('align-right' + $suffix, false, false, true)}                 {text-align: right!important}
		.#{_format-selector('align-justify' + $suffix, false, false, true)}                 {text-align: justify!important}
	}

	@if _common('misc') {
		.#{_format-selector('no-border' + $suffix, false, false, true)}                  {border: 0 !important}

		.#{_format-selector('no-transition' + $suffix, false, false, true)}              {transition: all, 0s}

		.#{_format-selector('no-box-sizing' + $suffix, false, false, true)}               {box-sizing: content-box}
		.#{_format-selector('no-box-sizing-all' + $suffix, false, false, true)}            { &,*{box-sizing: content-box}}

		.#{_format-selector('cursor-pointer' + $suffix, false, false, true)}            {cursor: pointer !important}
	}

	@if _common('color') {
		@each $name, $color in _common('colors') {
			.#{_format-selector('color-#{$name}' + $suffix, false, false, true)}                {color: var($color)}
			.#{_format-selector('color-hover-#{$name}' + $suffix, false, false, true)}:hover    {color: var($color)}
		}
	}
}
$isCommonModuleDefaultRendered: false;
// Apply without suffix
@if _module('common') and $isCommonModuleDefaultRendered == false {
	$isCommonModuleDefaultRendered: true !global;
	@include _common-rules;
}

////
/// Misc mixins but still very useful :)
/// @group Misc
/// @author Viktor Vincze
////

/// Creates CSS triangles using the border technique
/// @access public
/// @param {String}		$triangle-size			Used to set border-size. No default, set a px or em size.
/// @param {String}		$triangle-color			Used to set border-color which makes up triangle. No default
/// @param {String}		$triangle-direction		Used to determine which direction triangle points. Options: top, bottom, left, right
@mixin _css-triangle($triangle-size, $triangle-color, $triangle-direction) {
	content: "";
	display: block;
	width: 0;
	height: 0;
	border: inset $triangle-size;
	@if ($triangle-direction == top) {
		border-color: $triangle-color transparent transparent transparent;
		border-top-style: solid;
	}
	@if ($triangle-direction == bottom) {
		border-color: transparent transparent $triangle-color transparent;
		border-bottom-style: solid;
	}
	@if ($triangle-direction == left) {
		border-color: transparent transparent transparent $triangle-color;
		border-left-style: solid;
	}
	@if ($triangle-direction == right) {
		border-color: transparent $triangle-color transparent transparent;
		border-right-style: solid;
	}
}

/// Makes an element visually hidden, but accessible.
/// @access public
@mixin _invisible {
	position: absolute !important;
	height: 1px;
	width: 1px;
	overflow: hidden;
	clip: rect(1px, 1px, 1px, 1px);
	-moz-clip: rect(1px, 1px, 1px, 1px);
	-webkit-clip: rect(1px, 1px, 1px, 1px);
}

/// Turns off the element-invisible effect.
/// @access public
@mixin _invisible-off {
	position: inherit !important;
	height: inherit !important;
	width: inherit !important;
	overflow: visible;
	clip: auto;
}

/*
A simple little SCSS mixin for creating scrim gradients
Inspired by Andreas Larson - https://github.com/larsenwork
https://css-tricks.com/easing-linear-gradients/
*/

@mixin scrimGradient($startColor: $color-black, $direction: 'to bottom') {
	$scrimCoordinates: (
			0: 1,
			19: 0.738,
			34: 0.541,
			47: 0.382,
			56.5: 0.278,
			65: 0.194,
			73: 0.126,
			80.2: 0.075,
			86.1: 0.042,
			91: 0.021,
			95.2: 0.008,
			98.2: 0.002,
			100: 0
	);

	$hue: hue($startColor);
	$saturation: saturation($startColor);
	$lightness: lightness($startColor);
	$stops: ();

	@each $colorStop, $alphaValue in $scrimCoordinates {
		$stop: hsla($hue, $saturation, $lightness, $alphaValue) percentage($colorStop/100);
		$stops: append($stops, $stop, comma);
	}

	background: linear-gradient(unquote($direction), $stops);
}
@import '../../../scss/shell';

:global {

	#migration_chooseperson,
	#MigrationFinishPerformerProcess {
		.box_container {
			margin: 50px auto;
			width: 880px;
		}

		.box_header {
			overflow: inherit;
		}

		& > p,
		p.choosePersonInfo {
			color: var(--text--base);
			font-size: 14px;
			line-height: 14px;
			margin: 30px;
		}

		// Only for the 'registration' version.
		// When the perf migrating from the performercenter
		form {
			display: none;
		}

		// Form when the chooseperson
		form#migrationFinishPerformerProcessForm {
			display: block;
			background: var(--migration--finish-form-bg);
			margin: 40px 0 0;
			padding: 40px 0 35px;

			.form_row {
				float: left;

				label {
					width: 70px;
				}
			}

			input {
				width: 200px;
			}

			.inputs {
				margin-bottom: 0;
			}

			label {
				width: 110px;
				margin-right: 20px;

				em {
					display: none;
				}
			}

			.formSubmit {
				margin: 3px 0 0 30px;
			}
		}
	}
}

@import '../../scss/shell';

.videoContent {
	position: relative;
	margin: 3.5rem 4rem 0;
	padding: 3.8% 3.1% 0;
	background-image: url('./tablet_border.png');
	background-size: 100%;

	video {
		display: block;
	}
}
@use 'global/icons';
@import '../../../scss/shell';

$tabletLandscapeMaxWidth: 1200;

:global {
	.memberProfileHeader {
		.loyaltyMask {
			width: 25px;
			margin: 0 10px;
			vertical-align: middle;
		}

		.closeButton:hover i {
			color: var(--members--icon);
		}

		.iconClose {
			margin: 1.2rem;
			font-size: 2.4rem;
			color: var(--members--icon);
			opacity: 1;

			&:hover::before{
				content: icons.get('close-solid');
			}
		}
	}

	#memberProfileMenu {
		.filterBox {
			height: 90px;
			padding: 40px 40px 0;
			background: var(--members--tab-container-bg);

			li {
				width: 50%;
				height: 50px;
				text-align: center;
				border-left: 1px solid var(--members--tab-container-bg);
				border-right: 1px solid var(--members--tab-container-bg);

				a {
					padding: 0;
					line-height: 51px;
					font-size: 16px;
					text-transform: none;
					border-radius: 5px 5px 0 0;
					background: var(--members--tab-bg);

					&.active {
						font-weight: bold;
						background: var(--box--main-bg);
					}
				}
			}
		}
	}

	#memberProfileWrapper {
		position: relative;
		width: 100%;
		height: 480px;

		@include _on(0, $tabletLandscapeMaxWidth) {
			height: 220px;
		}

		#profile_leftArrow,
		#profile_rightArrow {
			height: 480px;
			position: absolute;
			width: 20%;
			top: 0;
			z-index: 8;

			&:hover {
				span {
					visibility: visible;
				}
			}

			span {
				visibility: hidden;
				position: absolute;
				top: 225px;

			}
		}
		#profile_leftArrow {
			left: 0;
			span {
				left: 16px;
			}
		}
		#profile_rightArrow {
			right: 0;
			span {
				right: 16px;
			}
		}

		#memberImages {
			img {
				position: absolute;
				top: 0;
				left: 0;
				width: 640px;
				height: 480px;
				z-index: 1;
				opacity: 0;

				&.active {
					z-index: 3;
				}
				&.preActive {
					z-index: 2;
				}
			}

			.emptyImage {
				margin: 90px 0 0;
				text-align: center;

				i {
					font-size: 290px;
					color: var(--members--empty-profile-icon);
				}

				@include _on(0, $tabletLandscapeMaxWidth) {
					margin-top: 30px;

					i {
						font-size: 120px;
					}
				}
			}
		}

		.memberProfileShadow {
			border-radius: 0 0 4px 4px;
			width: 100%;
			height: 100%;
			position: absolute;
			top: 0;
			@include _vertical-gradient(
					transparent,
					var(--members--shadow),
					50%, 100%
			);
		}

		#pictureTexts {
			z-index: 10;
			position: absolute;
			bottom: 0;
			width: 100%;
			padding: 0 0 30px 40px;

			h1 {
				font-size: 44px;
				line-height: 44px;
				font-weight: 900;
				display: block;
				text-align: left;
				letter-spacing: -2px;
				margin-bottom: 10px;
				color: var(--text--link);
			}

			ul {
				margin: 0 0 0 -18px;
				width: 100%;
				color: var(--members--profile-picture-texts);

				li {
					list-style: none;
					font-size: 20px;
					line-height: 33px;
					float: left;
					margin: 0 18px;
					position: relative;
					height: 34px;

					span {
						z-index: 12;
						position: relative;
					}

					em {
						font-size: 28px;
						line-height: 16px;
						display: inline-block;
						position: absolute;
						right: -22px;
						top: 9px;
						opacity: 0.3;
						filter: alpha(opacity=30);
						cursor: default;
					}

					&:last-child em {
						display: none;
					}
				}
			}

			.loyaltyMask {
				position: relative;
				float: left;
				width: 38px;
				max-height: 40px;
				margin-right: 12px;
				opacity: 1;
			}

			@include _on(0, $tabletLandscapeMaxWidth) {
				padding: 0 0 10px 20px;

				h1 {
					font-size: 28px;
					font-weight: 700;
					line-height: 1;
				}

				ul li {
					font-size: 16px;
					line-height: 1.5;
					height: 24px;

					em {
						font-size: 22px;
						top: 5px;
					}
				}

				.loyaltyMask {
					width: 28px;
					max-height: 30px;
				}
			}
		}

	}
	.questionarie {
		color: var(--text--base);
		margin-bottom: 40px;

		p {
			padding: 23px 15px;
			font-size: 13px;
			line-height: 13px;

			span {
				display: block;
				font-weight: bold;
				font-size: 13px;
				line-height: 13px;
			}
			// If 2 spans in the p, title and content spans.
			span + span {
				margin: 10px 0 0 0;
				font-weight: normal;
				word-wrap: break-word;
			}
		}

		hr {
			margin: 0;
		}
	}
}
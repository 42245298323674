@use 'global/icons';
@use 'global/typography';
@use 'global/vars';
@import '../../../scss/shell';

//Messages osszas beszelgetes oldal
:global {
	#messageFilterContainer.filterBox {
		display: flex;
		justify-content: center;
		height: 53px;
		background-color: var(--page-background);

		li {
			flex: 0 1 166px;
			white-space: nowrap;
			position: relative;
			text-align: center;

			a {
				padding: 16px 0;
				font-size: 16px;
				line-height: 20px;
				color: var(--messenger--tab--inactive-text);
			}

			a.active {
				font-weight: bold;
				color: var(--messenger--tab--active-text);
			}

			&.active::after {
				content: '';
				display: block;
				position: absolute;
				bottom: 0;
				height: 2px;
				width: 100%;
				background-color: var(--messenger--tab--active-border);
			}
		}
	}

	.messengerTabs {
		display: flex;
		overflow-x: auto;
		overflow-y: hidden;
		border-bottom: 1px solid var(--messenger--tab--border);
	}

	.tabHeader {
		display: flex;
		padding: 4rem 3rem 0;
	}

	.actionBtnWrapper {
		width: 100%;
		margin-left: auto;
		white-space: nowrap;

		.button {
			&:first-of-type {
				margin-right: 0;

				@include _on('mobile') {
					width: 100%;
				}
			}
		}

		.deleteConversation i {
			font-size: 2rem;
		}
	}

	.messageListPageContainer {
		position: relative;
	}

	#messageListContainer {
		margin: 30px;
		text-align: center;

		#addNewMessage {
			margin-bottom: 20px;
			font-size: 16px;
		}

		.manageBox {
			@include style('box', 'common', 'info');

			display: table;
			width: 100%;
			height: 70px;
			margin: 5px 0 0;
			padding: 0;

			&.active {
				background-color: var(--messages--list--active-bg);

				.messageSumBox {
					a {
						font-weight: bold;
					}
				}
			}

			> * {
				display: table-cell;
				vertical-align: middle;
				padding: 0 1rem;

				&:first-child {
					padding-left: 2rem;
				}

				&:last-child {
					padding-right: 2rem;
				}
			}
		}

		.messageStatus {
			i {
				color: var(--text--secondary);
				font-size: 2rem;

				&.unread {
					width: 1.2rem;
					height: 1.2rem;
					display: block;
					margin: 0 vars.spacer(1);
					border-radius: 1.2rem;
					background: var(--text--link);
				}
			}
		}

		#emptyMsgContainer {
			position: relative;
            padding: vars.spacer(10);
            background-color: var(--messages--empty-bg);
            border-radius: vars.border-radius(1);

			i {
				font-size: 3.2rem;
				color: var(--messages--empty-icon);
			}

			h2 {
				@include typography.title2Bold;
                margin: vars.spacer(1) 0 0 0;
			}

            div {
                @include typography.body2Regular;
                color: var(--messages--empty-description);
            }
		}

		.messageSumBox {
			overflow: hidden;
			line-height: 15px;
			text-align: left;
			width: 80%;

			p {
				@include style('text', 'normalSize', 'regularColor');

				text-overflow: ellipsis;
				overflow: hidden;
				padding: 0 0 10px 0;
				display: block;
				width: 100%;

				@include _on('mobile') {
					font-size: 11px;
				}
			}

			a {
				font-size: 14px;
				line-height: 16px;
				margin: 10px 0 0 0;
				display: inline-block;

				.messageArrow {
					margin: 0 3px 0 5px;
				}

				@include _on('mobile') {
					font-size: 12px;
				}
			}
		}

		.hasAttachment {
			i {
				font-size: 2rem;
				color: var(--messages--attachment-icon);
			}
		}

		.messageDate {
			@include style('text', 'normalSize', 'passiveColor');

			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			margin: 20px 15px;
			line-height: 30px;
			text-align: right;
		}

		// Oldalt valto dobozok formazasai
		.switchPageContainer {
			height: 35px;
			margin: 20px 0 0 0;
			display: inline-block;

			a {
				@include extendPageSwitchBox;

				float: left;
				line-height: 33px;
				margin: 0 5px 0 0;

				&:hover {
					box-shadow: 0 1px 0 RGBA(var(--switch-box--shadow-rgb), 0.3);

					.linkArrowLeft {
						@include style('icon', 'linkArrowHorizontal', 'linkArrowLeftActive');
					}

					.linkArrowRight {
						@include style('icon', 'linkArrowHorizontal', 'linkArrowRightActive');
					}
				}

				.linkArrowLeft,
				.linkArrowRight {
					margin: 12px 14px;
				}
				.icon-angle-right,
				.icon-angle-left {
					font-size: 10px;
				}
			}
		}
	}

	#message_thread,
	#messageListContainer,
	.messageListPageContainer {
		.backButton {
			@include extendPageSwitchBox;

			float: left;
			margin: 0 14px 0 0;
			text-align: center;

			i {
				position: relative;
				top: vars.spacer(1);
				font-size: 2.4rem;
			}

			&:hover {
				.trashCanCross {
					@include style('icon', 'trashCanCrossHovered');
				}
			}
		}
	}

	#addNewMessage {
		float: right;
	}

	.oranum-theme {
		#message_thread
		.messageHeader
		h2 {
			color: var(--text--secondary);
		}
	}

	//Messages beszelgetes reszletei oldal
	#message_thread {

		.messageHeader {
			position: relative;
			margin: 30px;
			min-height: 35px;

			h2 {
				font-weight: bold;
				font-size: 15px;
				line-height: 35px;
				margin: 0;
				color: var(--text--link);
				text-transform: capitalize;
				text-overflow: ellipsis;
				max-width: 560px;
				white-space: nowrap;
				display: block;
				overflow: hidden;

				.messageSubject {
					overflow: hidden;
					max-width: 690px;
				}
				.messageSubject {
					overflow: hidden;
					padding-right: 10px;
				}

				.messageDayHeader {
					position: absolute;
					left: 50px;
					bottom: 0;

					p {
						@include style('text', 'normalSize', 'regularColor');
					}
				}
			}

			.btnWrapper {
				float: right;

				@include _on('mobile', 'portrait') {
					top: 35px;
					position: absolute;
				}
			}

			.button {
				position: relative;
				top: 2px;
				margin-left: 8px;

				i {
					font-size: 2rem;
				}
			}

			@include _on('mobile') {
				.backButton {
					display: none;
				}
			}
		}

		#messageBoardMainContainer {
			border-radius: 5px;
			overflow: hidden;
			width: auto;
			padding: 0 30px;
			margin: 0 30px 20px 30px;

			@include _on('mobile', 'portrait') {
				margin-top: 35px;
			}

			.shadowBox,
			.shadowBoxBottom {
				z-index: 10;
			}

			.shadowBoxBottom {
				bottom: 0;
				left: 0;
				width: 100%;
				height: 6px;
				position: absolute;
				background: url('../../App/gfx_gradients_sprite.png') repeat-x 0 -19px;
			}
		}

		.attachment {
			display: block;
			margin: 0 0 20px 30px;

			i {
				position: relative;
				font-size: 2rem;
				color: var(--messages--support--attachment-icon);
				float: none;
				top: 0.5rem;
			}

			&:hover {
				i {
					color: var(--text--base);
				}
			}
		}

		#messageBoardScrollContainer {
			min-height: 200px;
		}

		#messageBoardContent {
			text-align: left;
			padding: 20px 0;

			.messageBlock {
				float: left;
				width: 100%;
				margin: 0 30px 0 0;
			}

			.messageContainer {
				float: left;
				width: 100%;
				margin: 0 0 30px 0;

				.date {
					margin: 10px 0 0;
				}

				&.offlineSurprise {
					width: 100%;
					margin: 0;
				}

				object {
					display: block;
					margin: 0 auto;
					position: relative;
					left: -20px;
				}
			}

			.messageTime {
				float: left;
				width: 110px;
				margin: 0 0 0 20px;
				text-align: right;

				@include style('text', 'normalSize', 'passiveColor');
			}

			.messageSender {
				color: var(--text--base);
				font-weight: bold;
				font-size: 12px;
				line-height: 16px;
			}

			.messageContent {
				@include style('text', 'normalSize', 'regularColor');

				a {
					overflow-wrap: break-word;
					word-wrap: break-word;
					-ms-word-break: break-all;
					word-break: break-all;
					-ms-hyphens: auto;
					-moz-hyphens: auto;
					-webkit-hyphens: auto;
					hyphens: auto;
				}
			}
		}

		#quickReply {
			.inputs {
				width: 100%;

				&.messageSubmit {
					margin: 0;

					input[type="submit"] {
						float: left;
					}

					.attachBlock {
						float: left;
					}
				}
			}

			.hint {
				right: 0;
				bottom: -40px;
				text-align: right;
			}

			width: 890px;
			margin: 30px;

			.inputs {
				margin: 0 0 10px 0;

				textarea {
					width: 860px;
					height: 52px;
					padding: 10px 14px 10px 14px;
				}
			}
		}
	}

	.oranum-theme {
		#sendNewMessage
		.inputs.messageSubmit
		.uploader5__items > div
		.result i {
			color: var(--uploader5--progress-text);
			&:hover{
				color: var(--text--secondary);
			}
		}
	}

	#sendNewMessage {
		.inputs {
			width: 100%;

			&.messageSubmit {
				margin-top: 15px;

				button[type="submit"] {
					float: right;
				}

				.uploadBlock {
					display: inline-block;
					float: left;
					position: relative;
					width: 100%;

					.secondaryLarge {
						min-width: 0;
					}

					* {
						margin-top: 0;
						margin-bottom: 0;
					}
				}

				.uploader5 {
					text-align: left;

					&__button {
						float: left;

						i {
							font-size: 2rem;
						}

						.icon-info-circular-outline {
							position: relative;
							top: 0.1rem;
							left: 0.4rem;
							font-size: 2.4rem;
							color: var(--form--info-icon);

							&:hover::before {
								content: icons.get('info-circular-solid');
								color: var(--form--info-icon-hover);
							}
						}
					}

					&__items {
						float: left;
						margin: 20px 0;
						width: 100%;
						font-size: 12px;

						&:empty {
							margin: 0;
						}

						> div {
							text-align: left;
							width: 100%;
							padding: 0 0 0 15px;
							line-height: 36px;
							border-radius: 5px;
							background-color: var(--messages--attachment-uploader-bg);
							color: var(--text--secondary);
							margin: 5px 0;

							&:first-child {
								margin-top: 0;
							}

							em {
								display: inline-block;
								width: 170px;
								white-space: nowrap;
								overflow: hidden;
								text-overflow: ellipsis;
								vertical-align: middle;
								text-align: left;

								@include _on('mobile') {
									width: calc(100% - 160px);
								}
							}

							.commonProgressBar {
								display: inline-block;
								width: 195px;
							}

							.result {
								text-transform: uppercase;
								font-weight: bold;
								padding-right: 20px;
								float: right;
								display: inline-block;
								position: relative;
								vertical-align: middle;

								& .success {
									color: var(--common--success)
								}
								& .fail {
									color: var(--common--error)
								}

								i {
									position: absolute;
									top: 0;
									left: -4rem;
									right: auto;
									margin: 0 0 0 10px;
									color: var(--form--info-icon);
									font-size: 2.4rem;

									&:hover::before {
										content: icons.get('info-circular-solid');
										color: var(--form--info-icon-hover);
									}
								}
							}

							a.remove {
								text-transform: uppercase;
								font-weight: bold;
								float: right;
								display: inline-block;
								color: var(--text--secondary);
								vertical-align: middle;
								padding-right: 20px;

								&:hover {
									color: var(--text--base);
								}
							}
						}
					}

					&__progress {
						position: relative;
						top: 6px;
						left: 13px;

						&-bar {
							@include _on('mobile') {
								width: calc(100vw - 150px);
							}
						}
					}

					&__container i.icon-info-circle {
						top: 17px;
					}
				}
			}
		}

		.hint {
			right: 1px;
			bottom: -34px;
			text-align: right;
			opacity: 1 !important;
		}

		padding: 30px;

		.inputs {
			margin: 0 0 15px 0;
		}

		.subjectCaption {
			text-align: left;
			display: block;
			margin: 0 0 15px 15px;
		}

		textarea {
			width: 100%;
			height: 140px;
			padding: 10px 30px 10px 14px;
		}
	}

	/** Coming soon */
	#messagesComingSoon {
		@include style('box', 'common');

		text-align: center;

		.icon {
			position: static;
			display: inline-block;
			margin: 60px 0;
		}

		h1 {
			color: var(--text--base);
			font-size: 28px;
			font-weight: bold;
			margin-bottom: 30px;
		}

		p {
			color: var(--text--secondary);
			font-size: 14px;
		}
	}
}

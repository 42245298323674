@import '../../scss/shell';

:global {
    .sidebar {
        position: relative;
        border-radius: 4px 0 0 4px;
        width: #{_rem(_dim('menu-width'))};
        z-index: 11;
        background-color: var(--menu--mobile-cover-background);

        &::-webkit-scrollbar {
            display: none;
        }

        @include _on('small', 'down') {
            overflow-y: auto;
            position: fixed;
            border-radius: 0;
            height: calc(100% - 4rem);
            // On mobile/table, menu should be over tooltips
            z-index: 51;
        }
    }
    .sidebar-with-app-promotion-banner-on,
    .promotion-banner-on .sidebar {
        height: calc(100% - 13.7rem);
    }

    #accountBox {
        &.performerAccountBox {
            height: 238px;
        }

        &.exclusivePerformerAccountBox {
            height: 256px;
        }

        &.studioAccountBox {
            height: 218px;
        }
    }

    @include _on('small', 'down') {
        .menu-wrapper {
            width: #{_rem(_dim('menu-width'))};
            float: left;
            top: -1px;
            position: relative;
        }
    }

    #menuBox {
        float: left;
        border-bottom: 1px solid var(--menu--separator);

        .menuItem {
            display: block;
            float: left;
            position: relative;

            i {
                transition: none;
            }

            .menuArrow {
                margin-right: 16px;

                i::before {
                    display: inline-block;
                    opacity: 0.6;
                    transition: opacity 0.3s linear, transform 0.3s linear;
                }

                &.up i::before {
                    opacity: 1;
                    transform: rotate(180deg);
                }
            }

            .notification {
                background: var(--menu--new-bg);
                position: absolute;
                top: 12px;
                left: 14px;
                display: inline-block;
                height: 12px;
                width: 12px;
                border-radius: 50%;
                border: 2px solid var(--menu--notification--border);
            }

            &:hover .notification {
                border-color: var(--menu--notification--hover--border);
            }

            &:hover {
                .menuLine {
                    background-color: var(--menu--hover-bg);
                }

                .menuArrow i::before {
                    opacity: 1;
                }
            }

            &.activeMenu,
            &.activeSubMenu {
                ul {
                    visibility: visible;
                    height: auto;
                }
            }

            // Menu get activeMenu class after animation
            &.activeSubMenu.noClose {
                .menuLine {
                    color: var(--menu--active-text);
                    border-top: 1px solid var(--primary);
                }
            }

            &.activeMenu {
                .menuLine {
                    color: var(--menu--active-text);
                    background: var(--menu--active-bg);
                }
                i {
                    text-shadow: 0 1px 0 var(--menu--active-shadow);
                    color: var(--menu--active-text);
                }
            }

            &.activeMenu.hasSubmenu,
            &.activeSubMenu.hasSubmenu {
                .menuLine {
                    background-color: var(--menu--selected-bg);
                    border-color: var(--menu--separator);
                }
            }

            &.activeSubMenu.hasSubmenu.noClose {
                .menuLine {
                    color: var(--menu--active-text);
                }

                &:hover .notification {
                    border-color: var(--menu--notification--hover--sub);
                }
            }

            &.activeSubMenu.hasSubmenu.noClose .menuLine {
                color: var(--menu--active-text);
                background: var(--menu--active-submenu-parent);
                cursor: default;
            }

            //Sub menuk
            ul {
                visibility: hidden;
                height: 0;
                overflow: hidden;
                float: left;
                width: 100%;
            }

            li {
                display: block;
                float: left;

                &.activeSub,
                &.activeSub:hover {
                    .subMenu {
                        color: var(--menu--active-text);
                        background: var(--menu--active-bg);

                        .notificationCounter {
                            color: var(--menu--notification--counter--active);
                        }
                    }
                }

                &:hover {
                    .subMenu {
                        background-color: var(--menu--hover-bg);
                    }
                }
            }

            .subMenu {
                position: relative;
                display: flex;
                align-items: center;
                float: left;
                width: #{_rem(_dim('menu-width'))};
                min-height: 25px;
                word-wrap: break-word;
                border-top: 1px solid;
                border-color: var(--menu--separator);
                text-transform: capitalize;
                font-size: 14px;
                line-height: 18px;
                padding: 15px 0;
                color: var(--menu--sub-text);
                background-color: var(--menu--box-background);
                transition: background-color 0.3s linear, color 0.3s linear;

                .subMenuText {
                    flex-grow: 1;
                    padding-left: 52px;
                    padding-right: 16px;
                }

                .notification {
                    border: none;
                    top: 50%;
                    transform: translateY(-50%);
                    left: 20px;
                    width: 8px;
                    height: 8px;
                }

                .notificationCounter {
                    font-size: 14px;
                    line-height: 18px;
                    color: currentColor;
                    padding: 0 20px 0 10px;

                    i {
                        color: var(--common--alert);
                        margin-right: -4px;
                    }
                }
            }
        }

        .menuLine {
            align-items: center;
            display: flex;
            float: left;
            width: #{_rem(_dim('menu-width'))};
            min-height: 48px;
            line-height: 48px;
            word-wrap: break-word;
            background-color: var(--menu--bg);
            border-top: 1px solid;
            border-color: var(--menu--separator);
            text-transform: capitalize;
            font-weight: bold;
            font-size: 15px;
            color: var(--menu--text);
            transition: background-color 0.3s linear, color 0.3s linear;

            .menuIcon {
                display: flex;
                align-items: center;
                padding: 0 12px 0 16px;
                width: 52px;

                i {
                    font-size: 24px;
                    transition: color 0.3s linear;
                }
            }

            .profile {
                margin: 2px 16px 5px 23px;
            }

            .mainMenuText {
                font-size: 16px;
                line-height: 20px;
                padding: 14px 16px 14px 0;
                flex-grow: 1;
            }
        }

        [data-menu-label='Scheduled Shows'] .menuLine {
            text-transform: unset;
        }
    }

    .new {
        margin-left: auto;
        margin-right: 1.6rem;
        padding: 0.2rem 0.4rem;
        line-height: 1rem;
        text-transform: uppercase;
        font-size: 0.8rem;
        font-weight: 700;
        border-radius: 0.2rem;
        background: var(--menu--new-bg);
        color: var(--menu--new-text);
    }
}

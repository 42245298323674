@import '../../../scss/shell';

:global {
	.privateShowDiscount {
		input[type="radio"] {
			display: none;
		}
	}
}

.privateShowDiscount {

	.section1 {
		position: relative;
		padding: 57px 40px 48px;
		background: url('./section1_bg.jpg') no-repeat;
		background-size: cover;

		h2 {
			margin: 0 0 12px;
			font-size: 22px;
			font-weight: 300;
			text-transform: uppercase;
			color: var(--text--secondary);
		}

		h3 {
			margin: 0 0 56px;
			font-size: 30px;
			text-transform: uppercase;
		}

		p {
			max-width: 570px;
			margin-bottom: 18px;
			font-size: 18px;
			font-weight: 300;
			line-height: 1.3;
			color: var(--text--secondary);

			span {
				font-weight: bold;
				text-transform: uppercase;
				color: var(--text--base);
			}

			strong,
			&:last-of-type {
				font-weight: bold;
				color: var(--text--base);
			}
		}
	}

	.section2 {
		padding: 55px 40px 65px;
		text-align: center;

		@include _vertical-gradient(
				var(--earn-more--private-show-discount--gradient-from),
				var(--earn-more--private-show-discount--gradient-to),
				0%, 100%
		);

		h2 {
			font-size: 22px;
			text-transform: uppercase;
		}

		ul {
			display: flex;
			margin: 38px 0 0;
		}

		li {
			margin: 0 20px;
			text-align: left;
			color: var(--text--secondary);

			p {
				font-size: 18px;
				font-weight: 300;
				line-height: 1.2;

				strong {
					font-weight: bold;
					color: var(--text--base);
				}

				&:last-of-type {
					padding-left: 62px;
				}
			}

			span {
				float: left;
				width: 48px;
				height: 48px;
				margin: 0 14px 0 0;
				border: 3px var(--text--link) solid;
				border-radius: 50%;
				color: var(--text--link);
				text-align: center;

				i {
					position: relative;
					top: 8px;
					font-size: 27px;
					color: var(--text--link);

					@include _vertical-gradient(
							var(--earn-more--text-gradient-start),
							var(--earn-more--text-gradient-stop),
							0%, 100%,
							true
					);

					&.favorite {
						top: 10px;
						font-size: 22px;
					}

					&.star {
						font-size: 25px;
					}
				}
			}
		}
	}

	.section3 {
		position: relative;
		min-height: 495px;
		padding: 36px 0 25px;
		text-align: center;

		h2 {
			font-size: 22px;
			text-transform: uppercase;
		}

		input:checked {
			+ label {
				color: var(--text--base);
				font-weight: bold;
				border-bottom: 2px solid var(--text--base);
				cursor: default;

				+ section {
					width: 100%;
					display: flex;
					align-items: center;
				}
			}
		}

		label {
			display: inline-block;
			margin: 0 28px 12px;
			padding: 9px 0 7px;
			font-size: 14px;
			color: var(--earn-more--text);
			cursor: pointer;
		}

		section {
			display: none;
			position: absolute;

			div {
				text-align: left;

				&.image {
					flex-basis: 43%;
				}

				&.text {
					flex-basis: 57%;
					padding: 0 60px 0 8px;
				}
			}

			&.tabContent1 {
				margin: 10px 0 0;

				.text {
					margin: -4px 0 0;
				}
			}

			&.tabContent2 {
				margin: 8px 0 0;

				.text {
					flex-basis: 54%;
					margin: -12px 0 0;
					padding: 0 15px 0 60px;
				}

				.image {
					flex-basis: 46%;
					text-align: right;
				}
			}

			&.tabContent3 {
				margin: 8px 0 0;

				.text {
					margin: -12px 0 0;
				}
			}

			h3 {
				margin: 0 0 4px;
				font-size: 16px;
				text-transform: uppercase;
				color: var(--text--link);
			}

			h4 {
				margin: 0 0 12px;
				font-size: 18px;
				line-height: 1.3;
				text-transform: uppercase;
			}

			p {
				font-size: 16px;
				font-weight: 200;
				line-height: 1.25;
				color: var(--earn-more--text);
			}
		}
	}

	.moreInfo {
		display: block;
		margin: 20px 0 0;
		text-align: center;
		font-size: 12px;
	}
}

@import '../../../scss/shell';

:global {
	.twoWayAudioContent,
	.cam2CamContent {

		h2 {
			display: inline-block;
			margin: 0 0 2.2rem;
			padding: 4rem 0 0;
		}

		p {
			line-height: 1.3;
			color: var(--text--paragraph);
		}

		.button {
			position: absolute !important;
			top: 4rem;
			right: 4rem;
		}

		.content {
			font-size: 1.4rem;
			padding: 0 4rem;

			&.padded {
				padding: 0 40rem 2rem 4rem;

				@include _on('mobile') {
					padding-right: 4rem;
				}
			}

			&.list {
				padding: 0 4rem 6rem;
			}
		}

		.mac {
			position: relative;
			min-height: 28rem;
			background: url('./../TwoWayAudioSettings/TwoWayAudioBg.png') calc(100% + 13rem) -3rem no-repeat;
			z-index: 2;

			@include _on('mobile') {
				background: url('./../TwoWayAudioSettings/TwoWayAudioBg.png') center calc(100% + 30vw) no-repeat;
				background-size: contain;
				padding-bottom: 40vw;
			}
		}

		.gradient {
			position: absolute;
			height: 5rem;
			width: 100%;
			bottom: 0;
			z-index: 1;

			@include _vertical-gradient(var(--content-background), RGBA(var(--hr-background-rgb), 0.4));
		}

		.textGradient,
		.textGradient:before {
			color: var(--text--link);

			@include _vertical-gradient(
				var(--earn-more--text-gradient-start),
				var(--earn-more--text-gradient-stop),
				0%, 100%,
				true
			);
		}

		.modelsEarn {
			position: relative;
			margin: 1.5rem 0;
			@include _flexbox;

			.text {
				flex: 1;
				align-self: center;
				margin: 0 1.2rem;
			}
		}

		.badge {
			border: 0.2rem var(--text--link) solid;
			border-radius: 50%;
			color: var(--text--link);
			text-align: center;
			line-height: 3.1rem;
			width: 3.6rem;
			height: 3.6rem;
			font-size: 2rem;

			.icon-money,
			.icon-microphone {
				position: relative;
				top: 0.1rem;
			}

			.icon-cam2cam {
				font-size: 2.7rem;
			}

			.icon-ideas {
				font-size: 2.2rem;
			}

			.icon-star {
				font-size: 1.9rem;
			}
		}

		.info {
			font-size: 1.2rem;
			padding: 2rem 2.5rem;
			margin: 2.5rem 0;
			border-radius: .4rem;
			color: var(--text--secondary-darker);

			@include _flexbox;
			@include _horizontal-gradient(
				var(--earn-more--twowayaudio--block-gradient-start),
				var(--earn-more--twowayaudio--block-gradient-stop)
			);

			.badge {
				background-color: var(--content-background);
			}

			.text {
				flex: 1;
				margin: 0 1rem;
				align-self: center;
			}
		}

		.bulletList {
			list-style: disc;
			margin-left: 1.6rem;
			color: var(--text--paragraph);

			li {
				padding: 1.1rem 0;
			}
		}
	}
}
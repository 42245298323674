@use 'global/icons';

@mixin radial-gradient($fromColor, $fromPercentage, $toColor, $toPercentage) {
	background: -moz-radial-gradient(center, ellipse cover, $fromColor $fromPercentage, $toColor $toPercentage);
	background: -webkit-gradient(radial, center center, 0px, center center, $toPercentage, color-stop($fromPercentage,$fromColor), color-stop($toPercentage,$toColor));
	background: -webkit-radial-gradient(center, ellipse cover, $fromColor $fromPercentage,$toColor $toPercentage);
	background: -o-radial-gradient(center, ellipse cover, $fromColor $fromPercentage,$toColor $toPercentage);
	background: -ms-radial-gradient(center, ellipse cover, $fromColor $fromPercentage,$toColor $toPercentage);
	background: radial-gradient(ellipse at center, $fromColor $fromPercentage,$toColor $toPercentage);
}

@mixin user-select($value) {
	-webkit-touch-callout: $value;
	-webkit-user-select: $value;
	-khtml-user-select: $value;
	-moz-user-select: $value;
	-ms-user-select: $value;
	user-select: $value;
}

@mixin transform-origin($arguments...) {
	-webkit-transform-origin: $arguments;
	-moz-transform-origin: $arguments;
	-ms-transform-origin: $arguments;
	-o-transform-origin: $arguments;
	transform-origin: $arguments;
}

@mixin placeholder {
	&[placeholder] { @content }
	&:-moz-placeholder { @content }
	&::-webkit-input-placeholder { @content }
}

@mixin textBorder($color: #000) {
	text-shadow: 1px 0 0 $color, -1px 0 0 $color, 0 1px 0 $color, 0 -1px 0 $color, 1px 1px $color, -1px -1px 0 $color, 1px -1px 0 $color, -1px 1px 0 $color;
}

@mixin verticalOpaqueGradient() {
	background: -moz-linear-gradient(top, RGBA(255,255,255,0) 0%, RGBA(0,0,0,0.5) 100%); /* FF3.6+ */
	background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,RGBA(255,255,255,0)), color-stop(100%,RGBA(0,0,0,0.5))); /* Chrome,Safari4+ */
	background: -webkit-linear-gradient(top, RGBA(255,255,255,0) 0%,RGBA(0,0,0,0.5) 100%); /* Chrome10+,Safari5.1+ */
	background: -o-linear-gradient(top, RGBA(255,255,255,0) 0%,RGBA(0,0,0,0.5) 100%); /* Opera 11.10+ */
	background: -ms-linear-gradient(top, RGBA(255,255,255,0) 0%,RGBA(0,0,0,0.5) 100%); /* IE10+ */
	background: linear-gradient(to bottom, RGBA(255,255,255,0) 0%,RGBA(0,0,0,0.5) 100%); /* W3C */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#80000000',GradientType=0 ); /* IE6-9 */
}

/* Sprite Background */
@mixin sprite {
	background-repeat: no-repeat;
}

@mixin transition($duration: 0.25s, $property: all, $ease: linear) {
	-webkit-transition: $property $duration $ease;
	-moz-transition: $property $duration $ease;
	transition: $property $duration $ease;
}

@mixin transform($transforms) {
	-moz-transform: $transforms;
	-o-transform: $transforms;
	-ms-transform: $transforms;
	-webkit-transform: $transforms;
	transform: $transforms;
}

@mixin transformStyle($transform) {
	-webkit-transform-style: $transform;
	-moz-transform-style: $transform;
	-ms-transform-style: $transform;
	transform-style: $transform;
}

@mixin perspective($perspective) {
	-webkit-perspective: $perspective;
	-moz-perspective: $perspective;
	-ms-perspective: $perspective;
	perspective: $perspective;
}

@mixin backfaceVisibility($visibility) {
	-webkit-backface-visibility: $visibility;
	-moz-backface-visibility: $visibility;
	-ms-backface-visibility: $visibility;
	backface-visibility: $visibility;
}

////////////////////////
// New Mixins
////////////////////////

// We use this to create isosceles triangles
// $triangle-size - Used to set border-size. No default, set a px or em size.
// $triangle-color - Used to set border-color which makes up triangle. No default
// $triangle-direction - Used to determine which direction triangle points. Options: top, bottom, left, right
@mixin css-triangle($triangle-size, $triangle-color, $triangle-direction) {
	content: "";
	display: block;
	width: 0;
	height: 0;
	border: inset $triangle-size;
	@if ($triangle-direction == top) {
		border-color: $triangle-color transparent transparent transparent;
		border-top-style: solid;
	}
	@if ($triangle-direction == bottom) {
		border-color: transparent transparent $triangle-color transparent;
		border-bottom-style: solid;
	}
	@if ($triangle-direction == left) {
		border-color: transparent transparent transparent $triangle-color;
		border-left-style: solid;
	}
	@if ($triangle-direction == right) {
		border-color: transparent $triangle-color transparent transparent;
		border-right-style: solid;
	}
}

// We use this to translate elements in 3D
// $horizontal: Default: 0
// $vertical: Default: 0
@mixin translate($x:0, $y:0, $z: false) {
	@if $z == false {
		transform: translate($x,$y);
		-webkit-transform: translate($x,$y);
	}
	@else {
		transform: translate3d($x,$y,$z);
		-webkit-transform: translate3d($x,$y,$z);
	}
}

@mixin blur($px: false) {
	@if $px == false {
		$px: 5px;
	}
	-webkit-filter: blur($px);
	-moz-filter: blur($px);
	-o-filter: blur($px);
	-ms-filter: blur($px);
	filter: blur($px);
}

@mixin noFilter() {
	-webkit-filter: none;
	-moz-filter: none;
	-o-filter: none;
	-ms-filter: none;
	filter: none;
}

// Makes an element visually hidden, but accessible.
@mixin invisible {
	position: absolute !important;
	height: 1px;
	width: 1px;
	overflow: hidden;
	clip: rect(1px, 1px, 1px, 1px);
	-moz-clip: rect(1px, 1px, 1px, 1px);
	-webkit-clip: rect(1px, 1px, 1px, 1px);
}

// Turns off the element-invisible effect.
@mixin invisible-off {
	position: inherit !important;
	height: inherit !important;
	width: inherit !important;
	overflow: visible;
	clip: auto;
}

@mixin placeholder {
	&:-moz-placeholder { @content }
	&::-webkit-input-placeholder { @content }
}

@mixin transform($val){
	-moz-transform: $val;
	-webkit-transform: $val;
	-ms-transform: $val;
	-o-transform: $val;
	transform: $val;
}

@mixin animation($animate...) {
	$max: length($animate);
	$animations: '';

	@for $i from 1 through $max {
		$animations: #{$animations + nth($animate, $i)};

		@if $i < $max {
			$animations: #{$animations + ", "};
		}
	}
	-webkit-animation: $animations;
	-moz-animation:    $animations;
	-ms-animation:     $animations;
	-o-animation:      $animations;
	animation:         $animations;
}

@mixin keyframes($animationName) {
	@-webkit-keyframes #{$animationName} {
		@content;
	}
	@-moz-keyframes #{$animationName} {
		@content;
	}
	@-o-keyframes #{$animationName} {
		@content;
	}
	@keyframes #{$animationName} {
		@content;
	}
}

// Responsive media mixins
@mixin onLargeAndUp($orientation: false) {
	@if $orientation == false {
		@media only screen and (min-width: 1441px) {@content;}
	}
	@else {
		@media only screen and (min-width: 1441px) and (orientation : #{$orientation}) {@content;}
	}
}

@mixin onLargeOnly($orientation: false) {
	@if $orientation == false {
		@media only screen and (min-width: 1441px) and (max-width: 1920px) {@content;}
	}
	@else {
		@media only screen and (min-width: 1441px) and (max-width: 1920px) and (orientation : #{$orientation}) {@content;}
	}
}

@mixin onLargeAndDown($orientation: false) {
	@if $orientation == false {
		@media only screen and (max-width: 1920px) {@content;}
	}
	@else {
		@media only screen and (max-width: 1920px) and (orientation : #{$orientation}) {@content;}
	}
}

@mixin onLargePlus($orientation: false) {
	@if $orientation == false {
		@media only screen and (min-width: 1921px) {@content;}
	}
	@else {
		@media only screen and (min-width: 1921px) and (orientation : #{$orientation}) {@content;}
	}
}

@mixin onLargePlusAndDown($orientation: false) {
	@if $orientation == false {
		@media only screen and (max-width: 1921px) {@content;}
	}
	@else {
		@media only screen and (max-width: 1921px) and (orientation : #{$orientation}) {@content;}
	}
}

@mixin onMediumOnly($orientation: false) {
	@if $orientation == false {
		@media only screen and (min-width: 1025px) and (max-width: 1440px) {@content;}
	}
	@else {
		@media only screen and (min-width: 1025px) and (max-width: 1440px) and (orientation : #{$orientation}) {@content;}
	}
}

@mixin onMediumAndUp($orientation: false) {
	@if $orientation == false {
		@media only screen and (min-width: 1025px) {@content;}
	}
	@else {
		@media only screen and (min-width: 1025px) and (orientation : #{$orientation}) {@content;}
	}
}

@mixin onMediumAndDown($orientation: false) {
	@if $orientation == false {
		@media only screen and (max-width: 1440px) {@content;}
	}
	@else {
		@media only screen and (max-width: 1440px) and (orientation : #{$orientation}) {@content;}
	}
}

@mixin onMobile($orientation: false) {
	@if $orientation == false {
		@media only screen and (max-width: 640px) {@content;}
	}
	@else {
		@media only screen and (max-width: 640px) and (orientation : #{$orientation}) {@content;}
	}
}

@mixin onSmallOnly($orientation: false) {
	@if $orientation == false {
		@media only screen and (min-width: 641px) and (max-width: 1024px) {@content;}
	}
	@else {
		@media only screen and (min-width: 641px) and (max-width: 1024px) and (orientation : #{$orientation}) {@content;}
	}
}

@mixin onSmallAndUp($orientation: false) {
	@if $orientation == false {
		@media only screen and (min-width: 641px) {@content;}
	}
	@else {
		@media only screen and (min-width: 641px) and (orientation : #{$orientation}) {@content;}
	}
}

@mixin onSmallAndDown($orientation: false) {
	@if $orientation == false {
		@media only screen and (max-width: 1024px) {@content;}
	}
	@else {
		@media only screen and (max-width: 1024px) and (orientation : #{$orientation}) {@content;}
	}
}

@mixin onBreakPoint($orientation: false) {
	@if $orientation == false {
		@media only screen and (max-width: grid('breakPoint')) {@content;}
	}
	@else {
		@media only screen and (max-width: grid('breakPoint')) and (orientation : #{$orientation}) {@content;}
	}
}

@mixin onRetina {
	@media only screen and (-webkit-min-device-pixel-ratio: 1.25), (min-resolution: 120dpi) {@content;}
	@media only screen and (-webkit-min-device-pixel-ratio: 1.3), (min-resolution: 124.8dpi) {@content;}
	@media only screen and (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi) {@content;}
	@media only screen and (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {@content;}
}

$once: true;

/**
* Gets a style from style config and applies it
**/
@mixin style($key...){
	$length: length($key);
	@if $length > 2 {
		@for $i from 2 through $length {
			@include style(nth($key, 1), nth($key, $i));
		}
	}
	@else{
		@if map-has-key($styleMap, nth($key, 1)){
			$map: map-get($styleMap, nth($key, 1));
			@if map-has-key($map, nth($key, 2)){
				@each $prop, $val in map-get($map, nth($key, 2)){
					@if type-of($prop) == map {
						@each $prop2, $val2 in map-keys($prop) {
							#{$prop2}: #{$val2};
						}
					}
					@else {
						#{$prop}: $val;
					}
				}
			}
		}
	}
}

/**
* Clear on after pseudo class
**/
@mixin clear {
	&::after {
		content: '';
		clear: both;
		display: table;
	}
}

@mixin prefix($map, $vendors: webkit moz ms o) {
	@each $key in map-keys($map) {
		$prop: $key;
		$value: map-get($map, $key);
		@if $vendors {
			@each $vendor in $vendors {
				#{"-" + $vendor + "-" + $prop}: #{$value};
			}
		}
		#{$prop}: #{$value};
	}
}

// Custom, site related mixins

@mixin commonTextGradient {
	background: -webkit-linear-gradient(
		var(--mixin--common-text-gr--from),
		var(--mixin--common-text-gr--to)
	);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	background-clip: text;
	text-fill-color: transparent;
}

@mixin extendProfileHeadTextsGeneral {
	//Belepo resz formazasai ures galeria eseten
	.upload {
		margin: 54px 0 27px -7px;
	}

	.titleText {
		font-weight: bold;
		font-size: 30px;
		line-height: 40px;
		margin: 0 0 11px 0;
	}

	h6 {
		@include style('text', 'hugeSize', 'passiveColor');

		font-size: 18px;
		line-height: 22px;
		margin: 0 110px;
	}
}

// Altalanos galeria kontener, es elemei
@mixin extendProfileGalleryGeneralContainer {
	text-align: left;
	margin: 0 auto;

	.emptyPictureBox i {
		transform: translate(-50%,-50%);

		position : absolute;
		top: 50%;
		left: 50%;
		font-size: 5rem;
		color: var(--category-selector--border);
	}

	.galleryManageText {
		@include style('text', 'largeSize', 'regularColor');

		float: left;
		max-width: 690px;
		text-align: left;
		margin: 0 0 10px 0;
	}

	//Lenyilo select lista
	.selectTypes {
		float: right;
		top: -30px;
		right: 40px;
		margin: 0;

		input {
			height: 33px;
		}

		.selectArrow {
			top: 4px;
		}

		.scrollContainer {
			top: 38px;
		}
	}
}




@mixin radioInactive {
	box-shadow: 0 1px 1px 0 RGBA(50, 50, 50, 0.35);
	cursor: pointer;
	background: var(--form--input-background);
	border-radius: 50%;
	display: block;
	width: 20px;
	height: 20px;

	&.disabled {
		opacity: 0.4;
		cursor: default;
	}

}

@mixin radioActive {
	box-shadow: 1px 1px 0 RGBA(50, 50, 50, 0.35);
	cursor: pointer;
	background: var(--form--radio-check);
	border-radius: 50%;
	width: 20px;
	height: 20px;
	border: 5px solid var(--form--input-background);
	display: block;

	&.disabled {
		opacity: 0.4;
		cursor: default;
	}
}

@mixin extendPageSwitchBox {
	box-shadow:
		0 1px 0 RGBA(var(--switch-box--shadow-rgb), 0.3),
		0 1px 0 RGBA(var(--switch-box--shadow-rgb), 0.3) inset;
	display: block;
	position: relative;
	width: 33px;
	height: 33px;
	border: 1px solid var(--switch-box--border);
	color: var(--text--link);
	background-color: transparent;
	border-radius: 4px;
	font-size: 12px;

	@include _on('mobile') {
		margin: 0 3px 0 0;
		width: 28px;
		height: 28px;
		line-height: 28px;

		.icon.linkArrowRight,
		.icon.linkArrowLeft {
			margin: 8px 10px;
		}
	}

	.icon-arrow-left-outline,
	.icon-arrow-right-outline {
		font-size: 2.4rem;
	}

	&.activePageSwitchBox,
	&.active,
	&:hover {
		background-color: var(--form--input-background);
		color: var(--text--link);
		box-shadow: 0 1px 0 RGBA(var(--switch-box--shadow-rgb), 0.3);
	}

	&:hover {
		.icon-arrow-left-outline::before {
			content: icons.get('arrow-left-solid');
		}

		.icon-arrow-right-outline::before {
			content: icons.get('arrow-right-solid');
		}
	}

	&.passivePageSwitchBox {
		&:hover {
			background-color: transparent;
			color: var(--text--link);
			box-shadow: 0 1px 0 RGBA(var(--switch-box--shadow-rgb), 0.3);
		}
	}

	&.activePageSwitchBox {
		cursor: default;
	}
}

@mixin selectorBox {
	@include style('box', 'common');

	width: 100%;
	position: relative;
	padding: 20px;
	text-align: center;
	border: 4px solid var(--category-selector--border);
	cursor: pointer;
	transition: border-color 200ms;

	.radio-active, .radio-inactive {
		position: relative;
		margin: 0 auto;
	}

	&.selected .radio {
		@include radioActive;
	}

	h3 {
		font-size: 18px;
		margin: 20px 0 0 0;
	}

	p {
		@include style('text', 'normalSize', 'regularColor');
		margin: 15px 0 25px 0;
	}

	.errorContainer p {
		color: var(--text--base);
	}

	.inputs {
		margin: 0 25px 20px ;
	}

	.selectContainer {
		opacity: 0.4;
	}

	.transparentBox {
		top: 0;
		left: 0;
		z-index: 3;
		opacity: 0.4;
		width: 100%;
		height: 100%;
		background: var(--box--main-bg);
		position: absolute;
	}

	&:hover {
		border: 4px solid RGBA(var(--category-selector--border-hover-rgb), 0.4);
	}

	&.selected {
		border: 4px solid var(--category-selector--border-selected);

		.transparentBox {
			z-index: -3 !important;
		}

		.selectContainer {
			opacity: 1;
		}
	}
}

/* square button with icon general */
@mixin extendSquareImageButton {
	width: 38px;
	height: 38px;
	position: relative;
	margin: 33px 0 20px 2px;
	display: inline-block;
	border: 1px solid RGBA(#fff, 0.4);
	cursor: pointer;
	border-radius: 4px;

	.icon {
		top: 10px;
	}

	.enlarge {
		margin: 1px 0 1px 0;
	}

	.humanFigureBright {
		margin: 1px 0 2px 0;
	}

	.play {
		margin: 0px 0 0 2px;
		top: 11px;
	}

	.scissor {
		margin: -4px 0 0 0;
		top: 12px;
	}

	.smallSetting {
		top: 11px;
	}

	//play, scissor, smallSetting

	&:hover .upHelpTipContainer {
		display: block;
	}
}

// Base table mixin
@mixin commonTable{
	display: block;
	padding: 0 30px;

	//szintlepest jelolo csik
	.shareLineLeft,
	.shareLineRight {
		box-sizing: content-box;
		width: 100%;
		height: 2px;
		background-color: var(--text--link);
		border-top: solid 10px var(--box--main-bg);
		border-bottom: solid 10px var(--box--main-bg);
	}
	.shareLineValue {
		background-color: var(--box--main-bg);
		color: var(--text--link);
		font-weight: bold;
		font-size: 11px;
		line-height: 11px;
		height: 11px;
		vertical-align: middle;
	}
	.help {
		position: relative !important;
		margin: 0 !important;
	}
	.tableRow {
		p {
			float: left !important;
			line-height: 10px!important;
		}
	}
	td.center,
	th.center{
		text-align: center;
	}
	td.white{
		color: var(--text--base);
	}
	td.green{
		color: var(--common--success);
	}
	td.red{
		color: var(--common--error);
	}
	tfoot {
		td {
			@include style('table', 'footerBg');
		}
	}
}

@mixin draggable-cursor-grab {
	cursor: move;
	cursor: grab;
	cursor: -moz-grab;
	cursor: -webkit-grab;
}

@mixin draggable-cursor-grabbing {
	cursor: move !important;
	cursor: grabbing !important;
	cursor: -moz-grabbing !important;
	cursor: -webkit-grabbing !important;
}

@mixin extendEarnMoreStatusHeader {
	.statusHeader {
		position: relative;
		background: var(--earn-more--status-header-bg);
		padding: 24px 40px 18px 30px;
		width: 100%;

		i {
			left: auto;
			top: -3px;
			font-size: 28px;
			display: inline-block;
			position: relative;
			float: left;
			margin: 0 10px 0 0;
		}

		.icon-alert {
			color: var(--common--alert);
			margin: 0 2px 0 0;
		}

		p {
			display: inline-block;
			position: relative;
			float: left;
			color: var(--text--base);
			font-weight: bold;
			font-size: 16px;
			line-height: 23px;
		}

		.serviceStatus {
			font-size: 18px;
		}

		a {
			position: absolute;
			top: 18px;
			right: 40px;
		}
	}

	.statusName,
	.serviceStatus {
		position: relative;
		float: left;
		color: var(--text--base);
		font-weight: bold;
		font-size: 16px;
		line-height: 32px;
	}

	.activateText,
	.deactivateText {
		float: right;
		font-size: 14px;
		line-height: 22px;

		p {
			float: left;
		}

		.uploadSmall {
			margin: 3px 10px 0px 0px;
			float: left;
		}

		&:hover {
			.uploadSmall {
				background-position: -102px -414px;
			}
		}
	}
}

//szoveges elemek
@mixin extendEarnMoreTextContentElements {
	h2 {
		letter-spacing: -1px;
		line-height: 28px;
	}

	h4 {
		line-height: 18px;
	}

	ul {
		@include style('text', 'largeSize', 'regularColor');
		@include style('list', 'large');
	}
}

//beveteleket kiiro info doboz
@mixin extendEarnMoreIncomeInfoBox {
	@include style('box', 'common', 'info');

	width: 350px;
	height: 153px;
	text-align: center;
	padding: 30px 0;
	margin: 5px 0 0 0;
	float: left;

	hr {
		border-color: var(--box--main-bg);
		margin: 36px auto 27px auto;
	}

	.incomeText {
		@include style('text', 'breadCrumb');

		float: left;
		line-height: 18px;
		width: 205px;
		text-align: right;
		margin: 0 5px 0 0;
	}

	.incomeAmount {
		color: var(--text--base);
		font-weight: bold;
		font-size: 16px;
		line-height: 18px;
		float: left;
	}

	.actualIncome {
		margin: 8px 0 10px 0;
	}
}
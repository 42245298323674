.hide {
	display: none;
}

.pager {
	position: absolute;
	top: calc(50% - 34px);
	left: 0;
	z-index: 2;
	padding: 10px;
	font-size: 48px;
	color: var(--video-player--pager);
	text-shadow: 0 0 12px var(--overlay--text-shadow);
	cursor: pointer;

	&:last-of-type {
		left: calc(100% - 68px);
	}
}

.videoPlayer {
	background: none;
	height: auto;
}


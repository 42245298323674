.oranum-theme {
	.agreementContent,
	.termsPageContainer,
	.policy-page,
	#privacyPolicyOverlay,
	#cookiePolicyOverlay,
	#softwareLicenceAgreementOverlay,
	#termsAndConditionsOverlay {
		margin-top: 0;

		.box_header {
			background: var(--background);
			border: 0;
		}

		h2 {
			background: var(--background);
			text-transform: uppercase;
		}
	}
}
@use 'global/icons';
@use 'global/colors';

[class^="icon-"], [class*=" icon-"] {
	line-height: inherit;
	&:before {
		line-height: 1;
	}
}

.icon {
	top: 0;
	right:0;
	background: url('./gfx_icons_sprite.png') no-repeat;
	position: absolute;
	width: 20px;
	height: 20px;

	&.icon-check:before {
		display: none;
	}

	&:hover {
		.helpTipContainer {
			display: block;
		}

		.leftHelpTipContainer {
			display: block;
		}
	}

	&.done { //Tick in white circle
		background-position: -20px -1257px;
		position: relative;
		display: inline-block;
	}

	&.forbidden { //Crossed yellow circle
		background-position: -124px -1261px;
		width: 12px;
		height: 12px;
	}

	&.error,
	&.rejected { //Exclamation mark in yellow triangle
		background-position: -101px -1260px;
		display: block;
		height: 15px;
		width: 18px;
	}

	&.checkbox-inactive { //Empty red square
		background-position: 0px -1237px;
		left: 0;
		cursor: pointer;

		&.focus {
			box-shadow: 0 0 3px 0 RGBA(var(--icon--shadow-rgb), 0.75);
		}
	}

	&.checkbox-active { //Red square with yellow tick
		background-position: 0 -1216px;
		left: 0;
		cursor: pointer;

		&.focus {
			box-shadow: 0 0 3px 0 RGBA(var(--icon--shadow-rgb), 0.75);
		}
	}

	&.checkbox-disabledInactive { //Empty red square(lighter)
		cursor: default;
		background-position: -60px -1237px;
	}

	&.checkbox-disabledActive { //Red square with light red tick
		cursor: default;
		background-position: -60px -1216px;
	}

	&.humanFigure { //Small yellow human figure
		background-position: -104px -1221px;
		display: block;
		width: 11px;
		height: 10px;
	}

	&.openUpArrow { //Full yellow arrow
		background-position: -49px -1262px;
		display: inline-block;
		width: 9px;
		height: 5px;
	}

	&.selectArrow { //Big red square with an arrow
		top: 7px;
		right: 5px;
		width: 26px;
		height: 26px;
		display: block;
		cursor: pointer;
		background-position: 0 -1321px;
	}

	&.activeProfileDot { //Small yellow circle
		display: inline-block;
		width: 5px;
		height: 5px;
		background-position: -42px -1270px;
		position: relative;
	}

	&.checkYellow {
		display: inline-block;
		width: 11px;
		height: 9px;
		background-position: -125px -1283px;
		position: relative;
	}

	&.notChecked {
		display: inline-block;
		width: 15px;
		height: 15px;
		background-position: -138px -1773px;
		position: relative;
	}

//Common icons
	&.ok {
		margin: 10px 15px 0 0;
		right: -5px;
	}

	&.forbidden {
		margin: 6px 10px 0 0;
	}

	&.tooltipHelp { //dark grey help
		display: block;
		position: relative;
		width: 16px;
		height: 16px;
		background-position: -4px -312px;
	}

	&.error {
		left: 20px;
		top: 13px;
	}

	&.humanFigureBright {
		display: inline-block;
		width: 16px;
		height: 15px;
		position: relative;
		background-position: -79px -331px;
	}

	//Site specific icons

	&.info { // i in yellow circle
		display: block;
		position: relative;
		width: 19px;
		height: 19px;
		background-position: -53px -186px;
	}

	&.hugeInfo { // i in yellow circle huge
		display: inline-block;
		position: relative;
		width: 40px;
		height: 40px;
		background-position: -107px -207px;
	}

	&.close, &.closeTooltip { //Pink X
		display: block;
		width: 12px;
		height: 12px;
		margin: 19px;
		position: relative;
		background-position: -64px -435px;
		opacity: .6;

		&.hover, &:hover{
			opacity: 1;
		}
	}

	&.closeTooltip { //grey x
		background-position: -35px -435px;
	}

	&.alert { //Exclamation mark in red triangle
		position: relative;
		display: block;
		background-position: -67px -243px;
		width: 22px;
		height: 18px;
		top: -5px;
	}

	&.notRated { //Line in grey circle
		position: relative;
		display: block;
		background-position: -77px -389px;
	}

	&.linkArrowRight { //Yellow right arrow
		@include style('icon', 'linkArrowHorizontal', 'linkArrowRight');
		background-image: url('./gfx_icons_sprite.png');
	}

	&.linkArrowRightActive { //White right arrow
		@include style('icon', 'linkArrowHorizontal', 'linkArrowRightActive');
		background-image: url('./gfx_icons_sprite.png');
	}

	&.linkArrowLeft { //Yellow left arrow
		@include style('icon', 'linkArrowHorizontal', 'linkArrowLeft');
		background-image: url('./gfx_icons_sprite.png');
	}

	&.linkArrowLeftActive { //White left arrow
		@include style('icon', 'linkArrowHorizontal', 'linkArrowLeftActive');
		background-image: url('./gfx_icons_sprite.png');
	}

	&.linkArrowDown { //Yellow down arrow
		@include style('icon', 'linkArrowVertical', 'linkArrowDown');
		background-image: url('./gfx_icons_sprite.png');
	}

	&.linkArrowDownActive { //White down arrow
		@include style('icon', 'linkArrowVertical', 'linkArrowDownActive');
		background-image: url('./gfx_icons_sprite.png');
	}

	&.stepperArrowLeft { //red left arrow
		@include style('icon', 'linkArrowHorizontal', 'stepperArrowLeft');
	}

	&.stepperArrowRight { //red right arrow
		@include style('icon', 'linkArrowHorizontal', 'stepperArrowRight');
		background-image: url('./gfx_icons_sprite.png');
	}

	&.horizontalScrollIcon {
		height: 8px;
		width: 9px;
		position: relative;
		display: block;
		background-position: -127px -918px;
		margin: 2px auto 3px;
	}

	&.calendarDownArrow { //red full arrow down
		display: inline-block;
		position: relative;
		width: 8px;
		height: 4px;
		background-position: -96px -275px;
	}

	&.calendarDownArrowActive { //yellow full arrow down
		background-position: -96px -280px;
	}

	&.clock { // grey circle clock
		display: block;
		width: 20px;
		height: 20px;
		position: relative;
		background-position: -78px -366px;
	}

	&.clockSmall { // grey smaller circle clock
		display: inline-block;
		width: 16px;
		height: 16px;
		position: relative;
		background-position: -80px -348px;
	}

	&.clockLarge { // red circle clock
		display: block;
		position: relative;
		width: 60px;
		height: 61px;
		background-position: -50px -911px;
	}

	&.humanFigureLarge { //huge human figure
		display: block;
		position: relative;
		width: 70px;
		height: 67px;
		background-position: 0 -489px;
	}

	&.humanFigureAddNewLarge { //huge human figure with plus
		display: block;
		position: relative;
		width: 74px;
		height: 67px;
		background-position: -72px -489px;
	}

	&.trashCan { //yellow trash can
		display: inline-block;
		width: 14px;
		height: 16px;
		position: relative;
		right: 0;
		background-position: -5px -330px;
	}

	&.trashCanHovered { //white trash can
		background-position: -5px -348px;
	}

	&.trashCanLarge { //white trash can (larger)
		display: inline-block;
		width: 16px;
		height: 18px;
		position: relative;
		background-position: -4px -390px;
	}


	&.trashCanQuick {
		display: block;
		width: 22px;
		height: 25px;
		margin: 0;
		background-position: -87px -1773px;
	}

	&.imageRotate {
		position: relative;
		display: inline-block;
		height: 17px;
		width: 15px;
		background-position: -64px -1850px;
	}

	&.enlarge { //two white arrows
		display: inline-block;
		width: 16px;
		height: 16px;
		position: relative;
		background-position: -54px -330px;
	}

	&.play { //play icon
		display: inline-block;
		width: 13px;
		height: 16px;
		position: relative;
		background-position: -106px -330px;
	}

	&.visibility { //white eye
		display: inline-block;
		width: 19px;
		height: 14px;
		position: relative;
		background-position: -128px -313px;
	}

	&.published { //cloud white
		width: 23px;
		height: 14px;
		display: inline-block;
		position: relative;
		background-position: -126px -331px;
	}

	&.snapshot { //huge red camera with a star
		width: 128px;
		height: 90px;
		display: inline-block;
		position: relative;
		background-position: 0 -972px;
	}

	&.camera { //camera white
		width: 18px;
		height: 14px;
		display: inline-block;
		position: relative;
		background-position: -104px -349px;
	}

	&.cameraLarge { //camera red
		width: 48px;
		height: 38px;
		display: block;
		position: relative;
		background-position: 0 -890px;
	}

	&.printer { //yellow printer
		display: inline-block;
		width: 16px;
		height: 15px;
		position: relative;
		background-position: -29px -331px;
	}

	&.shieldQuestion {
		width: 80px;
		height: 85px;
		display: block;
		position: relative;
		background-position: -68px -560px;
	}

	&.shieldHuman {
		@include sprite;

		width: 80px;
		height: 85px;
		display: block;
		position: relative;
		background-position: -68px -646px;
	}

	&.shieldThunder {
		@include sprite;

		width: 80px;
		height: 85px;
		display: block;
		position: relative;
		background-position: -68px -732px;
	}

	&.question {
		@include sprite;

		width: 16px;
		height: 16px;
		position: relative;
		background-position: -146px -1280px;
	}

	&.newsFeed {
		background-position: -100px -3px;
		width: 25px;
	}

	/* Member detail icons*/
	&.favorite,
	&.memberProfile,
	&.notes,
	&.memberMessage {
		display: block;
		position: relative;
	}

	&.favorite {
		width: 20px;
		height: 20px;
		background-position: 0 -1795px;
		&.active {
			background-position: 0 -1773px;
		}
	}

	&.favoriteHovered,
	&.favoriteActive{
		background-position: 0 -1773px;
	}

	&.memberProfile {
		width: 20px;
		height: 18px;
		background-position: -23px -1773px;
	}

	&.memberProfileHovered {
		background-position: -23px -1793px;
	}

	&.notes {
		width: 15px;
		height: 20px;
		background-position: -46px -1773px;
	}

	&.notesHovered {
		background-position: -46px -1795px;
	}

	&.memberMessage {
		margin: 3px 0 0;
		width: 20px;
		height: 14px;
		background-position: -64px -1773px;
	}

	&.profileOverlayLeft {
		width: 28px;
		height: 43px;
		background-position: -134px -972px;
	}

	&.profileOverlayRight {
		width: 28px;
		height: 43px;
		background-position: -134px -1021px;
	}

	&.periodSliderLineEnd { //Slider (pink pentagon with dots)
		display: block;
		width: 15px;
		height: 25px;
		top: -7px;
		background-position: -112px -935px;
		cursor: pointer;
		z-index: 2;
	}
}

.icon-birthday {
	display: inline-block;
	width: 20px;
	height: 21px;
	background: url('../App/gfx_icons_sprite.png') no-repeat -45px -450px;
	position: relative;
}

// position of the Help icon if there is a Warning (rejected) icon after the form element
.rejected + .icon.help {
	right: -60px;
}

.galleryArrow {
	transform: translate( -50%, -50%);
	left: 30%;
	top: 50%;
	position: absolute;
	opacity: .6;
	color: var(--text--base);
	font-size: 60px;

	&.left {
		transform: translate( 50%, -50%);
		right: 30%;
		left: auto;
	}

	&:hover{
		opacity: 1;
	}
}

/**
* Vector icons
*/
.icon-ok {
	color: var(--icon--ok--green);
	font-size: 20px;
}

.icon-ok-circle {
	color: var(--icon--ok--green);
	font-size: 20px;
}

.icon-error {
	color: var(--common--error);
	font-size: 20px;
}

.icon-hourglass {
	color: var(--common--disabled);
	font-size: 18px;
	margin-right: 10px;
	position: relative;
	top: 2px;
}

.icon-help {
	font-size: 20px;

	&--common {
		position: absolute;
		z-index: 3;
		top: 50%;
		right: -30px;
		cursor: pointer;
		margin: -9px 0 0;
		color: var(--icon--help--text);
		text-shadow: 0 1px var(--icon--help--shadow);
		transition: all 0.3s;

		&:hover {
			color: var(--icon--help--hover);
		}
	}
}

.msc-icon.icon-info-circle--common,
.icon-info-circle--common {
	transform: translateY(-50%);

	font-size: 18px;
	z-index: 3;
	color: var(--text--secondary-darker);
	cursor: pointer;
	position: absolute;
	top: 50%;
	right: -30px;
}


.msc-icon.icon-info-circle--common {
	font-size: 2.4rem;

	&:hover::before {
			content: icons.get('info-circular-solid');
			color: #{colors.get(neutral, 100)};
	} 
}

.msc-icon.transparentUnderlay {
	position: relative;

	&::before {
		position: relative;
		z-index: 1;
	}

	&:after {
		content: '';
		position: absolute;
		z-index: 0;
		left: 10%;
		top: 10%;
		width: 80%;
		height: 80%;
		border-radius: 50%;
		background: #{colors.get(neutral, 900, 0.4)};
	}
}

.radio-inactive,
.radio { //Empty red circle
	@include radioInactive;
}

.radio-active,
.radio.active { //Red circle with yellow center
	@include radioActive;
}

/**
* JASMIN ICONS
*/
.icon-close {
	color: var(--primary);
	font-size: 16px;
	position: relative;
	top: 3px;
}
.icon-check-0 {
	color: var(--common--success);
	font-size: 16px;
	position: relative;
	top: 3px;
}
.icon-exlamation-circle {
	color: var(--common--alert);
	font-size: 16px;
	position: relative;
	top: 3px;
}
.icon-angle-double-right {
	position: relative;
	top: 1px;
}

@import "icons.oranum";
@import '../../../scss/shell';

:global {
	#mobile-crop {
		position: relative;
		width: 806px !important;
		overflow: hidden;

		img {
			float: right;
			position: absolute;
			bottom: -95px;
			right: 0;
		}

		.box_header {
			font-size: 18px;
		}

		h3 {
			width: 60%;
			font-size: 28px;
			line-height: 1;
			margin: 3px 0 33px;
		}

		h4 {
			font-weight: normal;
			line-height: 1.4;
			margin: 0 0 13px;
			color: var(--mobile-crop-overlay--text) !important;

			strong {
				color: var(--mobile-crop-overlay--bold);
			}
		}

		.overlayBlock {
			padding: 55px 40px 60px;
			color: var(--mobile-crop-overlay--text);

			a {
				padding: 14px 30px;
			}

			strong {
				color: var(--mobile-crop-overlay--bold);
			}
		}

		p {
			font-size: 16px;
			display: inline-block;
			margin: 22px 0 25px;
		}

		ul {
			font-size: 16px;
			margin-bottom: 20px;
			display: inline-block;
			width: 100%;

			li {
				position: relative;
				padding: 0 0 26px 39px;

				span {
					position: absolute;
					top: -7px;
					left: -2px;
					display: inline-block;
					width: 30px;
					height: 30px;
					border: 2px solid var(--icon-in-circle--main);
					border-radius: 50%;
					color: var(--icon-in-circle--main);
					text-align: center;
				}

				i {
					position: relative;
					top: 5px;
					left: 3px;
					font-size: 15px;
					color: var(--icon-in-circle--main);
					background: -webkit-linear-gradient(top, var(--icon-in-circle--icon-gradient-start), var(--icon-in-circle--icon-gradient-end));
					-webkit-background-clip: text;
					-webkit-text-fill-color: transparent;

					&.icon-topic-picvid {
						font-size: 17px;
						top: 4px;
						left: 4px;
					}
				}
			}
		}

		img {
			position: absolute;
			bottom: -90px;
			right: 11px;
			width: 256px;
		}
	}
}

.mainstream-theme {
	.commonTabs {
		& > .tab {
			a {
				.icon-alert {
					color: var(--channel--box--icon-alert);
				}
			}
		}
	}
	.filterBox li {
		background-color: var(--common-tabs--link-bg);
		a.active {
			font-weight: bold;
		}
	}
}
@import '../../../scss/shell';

:global {

	#account-settings {
		width: 830px !important;

		.overlayBlock {
			text-align: center;
			display: flex;
			margin: 0 !important;
			padding: 0 !important;
		}

		.box_header {
			background: var(--account-settings--overlay-header);
			font-size: 1.6rem;
		}

		.menu {
			flex-basis: 27.7%;
			background: var(--account-settings--menu-background);

			li {
				border-bottom: 1px solid var(--account-settings--list-border);
				padding: 12px 0 13px 19px;
				line-height: 2;
				cursor: pointer;
				text-align: left;
				font-size: 15px;
				color: var(--account-settings--list-text);
				transition: all 0.2s ease;
				&:hover {
					color: var(--account-settings--list-text-hover);
				}
				&.active {
					background: var(--account-settings--list-active-background);
					color: var(--account-settings--list-active-text);
				}
				&:last-child {
					border: 0;
				}
			}
		}

		.alertBox {
			min-height: 5.6rem;
			overflow: hidden;
			div {
				height: 100%;
			}
		}

		.menu-content {
			flex-basis: 72.4%;
			text-align: center;
			display: none;
			min-height: 450px;
			width: 100%;

			label:not(.toggle-on-off) {
				min-width: 215px;
			}

			&.active {
				display: block;
			}

			label {
				font-size: 12px;
				text-align: left;
				line-height: 2.5;
			}

			.inputs label {
				width: 6rem;
				height: 2.6rem;
				display: inline-block;
				border-radius: 25px;
				top: -2px;
			}

			.ajax-checkbox {
				display: inline-block;
				position: relative;
				top: 8px;
				margin: 0 0 0 15px;
				label {
					min-width: 0;
					display: inline-block;
				}
			}

			.inputs {
				margin: 0 0 27px;
				a {
					padding: 10px 30px;
				}
			}

			.wrapper {
				display: inline-block;
				padding: 36px 0 30px 30px;
				float: left;
				width: 100%;
			}

			.separatorWithText {
				margin: 49px 0 0;
				height: 45px;
				padding: 0 33px;
				&:first-child {
					margin: 2px 0 0;
				}

				p {
					font-size: 16px !important;
					top: 0;
					padding: 0 31px 0 0;
					margin-left: 0;
				}
			}

			.privacy-rights {
				i {
					margin: 0 15px;
					top: 0.5rem !important;
				}

				label {
					line-height: 1;
				}
			}

			&[data-content="private-data"] {
				text-align: left;

				.details {
					position: relative;

					p {
						display: inline-block;
						font-size: 14px;
					}
					a {
						margin: 0 5px;
					}
				}

				.protip {
					position: relative;
					right: auto;
					margin: 0 20px 0 5px;
					top: 2px;
				}

				a {
					margin: 0 0 0 26px;
					padding: 10px 39px;
				}

				.note {
					margin: 2rem 0 4rem;
					font-size: 12px;
					font-weight: bold;
					color: var(--account-settings--note-text);
				}

				small {
					display: block;
					font-size: 10px;
					font-weight: normal;
					margin: 5px 0;
					width: 65%;
				}
			}

			&[data-content="data-settings"] {
				label {
					font-size: 12px;
					line-height: 1;
				}
			}

			.messengerSettings {
				.separatorWithText {
					height: 3rem;
				}

				label {
					min-width: auto;
					margin-right: 2.5rem;
					font-size: 1.4rem;
					line-height: 2.1;
					color: var(--account-settings--label);
				}

				.messengerSettingsText {
					font-size: 1.4rem;
					line-height: 1.4;
					padding-right: 3.3rem;
					color: var(--account-settings--text);
				}

				.inputs {
					margin-bottom: 1.4rem;
				}
			}
		}
	}

	#accountSettingsContent {
		padding: 27px 0 30px !important;
		margin: 0 !important;
		text-align: center;

		.info-box {
			color: var(--account-settings--list-text);
			margin: 0 4px 0 1px;
		}

		.form-input-container {
			position: relative;
			display: flex;
		}

		&.consent-revoke-start-process {
			padding: 18px 0 30px !important;

			span, p {
				font-size: 14px;
				line-height: 1.3;
				margin: 18px auto;
				color: var(--privacy-rights--text);
				strong {
					color: var(--privacy-rights--bold);
				}
				a {
					font-size: 14px;
				}
			}
			.content {
				width: 68%;
				padding: 0 20px;
				margin: auto;
				text-align: left;

				@include _on('small', 'down') {
					width: 90%;
				}
			}
			h2 {
				color: var(--privacy-rights--warning);
				margin: 22px 0 16px 0;
			}
			h4 {
				color: var(--privacy-rights--attention-text);
			}
			.attention {
				background: var(--privacy-rights--attention-bg);
				border-radius: 3px;
				padding: 20px;
				width: 68%;
				text-align: left;
				color: var(--privacy-rights--attention-text);
				strong {
					color: var(--privacy-rights--attention-text);
				}
			}
			.highlighted {
				color: var(--privacy-rights--bold);
				margin-top: 40px;
			}
			label.icon-check {
				width: 2rem;
				height: 2rem;
				top: 0;

				&:before {
					font-size: 1rem;
					top: 4px;
					left: 4px;
				}
			}
			ul {
				li {
					color: var(--privacy-rights--text);
					margin: 0 0 15px 0;
					font-size: 14px;
				}
			}
			.button[size="s"] {
				padding: 14px 33px;
				margin: 8px 0 20px;
			}
			.proceed-container {
				a {
					transition: opacity .5s;
					font-size: 12px;

					&.disabled {
						opacity: .4;
						pointer-events: none;
					}
				}
			}
			form {
				padding: 0!important;

				label.icon-check {
					margin-right: 10px;
				}

				label[ref="yieldLabel"] {
					padding: 2px 0 0 0;
					height: auto;
					line-height: 1.3;

					strong {
						color: var(--privacy-rights--bold);
					}
				}
			}
			.icon-alert {
				position: relative;
				display: inline-block;
				width: 87px;
				font-size: 87px;
				color: var(--privacy-rights--icon);
			}
		}

		&.forget-data-password-confirmation {
			h2 {
				margin-bottom: 35px;
			}
			h4 {
				font-size: 24px;
				margin-top: 30px;
				margin-bottom: 5px;
			}
			p {
				margin: 10px 0 0;
				font-size: 18px;
			}
			span {
				margin: 32px auto 3px auto;
				font-size: 14px;
				display: block;
			}
			ph-row {
				width: 70%;
				float: none;
				margin: 20px auto;
			}

			a.button {
				margin-right: 20px;
			}
		}

		&.confirmation-overlay {
			label {
				float: none !important;
			}
			form {
				padding: 0 30px;
			}
			.inputs {
				float: none;
				margin: 0;
			}
		}

		.overlayBlock {
			label {
				min-width: 180px;
			}
		}

		.changePasswordDescription,
		.changeEmailDescription {
			@include style('text', 'largeSize', 'regularColor');
		}

		.changePasswordDescription,
		.changeEmailDescription {
			margin: 0 0 36px 0;
		}

		.accountSettingsLabel {
			margin: 0 14px 0 0;
		}

		.changepassLabel,
		.changeEmailLabel {
			width: 361px;
		}

		.inputs {
			margin: 0 0 20px;

			.secondaryLarge {
				margin: 4px 0;
			}
		}

		.pageBottomContainer {
			width: 100%;
			text-align: center;
			margin: 32px 0 0 0;
		}
	}

	@import "AccountSettings.oranum";
}
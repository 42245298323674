.loader {
	display: inline-block;

	&::before {
		position: absolute;
		content: '';
		top: 50%;
		left: 50%;
		margin: -.64285em 0 0 -.64285em;
		width: 1.2857em;
		height: 1.2857em;
		border-radius: 500rem;
		border: .2em solid RGBA(0,0,0,.15);
	}

	&::after {
		position: absolute;
		content: '';
		top: 50%;
		left: 50%;
		margin: -.64285em 0 0 -.64285em;
		width: 1.2857em;
		height: 1.2857em;
		animation: button-spin .6s linear;
		animation-iteration-count: infinite;
		border-radius: 500rem;
		border-color: #fff transparent transparent transparent;
		border-style: solid;
		border-width: .2em;
	}
}


@-webkit-keyframes button-spin {
	from {
		transform: rotate(0);
	}
	to {
		transform: rotate(360deg);
	}
}
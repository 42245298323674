@import '../../../scss/shell';
@import './../TwoWayAudioSettings/TwoWayAudioSettings.scss';

:global {
	.cam2CamContent {
		.mac {
			background: url('./Cam2CamBg.png') calc(100% + 13rem) -3rem no-repeat;

			@include _on('mobile') {
				background: url('./Cam2CamBg.png') center calc(100% + 30vw) no-repeat;
				background-size: contain;
			}
		}
	}
}
@use 'global/colors';

:global body#supplementalservicesagreement_index.mainstream-theme {
	#main_content {
		position: relative;
	}

	hamburger {
		visibility: hidden;
	}
}

.container {
	position: absolute;
	top: 20px;
	bottom: 20px;
	left: 0;
	right: 0;
	display: flex;
	flex-direction: column;
	justify-items: center;
	align-items: center;

	@media (max-width: 600px) {
		position: initial;
	}

	@media (max-width: 1024px) and (orientation: landscape) {
		position: relative;
	}
}

.content {
	display: flex;
	flex-direction: column;
	background: var(--box--main-bg);
	border-radius: 3px;
	box-shadow: 0 0 10px 0 #{colors.get(neutral, 900, 0.1)};
	max-width: 90vw;
	padding: 0 10px;
	width: 1190px;
	text-align: center;
	height: 100%;

	@media (max-width: 600px) {
		max-width: 100vw;
		box-shadow: none;
		margin: 0;
	}

	h1 {
		font-size: 20px;
		font-weight: normal;
		line-height: 30px;
		color: var(--box--secondary--text);
		margin: 40px 0 0;
		padding: 0 30px;

		@media (max-width: 600px) {
			margin: 20px 0 0;
		}

		strong {
			font-size: 24px;
			line-height: 30px;
			display: block;
			margin-top: 5px;
			text-transform: uppercase;
			color: var(--h2-color);
		}

		& + p {
			font-size: 14px;
			line-height: 19px;
			margin: 20px auto 0 auto;
			color: var(--box--secondary--text);
			max-width: 450px;
		}
	}

	&.done {

		@media (max-width: 600px) {
			margin: 20px 0;
			max-width: calc(100vw - 40px);
		}

		h1 {
			margin-top: 60px;

			@media (max-width: 600px) {
				padding: 0;
			}
		}

		p {
			font-size: 18px;
			line-height: 24px;
			margin-top: 30px;
		}

		a {
			font-size: 12px;
			line-height: 16px;
			margin-top: 30px;
			margin-bottom: 60px;
			font-weight: bold;
		}

		.agreement {
			p {
				font-size: 14px;
				line-height: 19px;
			}
		}
	}
}

.agreement {
	margin: 30px 0;
	overflow: auto;
	flex: 1;
	align-self: center;
	max-width: 700px;
	border-radius: 3px;
	border: 1px solid #{colors.get(neutral, 300)};
	background: var(--box--info-bg);
	padding: 20px 28px;

	/* Firefox only */
	scrollbar-width: thin;
	scrollbar-color: #{colors.get(neutral, 900, .2)} var(--box--info-bg);

	@media (max-width: 600px) {
		padding: 10px;
	}

	&::-webkit-scrollbar {
		width: 22px;
		background-color: var(--box--info-bg);
	}

	&::-webkit-scrollbar-thumb {
		background: #{colors.get(neutral, 900, .2)};
		background-clip: padding-box;
		width: 8px;
		opacity: .2;
		border-radius: 12px;
		border: 7px solid #{colors.get(neutral, 900, 0)};
	}

	h2 {
		font-size: 18px;
		line-height: 30px;
		text-transform: uppercase;
	}

	h3 {
		font-size: 15px;
		text-transform: uppercase;
	}

	p {
		display: block;
		text-align: left;
		font-size: 14px;
		line-height: 19px;
		margin-bottom: 25px;
		color: var(--box--secondary--text);
	}

	ul {
		list-style-type: disc;
		margin-bottom: 25px;
	}
	li {
		text-align: left;
		font-size: 14px;
		line-height: 19px;
		color: var(--box--secondary--text);
		margin-left: 18px;
		margin-bottom: 5px;
	}
}

.buttonWrapper {
	text-align: center;
	margin: 0 0 30px;

	p {
		margin: 10px auto 0 auto;
		color: var(--box--secondary--text);
		font-size: 12px;
		line-height: 16px;
		max-width: 280px;
	}
}

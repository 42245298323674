.oranum-theme {
	.guide {
		.orderNum {
			background: var(--first-login-tutorial--color-1);
			background: linear-gradient(45deg, var(--first-login-tutorial--color-1) 0%, var(--first-login-tutorial--color-2) 100%);
		}
	}

	.inputs {
		.clearLnk {
			padding-top: 0.7rem;
			padding-bottom: 0.7rem;

			&:hover .icon-close {
				color: var(--tooltip--close-hover);
			}
		}

		.icon-close {
			color: var(--tooltip--close);
			font-size: 1.3rem;
		}
	}
}
@import '../../../scss/shell';

:global {
    #promotionPeriodResetOverlay {
        .box_header {
            background: none;

            .closeButton i {
                text-shadow: none;
            }
        }

        form {
            text-align: center;
            padding: 2rem 0 6rem;
        }

        h4 {
            margin-bottom: 3.5rem;
        }

        p {
            margin: 0 8.5rem 2.3rem;
            font-size: 1.4rem;
            line-height: 1.3;

            span {
                color: var(--text--link);
                font-weight: bold;
            }

            &.highlightedText {
                font-weight: bold;
                margin-bottom: 3.3rem;
            }
        }

        button {
            margin: 1.2rem 1rem 0;
            padding: 1.3rem 3rem;
        }

        @include _on('mobile') {
            width: 100% !important;
            max-width: 100%;
            border-radius: 2rem 2rem 0 0;
            position: fixed;
            bottom: 0;
            left: 0;
            padding: 1rem 2rem 3rem;
            transform: scale(1);
            animation: moveUp 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;

            &::before {
                content: '';
                display: block;
                width: 6rem;
                height: 0.4rem;
                margin: 0 auto;
                background: var(--overlay--mobile-border);
                border-radius: 0.2rem;
            }

            form {
                padding: 2rem 0 0;
            }

            p {
                margin: 0 0 2.3rem;
            }

            .box_header .closeButton {
                display: none;
            }

            button {
                width: 100%;
                margin: 1rem 0;
            }

            form-submit > div {
                display: block;
            }
        }
    }
}
@keyframes :global(moveUp) {
    0% {
        transform: translateY(15rem);
    }
    100% {
        transform: translateY(0);
    }
}